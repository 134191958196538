import { all, call, delay, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { InvoiceReportsActionTypes, changeLoading, changeLoadingGenerate, changeParams, changeReports, changeSetOpenModal, reportsPolling } from "../reducers/invoices-reports.reducer";
import { IState } from "../reducers/reducers";
import { IChangedReports, IReport, ReportStatus } from "../models/report.model";
import { deleteReport, generateInvoicesReport, getInvoicesReports } from "../utils/webApi";
import handleError from "../utils/handleError";
import { requestUserData } from "../reducers/reports.reducer";
import { isEmpty } from "../utils/utils.functions";

function* requestReports() {
    try {
        const { status, order_by, page, pageSize, to, from } = yield select((state: IState) => state.invoicesReports);        
        yield put(changeLoading(true));
        const { reports, total } = yield call(getInvoicesReports, {
            status: status ? [status] : undefined,
            page,
            pageSize,
            order_by,
            to: to ? new Date(to) : undefined,
            from: from ? new Date(from) : undefined
        });
        yield put(changeReports(reports));
        yield put(changeParams({total}));
        yield put(reportsPolling());
        yield put(requestUserData());
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
}

function* requestGenerateReport() {
    try {
        yield put(changeLoadingGenerate(true));
        const { reports, statusGenerate, pageSize, isPolling, total, initialData, endData } = yield select((state: IState) => state.invoicesReports);
        const report: IReport = yield call(generateInvoicesReport, initialData, endData, statusGenerate);
        const newReports = [{...report, isLocal: true}, ...reports].slice(0,pageSize);
        
        yield put(changeReports(newReports));
        yield put(changeParams({total: total + 1}));
        yield put(changeSetOpenModal(false));
        if (!isPolling) {
            yield put(reportsPolling())
        }
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoadingGenerate(false));
    };

}

function* pollingForStatus() {
    try {
        const processingKeys = [ReportStatus.CREATED, ReportStatus.PROCESSING];

        yield delay(4000);
        const { reports } = yield select((state: IState) => state.invoicesReports);
        const processingLocalReports = reports.filter((r: IReport) => processingKeys.includes(r.status));

        if(processingLocalReports.length > 0) {
            const response: {reports: IReport[]} = yield call(getInvoicesReports, {status: processingKeys, page:1, pageSize: 1000});
            let changedReports: IChangedReports = {};
            for (let report of processingLocalReports) {
                const rep = response.reports.find((r: IReport) => r.id === report.id);

                if(rep && rep.status !== report.status) {
                    changedReports = {
                        ...changedReports,
                        [rep.id]: rep.status,
                    }
                } else if(!rep && !report.isLocal) {
                    changedReports = {
                        ...changedReports,
                        [report.id]: ReportStatus.READY
                    }
                }
            }

            if(!isEmpty(changedReports)) {
                yield put(changeReports(reports.map((r: IReport) => ({
                    ...r,
                    isLocal: false,
                    status: changedReports[r.id] || r.status,
                }))));
            }
            yield put(reportsPolling());
        }
    } catch (err) {
        handleError(err);
    }
}

function* requestDelete({payload}: {type: string, payload: string}) {
    const { reports } = yield select((state: IState) => state.invoicesReports);
    try {
        const filteredReports = reports.filter((el :IReport) => el.id !== payload);
        yield put(changeReports(filteredReports));
        yield call(deleteReport, payload);
    } catch (error) {
        yield put(changeReports(reports));
        handleError(error);
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeLatest(InvoiceReportsActionTypes.ASYNC_GENERATE_INVOICES_REPORT, requestGenerateReport),
        yield takeEvery(InvoiceReportsActionTypes.ASYNC_GET_INVOICES_REPORTS, requestReports),
        yield takeLatest(InvoiceReportsActionTypes.POLLING_FOR_STATUS, pollingForStatus),
        yield takeEvery(InvoiceReportsActionTypes.ASYNC_DELETE_REPORT, requestDelete),
    ]);
}