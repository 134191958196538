import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Pagination, Icon } from 'antd';
import { controlRegisterUnitsPage, controlRegisterUnitsPageSize,
    asyncGetUnits, controlRegisterUnitsIsOpen } from '../../../reducers/register-units.reducer';
import { IState } from '../../../reducers/reducers';
import Loading from '../../../components/loading/Loading';
import RegisterUnitsModal from '../register-units-modal/RegisterUnitsModal';
import { IUnits } from '../../../models/register-units.model';

import './RegisterUnitsList.scss';

const RegisterUnitsList = () => {

    const { units, isLoading, page, pageSize, search, total  } = useSelector(({ registerunits }: IState)=> registerunits);

    const [isEdit, setIsEdit] = useState(false);
    const [id, setId]:any = useState(null);

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(asyncGetUnits());
    // eslint-disable-next-line
    }, [page, pageSize, search])

    const columns = [
        {
            title: 'Núcleo',
            dataIndex: 'name',
            key: 'nome'
        },
        {
            title: 'cidade',
            dataIndex: 'city',
            key: 'cidade'
        },
        {
            title: 'estado',
            dataIndex: 'state',
            key: 'estado'
        },
        {
            title: 'país',
            dataIndex: 'country',
            key: 'pais'
        },
        {
            title: 'ativo',
            key: 'active',
            render: (units: IUnits) => <span>{units.active ? <Icon className='anticon-check-units' type='check' /> : <Icon className='anticon-close-units' type='close' />}</span>
        },
        {
            title: 'ações',
            key: 'ações',
            render: (units: IUnits) => {
                return (
                    <div>
                        <span><Icon onClick={()=>{
                            setIsEdit(true);
                            setId(units.id);
                            dispatch(controlRegisterUnitsIsOpen(true));
                        }} type='edit'/></span>
                    </div>
                )
            }
        }
    ]

    return (
        <>
            <Table
                className='register-units-list'
                dataSource={units}
                columns={columns}
                pagination={false}
                rowKey={(units: IUnits)=> `${units.id}`} />
            
            {isLoading && <Loading/>}

            <RegisterUnitsModal isEdit={isEdit} id={id} setId={setId} setEdit={setIsEdit} />

            <Pagination
                className='register-units-pagination'
                showSizeChanger
                current={page}
                onChange={(page)=> dispatch(controlRegisterUnitsPage(page))}
                onShowSizeChange = {(current, size) => {
                    if(current !== 1 ) dispatch(controlRegisterUnitsPage(1));
                    dispatch(controlRegisterUnitsPageSize(size));
                }}
                pageSizeOptions={['10','25','50','100']} 
                total={total} />
        </>
    )
};

export default RegisterUnitsList;