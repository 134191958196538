import React from 'react';
import { IResource, ResourceTypes, ExerciseTypes, FileTypes } from '../../../models/resources.model';
import { Table } from 'antd';
import ColumnSearchProps from '../../../components/column-search/column-search';

interface IResourceListProps {
  resources: IResource[],
  isLoading: boolean,
  onSelectResource(resource: IResource): void
};

const ResourcesList = ({ resources, isLoading, onSelectResource }: IResourceListProps) => {

  const getFilterTypes = () => {
    return Object.keys(ResourceTypes)
      .map((key: any) => ({ text: key, value: (ResourceTypes as any)[key] }));
  };

  const getFilterSubTypes = () => [{
    text: 'EXERCÍCIOS',
    value: 'subExercise',
    children: Object.keys(ExerciseTypes).map((key: any) => ({ text: key, value: (ExerciseTypes as any)[key] }))
  }, {
    text: 'ARQUIVOS',
    value: 'subFile',
    children: Object.keys(FileTypes).map((key: any) => ({ text: key, value: (FileTypes as any)[key] }))
  }]

  const columns = [
    {
      title: 'ID',
      dataIndex: 'Id',
      key: 'Id',
      sorter: (a: IResource, b: IResource) => a.Id > b.Id ? 1 : -1,
      ...ColumnSearchProps('Id')
    },
    {
      title: 'Tipo',
      dataIndex: 'Type',
      key: 'Type',
      filters: getFilterTypes(),
      onFilter: (value: string, record: IResource) => record.Type.indexOf(value) === 0,
      sorter: (a: IResource, b: IResource) => a.Type > b.Type ? 1 : -1,
    },
    {
      title: 'Sub Tipo',
      dataIndex: 'Properties.Type',
      key: 'Properties.Type',
      filters: getFilterSubTypes(),
      onFilter: (value: string, record: IResource) => (record.Properties.Type || '').indexOf(value) === 0,
      sorter: (a: IResource, b: IResource) => a.Properties.Type > b.Properties.Type ? 1 : -1,
    },
    {
      title: 'Obsoleto',
      dataIndex: 'IsObsolete',
      key: 'IsObsolete',
      render: (_: any, { IsObsolete }: IResource) => (<span>{IsObsolete ? 'Sim' : 'Não'}</span>),
      sorter: (a: IResource, b: IResource) => a.IsObsolete === b.IsObsolete ? 1 : -1,
    },
  ];

  return (
    <Table
      onRow={(record: IResource) => ({
        onClick: event => onSelectResource(record), // click row
      })}
      className="table-resources"
      loading={isLoading}
      columns={columns}
      dataSource={resources.map((resource: IResource) => ({ ...resource, key: resource.Id }))}
    />
  )
};

export default React.memo(ResourcesList);