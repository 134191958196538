import { takeEvery, all, put, call, select } from 'redux-saga/effects';
import handleError from '../utils/handleError';
import { getPanelClassesClasses, getPanelClassesUnits, getPanelFilter } from '../utils/webApi';
import { IState } from '../reducers/reducers';
import { PanelControlClassesActioTypes, setClasses, setCoacheAccessesSCIS, setCoachesByLicenseType, setCoachesByStatus, setCoachesTotal, setIsLoading, setUnits } from '../reducers/panelcontrolclasses.reducer';
import {  IClass, IRequestClasses, queryStringGetUnitAndClass } from '../models/panelcontrolClasses.model';
import _ from 'lodash';

function* requestAllUnits(){
    try {
        yield put(setIsLoading(true));
        const { centers } = yield call(getPanelClassesUnits);

        yield put(setUnits([{
            id: '',
                name: 'Todas as unidades'
            }, {
                id: 'true',
                name: 'Tem unidade'
            }, {
                id: 'false',
                name: 'Não tem unidade'
            }, ..._.sortBy(centers, 'name')])
        );
    } catch (err){
        handleError(err);
    } finally {
        yield put(setIsLoading(false));
    }
}

function* requestAlltClasses() {
    try {
        yield put(setIsLoading(true));

        const { classes } = yield call(getPanelClassesClasses);
        const notNullAbleClasses = classes.filter((e: IClass) => e.startDate)
        const nullAbleClasses = classes.filter((e: IClass) => !e.startDate).sort((a: IClass, b: IClass) => b.className.trim().localeCompare(a.className.trim()))
        let sortedClasses = notNullAbleClasses.sort((a: IClass, b: IClass) => (new Date(b.startDate).getTime()) - (new Date(a.startDate).getTime()));
        let newClasses = [...sortedClasses, ...nullAbleClasses];

        yield put(setClasses(newClasses));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    };
};

function* requestFilterByClassAndUnit() {
    try {
        yield put(setIsLoading(true));

        const { filterClass, filterUnit } = yield select(({ panelcontrolclasses }: IState)=> panelcontrolclasses );
        const response: IRequestClasses = yield call(getPanelFilter, queryStringGetUnitAndClass({ filterClass: filterClass.length > 0 ? filterClass : undefined, filterUnit }));

        yield put(setCoachesTotal(response.totalCoaches));
        yield put(setCoacheAccessesSCIS(response.totalAccess));
        yield put(setCoachesByStatus(response.coachesByStatus))
        yield put(setCoachesByLicenseType(response.coachesByLicenseType))

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    };
}

export default function* mySaga() {
    yield all([
        takeEvery(PanelControlClassesActioTypes.ASYNC_GET_UNITS, requestAllUnits),
        takeEvery(PanelControlClassesActioTypes.ASYNC_GET_CLASSES, requestAlltClasses),
        takeEvery(PanelControlClassesActioTypes.ASYNC_GET_FILTER_BY_CLASS_AND_UNIT, requestFilterByClassAndUnit),
    ])
}
