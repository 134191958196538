import React from 'react';
import { useSelector } from 'react-redux';

import { ICenter, ICountry } from '../../../models/utils.model';
import StepsComponent, { IStep } from '../../../components/steps/steps';

import CoacheInfo from './coache-infos/CoacheInfos';
import CoacheEmail from './coache-email/CoacheEmail';

import './CoacheCreate.scss';
import '../coache-edit/CoacheEdit.scss';
import { IState } from '../../../reducers/reducers';
import Loading from '../../../components/loading/Loading';

interface CoacheCreate {
    onChange(name: string, value: any): void,
    coachEdit: any,
    centers: ICenter[],
    canBecomeCoach: boolean,
    countries: ICountry[]
}

const CoacheCreate = ({ onChange, coachEdit, centers, canBecomeCoach, countries }: CoacheCreate) => {

    const steps: IStep[] = [{
        description: 'Informe o email do usuário',
        title: 'Email'
    }, {
        description: 'Cadastro',
        title: 'informações de cadastro'
    }];

    const { currentSteps, loading } = useSelector(({ CreateCoaches }:IState)=> ({ currentSteps: CreateCoaches.currentSteps, loading: CreateCoaches.isLoadingModal }));

    return (
        <>
            { loading && <Loading/> }
            
            <StepsComponent classType={'remove-cursor'} current={currentSteps} onChange={()=>{}} steps={steps} isChange={false} />

            { currentSteps === 0 && <CoacheEmail coachEdit={coachEdit} onChange={onChange} />}

            { currentSteps === 1 && <CoacheInfo onChange={onChange} coachEdit={coachEdit} centers={centers} canBecomeCoach={canBecomeCoach} countries={countries} />}

        </>
    )
};

export default CoacheCreate;