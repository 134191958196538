import React, {useCallback} from 'react';
import { Form, Button, Select } from 'antd';
import Search from 'antd/lib/input/Search';
import moment from 'moment-timezone';

import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';
import { PaymentTypes } from '../../../models/payment.model';

import './SearchFilter.scss';

const fieldsOptions: { label: string, value: PaymentTypes }[] = [
    { label: 'Pix', value: PaymentTypes.PIX },
    { label: 'Cielo', value: PaymentTypes.CIELO },
    { label: 'Boleto', value: PaymentTypes.BOLETO },
    { label: 'PagSeguro', value: PaymentTypes.PAGSEGURO },
    { label: 'Paypal', value: PaymentTypes.PAYPAL },
    { label: 'MundiPagg', value: PaymentTypes.MUNDIPAGG },
    { label: 'Experimental', value: PaymentTypes.EXPERIMENTAL },
    { label: 'Ajuste', value: PaymentTypes.AJUSTE },
    { label: 'Legado', value: PaymentTypes.LEGADO },
    { label: 'Avulso', value: PaymentTypes.AVULSO },
    { label: 'Outro', value: PaymentTypes.OUTRO },
];

const { Option } = Select;

interface IReportSearchFilterProps {
    onChangeFilter: (key: string, value?: string) => void;
    onSearch: (bool?: boolean) => void;
    from?: string;
    to?: string;
    search?: string;
    typesFields: PaymentTypes[];
    changeTypeFields: (fields: PaymentTypes[]) => void;
    requestXslxCoachesPayments: () => void;
    loadingXlsx: boolean,
};

const SearchFilter = ({
    onChangeFilter,
    from,
    to,
    onSearch,
    search,
    typesFields,
    changeTypeFields,
    requestXslxCoachesPayments,
    loadingXlsx,
}: IReportSearchFilterProps) => {

    const cleanFrom = useCallback(() => onChangeFilter('from', undefined), [onChangeFilter]);
    const cleanTo = useCallback(() => onChangeFilter('to', undefined), [onChangeFilter]);

    const changeFrom = useCallback((_: string, from: string) => onChangeFilter('from', moment(from).toISOString()), [onChangeFilter]);
    const changeTo = useCallback((_: string, to: string) => onChangeFilter('to', moment(to).endOf('day').toISOString()), [onChangeFilter]);

    return (
        <Form className="filter-content2">
            <div className="input-filter input-filter-search-all-payments">
                <label>Buscar</label>
                <Search
                    value={search}
                    className="search-input"
                    placeholder="Buscar"
                    style={{ width: '100%', minWidth: '100px', maxWidth: '100%'}}
                    onSearch={() => onSearch()}
                    onChange={({ target }) => onChangeFilter('search', target.value)} />
            </div>

            <div className="input-filter input-filter-all-payments">
                <label>Tipo de Pagamento</label>
                <Select
                    mode="multiple"
                    placeholder="Todos"
                    maxTagCount={1}
                    allowClear={true}
                    value={typesFields && typesFields.length > 0 ? typesFields : undefined}
                    onChange={(fields: PaymentTypes[]) => changeTypeFields(fields)}>

                    {fieldsOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="div-input-date" >
                <span>Início:</span>
                <MaskedDatePicker
                    onChange={changeFrom}
                    currentValue={from || ''}
                    stringType={'enrollment_date'}
                    classText="input-date"
                    placeholder=""
                    onClean={cleanFrom}
                />
            </div>
            <div className="div-input-date" >
                <span>Fim:</span>
                <MaskedDatePicker
                    onChange={changeTo}
                    currentValue={to || ''}
                    stringType={'enrollment_date'}
                    classText="input-date"
                    placeholder=""
                    onClean={cleanTo}
                />
            </div>
            <Button
                onClick={() => onSearch()}
                className="btn-search"
                type="primary"
                icon="search">
                Pesquisar
            </Button>
            <Button
                onClick={() => {
                    requestXslxCoachesPayments();
                    onSearch(true);
                }}
                className="btn-search"
                type="primary"
                disabled={loadingXlsx}
                loading={loadingXlsx}
            >
                Exportar
            </Button>
        </Form>
    )
};

export default SearchFilter;
