import { IBlockList } from "./blockList.model"

export interface ICoachee {
    id: string,
    email: string,
    phone_number: string,
    given_name: string,
    cpf: string,
    family_name: string,
    nickname: string,
    birthdate: string,
    address: IAddress,
    locale: string,
    zoneinfo: string,
    enable: boolean,
    center_id: string,
    trainer_id: string,
    email_verified?: boolean,
    enabled?: boolean,
    phone_number_verified?: boolean,
    created_at?: string,
    updated_at?: string,
    personal_ids: IPersonalIDs[],
    coachee: any,
    convert_coach?: boolean,
    enrollment?: string,
    enrollment_date?: string,
    phones: string[]
};

export interface ICoacheeDetails {
    CoachId: string,
    CoacheeId: string,
    CoachsCoacheeId: string,
    DataNascimento: string,
    DataUltimaSessao: string,
    Email: string,
    Habilitado: boolean
    Img?: string,
    ImgCoach: string,
    LastChanged: string,
    MessageId: string,
    NomeCoach: string,
    NomeCompleto: string,
    NomeUsual: string,
    NumeroSessoes: number
    Sexo: 0 | 1,
    Status: 'PendingCognito' | 'PendingConfirmation' | 'Confirmed' | 'Removed',
    Telefone?: string,
    blocklist: IBlockList,
    CoachEmail?: string,
};

export interface IUnit {
    id: string,
    name: string,
};

export interface IPersonalIDs {
    country: string,
    docs: IDocsInfo[],
}

export interface IDocsInfo {
    name: string
    value: string
}


export enum SearchFields {
    ID = 'id',
    EMAIL = 'email',
    PHONE_NUMBER = 'phone_number',
    NICKNAME = 'nickname',
    GIVEN_NAME = 'given_name',
    FAMILY_NAME = 'family_name',
};

export enum OrderMode {
    ASC = 'asc',
    DESC = 'desc',
};

export interface IAddress {
    street_address: string,
    locality: string,
    region: string,
    postal_code: string,
    country: string
};

interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string,
    search_field: SearchFields[],
    order: SearchFields,
    order_mode: OrderMode,
}

export const queryStringList = ({ page, pageSize, search, search_field, order, order_mode }: IQueryStringList) => {
    let params = [];

    if (page) {
        params.push(`page=${page - 1}`);
    }

    if (pageSize) {
        params.push(`page_size=${pageSize}`);
    }

    if (search) {
        params.push(`search=${window.encodeURIComponent(search)}`);

        if (!search_field || search_field.length === 0) {
            search_field = Object.values(SearchFields);
        }

        for (const field of search_field) {
            params.push(`search_field=${field}`);
        }
    }

    params.push(`order=${order}`);
    params.push(`order_mode=${order_mode}`);

    return `?${params.join('&')}`;
};
