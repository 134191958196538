export interface IInternalLinkItem {
    id: string;
    enabled: boolean;
    audits: IAudit[];
    url: string;
    status: string;
    nickname: string;
    email: string;
    productType: string;
    observation?: string;
}

export interface ICheckout {
    url: string;
}
export interface IAudit {
    userId: string;
    linkId: string;
    createdAt: string;
    oldStatus: string;
    auditUserId: string;
    newStatus: string;
    auditEmail: string;
    auditName: string;
    coachName: string;
    coachEmail: string;
    observation: string;
}

export interface IInternalLinkListItem {
    id: string;
    createdAt: string;
    updatedAt: string;
    productId: string;
    title: string;
    linkId: string;
    url: string;
    description: string;
    typeCustomer: string;
    status: string;
    value: number;
    salesInterest: boolean;
    enabled: boolean;
    reducedCheckout: boolean;
    credit: {
        maxInstallments: number;
        allowTwoCards: boolean;
    };
    boleto: {
        enabled: boolean;
        conditional: boolean;
        daysToExpire: number;
        instructions: string;
    };
    pix: {
        enabled: boolean;
        secondsToExpire: number;
    };
}

export interface IInternalLinkStore {
    internalLinks: IInternalLinkListItem[];
    isLoading: boolean;
    search: string;
    search_field: SearchFields[];
    pageSize: number;
    total: number;
    nextPage: string | null;
    previousPage: string[];
    orderMode: string;
    filter_statuses?: string;
    internalLink?: IInternalLinkListItem & IInternalLinkItem;
    currentPage: number;
    itemsPerPage: number;
}

export enum SearchFields {
    TITLE = 'title',
    URL = 'url',
    DESCRIPTION = 'description',
}

export enum OrderMode {
    ASC = 'asc',
    DESC = 'desc',
}

export interface IQueryStringList {
    pageSize: number;
    search: string;
    nextPage?: string;
    orderMode?: string;
    page?: number;
    status?: string;
}

export interface IRequest {
    results: IInternalLinkItem[];
    total: number;
}

export const queryStringList = ({
    pageSize,
    search,
    nextPage,
    orderMode,
    status,
    page, // Add page parameter here
}: IQueryStringList) => {
    let params = [];

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }
    if (status) {
        params.push(`status=${status}`);
    }

    if (search) {
        params.push(`search=${encodeURIComponent(search)}`);
    }
    
    if (nextPage) {
        params.push(`nextPage=${nextPage}`);
    }

    if (orderMode) {
        params.push(`orderMode=${orderMode}`);
    }

    if (page) {
        params.push(`page=${page}`);
    }

    return `?${params.join('&')}`;
}