import React from 'react';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { IState } from '../../reducers/reducers';
import { IUser } from '../../models/user.model';
import { saveUserApi, changeUserEdit } from '../../reducers/users.reducer';
import UserEdit from '../../containers/users/user-edit/UserEdit';

interface IEditUser {
    onSaveListUpdate?: () => void,
};

const EditUser = ({onSaveListUpdate}: IEditUser): any => {
    const {
        userEdit,
        isLoadingSave,
    } = useSelector((state: IState) =>({isLoadingSave: state.users.isLoadingSave, userEdit: state.users.userEdit}));

    const dispatch = useDispatch();

    function onSaveUser(user: IUser) {
        dispatch(saveUserApi(user, onSaveListUpdate));
    }

    return Boolean(userEdit) && (
        <Modal
            width={700}
            title={
                Boolean(userEdit && userEdit.id)
                    ? 'Editar Usuário'
                    : 'Adicionar Usuário'
            }
            visible={true}
            footer={false}
            maskClosable={false}
            onCancel={() => dispatch(changeUserEdit(null))}
        >
            <UserEdit
                onOk={onSaveUser}
                onCancel={() => dispatch(changeUserEdit(null))}
                user={userEdit as IUser}
                loading={isLoadingSave}
                isEdit={Boolean(userEdit && userEdit.id)}
            />
        </Modal>
    )
}

export default EditUser;