export interface IDashboardData {
    value: number
}

export interface IDashboardDataArray {
    data: []
}

export interface IPanelcontrol {
    data: IDashboardData[]
}

export interface IPanelControlStore {
    panelControl: IDashboardData[][],
    panelControlCoaches: IDashboardData[][],
    isLoading: boolean
}

export const queryStringList = ( type : string) =>
    `admin/dashboard?type=${type}`

export const queryStringList2 = ( type : string) =>
    `/admin/dashboard/getvaluestats?type=${type}`
