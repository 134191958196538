import React, { useState, useMemo, useEffect } from 'react';
import {useSelector} from 'react-redux';
import {Checkbox, Row, Col, Tooltip, Divider} from 'antd';
import {CheckboxChangeEvent} from 'antd/lib/checkbox';
import {CheckboxValueType} from 'antd/lib/checkbox/Group';

import {FormTypeValue, IAdmin, IPermissionType} from '../../../../models/administrator.model';
import {IState} from '../../../../reducers/reducers';

import './PermissionForm.scss';

interface IPermissionsFormProps {
    adminToEdit: IAdmin,
    onChange(name: string, value: FormTypeValue): void,
}

function PermissionForm({adminToEdit, onChange}: IPermissionsFormProps) {
    const allPermissions = useSelector(({administrators}: IState) => administrators.allPermissions);

    const allPermissionsObj = useMemo(() => allPermissions.reduce((accumulator, newValue) => {
        accumulator[newValue.category] = [...accumulator[newValue.category] || [], newValue];
        return accumulator;
    }, {} as Record<string, IPermissionType[]>), [allPermissions]);

    function handleChangePermissions(permissions: CheckboxValueType[]) {
        onChange('permissions', permissions);
    };

    return (
        <div className='permissions-container'>
            {Object.keys(allPermissionsObj).map(category => {
                return (
                    <PermissionGroup
                        permissions={allPermissionsObj[category]}
                        category={category}
                        userPermissions={adminToEdit.permissions || []}
                        onChange={handleChangePermissions}
                        key={category}
                    />
                );
            })}
        </div>
    );
};

interface IPermissionGroupProps {
    permissions: IPermissionType[],
    category: string,
    userPermissions: string[],
    onChange(permissions: CheckboxValueType[]): void,
}

function PermissionGroup({permissions, category, userPermissions, onChange}: IPermissionGroupProps) {
    const [checkAll, setCheckAll] = useState(false);
    const allPermissionByCategory = permissions.map((item: IPermissionType) => item.permission);

    useEffect(() => {
        if (allPermissionByCategory.every(p => userPermissions.includes(p))) {
            setCheckAll(true);
        } else {
            setCheckAll(false);
        }
    }, [userPermissions]); // eslint-disable-line

    function handleCheckAllChange(event: CheckboxChangeEvent) {
        if (event.target.checked) {
            onChange(Array.from(new Set([...userPermissions, ...allPermissionByCategory])));
        } else {
            onChange(userPermissions.filter(p => !allPermissionByCategory.includes(p)))
        }
        setCheckAll(event.target.checked);
    }

    function handlec(event: CheckboxChangeEvent) {
        if (event.target.checked) {
            onChange([...userPermissions, event.target.value]);
        } else {
            onChange(userPermissions.filter(p => p !== event.target.value))
        }
    }

    return (
        <div>
            <Row>
                <Tooltip title='Selecionar todos da categoria'>
                    <Checkbox
                        checked={checkAll}
                        value={category}
                        onChange={handleCheckAllChange}
                    >
                        <span className='category-indicator'>{category}</span>
                    </Checkbox>
                </Tooltip>
            </Row>

            <Row>
                {permissions.map((item: IPermissionType) => {
                    return (
                        <Col span={8} key={item.permission}>
                            <Tooltip title={item.description}>
                                <Checkbox
                                    checked={userPermissions.includes(item.permission)}
                                    value={item.permission}
                                    onChange={handlec}
                                >
                                    {item.name}
                                </Checkbox>
                            </Tooltip>
                        </Col>
                    );
                })}
            </Row>

            <Divider />
        </div>
    );
}

export default PermissionForm;
