import React, { useState } from 'react';

import { Modal } from 'antd';
import { Input, Spin, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { controlIsOpen, asyncAddOrEditUrl } from '../../../reducers/url-shorteners.reducer';
import { IUrlShorteners, ECurrentModalUrlShorteners } from '../../../models/url-shorteners.model';
import { useDispatch, useSelector } from 'react-redux';
import ResultsUrlShorteners from '../results-url-shorteners/ResultsUrlShorteners';

import './CreateUrlShorteners.scss'
import { IState } from '../../../reducers/reducers';
import Notification, { NOTIFICATION_TYPE } from '../../../components/notification/Notification';

interface IProps {
    url?: IUrlShorteners,
    changeUrl: (obj: any) => void
}

const CreateUrlShorteners = ({
    url,
    changeUrl
}: IProps) => {

    const [currentUrlValue, setCurrentUrlValue] = useState(url ? url.url : '');
    const { loadingModal, currentModal } = useSelector(({ urlShorteners }: IState)=>({ loadingModal: urlShorteners.loadingModal, currentModal: urlShorteners.currentModal }))

    const dispatch = useDispatch();

    const checkValidate = (value?: any) => {
        let currentUrls = value ? value : currentUrlValue.replace(/(?:\r\n|\r|\n)/g, ',');

        if(currentUrls) {
            let successUrls: any = [];
            let errosUrls: any = [];
    
            currentUrls.split(',').forEach((url: string) => {
                (url.startsWith('https://') || url.startsWith('http://')) ? successUrls.push(url) : errosUrls.push({ url, error: 'Ausência de prefixo HTTP:// ou HTTPS://' })
            });
            dispatch(asyncAddOrEditUrl({ url: successUrls.join(','), code: url ? url.code : undefined, erros: errosUrls }))
        } else Notification(NOTIFICATION_TYPE.WARNING, 'Texto vazio', 'você não pode enviar uma url vazia');
    };

    return (
        <Modal
            className="modal-crate-url-shorteners"
           visible={true}
           footer={false}
           closable={false}
           title={url ? 'Editar URL Encurtada' : 'Adicionar URL Encurtada'}
           maskClosable={false}>
               { currentModal === ECurrentModalUrlShorteners.ModalUrlShortenersCreate ? 
                (<> { url && <label>Código: {url.code}</label>}
                    {url ? <Input 
                            value={currentUrlValue}
                            onChange={({ target }) => setCurrentUrlValue(target.value)}
                            placeholder={'Link Redirecionavel'} />
                            :
                            <TextArea 
                            value={currentUrlValue}
                            rows={10}
                            onChange={({ target }) => setCurrentUrlValue(target.value)}
                            placeholder={'Links Redirecionaveis'} />}
                            <div className={ (loadingModal.isLoading && loadingModal.total>0) ?  "container-loading-buttons" : "container-buttons"} >
                                { (loadingModal.isLoading && loadingModal.total>0) && <label className="loading-modal-url" >Carregando {loadingModal.finished+'/'+loadingModal.total} <Spin size="small" /></label>}
                                <div className="container-buttons" >
                                    <Button disabled={loadingModal.isLoading} className="button-modal-cancel" type="default" onClick={()=>{
                                        changeUrl(undefined);
                                        dispatch(controlIsOpen(false));
                                    }} >Cancelar</Button>
                                    <Button 
                                        disabled={loadingModal.isLoading} 
                                        type="primary" 
                                        onClick={()=> checkValidate() } >
                                    Enviar</Button>
                                </div>
                            </div>
                    </>) : <ResultsUrlShorteners validate={checkValidate}/>}
           </Modal>
    )
};

export default CreateUrlShorteners;