import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import TitleSection from '../../components/title-section/TitleSection';
import ReportList from '../../components/reports-list/ReportList';
import { IState } from '../../reducers/reducers';
import {
    changeParams,
    deleteReport,
    generateCoachingCycleReport,
    getCoachingCycleReports
} from '../../reducers/coaching-cycle-reports.reducer';

const CoachingCycleReport = (): any => {
    const isLoading = useSelector(({coachingCycleReports}: IState) => coachingCycleReports.isLoading);
    const isLoadingGenerate = useSelector(({coachingCycleReports}: IState) => coachingCycleReports.isLoadingGenerate);
    const reports = useSelector(({coachingCycleReports}: IState) => coachingCycleReports.reports);
    const total = useSelector(({coachingCycleReports}: IState) => coachingCycleReports.total);
    const dispatch = useDispatch();

    function handleGenerateReport() {
        dispatch(generateCoachingCycleReport());
    }

    return (
        <section>
            <TitleSection title='Relatório de Ciclos de Coaching' >
                <Button
                    onClick={handleGenerateReport}
                    size="large"
                    loading={isLoadingGenerate}
                    type="primary"
                >
                    Gerar relatório
                </Button>
            </TitleSection>

            <ReportList
                reports={reports}
                total={total}
                loading={isLoading}
                getReports={getCoachingCycleReports}
                deleteReport={deleteReport}
                changeParams={changeParams}
                statusSelector={({ coachingCycleReports }: IState) => coachingCycleReports.status}
                toSelector={({ coachingCycleReports }: IState) => coachingCycleReports.to}
                fromSelector={({ coachingCycleReports }: IState) => coachingCycleReports.from}
                orderSelector={({ coachingCycleReports }: IState) => coachingCycleReports.order_by}
                pageSelector={({ coachingCycleReports }: IState) => coachingCycleReports.page}
                pageSizeSelector={({ coachingCycleReports }: IState) => coachingCycleReports.pageSize}
            />
        </section>
    );
}

export default CoachingCycleReport;