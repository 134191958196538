import { ICoacheScisClasses, IUnitScisClasses, IClassScisClasses } from "../models/scis-classes.model";

//Types
export enum ScisClassesActionTypes {
    ASYNC_GET_COACHES = '@scis-classes/ASYNC_GET_COACHES',
    CHANGE_COACHES = '@scis-classes/CHANGE_COACHES',
    CHANGE_PAGE = '@scis-classes/CHANGE_PAGE',
    CHANGE_SEARCH_VALUE = '@scis-classes/CHANGE_SEARCH_VALUE',
    CHANGE_FILTER_UNIT = '@scis-classes/CHANGE_FILTER_UNIT',
    ASYNC_GET_COACHES_XLSX = '@scis-classes/ASYNC_GET_COACHES_XLSX',
    CHANGE_FILTER_CLASS = '@scis-classes/CHANGE_FILTER_CLASS',
    CHANGE_PAGE_SIZE = '@scis-classes/CHANGE_PAGE_SIZE',
    CHANGE_ORDER = '@scis-classes/CHANGE_ORDER',
    CHANGE_LOADING = '@scis-classes/CHANGE_LOADING',
    CHANGE_UNITS = '@scis-classes/CHANGE_UNITS',
    CHANGE_TOTAL = '@scis-classes/CHANGE_TOTAL',
    GET_CLASS = '@scis-classes/GET_CLASS',
    CHANGE_ORDER_MODE = '@scis-classes/CHANGE_ORDER_MODE',
    ASYNC_GET_CLASS = '@scis-classes/ASYNC_GET_CLASS',
};

//Interfaces
export interface IScisClassesStore {
    coaches: ICoacheScisClasses[],
    page: number,
    search: string,
    filter_unit: string[],
    filter_class: string[],
    pageSize: number,
    order: string,
    order_mode: string,
    isLoading: boolean,
    units: IUnitScisClasses[],
    total: number,
    allClass: IClassScisClasses[],
};

export interface ScisClassesAction {
    type: ScisClassesActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: IScisClassesStore = {
    coaches: [],
    page: 1,
    search: '',
    filter_unit: [],
    filter_class: [],
    pageSize: 10,
    order: 'email',
    order_mode: 'asc',
    isLoading: false,
    units: [],
    total: 0,
    allClass: [],
};

export const reducer = (state: IScisClassesStore = INITIAL_STATE, action: ScisClassesAction): IScisClassesStore => {
    switch (action.type) {
        case ScisClassesActionTypes.CHANGE_COACHES:
            return { ...state, coaches: action.payload };
        case ScisClassesActionTypes.CHANGE_PAGE:
            return { ...state, page: action.payload };
        case ScisClassesActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };
        case ScisClassesActionTypes.CHANGE_FILTER_UNIT:
            return { ...state, filter_unit: action.payload };
        case ScisClassesActionTypes.CHANGE_FILTER_CLASS:
            return { ...state, filter_class: action.payload };
        case ScisClassesActionTypes.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, page: 1 };
        case ScisClassesActionTypes.CHANGE_ORDER:
            return { ...state, order: action.payload.order, order_mode: action.payload.order_mode };
        case ScisClassesActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };
        case ScisClassesActionTypes.CHANGE_UNITS:
            return { ...state, units: action.payload };
        case ScisClassesActionTypes.CHANGE_TOTAL:
            return { ...state, total: action.payload };
        case ScisClassesActionTypes.GET_CLASS:
            return {...state, allClass: action.payload}
        default:
            return state;
    };
};

//Actions
export const getClass = () => ({
    type: ScisClassesActionTypes.ASYNC_GET_CLASS,
});
export const getCoaches = (firstRender?: boolean) => ({
    type: ScisClassesActionTypes.ASYNC_GET_COACHES,
    payload: firstRender,
});
export const changePage = (page: number) => ({
    type: ScisClassesActionTypes.CHANGE_PAGE,
    payload: page
});
export const changeSearchValue = (value: string) => ({
    type: ScisClassesActionTypes.CHANGE_SEARCH_VALUE,
    payload: value
});
export const changeFilterUnit = (filter_unit: string[]) => ({
    type: ScisClassesActionTypes.CHANGE_FILTER_UNIT,
    payload: filter_unit,
});
export const getCoachesXlsx = (callback: (a: string, b: boolean) => void) => ({
    type: ScisClassesActionTypes.ASYNC_GET_COACHES_XLSX,
    payload: {callback}
});
export const changeFilterClass = (filter_class: string[]) => ({
    type: ScisClassesActionTypes.CHANGE_FILTER_CLASS,
    payload: filter_class
});
export const changePageSize = (pageSize: number) => ({
    type: ScisClassesActionTypes.CHANGE_PAGE_SIZE,
    payload: pageSize
});
export const changeOrder = (order: string, order_mode: string) => ({
    type: ScisClassesActionTypes.CHANGE_ORDER,
    payload: { order, order_mode }
});
export const changeLoading = (value: boolean) => ({
    type: ScisClassesActionTypes.CHANGE_LOADING,
    payload: value
});
export const changeCoaches = (coaches: ICoacheScisClasses[]) => ({
    type: ScisClassesActionTypes.CHANGE_COACHES,
    payload: coaches
});
export const changeUnits = (units: IUnitScisClasses[]) => ({
    type: ScisClassesActionTypes.CHANGE_UNITS,
    payload: units
});
export const changeTotal = (total: number) => ({
    type: ScisClassesActionTypes.CHANGE_TOTAL,
    payload: total
});
export const getAllClass = (allClass: any) => ({
    type: ScisClassesActionTypes.GET_CLASS,
    payload: allClass
});

export default reducer;
