import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import ProfileFilter from './profile-filter/ProfileFilter';
import ProfileList from './profile-List/ProfileList';

const ProfileCoaches = () => {
    return (
        <>
            <TitleSection title="Perfil dos Coaches" />
            <ProfileFilter/>
            <ProfileList/>
        </>
    )
};

export default ProfileCoaches;
