import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import TitleSection from '../../components/title-section/TitleSection';
import ReportList from '../../components/reports-list/ReportList';
import CoachingEvaluationsModal from './coaching-evaluations-modal';

import { IState } from '../../reducers/reducers';

import { changeParams, changeSetOpenModal, deleteReport, getCoachingEvaluationReports } from '../../reducers/coaching-evaluations-reports.reducer';

function CoachingEvaluationReport() {
    const openModal = useSelector(({coachingEvaluationsReports}: IState) => coachingEvaluationsReports.openModal);
    const isLoading = useSelector(({coachingEvaluationsReports}: IState) => coachingEvaluationsReports.isLoading);
    const isLoadingGenerate = useSelector(({coachingEvaluationsReports}: IState) => coachingEvaluationsReports.isLoadingGenerate);
    const reports = useSelector(({coachingEvaluationsReports}: IState) => coachingEvaluationsReports.reports);
    const total = useSelector(({coachingEvaluationsReports}: IState) => coachingEvaluationsReports.total);

    const dispatch = useDispatch();

    const handleOpenModal = useCallback(() => dispatch(changeSetOpenModal(true)), [dispatch]);
    const handleCloseModal = useCallback(() => dispatch(changeSetOpenModal(false)), [dispatch]);

    return (
        <section>
             <TitleSection title={'Relatório de Avaliações'} >
                <Button
                    onClick={handleOpenModal}
                    size="large"
                    loading={isLoadingGenerate}
                    disabled={isLoadingGenerate}
                    type="primary"
                >
                    Gerar relatório
                </Button>
            </TitleSection>
            <ReportList
                showSelect={false}
                reports={reports}
                total={total}
                loading={isLoading}
                getReports={getCoachingEvaluationReports}
                deleteReport={deleteReport}
                changeParams={changeParams}
                statusSelector={({ coachingEvaluationsReports }: IState) => coachingEvaluationsReports.status}
                toSelector={({ coachingEvaluationsReports }: IState) => coachingEvaluationsReports.to}
                fromSelector={({ coachingEvaluationsReports }: IState) => coachingEvaluationsReports.from}
                orderSelector={({ coachingEvaluationsReports }: IState) => coachingEvaluationsReports.order_by}
                pageSelector={({ coachingEvaluationsReports }: IState) => coachingEvaluationsReports.page}
                pageSizeSelector={({ coachingEvaluationsReports }: IState) => coachingEvaluationsReports.pageSize}
            />
            {openModal && (
                <CoachingEvaluationsModal
                    loading={isLoadingGenerate}
                    onClose={handleCloseModal}
                />
            )
            }
        </section>
    );
};

export default CoachingEvaluationReport;
