import { Button, Icon, Input, Tooltip, Typography, Upload, Popconfirm } from "antd";
import React, { useState } from "react";
import { IVideo } from "../../models/video.model";
import { getBase64 } from "../../utils/utils.functions";
import { getPostUrls, uploadFile } from "../../utils/webApi";

import "./AttachmentList.scss";

interface IPropsAttachment {
    types: string,
    vidEdit: IVideo,
    setVidEdit: (video:IVideo) => void,
    values: [],
};

type Obj = {
    name: string,
    description: string,
    url: string,
    mime: string,
    fileName: string,
    isNew: boolean,
};

const obj: Obj = {
    name: '',
    description: '',
    url: '',
    mime: '',
    fileName: '',
    isNew: true,
};

const AttachmentsList = ({types, vidEdit, setVidEdit, values }: IPropsAttachment) => {
    
    const handleAdd = () => {
        
        setVidEdit({
            ...vidEdit,
            [types]: {
                ...vidEdit[types as keyof IVideo],
                attachments: [...vidEdit[types as keyof IVideo].attachments, {...obj}],
            },
        });
    };

    const handleRemove = (index: number) => {
        const removeAtt = vidEdit[types as keyof IVideo]['attachments'].filter((ele: Obj, idx: number) => idx !== index);

        setVidEdit({
            ...vidEdit,
            [types]: {
                ...vidEdit[types as keyof IVideo],
                attachments: removeAtt,
            },
        });
    };
    return (
        <div>
            <div className="attachments_list">
                {
                    vidEdit[types as keyof IVideo].attachments ? vidEdit[types as keyof IVideo].attachments.map((att: any, idx: number) => (
                    <ListItem
                        key={idx}
                        idx={idx}
                        att={att}
                        handleRemove={handleRemove}
                        types={types}
                        values={values}
                        vidEdit={vidEdit}
                        setVidEdit={setVidEdit}
                    />
                    ))
                    : null
                }
            </div>
            <div className="btn-add">
                    <Button onClick={handleAdd}>
                        <Icon type="plus-circle" />
                    </Button>
                </div>
        </div>
    );
};

const ListItem = ({idx, att, handleRemove, vidEdit, setVidEdit, types}:any) => {
    
    const [hasUpload, setHasUpload] = useState<string>();

    const handleUpload = async (info: any) => {
        const typeFile = info.file.type.includes("video") || info.file.type.includes("audio");
        if (typeFile) {
            await getVideoDuration(info.file.originFileObj);
            setHasUpload(info.file.name);
        } else {
            if (info.file.status === 'uploading') {
                return;
            }
            if (info.file.status === 'done') {
                    await getBase64(info.file.originFileObj, () => {
                    setHasUpload(info.file.name);
                });
            }
        }
    };

    const handleRequest = async ({file, onSuccess}: any) => {

        const data = {
            mime: file.type,
        };

        const urls = await getPostUrls(data);
        await uploadFile(urls.put, file, file.type);
        onSuccess({name: file.name, status: "done", url: urls.get, thumbUrl: urls.get}, file);

        let item = {
            ...vidEdit,
            [types]: {
                ...vidEdit[types],
                attachments: [...vidEdit[types].attachments],
            },
        };

        item[types].attachments[idx]["url"] = urls.get;
        setVidEdit(item);

        item[types].attachments[idx]["mime"] = data.mime;
        setVidEdit(item);

        item[types].attachments[idx]["fileName"] = file.name;
        setVidEdit(item);
    }

    function handleChange (key: string, value: string) {

        let currentVidEdit = {
            ...vidEdit,
            [types]: {
                ...vidEdit[types],
                attachments: [...vidEdit[types].attachments],
            },
        };
        currentVidEdit[types].attachments[idx][key] = value;
        setVidEdit(currentVidEdit);
    };

    return (
        <div className="container_item">
            <div className="attachment_header">
                <Typography style={{marginTop: '10px', fontSize: '12px', color: 'white'}}>{`Anexo ${idx + 1} :`}</Typography>
                {
                    att.isNew
                        ? (
                            <Tooltip title='Deletar anexo'>
                                <Icon type='delete' theme='filled' style={{color: '#ed4337'}} onClick={() => handleRemove(idx)}/>
                            </Tooltip>
                        ) : (
                            <Popconfirm
                                title={'Tem certeza que deseja remover esse anexo? ele é um resource antigo e não pode ser recuperado!'}
                                okText='Sim'
                                cancelText='Não'
                                onConfirm={() => handleRemove(idx)}
                                placement='left'
                            >
                                <Tooltip title='Deletar anexo'>
                                    <Icon type='delete' theme='filled' style={{color: '#ed4337'}}/>
                                </Tooltip>
                            </Popconfirm>
                        )
                }
            </div>

            <div className="container_input">
                <Input
                    placeholder='Nome'
                    value={att.name || att.url}
                    onChange={({target}) => handleChange('name', target.value)}
                    readOnly={!att.isNew}
                />
                <Input
                    placeholder='Descrição'
                    value={att.description || att.url}
                    onChange={({target}) => handleChange('description', target.value)}
                    readOnly={!att.isNew}
                />
                {att.isNew && <div style={{width:'100%', display: 'flex', marginTop: '5px'}}>

                    <Upload
                        name= "attachment-upload"
                        listType="text"
                        showUploadList={false}
                        multiple={false}
                        onChange={handleUpload}
                        customRequest={handleRequest}
                    >
                        {
                            (hasUpload || vidEdit[types].attachments[idx].fileName) ? 
                            <div className="upload-link">
                                {hasUpload || vidEdit[types].attachments[idx].fileName}
                            </div>
                            :
                            <Button style={{width: '100%', backgroundColor: 'transparent', color: 'white'}}>
                                <Icon type="cloud-upload" />
                                <span style={{color: 'white'}}>Selecione o arquivo</span>
                            </Button>
                        }
                    </Upload>
                </div>}
            </div>
        </div>
    );
};

const getVideoDuration = (video: any) => {    
    new Promise((resolve, reject) => {
        const reader = new FileReader();
      
        reader.onload = () => {
            const media = new Audio(reader.result as string | undefined);
            media.onloadedmetadata = () => resolve(Math.round(media.duration));
        };
        reader.readAsDataURL(video);
        reader.onerror = error => reject(error);
    });
};

export default AttachmentsList;