import { put, all, takeEvery, select, call, delay } from 'redux-saga/effects';
import { ETypesUrlShorteners, controlIsLoading, controlUrls, controlPageList, controlIsOpen, controlLoadingModal, controlUrlsResults, controlCurrentModal, controlPage } from '../reducers/url-shorteners.reducer';
import { queryStringList, queryStringAdicionar, IUrlShorteners, IUrlShortenersStore, IErrosUrlShorteners, ECurrentModalUrlShorteners } from '../models/url-shorteners.model';
import { getListUrls, putAddUrl, postEditUrl, deleteRemoveUrl, getReadCode } from '../utils/webApi';
import { IState } from '../reducers/reducers';
import handleError from '../utils/handleError';
import Notification, { NOTIFICATION_TYPE } from '../components/notification/Notification';

function* getUrls() {
    try {
        yield put(controlIsLoading(true));

        const { pagesList, page } = yield select(({ urlShorteners }:IState)=> urlShorteners);
        const response = yield call(getListUrls, queryStringList({ nextPage: page === 0 ? undefined : pagesList[page] }));
        
        let newPageList = [ ...pagesList ];

        if(response.nextPage) {
            newPageList.push(response.nextPage.code)
            yield put(controlPageList(newPageList));
        } else {
            newPageList.push(null);
            yield put(controlPageList(newPageList));
        }

        yield put(controlUrls(response.urlShorteners));
    } catch (err) {
        handleError(err);
    } finally {
        yield put(controlIsLoading(false));
    }
};

function* readCode({ payload }: any) {
    if(payload.code) {
        try {
            yield put(controlIsLoading(true));
            const response = yield call(getReadCode, queryStringAdicionar(payload.code));
            yield put(controlUrls([response]));
        } catch (err) {
            yield put(controlUrls([]));
        } finally {
            yield put(controlIsLoading(false));
        }
    } else {
        yield getUrls();
    }
}

function* editOrAddUrl({ payload }: IUrlShorteners|any) {
    try {
        yield put(controlIsLoading(true));

        const success = yield select(({ urlShorteners }: IState)=> urlShorteners.urlsResults.success)

        let erros = payload.erros || [ ];
        let newSuccess = []

        if(!payload.code) {
            if(payload.url) {
                let sendUrls = payload.url.split(',');
                let finished = 0;
                yield put(controlLoadingModal({ isLoading: true, total: sendUrls.length, finished: finished }))
                for( let url of sendUrls) {
                    try {
                        const response = yield call(putAddUrl, { url });
                        newSuccess.push({ url, code: response.code })
                        yield delay(500);
                    } catch (err) {
                        const { data } = err.response || {};
                        erros.push({ url, error: data.error_description ? data.error_description : err.message  });
                    } finally {
                        yield put(controlLoadingModal({ finished: ++finished }))
                    }
                }

            }

            let currentErros = erros.map((element: IErrosUrlShorteners, index: number)=>({
                url: element.url,
                error: element.error,
                id: index
            }));

            yield put(controlUrlsResults({ erros: [ ...currentErros ], success : [ ...(success ? success : []), ...newSuccess ] }));
            yield put(controlCurrentModal(ECurrentModalUrlShorteners.ModalUrlShortenersResults));
        }
        else {
            yield put(controlLoadingModal({ isLoading: true}));
            if(payload.url) {
                yield call(postEditUrl, queryStringAdicionar(payload.code), { url: payload.url });
                yield put(controlIsOpen(false));
                yield getUrls();
            } else Notification(NOTIFICATION_TYPE.WARNING, 'Inválido', 'URL inválido');
        }
    } catch(err) {
        handleError(err);
    } finally {
        yield put(controlIsLoading(false));
        yield put(controlLoadingModal({ isLoading: false, total: 0, finished: 0 }));
    }
}

function* deleteUrl ({ payload }: IUrlShortenersStore|any) {
    try {

        yield put(controlIsLoading(true));
        yield call(deleteRemoveUrl, queryStringAdicionar(payload.code));
        
        yield put(controlPageList([null]));
        yield put(controlPage(0));

        yield getUrls();

    } catch(err){
        handleError(err);
    } finally {
        yield put(controlIsLoading(false));
    }
}


export default function* mySaga() {
    yield all([
        takeEvery(ETypesUrlShorteners.URL_SHORTENERS_ASYNC_GET_URLS, getUrls),
        takeEvery(ETypesUrlShorteners.URL_SHORTENERS_ASYNC_ADD_EDIT_URL, editOrAddUrl),
        takeEvery(ETypesUrlShorteners.URL_SHORTENERS_ASYNC_DELETE_URL, deleteUrl),
        takeEvery(ETypesUrlShorteners.URL_SHORTENERS_ASYNC_READ_CODE, readCode)
    ])
};