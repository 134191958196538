import { all } from 'redux-saga/effects';

import Resources from './resources.saga';
import Auth from './auth.saga';
import Utils from './utils.saga';
import Coaches from './coaches.saga';
import ScisClasses from './scis-classes.saga';
import Coachees from './coachees.saga';
import Users from './users.saga';
import Importations from './importation.saga';
import Contact from './contact.saga';
import PanelControl from './panelcontrol.saga';
import PanelControlCoaching from './panelcontrol.coaching.saga';
import SalesRegistration from './sales-registration.saga';
import PanelControlClasses from './panelcontrol.classes.saga';
import PanelControlGraph from './panelcontrol.graph.saga';
import Profile from './profile.saga';
import RegisterUnits from './register-units.saga';
import UrlShorteners from './url-shorteners.saga';
import BlockList from './blocklist.saga';
import NotificationList from './notificationList.saga';
import Administrators from './administrators.saga';
import CoachReports from './coach-reports.saga';
import Reports from './reports.saga';
import CoachingHoursReport from './coaching-hours-reports.saga';
import InvoiceReports from './invoice-reports.saga';
import CoachingCycleReport from './coaching-cycle-report.saga';
import CoachingEvaluationReport from './coaching-evaluations-reports.saga';
import ExperimentalSessionReport from './experimental-session-reports.saga';
import MonthlyCoachingReport from './monthly-coaching-reports.saga';
import AuditSaga from './audit.saga';
import Categories from './categories.saga';
import Videos from './videos.saga';
import InternalLinks from './internal-links.saga';
import LoosePayments from './loose-payment.saga';

export default function* RootSaga() {
    yield all([
        Resources(),
        Auth(),
        Utils(),
        Coaches(),
        ScisClasses(),
        Coachees(),
        Users(),
        Importations(),
        Contact(),
        PanelControl(),
        PanelControlCoaching(),
        PanelControlClasses(),
        PanelControlGraph(),
        Profile(),
        RegisterUnits(),
        UrlShorteners(),
        BlockList(),
        NotificationList(),
        Administrators(),
        CoachReports(),
        Reports(),
        CoachingHoursReport(),
        InvoiceReports(),
        CoachingCycleReport(),
        CoachingEvaluationReport(),
        ExperimentalSessionReport(),
        MonthlyCoachingReport(),
        AuditSaga(),
        Categories(),
        Videos(),
        SalesRegistration(),
        InternalLinks(),
        LoosePayments(),
    ]);
};
