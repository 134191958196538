import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {Table, Icon, Pagination, Popconfirm, Typography} from 'antd';

import {IAdmin} from '../../../models/administrator.model';
import {IState} from '../../../reducers/reducers';
import {changePageSize, changePage, asyncGetAdminToEdit, changeOrder, asyncRequestAdminList, asyncRemoveAdmin} from '../../../reducers/administrators.reducer';
import {formatDate} from '../../../utils/moment.functions';
import {permissionKeys} from '../../../utils/permissions';
import RestrictedComponent from '../../../components/restricted-component/RestrictedComponent';
import SearchBar from './search-bar/SearchFilter';

import './AdminList.scss';

const {Text} = Typography;

const allActionPermissionKeys = [
    permissionKeys.USER_ADMIN_EDIT,
    permissionKeys.USER_ADMIN_DELETE,
];

interface IAdminListProps {
    adminList: IAdmin[],
    loading: boolean,
    total: number,
    checkPermissions(permissions: string[], atLeastOne?: boolean): boolean,
};

function AdminList({adminList, loading, total, checkPermissions}: IAdminListProps) {
    const { page, pageSize } = useSelector(({ administrators }: IState) => ({ page: administrators.page, pageSize: administrators.pageSize }))
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(asyncRequestAdminList());
        // eslint-disable-next-line
    }, [page, pageSize]);

    const handleRemoveAdmin = (id: string): void => {
        dispatch(asyncRemoveAdmin(id));
    };

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nickname',
            key: 'nickname',
            sorter: true,
            ellipsis: true,
            render: (_: string, record: IAdmin) => {
                const { given_name: givenName, family_name: familyName, nickname } = record;

                if (nickname && givenName && familyName) {
                    return `${nickname} (${givenName} ${familyName})`;
                } else if (nickname) {
                    if (givenName || familyName) {
                        return `${nickname} (${givenName || familyName})`;
                    } else {
                        return nickname;
                    }
                } else if (givenName && familyName) {
                    return `${givenName} ${familyName}`;
                } else if (givenName || familyName) {
                    return `${givenName || familyName}`;
                } else {
                    return '(sem nome)';
                }
            },
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ellipsis: true,
        }, {
            title: 'Data de criação',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 120,
            render: (text: string) => text ? formatDate(text) : '',
        }, {
            title: 'Ações',
            key: 'action',
            width: 140,
            render: (admin: IAdmin) => (
                <div className='actions'>
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_ADMIN_EDIT])}>
                        <Icon
                            type='edit'
                            onClick={() => {dispatch(asyncGetAdminToEdit(admin.id))}}
                        />
                    </RestrictedComponent>
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_ADMIN_DELETE])}>
                        <Popconfirm
                            title={`Tem certeza que deseja remover o administrador '${admin.nickname}'?`} 
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => handleRemoveAdmin(admin.id)}
                            placement='left'
                        >
                            <Icon type='delete' theme='filled' style={{color: '#ed4337'}} />
                        </Popconfirm>
                    </RestrictedComponent>
                </div>
            ),
        }
    ];

    if (!checkPermissions(allActionPermissionKeys, true)) {
        columns.pop();
    }

    return (
        <>
            <SearchBar />
            <Table
                className='admin-list'
                pagination={false}
                loading={loading}
                rowClassName={(admin: IAdmin) => (admin.status === 'INACTIVE') ? 'row-canceled' : 'row'}
                columns={columns}
                dataSource={adminList.map((admin: IAdmin) => ({ ...admin, key: admin.id }))}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={(c1, c2, sorter) => dispatch(changeOrder(sorter.field, sorter.order.replace('end', '')))}
            />
            <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                <Text><b>{total}</b> coaches encontrados</Text>
            </div>
            <Pagination
                className='admin-pagination'
                showSizeChanger
                onShowSizeChange={(_, pageSize) => dispatch(changePageSize(pageSize))}
                defaultPageSize={pageSize}
                defaultCurrent={page}
                current={page}
                total={total}
                pageSizeOptions={['10', '25', '50', '100']}
                onChange={page => dispatch(changePage(page))}
            />
        </>
    );
};

export default AdminList;
