import React, { useEffect } from 'react';

import { formatDate } from '../../../utils/moment.functions';
import { Table, Pagination } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { IDataCoaching, SessionTypes } from '../../../models/panelcontrol.coaching.model';
import { controlPageSize, controlPage, asyncGetCoaching } from '../../../reducers/panelcontrol.coaching.reducer';
import './PanelCoachingList.scss';
import Loading from '../../../components/loading/Loading';

const PanelCoachingList = () => {

    const { dataCoaching, total, isLoading, page, pageSize } = useSelector(
        ({ panelcontrolcoaching }: IState) => panelcontrolcoaching
    )

    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(asyncGetCoaching());
    }, [page, pageSize, dispatch]);

    const columns = [
        // USER INFORMATION
        {
            title: 'Usuário',
            dataIndex: 'nickname',
            key: 'nickname'
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Telefone',
            dataIndex: 'phone_number',
            key: 'Telefone'
        },
        // GERAL 
        {
            title: 'Total de Coachees Atendimento',
            dataIndex: 'stats.coachees',
            key: 'coaches',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.coachees - b.stats.coachees
                } else return 1
            }
        },
        {
            title: 'Total de Coachees',
            dataIndex: 'stats.geralTotalCoachees',
            key: 'geralTotalCoachees',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.geralTotalCoachees - b.stats.geralTotalCoachees
                } else return 1
            }
        },
        // {
        //     title: 'Coachees Finalizados',
        //     dataIndex: 'stats.coacheesFinalizados',
        //     key: 'coacheesFinalizados',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.ciclosFinalizados - b.stats.ciclosFinalizados
        //         } else return 1
        //     }
        // },
        {
            title: 'Ciclos Finalizados',
            dataIndex: 'stats.ciclosFinalizados',
            key: 'ciclosFinalizados',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.ciclosFinalizados - b.stats.ciclosFinalizados
                } else return 1
            }
        },
        {
            title: 'Sessões Realizadas',
            dataIndex: 'stats.sessoesRealizadas',
            key: 'sessoesRealizadas',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.sessoesRealizadas - b.stats.sessoesRealizadas
                } else return 1
            }
        },
        {
            title: 'Horas Coaching',
            dataIndex: 'stats.horasCoach',
            key: 'horasCoaching',
            render: (element: number) => (<span>{ typeof element !== 'undefined' && element !== null ? Math.round(element) : ''}</span>),
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.horasCoach - b.stats.horasCoach
                } else return 1
            }
        },
        {
            title: 'Última Sessão',
            dataIndex: 'stats.ultimaSessao',
            key: 'ultimaSessao',
            render: function(element: string){
                if(element){
                    const date = new Date(element)
                    const newDate = formatDate(date, 'DD/MM/YYYY HH:mm')
                    return ( <span>{newDate}</span> )
                } else return null
            }
        },
        {
            title: ' Tipo da Última Sessão',
            dataIndex: 'stats.ultimaSessaoTipo',
            key: 'ultimaSessaoTipo',
            render: function(element: string){
                if(element){
                    return ( <span>{SessionTypes[element as keyof typeof SessionTypes]}</span> )
                } else return null
            }
        },
        // {
        //     title: 'Comprometimento',
        //     dataIndex: 'stats.comprometimento',
        //     key: 'comprometimento',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.comprometimento - b.stats.comprometimento
        //         } else return 1
        //     }
        // },
        // LIFE
        // {
        //     title: 'Coachees Life',
        //     dataIndex: 'stats.totalCoacheeComum',
        //     key: 'totalCoacheeComum',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCoacheeComum - b.stats.totalCoacheeComum
        //         } else return 1
        //     }
        // },
        // {
        //     title: 'Ciclos Life',
        //     dataIndex: 'stats.totalCicloLifeCoaching',
        //     key: 'totalCicloLifeCoaching',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCicloLifeCoaching - b.stats.totalCicloLifeCoaching
        //         } else return 1
        //     }
        // },
        {
            title: 'Ciclos Life Finalizados',
            dataIndex: 'stats.totalCicloLifeCoachingFinalizado',
            key: 'totalCicloLifeCoachingFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalCicloLifeCoachingFinalizado - b.stats.totalCicloLifeCoachingFinalizado
                } else return 1
            }
        },
        {
            title: 'Sessões Life Realizadas',
            dataIndex: 'stats.totalSessaoComumFinalizado',
            key: 'totalSessaoComumFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalSessaoComumFinalizado - b.stats.totalSessaoComumFinalizado
                } else return 1
            }
        },
        // BUSINESS
        // {
        //     title: 'Coachees Business',
        //     dataIndex: 'stats.totalCoacheeBusiness',
        //     key: 'totalCoacheeBusiness',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCoacheeBusiness - b.stats.totalCoacheeBusiness
        //         } else return 1
        //     }
        // },
        // {
        //     title: 'Total Ciclos Business',
        //     dataIndex: 'stats.totalCicloBusiness',
        //     key: 'totalCicloBusiness',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCicloBusiness - b.stats.totalCicloBusiness
        //         } else return 1
        //     }
        // },
        {
            title: 'Ciclos Business Finalizados',
            dataIndex: 'stats.totalCicloBusinessFinalizado',
            key: 'totalCicloBusinessFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalCicloBusinessFinalizado - b.stats.totalCicloBusinessFinalizado
                } else return 1
            }
        },
        {
            title: 'Sessões Business Realizadas',
            dataIndex: 'stats.totalSessaoBusinessFinalizado',
            key: 'totalSessaoBusinessFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalSessaoBusinessFinalizado - b.stats.totalSessaoBusinessFinalizado
                } else return 1
            }
        },
        // {
        //     title: 'Total Sessão Business',
        //     dataIndex: 'stats.totalSessaoBusiness',
        //     key: 'totalSessaoBusiness',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalSessaoBusiness - b.stats.totalSessaoBusiness
        //         } else return 1
        //     }
        // },
        // EXPERIMENTAL
        // {
        //     title: 'Coachees Experimentais',
        //     dataIndex: 'stats.totalCoacheeExperimental',
        //     key: 'totalCoacheeExperimental',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCoacheeExperimental - b.stats.totalCoacheeExperimental
        //         } else return 1
        //     }
        // },
        // {
        //     title: 'Ciclos Experimentais',
        //     dataIndex: 'stats.totalCicloExperimental',
        //     key: 'totalCicloExperimental',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCicloExperimental - b.stats.totalCicloExperimental
        //         } else return 1
        //     }
        // },
        {
            title: 'Ciclos Experimentais Finalizados',
            dataIndex: 'stats.totalCicloExperimentalFinalizado',
            key: 'totalCicloExperimentalFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalCicloExperimentalFinalizado - b.stats.totalCicloExperimentalFinalizado
                } else return 1
            }
        },
        {
            title: 'Sessões Experimentais Realizadas',
            dataIndex: 'stats.totalSessaoExperimentalFinalizado',
            key: 'totalSessaoExperimentalFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalSessaoExperimentalFinalizado - b.stats.totalSessaoExperimentalFinalizado
                } else return 1
            }
        },
        // {
        //     title: 'Sessões Experimentais',
        //     dataIndex: 'stats.sessoesExperimentais',
        //     key: 'sessoesExperimentais',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.sessoesExperimentais - b.stats.sessoesExperimentais
        //         } else return 1
        //     }
        // },
        // {
        //     title: 'Total Sessão Experimental',
        //     dataIndex: 'stats.totalSessaoExperimental',
        //     key: 'totalSessaoExperimental',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalSessaoExperimental - b.stats.totalSessaoExperimental
        //         } else return 1
        //     }
        // },
        // REUNIÃO DE ALINHAMENTO
        // {
        //     title: 'Coachees Reunião Alinhamento',
        //     dataIndex: 'stats.totalCoacheeAlignment',
        //     key: 'totalCoacheeAlignment',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCoacheeAlignment - b.stats.totalCoacheeAlignment
        //         } else return 1
        //     }
        // },
        // {
        //     title: 'Ciclos Alinhamento',
        //     dataIndex: 'stats.totalCicloAlignment',
        //     key: 'totalCicloAlignment',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCicloAlignment - b.stats.totalCicloAlignment
        //         } else return 1
        //     }
        // },
        {
            title: 'Ciclos Alinhamento Finalizados',
            dataIndex: 'stats.totalCicloAlignmentFinalizado',
            key: 'totalCicloAlignmentFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalCicloAlignmentFinalizado - b.stats.totalCicloAlignmentFinalizado
                } else return 1
            }
        },
        // {
        //     title: 'Reuniões Alinhamento',
        //     dataIndex: 'stats.totalSessaoAlignment',
        //     key: 'totalSessaoAlignment',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalSessaoAlignment - b.stats.totalSessaoAlignment
        //         } else return 1
        //     }
        // },
        {
            title: 'Reuniões Alinhamento Realizadas',
            dataIndex: 'stats.totalSessaoAlignmentFinalizado',
            key: 'totalSessaoAlignmentFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalSessaoAlignmentFinalizado - b.stats.totalSessaoAlignmentFinalizado
                } else return 1
            }
        },
        // TEAM COACHING
        {
            title: 'Total Team Coaching',
            dataIndex: 'stats.totalCoacheeTeam',
            key: 'totalCoacheeTeam',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalCoacheeTeam - b.stats.totalCoacheeTeam
                } else return 1
            }
        },
        // {
        //     title: 'Total Ciclos Team',
        //     dataIndex: 'stats.totalCicloTeam',
        //     key: 'totalCicloTeam',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCicloTeam - b.stats.totalCicloTeam
        //         } else return 1
        //     }
        // },
        {
            title: 'Ciclos Team Finalizados',
            dataIndex: 'stats.totalCicloTeamFinalizado',
            key: 'totalCicloTeamFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalCicloTeamFinalizado - b.stats.totalCicloTeamFinalizado
                } else return 1
            }
        },
        // {
        //     title: 'Total Sessão Team',
        //     dataIndex: 'stats.totalSessaoTeam',
        //     key: 'totalSessaoTeam',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalSessaoTeam - b.stats.totalSessaoTeam
        //         } else return 1
        //     }
        // },
        {
            title: 'Sessões Team Realizadas',
            dataIndex: 'stats.totalSessaoTeamFinalizado',
            key: 'totalSessaoTeamFinalizado',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.totalSessaoTeamFinalizado - b.stats.totalSessaoTeamFinalizado
                } else return 1
            }
        },
        {
            title: 'Participantes Team Coaching',
            dataIndex: 'stats.teamCoachingAlunos',
            key: 'teamCoachingAlunos',
            sorter: function(a: IDataCoaching,b: IDataCoaching){
                if(a.stats && b.stats){
                    return a.stats.teamCoachingAlunos - b.stats.teamCoachingAlunos
                } else return 1
            }
        },
        // {
        //     title: 'Ciclos Executive Coaching',
        //     dataIndex: 'stats.totalCicloExecutiveCoaching',
        //     key: 'totalCicloExecutiveCoaching',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCicloExecutiveCoaching - b.stats.totalCicloExecutiveCoaching
        //         } else return 1
        //     }
        // },
        // {
        //     title: 'Total Ciclos Executive Coaching Finalizado',
        //     dataIndex: 'stats.totalCicloExecutiveCoachingFinalizado',
        //     key: 'totalCicloExecutiveCoachingFinalizado',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalCicloExecutiveCoachingFinalizado - b.stats.totalCicloExecutiveCoachingFinalizado
        //         } else return 1
        //     }
        // },
        // {
        //     title: 'Total Sessão Comum',
        //     dataIndex: 'stats.totalSessaoComum',
        //     key: 'totalSessaoComum',
        //     sorter: function(a: IDataCoaching,b: IDataCoaching){
        //         if(a.stats && b.stats){
        //             return a.stats.totalSessaoComum - b.stats.totalSessaoComum
        //         } else return 1
        //     }
        // },
    ]

    return (
        <>
            {isLoading && <Loading/>}
            <Table
                className='table-panelcoaching'
                columns={columns}
                rowKey={(element: IDataCoaching)=> `${element.email+element.phone_number}`}
                dataSource={dataCoaching.map((element: IDataCoaching)=>({...element}))}
                pagination={false}
            />
            <Pagination
                className='panelcontrol-pagination'
                showSizeChanger
                onShowSizeChange={(current, size)=> dispatch(controlPageSize(size))}
                defaultPageSize={10}
                defaultCurrent={1}
                pageSizeOptions={['5', '10', '25']}
                total={total}
                onChange={page => dispatch(controlPage(page))}
            />
        </>
    )
}

export default PanelCoachingList;
