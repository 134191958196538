/* eslint-disable jsx-a11y/anchor-has-content */
import React, {useRef, useState} from 'react';
import { Select, Form, Button } from 'antd';
import Search from 'antd/lib/input/Search';

import { SearchFields, CoachingType, IClass } from '../../../../models/coache.model';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../reducers/reducers';
import {
    changeSearchValue,
    changeSearchField,
    getCoaches,
    changePage,
    changeFilterUnit,
    changeFilterSpecs,
    changeFilterStatuses,
    getCoachesCsv,
    changeFilterClass,
} from '../../../../reducers/coaches.reducer';
import './SearchFilter.scss';

const { Option } = Select;

const fieldsOptions: { label: string, value: SearchFields }[] = [
    { label: 'ID do Usuário', value: SearchFields.ID },
    { label: 'Email', value: SearchFields.EMAIL },
    { label: 'Telefone', value: SearchFields.PHONE_NUMBER },
    { label: 'Nome Usual', value: SearchFields.NICKNAME },
    { label: 'Primeiro Nome', value: SearchFields.GIVEN_NAME },
    { label: 'Segundo Nome', value: SearchFields.FAMILY_NAME },
    { label: 'Cpf', value: SearchFields.CPF },
    { label: 'Recorrente ID', value: SearchFields.RECURRENT_IDS },
    { label: 'Cupons', value: SearchFields.COUPONS },
];

const SpecsOptions: { label: string, value: CoachingType | string }[] = [
    { label: 'Master', value: CoachingType.MASTER },
    { label: 'Executive', value: CoachingType.EXECUTIVE },
    { label: 'Business', value: CoachingType.BUSINESS },
    { label: 'Career', value: CoachingType.CAREER },
    { label: 'Trainer', value: CoachingType.TRAINER },
    { label: 'Jeito de Viver Família', value: CoachingType.LIVING_FAMILY },
    { label: 'Microfranchisee', value: CoachingType.MICROFRANCHISEE },
    { label: 'Mindfulness', value: CoachingType.MINDFULNESS },
    { label: 'Sem atribuição', value: '' },
]

const StatusOptions: { label: string, value?: string }[] = [
    { label: 'Ativo', value: 'ATIVO' },
    { label: 'Encerrado', value: 'ENCERRADO' },
    { label: 'Expirando', value: 'EXPIRANDO' },
    { label: 'Expirado', value: 'EXPIRADO' },
    { label: 'Experimental', value: 'EXPERIMENTAL' },
    { label: 'Dias Adicionais', value: 'RECORRENTE_PERIODO_EXTRA' },
    { label: 'Recorrente Expirado', value: 'RECORRENTE_EXPIRADO' },
    { label: 'Pronto', value: 'PRONTO' },
    { label: 'Sem Licença', value: 'SEM_LICENCA' },
    { label: 'Inativo', value: 'INATIVO' },
]

interface ISearch {
    classes: IClass[],
}

const SearchFilter = ({classes}: ISearch) => {
    const [loadingCsv, setLoadingCsv] = useState<boolean>(false);
    const refA = useRef<HTMLAnchorElement | null>(null);
    const { search, search_field, filter_unit, units, filter_specs, filter_statuses, filter_class } = useSelector(({ coaches }: IState) => ({
        search: coaches.search,
        search_field: coaches.search_field,
        filter_unit: coaches.filter_unit,
        units: coaches.units,
        filter_specs: coaches.filter_specs,
        filter_statuses: coaches.filter_statuses,
        filter_class: coaches.filter_class,
    }));

    const dispatch = useDispatch();

    const onSearch = () => {
        dispatch(changePage(1));
        dispatch(getCoaches());
    };

    const onDownload = () => {
        setLoadingCsv(true);
        dispatch(getCoachesCsv((string: string, error: boolean)=> {
            let current = refA.current;
            if (current && !error) {
                current.href = string;
                current.download = `csv_${new Date().toLocaleString()}-coaches.csv`;
                current.click();
            }
            setLoadingCsv(false);
        }));
    }

    return (
        <Form className="coaches-filter">
            <div className="input-filter">
                <label>Buscar</label>
                <Search
                    value={search}
                    className="search-input"
                    placeholder="Buscar"
                    style={{ width: 250 }}
                    onSearch={onSearch}
                    onChange={({ target }) => dispatch(changeSearchValue(target.value))} />
            </div>

            <div className="input-filter">
                <label>Campo de busca</label>
                <Select
                    mode="multiple"
                    placeholder="Todos"
                    maxTagCount={1}
                    allowClear={true}
                    value={search_field && search_field.length > 0 ? search_field : undefined}
                    onChange={(fields: SearchFields[]) => dispatch(changeSearchField(fields))}>

                    {fieldsOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="input-filter">
                <label>Filtrar Por Turmas</label>
                <Select
                    mode="multiple"
                    className="filter-class"
                    placeholder="Todas"
                    allowClear={true}
                    maxTagCount={1}
                    value={filter_class && filter_class.length > 0 ? filter_class : undefined}
                    onChange={(filter_class: string[]) => dispatch(changeFilterClass(filter_class))}>

                    {classes.map(({ className, id }) => (
                        <Option key={id} value={id}>
                            {className}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="input-filter">
                <label>Filtrar Por Unidade</label>
                <Select
                    className="select-filter"
                    value={filter_unit}
                    onChange={(field: string) => dispatch(changeFilterUnit(field))}>

                    {units.map(({ id, name }) => (
                        <Option key={id} value={id}>
                            {name}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="input-filter">
                <label>Filtrar Por Atribuições</label>
                <Select
                    mode="multiple"
                    placeholder="Todos"
                    allowClear={true}
                    maxTagCount={1}
                    value={filter_specs && filter_specs.length > 0 ? filter_specs : undefined}
                    onChange={(specs: (CoachingType | string)[]) => dispatch(changeFilterSpecs(specs))}>

                    {SpecsOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="input-filter">
                <label>Filtrar Por Status</label>
                <Select
                    mode="multiple"
                    placeholder="Todos"
                    allowClear={true}
                    maxTagCount={1}
                    value={filter_statuses && filter_statuses.length > 0 ? filter_statuses : undefined}
                    onChange={(statuses: string[]) => dispatch(changeFilterStatuses(statuses))}>

                    {StatusOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>

            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search">
                Pesquisar
            </Button>

            <a style={{display: 'none'}} ref={refA} href="/#" />
            <Button
                onClick={onDownload}
                className="btn-search"
                type="primary"
                loading={loadingCsv}
            >
                Exportar CSV
            </Button>
        </Form>
    )
};

export default SearchFilter;
