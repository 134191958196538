import { takeEvery, put, all, call, select } from 'redux-saga/effects';
import { controlContactUsMessage, ContactActionTypes, controlIsLoading, controlContactUsMessageComplet } from '../reducers/contact.reducer';
import { queryStringList, queryStringComplet } from '../models/contact.modal';
import { getContactUsMessage, getContactUsMessageComplet } from '../utils/webApi';
import handlerError from '../utils/handleError';
import { IMessage } from '../models/contact.modal';
import { IState } from '../reducers/reducers';

function* requestContactUsMessage () {
    try {
        const { page, pageSize, search } = yield select(({ contact }: IState )=> contact);

        yield put(controlIsLoading(true));

        const data = yield call(getContactUsMessage, queryStringList({ page, pageSize, search }));
        const request: IMessage[] = data.messages
        yield put(controlContactUsMessage(request))

    } catch (err){
        handlerError(err)
    } finally {
        yield put(controlIsLoading(false));
    }
}

function* requestContactUsMessageComplet ({ payload }: any) {
    try {
        yield put(controlIsLoading(true));

        const request: IMessage = yield call(getContactUsMessageComplet, queryStringComplet(payload))

        yield put(controlContactUsMessageComplet(request))

    } catch (err){
        handlerError(err)
    } finally {
        yield put(controlIsLoading(false));
    }
}

export default function* mySaga(){
    yield all([
        takeEvery( ContactActionTypes.ASYNC_GET_MESSAGE , requestContactUsMessage),
        takeEvery( ContactActionTypes.ASYNC_CONTACT_US_MESSAGE_COMPLET, requestContactUsMessageComplet )
    ])
}