import { call, all, takeEvery } from 'redux-saga/effects';
import handleError from '../utils/handleError';
import { AuthActionTypes } from '../reducers/auth.reducer';
import { getTokenFromCode } from '../utils/webApi';
import CredentialsHelper from '../utils/credentials-helper';
import { history } from '../config/Store.config';
import { routes } from '../utils/routers.constants';
import { externalUrls } from '../utils/urls.constants';

function* doLogin({ payload }: any) {
    try {
        const { access_token, refresh_token } = yield call(getTokenFromCode, payload);
        CredentialsHelper.update(access_token, refresh_token);
        history.push(routes.DASHBOARD);
    } catch (error) {
        handleError(error);
    }
};

function doLogout() {
    try {
        window.location.href = externalUrls.LOGOUT;
        CredentialsHelper.clear();
    } catch (error) {
        handleError(error);
    }
};

export default function* MySaga() {
    yield all([
        yield takeEvery(AuthActionTypes.ASYNC_DO_LOGIN, doLogin),
        yield takeEvery(AuthActionTypes.ASYNC_DO_LOGOUT, doLogout),
    ]);
}