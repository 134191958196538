import React, { useEffect, useState } from 'react';
import { Pagination, Table, Icon, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { controlProfilePage, controlProfilePageSize, asyncGetCoaches, controlProfileIsOpen } from '../../../reducers/profile.reducer';
import { formatDate } from '../../../utils/moment.functions';
import { isLink } from '../profile-modal/profile-modal-profile/ProfileModalProfile';
import ProfileModal from '../profile-modal/ProfileModal';
import { ICoache } from '../../../models/profile.modal';
import Loading from '../../../components/loading/Loading';
import { ICenter } from '../../../models/utils.model';
import { noExists } from '../../../utils/utils.functions'
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import { permissionKeys } from '../../../utils/permissions';
import RestrictedComponent from '../../../components/restricted-component/RestrictedComponent';

import './ProfileList.scss';

const allActionPermissionKeys = [
    permissionKeys.USER_PROFILE_OPEN,
];

const ProfileList = () => {
    //email, telefone, site, nucleo, rating, horas de coaching, data de expiração, status, especializações, último acesso scis, último acesso site

    const [ currentProfile, setCurrentProfile ] = useState<ICoache>({} as ICoache );
    const [ currentCenter, setCurrentCenter ] = useState<ICenter>({} as ICenter);
    const checkPermissions = useCheckPermissions();

    const { page, pageSize, total, coaches, isLoading, isOpen } = useSelector(({ profile }: IState) => 
        ({ page: profile.page,
            pageSize: profile.pageSize,
            total: profile.total,
            coaches: profile.coaches,
            isLoading: profile.isLoading,
            isOpen: profile.isOpen 
        }));

    const centers = useSelector(({ utils }: IState)=> utils.centers);

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(asyncGetCoaches());
    // eslint-disable-next-line
    },[page, pageSize])

    const columns = [
        {
            title: 'email do perfil',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'telefone do perfil',
            dataIndex: 'openid_phone_number',
            key: 'phone_number',
            render: (phone?: string) => {
                return <label>{phone ? phone : noExists}</label>
            }
        },
        {
            title: 'site',
            key: 'site',
            render: (profile: ICoache ) => {
                return isLink(profile) || <label>{profile.username}</label>
            }
        },
        {
            title: 'núcleo',
            dataIndex: 'unit_id',
            key: 'core',
            render: (id_core: string)=> {
                const core = centers.find((core: any)=> core.id === id_core);
                return <span>{core ? core.name : ''}</span>
            }
        },
        {
            title: 'rating',
            dataIndex: 'rating',
            key: 'rating'
        },
        {
            title: 'horas de coaching',
            dataIndex: 'total_hours',
            key: 'hours'
        },
        {
            title: 'data de expiração',
            dataIndex: 'date_expiration',
            key: 'date_expirate',
            render: (data?: string) => {
                return <span>{ data ? formatDate(new Date(data)) : noExists }</span>
            }
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            render: (status: string) =>(
                <Tag key={status} className={`tags-status ${status}`}>
                    {status && status.toUpperCase()}
                </Tag>
            )
        },
        {
            title: 'especializações',
            dataIndex: 'expertises',
            key: 'especializações',
            render: (expertises: any) => (
                <>
                    {(expertises ? expertises.split(',') : []).map((tag: string) => (
                        <Tag key={tag} className={`tags-status ${tag}`}>
                            {tag.toUpperCase()}
                        </Tag>
                    ))}
                </>
            )
        },
        {
            title: 'último acesso scis',
            dataIndex: 'scis20_last_access',
            key: 'last_access',
            render: (data?: string) => {
                return <span>{ data ? formatDate(new Date(data), 'DD/MM/YYYY HH:mm') : noExists }</span>
            }
        },
        {
            title: 'último acesso site',
            dataIndex: 'coach_profile_last_access',
            key: 'coach_profile_last_access',
            render: (data?: string) => {
                return <span>{ data ? formatDate(new Date(data), 'DD/MM/YYYY HH:mm') : noExists }</span>
            }
        },
        {
            title: 'ações',
            key: 'ações',
            render: (element : any) : any=> (
                <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_PROFILE_OPEN])}>
                    <span>
                        <Icon
                            type="fullscreen"
                            onClick={() => {
                                setCurrentProfile(element);
                                const core = centers.find((core: any)=> core.id === element.unit_id);
                                setCurrentCenter(core as ICenter);
                                dispatch(controlProfileIsOpen(true));
                            }}
                        />
                    </span>   
                </RestrictedComponent>
            )
        }
    ];

    if (!checkPermissions(allActionPermissionKeys, true)) {
        columns.pop();
    }

    return (
        <>
            { isLoading && <Loading/> }

            { !centers[0] ? <Loading/> : <Table
                className="tabel-profile-list"
                columns={columns}
                rowKey={(element: any)=> `${element.email + element.id}`}
                dataSource={coaches}
                pagination={false}/>}
            
            { isOpen && <ProfileModal currentProfile={currentProfile} currentCenter={currentCenter} />}

            <Pagination
                className="pagination-profile-list"
                total={total}
                showSizeChanger
                current={page}
                pageSizeOptions={["10","25","50","100"]}
                onShowSizeChange={(current, size)=> dispatch(controlProfilePageSize(size)) }
                onChange={(page) => dispatch(controlProfilePage(page))}/>
        </>
    )
}

export default ProfileList;