import React, { useEffect } from "react";
import { Button, Pagination, Table, Tag } from "antd";
import { formatDateWithHours } from "../../../utils/moment.functions";
import { IInternalLinkListItem } from "../../../models/internal-links.model";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../reducers/reducers";
import { fetchInternalLinks, setInternalLinkInfo } from "../../../reducers/internal-links.reducer";
import { routes } from "../../../utils/routers.constants";
import { history } from "../../../config/Store.config";

import './InternalLinksList.scss'

export const statusTranslations = {
    CREATED: "Criado",
    WAIT_PUBLISH: "Aguardando Publicação",
    PUBLISHED: "Publicado",
    WAITING_CORRECTION: "Aguardando Correção",
    PUBLISHED_WITH_DRAFT: "Publicado com Rascunho"
};

const InternalLinksList = () => {
    const dispatch = useDispatch();
    const isLoading = useSelector(({ internalLinks }: IState) => internalLinks.isLoading);
    const currentItems = useSelector(({ internalLinks }: IState) => internalLinks.internalLinks);
    const total = useSelector(({ internalLinks }: IState) => internalLinks.total);
    const currentPage = useSelector(({ internalLinks }: IState) => internalLinks.currentPage);
    const itemsPerPage = useSelector(({ internalLinks }: IState) => internalLinks.itemsPerPage);
    const { search } = useSelector(({ internalLinks }: IState) => ({
        search: internalLinks.search,
    }));
    const {filter_statuses } = useSelector(({ internalLinks }: any) => ({
        filter_statuses: internalLinks.filter_statuses
    }));

    const handleChangePage = (page: number) => {
        dispatch(setInternalLinkInfo('currentPage', page));
    };

    useEffect(() => {
        dispatch(fetchInternalLinks(currentPage, itemsPerPage));
    }, [currentPage, dispatch, itemsPerPage, search, filter_statuses]);

    const translateStatus = (status: string) => { 
        return statusTranslations[status as keyof typeof statusTranslations] || status;
    };

    const columns = [
        { title: "ID do link", dataIndex: "linkId", key: "linkId" },
        { title: "Email do Coach", dataIndex: "email", key: "email" },
        { title: "Título do Produto", dataIndex: "title", key: "title" },
        { title: "TIpo do produto", dataIndex: "productType", key: "productType" },
        { title: "Preço", dataIndex: "value", key: "value", render: (value: number) => new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value/100) },
        {
            title: "Status", dataIndex: "status", key: "status",
            render: (status: string) => {
                return <Tag className={`links-status ${status}`}>{translateStatus(status)}</Tag>;
            }
        },
        {
            title: 'Data e hora da criação',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (createdAt: string) => formatDateWithHours(createdAt)
        },
        {
            title: 'Data e hora da modificação',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            render: (updatedAt: string) => formatDateWithHours(updatedAt)
        },
        {
            title: "Ação", 
            key: "action",
            render: (_: any, record: IInternalLinkListItem) => (
                <Button type="primary" onClick={() => handleDetail(record)}>Detalhes</Button>
            )
        }
    ]; 

    const handleDetail = (record: IInternalLinkListItem) => {
        const detailPath = routes.INTERNAL_LINKS_DETAIL(record.id);
        history.push({
            pathname: detailPath
        });
    };

    return (
        <>
            <Table
                loading={isLoading}
                columns={columns}
                dataSource={currentItems}
                pagination={false}
                rowKey="linkId"
            />
            <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={total}
                onChange={handleChangePage}
                style={{ marginTop: '16px', textAlign: 'right' }}
                showSizeChanger={false}
            />
        </>
    );
};

export default InternalLinksList;