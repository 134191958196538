import { emailAllErros, keyMapEmail, emailAllSubErros } from "./utils.constants";

export const difference = (object: any, base: any) => {
    let result: any = {};

    for (let key of Object.keys(object)) {
        if (JSON.stringify(object[key]) !== JSON.stringify(base[key])) {
            const type = Array.isArray(object[key]) ? 'isArray' : (typeof object[key] === 'object' && typeof base[key] === 'object' ? 'isObject' : null)
            if (type) {
                result[key] = type === 'isArray' ? [] : {}
                for (let ikey of Object.keys(object[key])) {
                    if (JSON.stringify(object[key][ikey]) !== JSON.stringify(base[key][ikey])) {
                        result[key][ikey] = object[key][ikey];
                    }
                }
            } else {
                result[key] = object[key];
            }
        }
    }

    return result;
};

export const isEmpty = (obj: any): boolean => {
    const hasOwnProperty = Object.prototype.hasOwnProperty;
    if (obj == null) return true;
    if (typeof obj.length !== 'undefined') {
        if (obj.length > 0) return false;
        if (obj.length === 0) return true;
    }
    if (typeof obj !== "object") return true;
    for (var key in obj) {
        if (hasOwnProperty.call(obj, key)) return false;
    }

    return true;
}

export const sanitize = (obj: any): any => {
    if (typeof obj !== 'object') {
        return obj;
    }

    let newObj: any = {};

    for (let key in obj) {
        if (!obj.hasOwnProperty(key)) {
            continue;
        }

        let value: any = obj[key];

        if (typeof value === 'string') {
            value = value.trim();
            newObj[key] = value.length > 0 ? value : null;
        } else if (value && (typeof value === 'object') && !Array.isArray(value)) {
            newObj[key] = sanitize(value);
        } else {
            newObj[key] = value;
        }
    }

    return newObj;
}

export const mapEmail = (error: string) => {
    const key = emailAllErros.find(e => error.includes(e));
    const subKey = emailAllSubErros.find(e => error.includes(e));
    const keySubKey = `${key}_${subKey}`;

    return {
        status: key ? keyMapEmail[key] : key,
        description: (key && subKey) ? keyMapEmail[keySubKey] : keySubKey,
    }
}

export const convertAttachments = (attachments: Array<object>) => attachments.map((att: any) => ({
    name: att.name,
    description: att.description,
    mime: att.mime,
    url: att.url,
    fileName: att.fileName,
    isNew: att.isNew,
}));

export const noExists: string = '';

export const getBase64 = (img: any, callback: Function) => {
    const reader = new FileReader();
    
    reader.addEventListener("load", () => callback(reader.result));
    reader.readAsDataURL(img);
};
