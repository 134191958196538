import { call, put, takeEvery, all, select, delay } from 'redux-saga/effects';
import { setLoading, setInternalLinks, InternalLinkActionTypes, setInternalLink, setInternalLinkInfo } from '../reducers/internal-links.reducer';
import handleError from '../utils/handleError';
import { editInternalLink, getCustomLink, getInternalLink, getInternalLinks } from '../utils/webApi';
import { IState } from '../reducers/reducers';
import { IRequest, IInternalLinkItem, queryStringList, ICheckout } from '../models/internal-links.model';
import { SagaIterator } from 'redux-saga';
import Notification, { NOTIFICATION_TYPE } from '../components/notification/Notification';

function* fetchInternalLinks({ payload }: any) {
    const { page, pageSize } = payload;

    try {
        yield put(setLoading(true));

        let { search, filter_statuses } = yield select(({ internalLinks }: IState) => ({
            search: internalLinks.search,
            filter_statuses: internalLinks.filter_statuses,
        }));

        const queryString = queryStringList({
            status: filter_statuses,
            pageSize,
            search,
            page
        });

        const response: IRequest | undefined = yield call(getInternalLinks, queryString);
        if (response) {
            yield put(setInternalLinks(response.results));
            yield put(setInternalLinkInfo('total', response.total));
        }

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

function* editInternalLinkSaga(action: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { id, observation, status } = action.payload;
        const payload: { observation: string; status?: string } = { observation };
        if (status) {
            payload.status = status;
        }
        yield call(editInternalLink, id, payload);
        yield delay(2000);
        yield call(fetchInternalLink, {payload: {id}})
        Notification(NOTIFICATION_TYPE.SUCCESS,'Sucesso', 'Link interno atualizado com sucesso!');

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

function* fetchInternalLink({ payload }: any) {
    const { id } = payload as {id: string};

    try {
        yield put(setLoading(true));
        const response: IInternalLinkItem | undefined = yield call(getInternalLink, id);
        if (response) {
            yield put(setInternalLink(response));
        }

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

function* fetchCheckoutLink({ payload }: any) {
    const { id } = payload as {id: string};

    try {
        yield put(setLoading(true));
        const response: ICheckout | undefined = yield call(getCustomLink, id);
        if (response && response.url) {
            const w = window.open(response.url, '_blank');
            if (w) {
                w.focus(); // okay now
            }
        }
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

export default function* internalLinkSaga() {
    yield all([
        takeEvery(InternalLinkActionTypes.ASYNC_INTERNAL_LINKS, fetchInternalLinks),
        takeEvery(InternalLinkActionTypes.ASYNC_INTERNAL_LINK, fetchInternalLink),
        takeEvery(InternalLinkActionTypes.UPDATE_INTERNAL_LINK, editInternalLinkSaga),
        takeEvery(InternalLinkActionTypes.ASYNC_GET_CHECKOUT_LINK, fetchCheckoutLink)
    ])
}