export interface IProfileCoacheStore {
    isLoading: boolean,
    coaches: ICoache[],
    page: number,
    pageSize: number,
    total: number,
    search: string,
    isOpen: boolean
};

export interface ICoache {
    city: string,
    coach_profile_last_access?: string,
    complete_cycles: number,
    country: string,
    date_expiration: string,
    email: string,
    expertises: string,
    firstName: string,
    id: string,
    last_session: string,
    openid_address: any,
    openid_email: string,
    openid_enabled: number,
    openid_family_name: string,
    openid_gender: string,
    openid_given_name: string,
    openid_nickname: string,
    openid_phone_number?: string,
    photoUrl: string,
    published: number,
    rating: number,
    scis20_last_access: string,
    secondName: string,
    state: string,
    status: string,
    status_code: number,
    total_coachees: number,
    total_hours: number,
    total_sessions: number,
    unit_id: string,
    username: string
};

export interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string
};

export interface IRequestProfile {
    profiles: ICoache[],
    total: number
};

export const queryStringList = ({ page, pageSize, search }: IQueryStringList) =>
    `?page=${page - 1}${pageSize && `&pageSize=${pageSize}`}${search && `&search=${window.encodeURIComponent(search)}`}` 