import { all, call, put, select, takeEvery, takeLeading } from "redux-saga/effects";
import {
    changeParams,
    changeReports,
    changeLoading,
    reportsPolling,
    changeSetOpenModal,
    changeLoadingGenerate,
    IMonthlyCoachingActionTypes,
} from "../reducers/monthly-coaching.reducer";
import handleError from "../utils/handleError";
import { IState } from "../reducers/reducers";
import {
    deleteReport,
    getMonthlyCoachingReport,
    generateMonthlyCoachingReport,
} from "../utils/webApi";
import { requestUserData } from "../reducers/reports.reducer";
import { IChangedReports, IReport, ReportStatus } from "../models/report.model";
import { isEmpty } from "lodash";

function* asyncGetReport() {
    try {
        const {
            status,
            order_by,
            page,
            pageSize,
            to,
            from,
        } = yield select((state: IState) => state.monthlyCoachingReports);
        
        yield put(changeLoading(true));

        const {reports, total} = yield call(getMonthlyCoachingReport, {
            status: status ? [status] : undefined,
            page,
            pageSize,
            order_by,
            to: to ? new Date(to) : undefined,
            from: from ? new Date(from) : undefined
        });

        yield put(changeReports(reports));
        yield put(changeParams({total}));

        yield put(reportsPolling());
        yield put(requestUserData());
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    }
}

function* asyncGenerateReport() {
    try  {
        yield put(changeLoadingGenerate(true));

        const {
            total,
            reports,
            endMonth,
            pageSize,
            isPolling,
            initialMonth,
        } = yield select((state: IState) => state.monthlyCoachingReports);

        const report: IReport = yield call(
            generateMonthlyCoachingReport,
            initialMonth,
            endMonth
        );

        const newReports = [{ ...report, isLocal: true }, ...reports].slice(0, pageSize);

        yield put(changeReports(newReports));
        yield put(changeSetOpenModal(false));
        yield put(changeParams({ total: total + 1 }));

        if (!isPolling) {
            yield put(reportsPolling());
        }
    } catch (error) {
        handleError(error)
    } finally {
        yield put(changeLoadingGenerate(false));
    }
}

function* pollingForStatus() {
    try {
        const processingKeys = [ReportStatus.CREATED, ReportStatus.PROCESSING];

        const { reports } = yield select((state: IState) => state.monthlyCoachingReports);
        const processingLocalReports = reports.filter((r: IReport) => processingKeys.includes(r.status));

        if(processingLocalReports.length > 0) {
            const response: {reports: IReport[]} = yield call(getMonthlyCoachingReport, {status: processingKeys, page:1, pageSize: 1000});
            let changedReports: IChangedReports = {};
            for (let report of processingLocalReports) {
                const rep = response.reports.find((r: IReport) => r.id === report.id);

                if(rep && rep.status !== report.status) {
                    changedReports = {
                        ...changedReports,
                        [rep.id]: rep.status,
                    }
                } else if(!rep && !report.isLocal) {
                    changedReports = {
                        ...changedReports,
                        [report.id]: ReportStatus.READY
                    }
                }
            }

            if(!isEmpty(changedReports)) {
                yield put(changeReports(reports.map((r: IReport) => ({
                    ...r,
                    isLocal: false,
                    status: changedReports[r.id] || r.status,
                }))));
            }
            yield put(reportsPolling());
        }


    } catch (err) {
        handleError(err);
    }
}

function* requestDeleteReport({ payload }: { type: string; payload: string }) {
    const { reports } = yield select(
        (state: IState) => state.monthlyCoachingReports
    );

    try {
        const filteredReports = reports.filter((el: IReport) => el.id !== payload);
        yield put(changeReports(filteredReports));
        yield call(deleteReport, payload);
    } catch (err) {
        yield put(changeReports(reports));
        handleError(err);
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeLeading(
            IMonthlyCoachingActionTypes.ASYNC_GET_MONTHLY_COACHING_REPORTS,
            asyncGetReport,
        ),
        yield takeLeading(
            IMonthlyCoachingActionTypes.ASYNC_GENERATE_MONTHLY_COACHING_REPORT,
            asyncGenerateReport,
        ),
        yield takeLeading(
            IMonthlyCoachingActionTypes.ASYNC_DELETE_REPORT,
            requestDeleteReport,
        ),
        yield takeEvery(
            IMonthlyCoachingActionTypes.POLLING_FOR_STATUS,
            pollingForStatus,
        )
    ]);
}