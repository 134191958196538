export interface IAudit {
    id: string;
    userId: string;
    action: string;
    name: string;
    email: string;
    realms: string;
    service: string;
    description: string;
    createdAt: string;
    extras: any;
    context: any;
    request: any;
}

interface IQueryStringList {
    pageSize?: number,
    page?: number,
    search?: string,
    action?: string;
}

export const queryStringList = ({ pageSize, page, search, action }: IQueryStringList) => {
    const params = [];

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }

    if (page) {
        params.push(`page=${page}`);
    }

    if (search) {
        params.push(`search=${search}`);
    }

    if (action) {
        params.push(`action=${action}`);
    }

    return `?${params.join('&')}`;
};
