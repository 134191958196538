import { Modal } from 'antd';
import './Notification.scss';

export enum NOTIFICATION_TYPE {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info',
    WARNING = 'warning',
}

const Notification = (type: NOTIFICATION_TYPE, message: string, description: string) => {
    Modal[type]({
        title: message,
        content: description,
        centered: true,
        className: type
      })
};


export default Notification;