import React, {useState, useEffect} from 'react';
import {Radio, Checkbox, Input} from 'antd';

import {FormTypeValue, IAdmin} from '../../../../models/administrator.model';
import {PasswordMode,getDefaultPassword} from '../../../../models/coache.model';

import Generate from 'generate-password';

const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
};

interface IPasswordFormProps {
    isEdit: boolean,
    onChange(name?: string, value?: FormTypeValue): void,
    adminToEdit: IAdmin,
};

function randomPassword() {
    return Generate.generate({
        length: 16,
        excludeSimilarCharacters: true,
        numbers: true
    });
}

enum PasswordType {
    NONE = 0,
    RANDOM = 1,
    CUSTOM = 2,
};

function PasswordForm({isEdit, adminToEdit, onChange}: IPasswordFormProps) {
    const [passwordType, setPasswordType] = useState<PasswordType>(isEdit ? PasswordType.NONE : PasswordType.CUSTOM);

    useEffect(() => {
        let params = [];

        if ((passwordType === PasswordType.CUSTOM || passwordType === PasswordType.RANDOM) && !adminToEdit.password_mode) {
            params.push('password_mode');
            params.push(PasswordMode.PERM);
        }

        if (passwordType === PasswordType.CUSTOM) {
            params.push('password');
            params.push(isEdit ? '' : getDefaultPassword());
        }

        if (passwordType === PasswordType.RANDOM) {
            params.push('password');
            params.push(randomPassword());
        }

        onChange(...params);

        // eslint-disable-next-line
    }, [passwordType]);

    return (
        <>
            <Radio.Group
                value={passwordType}
                onChange={({target}) => setPasswordType(target.value)}
            >
                <Radio
                    style={radioStyle}
                    value={PasswordType.RANDOM}
                >
                    Senha Aleatória
                </Radio>
                <Radio
                    style={radioStyle}
                    value={PasswordType.CUSTOM}
                >
                    Senha Personalizada
                </Radio>
            </Radio.Group>

            <Input
                disabled={passwordType === PasswordType.NONE}
                readOnly={passwordType === PasswordType.RANDOM}
                onChange={({ target }) => onChange('password', target.value)}
                value={adminToEdit.password}
                placeholder='Senha'
            />

            {passwordType === PasswordType.RANDOM && (
                <p style={{color: 'red'}}>
                    Esta será a única vez que você será capaz de ver essa senha. Copie e salve a senha para futura referência, ou você precisará redefinir a senha do usuário novamente.
                </p>
            )}

            <Checkbox onChange={({target}) => onChange('password_mode', target.checked ? PasswordMode.TEMP : PasswordMode.PERM)}>
                Usuário precisará criar uma nova senha após o primeiro acesso com a senha temporária

                <span style={{display: 'block', marginLeft: 23, fontSize: 12}}>
                    não marcar esta opção se o próximo acesso do usuário for no Sistema de Coaching
                </span>
            </Checkbox>
        </>
    );
};

export default PasswordForm;
