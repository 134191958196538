import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import resources, { IResourceStore } from './resources.reducer';
import utils, { IUtilsStore } from './utils.reducer';
import coaches, { ICoachesStore } from './coaches.reducer';
import scisClasses, { IScisClassesStore } from './scis-classes.reducer';
import coachees, { ICoacheesStore } from './coachees.reducer';
import videos, { IVideosStore } from './videos.reducer';
import users, { IUsersStore } from './users.reducer';
import importation, { IImportationStore } from './importation.reducer';
import contact from './contact.reducer';
import { IContactStore } from '../models/contact.modal';
import panelcontrol from '../reducers/panelcontrol.reducer';
import { IPanelControlStore } from '../models/panelcontrol.model';
import panelcontrolcoaching from '../reducers/panelcontrol.coaching.reducer';
import panelcontrolclasses from '../reducers/panelcontrolclasses.reducer';
import { IPanelControlCoachingStore } from '../models/panelcontrol.coaching.model';
import panelcontrolgraph from '../reducers/panelcontrol.graph.reducer';
import { IGraphStore } from '../models/panelcontrol.graph.model';
import profile from './profile.reducer';
import { IProfileCoacheStore } from '../models/profile.modal';
import registerunits from '../reducers/register-units.reducer';
import { IRegisterUnitsStore } from '../models/register-units.model';
import urlShorteners from '../reducers/url-shorteners.reducer';
import { IUrlShortenersStore } from '../models/url-shorteners.model';
import CreateCoaches from './create-coache.reducer';
import { ICreateCoachesStore } from '../models/create-coaches.model';
import blockList, { IBlockListStore } from './blockList.reducer';
import coachReports, { ICoachReportsStore } from './coach-reports.reducer';
import notificationsList, {INotificationsListStore} from './notificationsList.reducer';
import administrators, { IAdminStore } from './administrators.reducer';
import createAdmin from './create-admin.reducer';
import reports, { IReportsStore } from './reports.reducer';
import coachingHoursReports, { ICoachingHoursReportsStore } from './coaching-hours-reports.reducer';
import coachingEvaluationsReports, { ICoachingEvaluationsReportsStore } from './coaching-evaluations-reports.reducer';
import audits, { IAuditStore } from './audit.reducer';
import categories, { ICategoriesStore } from './categories.reducer';
import coachingCycleReports, { ICoachingCycleReportsStore } from './coaching-cycle-reports.reducer';
import  experimentalSessionReports, { IExperimentalSessionReportsStore } from './experimental-session-report.reducer';
import invoicesReports, { IInvoicesReportsStore } from './invoices-reports.reducer';
import monthlyCoachingReports, { IMonthlyCoachingReportsStore } from './monthly-coaching.reducer';
import { IPanelControlClassesStore } from '../models/panelcontrolClasses.model';
import { ISalesRegistrationStore } from "../models/sales-registration.model";
import salesRegistrations from './sales-registrations.reducer';
import { IInternalLinkStore } from '../models/internal-links.model';
import internalLinks from './internal-links.reducer';
import loosePayments, { ILoosePaymentStore } from './loose-payment.reducer';

const RootReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    resources,
    utils,
    coaches,
    scisClasses,
    coachees,
    users,
    importation,
    contact,
    panelcontrol,
    panelcontrolcoaching,
    panelcontrolclasses,
    panelcontrolgraph,
    profile,
    registerunits,
    urlShorteners,
    CreateCoaches,
    blockList,
    notificationsList,
    administrators,
    createAdmin,
    coachReports,
    reports,
    coachingHoursReports,
    invoicesReports,
    coachingCycleReports,
    audits,
    coachingEvaluationsReports,
    videos,
    categories,
    experimentalSessionReports,
    monthlyCoachingReports,
    salesRegistrations,
    internalLinks,
    loosePayments,
});

export interface IState {
    resources: IResourceStore,
    utils: IUtilsStore,
    coaches: ICoachesStore,
    salesRegistrations: ISalesRegistrationStore;
    internalLinks: IInternalLinkStore;
    scisClasses: IScisClassesStore,
    users: IUsersStore,
    importation: IImportationStore,
    contact: IContactStore,
    panelcontrol: IPanelControlStore,
    panelcontrolcoaching: IPanelControlCoachingStore,
    panelcontrolclasses: IPanelControlClassesStore,
    panelcontrolgraph: IGraphStore,
    profile: IProfileCoacheStore,
    registerunits: IRegisterUnitsStore,
    urlShorteners: IUrlShortenersStore,
    CreateCoaches: ICreateCoachesStore,
    coachees: ICoacheesStore,
    blockList: IBlockListStore,
    notificationsList: INotificationsListStore,
    administrators: IAdminStore,
    createAdmin: ICreateCoachesStore,
    coachReports: ICoachReportsStore,
    reports: IReportsStore,
    coachingHoursReports: ICoachingHoursReportsStore,
    invoicesReports: IInvoicesReportsStore,
    coachingCycleReports: ICoachingCycleReportsStore,
    coachingEvaluationsReports: ICoachingEvaluationsReportsStore,
    experimentalSessionReports: IExperimentalSessionReportsStore,
    audits: IAuditStore,
    videos: IVideosStore,
    categories: ICategoriesStore,
    monthlyCoachingReports: IMonthlyCoachingReportsStore,
    loosePayments: ILoosePaymentStore,
};

export default RootReducer;
