import React, {useCallback} from 'react';
import { Select, Form, Button } from 'antd';

import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';
import './SearchFilter.scss';

const { Option } = Select;

const StatusOptions: { label: string, value?: string }[] = [
    { label: 'Todos', value: '' },
    { label: 'Criado', value: 'created' },
    { label: 'Processando', value: 'processing' },
    { label: 'Pronto', value: 'ready' },
    { label: 'Removido', value: 'removed' },
    { label: 'Erro', value: 'error' },
]

interface IReportSearchFilterProps {
    statusSelector(p: IState): string | null,
    toSelector(p: IState): string | null,
    fromSelector(p: IState): string | null,
    onSearchAction(): void,
    changeParams(p: any): void,
};

const SearchFilter = ({
    statusSelector,
    toSelector,
    fromSelector,
    onSearchAction,
    changeParams,
}: IReportSearchFilterProps) => {

    const status = useSelector(statusSelector);

    const to = useSelector(toSelector);
    const from = useSelector(fromSelector);

    const dispatch = useDispatch();

    const onSearch = () => {
        dispatch(onSearchAction());
    };

    const changeStatus = useCallback((statuses: string) => dispatch(changeParams({status: statuses})), [dispatch, changeParams]);

    const cleanFrom = useCallback(() => dispatch(changeParams({from: ''})), [dispatch, changeParams]);
    const cleanTo = useCallback(() => dispatch(changeParams({to: ''})), [dispatch, changeParams]);

    const changeFrom = useCallback((_: string, from: string) => dispatch(changeParams({from})), [dispatch, changeParams]);
    const changeTo = useCallback((_: string, to: string) => dispatch(changeParams({to})), [dispatch, changeParams]);

    return (
        <Form className="filter-content2">
            <div className="input-filter">
                <label>Filtrar Por Status</label>
                <Select
                    mode="default"
                    placeholder="Todos"
                    maxTagCount={1}
                    style={{ width: 220 }}
                    value={status || undefined}
                    onChange={changeStatus}>

                    {StatusOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>
            <div className="div-input-date" >
                <span>Início:</span>
                <MaskedDatePicker
                    onChange={changeFrom}
                    currentValue={from || ''}
                    stringType={'enrollment_date'}
                    classText="input-date"
                    placeholder=""
                    onClean={cleanFrom}
                />
            </div>
            <div className="div-input-date" >
                <span>Fim:</span>
                <MaskedDatePicker
                    onChange={changeTo}
                    currentValue={to || ''}
                    stringType={'enrollment_date'}
                    classText="input-date"
                    placeholder=""
                    onClean={cleanTo}
                />
            </div>
            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search">
                Pesquisar
            </Button>
        </Form>
    )
};

export default SearchFilter;
