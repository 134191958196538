import { Icon, Upload } from "antd";
import { RcCustomRequestOptions } from "antd/lib/upload/interface";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { IVideo } from "../../models/video.model";
import { getPostUrls, uploadFile } from "../../utils/webApi";

import "./VideoUpload.scss";

interface IPropsUploadVideo {
    disabled: boolean,
    currentEdit: IVideo,
    setCurrentEdit: (video:IVideo) => void,
};

const getVideoDuration = (file: any) =>   
    new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            const media = new Audio(reader.result as any);
            media.onloadedmetadata = () => resolve(Math.round(media.duration));
        };

        reader.readAsDataURL(file);
        reader.onerror = error => reject(error);
    });

const VideoUpload = ({
    disabled,
    setCurrentEdit,
    currentEdit,
}: IPropsUploadVideo) => {

    const [hasVideo, setHasVideo] = useState();
    const {name} = useSelector(({videos}: any) => ({name: videos.videoModal.name}));    

    async function handleSetVideo(video: any) {
        const duration = await getVideoDuration(video);
        const keyDuration: string = "duration"
        updatePayload(keyDuration, duration);
    }

    const handleChange = async (info: any) => {
        setHasVideo(info.file.name);
        handleSetVideo(info.file.originFileObj);
    };

    const handleRequest = async ({file, onSuccess}: RcCustomRequestOptions) => {
        const data = {
            mime: file.type,
        };
        const keyUpload: string = "uploadedVideo"
        updatePayload(keyUpload, file);
        const urls = await getPostUrls(data);
        onSuccess({name: file.name, status: "done", url: urls.get, thumbUrl: urls.get}, file)
        await uploadFile(urls.put, file, file.type);
    };
    
    function updatePayload(key: string, value: any) {

        let item = {...currentEdit};
        item = {...item, [key]: value};
        setCurrentEdit(item as IVideo);
    };

    return (
        <>
            <div>
                <Upload
                    name='video-upload'
                    showUploadList={false}
                    accept={"video/mp4"}
                    onChange={handleChange}
                    customRequest={handleRequest}
                    disabled={disabled}
                    style={{height: "74px"}}
                >
                    {
                        (hasVideo || (disabled && name)) ? 
                            <div className="video_upload"> {name || hasVideo} </div>
                            :
                            <div className={'upload_btn'}>
                               <Icon type="cloud-upload" style={{ fontSize: 24, marginRight: "8px"}} />
                                <div style={{fontWeight: "bold",}}>
                                    Escolha um Video
                                </div>
                            </div>
                    }
                </Upload>
            </div>        
        </>
    )
};

export default VideoUpload;