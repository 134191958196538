import { ILoadingKey } from "../models/report.model";

//Types
export enum ReportsActionTypes {
    ASYNC_DOWNLOAD_REPORT = '@reports/ASYNC_DOWNLOAD_REPORT',
    CHANGE_LOADING_DOWNLOAD = '@reports/CHANGE_LOADING_DOWNLOAD',
    ASYNC_USER_DATA = '@reports/ASYNC_USER_DATA',
    SET_USERNAMES = '@reports/SET_USERNAMES',
};

//Interfaces
export interface IReportsStore {
    loadingDownload: ILoadingKey,
    usernames: any,
};

export interface ReportsAction {
    type: ReportsActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: IReportsStore = {
    loadingDownload: {},
    usernames: {},
};

export const reducer = (state: IReportsStore = INITIAL_STATE, action: ReportsAction): IReportsStore => {
    switch (action.type) {
        case ReportsActionTypes.CHANGE_LOADING_DOWNLOAD:
            return {...state, loadingDownload: {...state.loadingDownload, [action.payload.reportId]: action.payload.value}}

        case ReportsActionTypes.SET_USERNAMES:
            return {...state, usernames: action.payload};

        default:
            return state;
    };
};

//Actions

export const downloadReport = (reportId: string) => ({
    type: ReportsActionTypes.ASYNC_DOWNLOAD_REPORT,
    payload: reportId,
});

export const changeLoadingDownload = (reportId: string, value: boolean) => ({
    type: ReportsActionTypes.CHANGE_LOADING_DOWNLOAD,
    payload: {reportId, value},
});

export const requestUserData = () => ({
    type: ReportsActionTypes.ASYNC_USER_DATA,
});

export const setUsernames = (usernames: {}) => ({
    type: ReportsActionTypes.SET_USERNAMES,
    payload: usernames,
});

export default reducer;
