import React, { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Resources from '../resources/Resources';
import { routes } from '../../utils/routers.constants';
import SideMenu from '../../components/side-menu/SideMenu';
import Footer from '../../components/footer/Footer';
import Coache from '../coache/Coache';
import Coachee from '../coachee/Coachee';
import User from '../users/User';
import Payments from '../payments/Payments';
import Contact from '../contact/ContactUs';
import Integrations from '../integrations/Integrations';
import { useDispatch, useSelector } from 'react-redux';
import { getSerializeUtils } from '../../reducers/utils.reducer';
import PanelControl from '../panelcontrol/PanelControl';
import PanelCoaches from '../panelcoaches/PanelCoaches';
import PanelCoaching from '../panelcoaching/PanelCoaching';
import PanelGraph from '../panelgraph/PanelGraph';
import ProfileCoaches from '../profile/profile_coache';
import RegisterUnits from '../register-units/RegisterUnits';
import UrlShorteners from '../url-shorteners/UrlShorteners';
import NotificationsList from "../notifications-list/NotificationsList";
import Administrators from '../administrators/Administrators';
import CoachReport from "../coach-report/CoachReport";
import Audit from '../audit/audit';

import BlockList from '../blocklist/BlockList';
import { permissionKeys } from '../../utils/permissions';
import RestrictedRoute from '../../components/restricted-route/RestrictedRoute';
import { IState } from '../../reducers/reducers';
import Loading from '../../components/loading/Loading';
import CoachingHoursReport from '../coaching-hours-report/CoachingHoursReport';
import Videos from '../videos/Videos';
import Categories from '../categories/Categories';

import './Dashboard.scss';
import CoachingCycleReport from '../coaching-cycle-report/CoachingCycleReport';
import CoachingEvaluationReport from '../coaching-evaluation-report/CoachingEvaluationReport';
import ExperimentalSessionReport from '../experimental-session-report/ExperimentalSessionReport';
import InvoicesReports from '../invoices-report/InvoicesReport';
import MonthlyCoachingReports from '../monthly-coaching-reports/monthlyCoachingReports';
import ScisClasses from '../scis-classes/ScisClasses';
import PanelClasses from '../panelclasses/PanelClasses';
import SalesRegistrations from '../sales-registrations/SalesRegistrations';
import SalesRegistrationDetail from '../sales-registrations/sales-registration-detail/SalesRegistrationDetail';
import InternalLinks from '../internalLinks/InternalLinks';
import InternalLinksDetails from '../internalLinks/links-detail/LinkDetail';
import LoosePayments from '../loose-payment/LoosePayments';

const Dashboard: React.FC = () => {
    const dispatch = useDispatch();
    const isPermissionsLoading = useSelector(({ utils }: IState) => utils.isPermissionsLoading);

    useEffect(() => {
        setTimeout(() => {
            dispatch(getSerializeUtils());
        }, 500);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <main className="dashboard-container">
                <SideMenu />
                {isPermissionsLoading
                    ? <Loading />
                    : (
                        <section className={`dashboard-content  ${process.env.REACT_APP_STAGE || 'dev'}`}>
                            <Switch>
                                <Route exact={false} path={routes.RESOURCES} component={Resources} />
                                <RestrictedRoute exact path={routes.COACHES} component={Coache} permissions={[permissionKeys.USER_COACHES]} />
                                <RestrictedRoute exact path={routes.COACHEES} component={Coachee} permissions={[permissionKeys.USER_COACHEE]} />
                                <RestrictedRoute exact path={routes.SCIS_CLASSES} component={ScisClasses} permissions={[permissionKeys.USER_COACHEE]} />
                                <RestrictedRoute exact path={routes.USERS} component={User} permissions={[permissionKeys.USER_USERS]} />
                                <RestrictedRoute exact path={routes.PAYMENTS()} component={Payments} permissions={[permissionKeys.USER_PAYMENT]} />
                                <RestrictedRoute exact path={routes.PAYMENTS_LIST} component={Payments} permissions={[permissionKeys.USER_PAYMENT]} />
                                <RestrictedRoute exact path={routes.COACHES_INTEGRATIONS} component={Integrations} permissions={[permissionKeys.USER_IMPORT]} />
                                <RestrictedRoute exact path={routes.AUDIT} component={Audit} permissions={[permissionKeys.USER_AUDIT]} />
                                <Route exact path={routes.CONTACT_US} component={Contact} />
                                <Route exact path={routes.VIDEOS} component={Videos} />
                                <Route exact path={routes.CATEGORIES} component={Categories} />
                                <Route exact path={routes.PANEL_CONTROL} component={PanelControl} />
                                <Route exact path={routes.PANEL_CONTROL_CLASSES} component={PanelClasses} />
                                <Route exact path={routes.PANEL_CONTROL_COACHES} component={PanelCoaches} />
                                <Route exact path={routes.PANEL_CONTROL_COACHING} component={PanelCoaching} />
                                <Route exact path={routes.PANEL_CONTROL_GRAPH} component={PanelGraph} />
                                <RestrictedRoute exact path={routes.PROFILE_COACHES} component={ProfileCoaches} permissions={[permissionKeys.USER_PROFILE]} />
                                <Route exact path={routes.REGISTER_UNITS} component={RegisterUnits} />
                                <Route exact path={routes.URL_SHORTENERS} component={UrlShorteners} />
                                <RestrictedRoute exact path={routes.BLOCKLIST} component={BlockList} permissions={[permissionKeys.USER_BLOCKLIST]} />
                                <Route exact path={routes.NOTIFICATIONS_LIST} component={NotificationsList} />
                                <RestrictedRoute exact path={routes.ADMINISTRATORS} component={Administrators} permissions={[permissionKeys.USER_ADMIN]} />
                                <Route exact path={routes.COACHES_REPORT} component={CoachReport} />
                                <Route exact path={routes.COACHING_HOURS_REPORT} component={CoachingHoursReport} />
                                <Route exact path={routes.COACHING_CYCLE_REPORT} component={CoachingCycleReport} />
                                <Route exact path={routes.INVOICES_REPORT} component={InvoicesReports} permissions={[permissionKeys.REPORT_INVOICES]} />
                                <Route exact path={routes.EVALUATION_REPORT} component={CoachingEvaluationReport} />
                                <Route exact path={routes.EXPERIMENTAL_SESSION_REPORT} component={ExperimentalSessionReport} />
                                <Route exact path={routes.MONTHLY_COACHING} component={MonthlyCoachingReports} />
                                <Route exact path={routes.SALES_REGISTRATIONS_LIST} component={SalesRegistrations} />
                                <Route exact path={routes.SALES_REGISTRATION_DETAIL()} component={SalesRegistrationDetail} />
                                <Route exact path={routes.INTERNAL_LINKS_LIST} component={InternalLinks} />
                                <Route exact path={routes.INTERNAL_LINKS_DETAIL()} component={InternalLinksDetails} />
                                <Route exact path={routes.LOOSE_PAYMENT} component={LoosePayments} permissions={[permissionKeys.FINANCEIRO_LIST]}/>

                                <Redirect from={routes.DASHBOARD} to={routes.PANEL_CONTROL} />
                            </Switch>
                        </section>
                    )
                }
            </main>
            <Footer />
        </>
    );
}

export default Dashboard;
