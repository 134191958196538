export interface IDataCoaching {
    email: string,
    nickname: string,
    phone_number: string,
    stats?: IStats
}

export interface IStats {
    ciclosFinalizados: number,
    coachees: number,
    coacheesFinalizados: number,
    comprometimento: number,
    horasCoach: number,
    horasCoaching: number,
    sessoesExperimentais: number,
    geralTotalCoachees: number,
    sessoesRealizadas: number,
    teamCoachingAlunos: number,
    totalCicloAlignment: number,
    totalCicloAlignmentFinalizado: number,
    totalCicloBusiness: number,
    totalCicloBusinessFinalizado: number,
    totalCicloExecutiveCoaching: number,
    totalCicloExecutiveCoachingFinalizado: number,
    totalCicloExperimental: number,
    totalCicloExperimentalFinalizado: number,
    totalCicloLifeCoaching: number,
    totalCicloLifeCoachingFinalizado: number,
    totalCicloTeam: number,
    totalCicloTeamFinalizado: number,
    totalCoacheeAlignment: number,
    totalCoacheeBusiness: number,
    totalCoacheeComum: number,
    totalCoacheeExperimental: number,
    totalCoacheeTeam: number,
    totalCoachees: number,
    totalSessaoAlignment: number,
    totalSessaoAlignmentFinalizado: number,
    totalSessaoBusiness: number,
    totalSessaoBusinessFinalizado: number,
    totalSessaoComum: number,
    totalSessaoComumFinalizado: number,
    totalSessaoExperimental: number,
    totalSessaoExperimentalFinalizado: number,
    totalSessaoTeam: number,
    totalSessaoTeamFinalizado: number,
    ultimaSessaoTipo: string,
    ultimaSessao: string,
    status: string,
}

export interface IPanelControlCoachingStore {
    dataCoaching: IDataCoaching[],
    isLoading: boolean,
    page: number,
    search: string,
    pageSize: number,
    total: number
}

export const SessionTypes = {
    Comum: 'Comum',
    Experimental: 'Experimental',
    Team: 'Team',
    Alignment: 'Alinhamento',
    Business: 'Business',
};

export interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string
}

export interface IRequest {
    total: number,
    users: IDataCoaching[]
}

export const queryStringList = ({page, pageSize, search}: IQueryStringList) =>
    `?${page && `page=${page - 1}`}${pageSize && `&page_size=${pageSize}`}${search && `&search=${window.encodeURIComponent(search)}`}`
