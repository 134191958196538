import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { IPostNotification, queryStringList } from '../models/notificationsList.model';
import { getNotificationsList, postNewNotification } from '../utils/webApi';
import handleError from '../utils/handleError';
import { IState } from '../reducers/reducers';
import {
    NotificationsListActionTypes,
    setIsLoading,
    setNotificationsList,
    setPageList,
} from '../reducers/notificationsList.reducer';

interface IPayload {
    type: string;
    payload: IPostNotification;
}

function* requestNotificationsList() {
    try {
        const { pageList, currentPage } = yield select(
            ({ notificationsList }: IState) => notificationsList
        );
        const {
            type,
            scope,
            realm, 
            search
        } = yield select(({notificationsList}: IState) => notificationsList.formFilter);
        const newPageList = [...pageList];
        const pageSize = 10;
        yield put(setIsLoading(true));
        const { results, nextPage } = yield call(
            getNotificationsList,
            queryStringList({
                pageSize,
                nextPage: pageList[currentPage],
                type,
                scope,
                realm, 
                search
            })
        );

        if (nextPage) {
            newPageList.push(nextPage);
            yield put(setPageList(newPageList));
        } else {
            newPageList.push(null);
            yield put(setPageList(newPageList));
        }

        yield put(setNotificationsList(results));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    }
}

function* postNotificaiton({ payload }: IPayload) {
    try {
        if (payload.expires_at === '') {
            payload.expires_at = null;
        }
        yield call(postNewNotification, payload);
        yield call(requestNotificationsList);
    } catch (error) {
        handleError(error);
    }
}

function* submitSearchFilters() {
    const pageSize = 10;
    try {
        yield put(setIsLoading(true));
        const {
            type,
            scope,
            realm, 
            search
        } = yield select(({notificationsList}: IState) => notificationsList.formFilter);
        const { results } = yield call(
            getNotificationsList,
            queryStringList({
                pageSize,
                type,
                scope,
                realm,
                search,
            })
        );

        yield put(setNotificationsList(results));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(
            NotificationsListActionTypes.ASYNC_GET_NOTIFICATIONS_LIST,
            requestNotificationsList
        ),
        yield takeEvery(
            NotificationsListActionTypes.ASYNC_POST_NOTIFICATION,
            postNotificaiton
        ),
        yield takeEvery(
            NotificationsListActionTypes.SUBMIT_SEARCH_FILTERS,
            submitSearchFilters
        ),
    ]);
}
