import { all, takeEvery, call, put } from 'redux-saga/effects';
import { ResourceActionTypes, changeResources, changeLoading } from '../reducers/resources.reducer';
import { listResources } from '../utils/webApi';
import { IResource } from '../models/resources.model';
import handleError from '../utils/handleError';

function* getResources() {
    try {
        yield put(changeLoading(true))
        const resources: IResource[] = yield call(listResources);
        yield put(changeResources(resources))
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false))
    };
};

export default function* mySaga() {
    yield all([
        yield takeEvery(ResourceActionTypes.ASYNC_GET_RESOURCES, getResources),
    ])
}