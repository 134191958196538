import React, { useMemo } from 'react';

import { Input, Select } from 'antd';
import MaskedDatePicker from '../../../../components/masked-date-picker/masked-date-picker';
import PasswordForm from '../../coache-edit/password-form/PasswordForm';
import { ICenter, ICountry } from '../../../../models/utils.model';
import { IState } from '../../../../reducers/reducers';
import { useSelector } from 'react-redux';

import '../CoacheCreate.scss';
import '../../coache-edit/CoacheEdit.scss';
import './CoacheInfos.scss';


const { Option } = Select;

interface CoacheCreate {
    onChange(name: string, value: any): void,
    coachEdit: any,
    centers: ICenter[],
    canBecomeCoach: boolean,
    countries: ICountry[]
}

const CoacheCreate = ({ onChange, coachEdit, centers, canBecomeCoach, countries }: CoacheCreate) => {

    const currentUser = useSelector(({ CreateCoaches }:IState)=> CreateCoaches.currentUser);

    

    function onChangeCoachInfo(key: string, value: any) {
        onChange('coach', { ...coachEdit.coach, [key]: key === 'unit_id' ? (value || null) : value })
    };

    function onChangeAddress(key: string, value: any) {
        if (key === 'country') {
            onChange('address', {
                ...coachEdit.address, ...{
                    country: value,
                    region: undefined,
                    locality: undefined
                }
            })
        } else if (key === 'region') {
            onChange('address', {
                ...coachEdit.address, ...{
                    region: value,
                    locality: undefined
                }
            })
        } else {
            onChange('address', { ...coachEdit.address, [key]: value })
        }
    };

    const nameId = useMemo(()=>{
        if(coachEdit.coach) {
            const results = centers.find((element): any => element.id === coachEdit.coach.unit_id);
            if(results) return results.name
        } else return ""
    // eslint-disable-next-line
    },[])

    const disableFields = useMemo((): boolean => {
        return Boolean(canBecomeCoach && !coachEdit.convert_coach)
    }, [canBecomeCoach, coachEdit.convert_coach ]);

    return (
        <>
        

            {!currentUser ? 
            (
            <>
                <fieldset>
                    <legend className="legend-fieldset">Usuário</legend>
                    <Input
                        value={coachEdit.nickname}
                        onChange={({ target }) => onChange('nickname', target.value)}
                        placeholder={'Nome completo'}
                    />

                    <div className="form-group trio-group">
                        <Input
                            className='email-create-coache'
                            value={coachEdit.email}
                            onChange={({ target }) => onChange('email', target.value)}
                            placeholder={'E-mail'}
                        />
                        <Input
                            className='input-cpf'
                            value={coachEdit.cpf}
                            disabled={coachEdit.address ? !(coachEdit.address.country === 'Brasil') : true}
                            onChange={({ target }: any) => onChange('cpf', target.value)}
                            placeholder={'CPF'}
                        />
                        <Select
                            className='select-contry'
                            value={coachEdit.address ? coachEdit.address.country : undefined}
                            allowClear={true}
                            showSearch={true}
                            placeholder={'País'}
                            onChange={(country: string) => onChangeAddress('country', country)}>
                            {countries.map((country: ICountry) => (
                                <Option key={country.url} value={country.name}>{country.name}</Option>
                            ))}
                        </Select>
                    </div>
                    <p className="text-cpf-create-coach" >
                        *CPF só é obrigatório se o país for Brasil
                    </p>
                </fieldset>
                
                <fieldset>
                    <legend className="legend-fieldset">Acesso</legend>
                    <Select
                        disabled={disableFields}
                        allowClear={true}
                        showSearch={true}
                        className="centers-select"
                        // eslint-disable-next-line
                        defaultValue={coachEdit.coach ? nameId : undefined}
                        placeholder={'Núcleo'}
                        onChange={(value: string) => {
                                const newCenter: any = centers.find((element): any => element.name === value );
                                onChangeCoachInfo('unit_id', newCenter.id);
                            }}>
                        {centers.map(({ id, name }: ICenter) => (
                            <Option key={id} value={name}>{name}</Option>
                        ))}
                    </Select>
                    <div className="form-group input-activation">
                        <MaskedDatePicker 
                            onChange={onChangeCoachInfo} 
                            currentValue={(coachEdit.coach && coachEdit.coach.activation_date) ? coachEdit.coach.activation_date : '' } 
                            stringType={"activation_date"} 
                            classText={"date-activation-2"}
                            placeholder={"Data de Ativação"} 
                        />
                        <MaskedDatePicker 
                            onChange={onChangeCoachInfo} 
                            currentValue={(coachEdit.coach && coachEdit.coach.expiration_date) ? coachEdit.coach.expiration_date : '' } 
                            stringType={"expiration_date"} 
                            classText={"date-expiration-2"}
                            placeholder={"Data de Expiração"} 
                        />
                    </div>
                </fieldset>

                <fieldset >
                    <legend className="legend-fieldset">Senha</legend>
                    <PasswordForm 
                        isEdit={Boolean(coachEdit && coachEdit.id)} 
                        onChange={onChange} 
                        coachEdit={coachEdit} 
                    />
                </fieldset>
            </>
        ):
        (
            <>
                <fieldset>
                    <legend className="legend-fieldset">Acesso</legend>
                    <Input
                            value={coachEdit.email}
                            onChange={({ target }) => onChange('email', target.value)}
                            placeholder={'E-mail'}
                    />
                    <Select
                        disabled={disableFields}
                        allowClear={true}
                        showSearch={true}
                        className="centers-select"
                        // eslint-disable-next-line
                        defaultValue={coachEdit.coach ? nameId : undefined}
                        placeholder={'Núcleo'}
                        onChange={(value: string) => {
                                const newCenter: any = centers.find((element): any => element.name === value );
                                onChangeCoachInfo('unit_id', newCenter.id);
                            }}>
                        {centers.map(({ id, name }: ICenter) => (
                            <Option key={id} value={name}>{name}</Option>
                        ))}
                    </Select>
                    <div className="form-group input-activation">
                        <MaskedDatePicker 
                            onChange={onChangeCoachInfo} 
                            currentValue={(coachEdit.coach && coachEdit.coach.activation_date) ? coachEdit.coach.activation_date : '' } 
                            stringType={"activation_date"} 
                            classText={"date-activation-2"}
                            placeholder={"Data de Ativação"} 
                        />
                        <MaskedDatePicker 
                            onChange={onChangeCoachInfo} 
                            currentValue={(coachEdit.coach && coachEdit.coach.expiration_date) ? coachEdit.coach.expiration_date : '' } 
                            stringType={"expiration_date"} 
                            classText={"date-expiration-2"}
                            placeholder={"Data de Expiração"} 
                        />
                    </div>
                </fieldset>

                <h6 className='h6-exist-user' >O usuário já existe na base de dados, mas não é
                     coach. Preencha os campos acima e clique em salvar para transformá-lo em coach e assim ele poder acessar o SCIS.</h6>
            
            </>
        )
        }

        </>
    )
};

export default CoacheCreate;