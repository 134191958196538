import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'antd';
import { IState } from '../../reducers/reducers';
import {
    deleteReport,
    changeParams,
    getMonthlyCoachingReports,
    generateMonthlyCoachingReports,
} from '../../reducers/monthly-coaching.reducer';
import TitleSection from '../../components/title-section/TitleSection';
import ReportList from '../../components/reports-list/ReportList';

const MonthlyCoachingReports = () => {
    const dispatch = useDispatch();

    const total = useSelector(({monthlyCoachingReports}: IState) => monthlyCoachingReports.total);
    const reports = useSelector(({monthlyCoachingReports}: IState) => monthlyCoachingReports.reports);
    const isLoading = useSelector(({monthlyCoachingReports}: IState) => monthlyCoachingReports.isLoading);

    const isLoadingGenerate = useSelector(({monthlyCoachingReports}: IState) => monthlyCoachingReports.isLoadingGenerate);

    const handleGenerateReport = useCallback(() => dispatch(generateMonthlyCoachingReports()), [dispatch]);

    return (
        <section>
            <TitleSection title='Relatório Coaching Mensal'>
                <Button
                    size='large'
                    type='primary'
                    onClick={handleGenerateReport}
                    loading={isLoadingGenerate}
                    disabled={isLoadingGenerate}
                >
                    Gerar relatório
                </Button>
            </TitleSection>

            <ReportList
                reports={reports}
                total={total}
                loading={isLoading}
                getReports={getMonthlyCoachingReports}
                deleteReport={deleteReport}
                changeParams={changeParams}
                toSelector={({ monthlyCoachingReports }: IState) => monthlyCoachingReports.to}
                fromSelector={({ monthlyCoachingReports }: IState) => monthlyCoachingReports.from}
                pageSelector={({ monthlyCoachingReports }: IState) => monthlyCoachingReports.page}
                statusSelector={({ monthlyCoachingReports }: IState) => monthlyCoachingReports.status}
                orderSelector={({ monthlyCoachingReports }: IState) => monthlyCoachingReports.order_by}
                pageSizeSelector={({ monthlyCoachingReports }: IState) => monthlyCoachingReports.pageSize}
            />
        </section>
    );
}

export default MonthlyCoachingReports;
