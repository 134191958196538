import { ICoachee, SearchFields, ICoacheeDetails } from "../models/coachee.model";

//Types
export enum CoacheesActionTypes {
    ASYNC_GET_COACHEES = '@coachees/ASYNC_GET_COACHEES',
    ASYNC_GET_COACHES_CSV = '@coachees/ASYNC_GET_COACHES_CSV',
    ASYNC_GET_COACHEE = '@coachees/ASYNC_GET_COACHEE',
    CHANGE_COACHES = '@coachees/CHANGE_COACHES',
    CHANGE_LOADING = '@coachees/CHANGE_LOADING',
    CHANGE_TOTAL = '@coachees/CHANGE_TOTAL',
    CHANGE_PAGE = '@coachees/CHANGE_PAGE',
    CHANGE_PAGE_SIZE = '@coachees/CHANGE_PAGE_SIZE',
    CHANGE_COACH_EDIT = '@coachees/CHANGE_COACH_EDIT',
    CHANGE_SEARCH_VALUE = '@coachees/CHANGE_SEARCH_VALUE',
    CHANGE_SEARCH_FIELD = '@coachees/CHANGE_SEARCH_FIELD',
    CHANGE_FILTER_SPECS = '@coachees/CHANGE_FILTER_SPECS',
    CHANGE_FILTER_STATUSES = '@coachees/CHANGE_FILTER_STATUSES',
    CHANGE_ORDER = '@coachees/CHANGE_ORDER',
    CHANGE_MODAL_IS_LOADING = '@coachees/CHANGE_MODAL_IS_LOADING',
    CHANGE_ORDER_MODE = '@coachees/CHANGE_ORDER_MODE',
    CHANGE_FILTER_UNIT = '@coachees/CHANGE_UNIT',
    CHANGE_EDIT_IS_OPEN_COACHEEE = '@coachees/CHANGE_EDIT_IS_OPEN_COACHEEE',
    CHANGE_EDIT_IS_OPEN_COACHEE_USER = '@coachees/CHANGE_EDIT_IS_OPEN_COACHEE_USER',
    CHANGE_COACHEE_DETAILS = '@coachees/CHANGE_COACHEE_DETAILS',
    ASYNC_SAVE_COACHEE = '@coachees/ASYNC_SAVE_COACHEE',
    ASYNC_RETRY_COACHEE = '@coachees/ASYNC_RETRY_COACHEE',
    ASYNC_CONFIRM_COACHEE = '@coachees/ASYNC_CONFIRM_COACHEE',
};

//Interfaces
export interface ICoacheesStore {
    coaches: ICoachee[],
    isLoading: boolean,
    isModalLoading: boolean,
    total: number,
    page: number,
    pageSize: number,
    coacheeEdit: Partial<ICoachee> | null
    search: string,
    search_field: SearchFields[],
    order: string,
    order_mode: string,
    isOpenEditCoachee: boolean,
    isOpenEditCoacheeUser: boolean,
    currentCoacheesDetails: ICoacheeDetails[],
};

export interface CoachesAction {
    type: CoacheesActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: ICoacheesStore = {
    coaches: [],
    isLoading: false,
    isModalLoading: false,
    total: 0,
    page: 1,
    pageSize: 10,
    coacheeEdit: null,
    search: '',
    search_field: [],
    order: 'email',
    order_mode: 'asc',
    isOpenEditCoachee: false,
    isOpenEditCoacheeUser: false,
    currentCoacheesDetails: [],
};

export const reducer = (state: ICoacheesStore = INITIAL_STATE, action: CoachesAction): ICoacheesStore => {
    switch (action.type) {
        case CoacheesActionTypes.CHANGE_COACHES:
            return { ...state, coaches: action.payload };

        case CoacheesActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case CoacheesActionTypes.CHANGE_TOTAL:
            return { ...state, total: action.payload };

        case CoacheesActionTypes.CHANGE_COACH_EDIT:
            return { ...state, coacheeEdit: action.payload };

        case CoacheesActionTypes.CHANGE_MODAL_IS_LOADING:
            return { ...state, isModalLoading: action.payload };

        case CoacheesActionTypes.CHANGE_PAGE:
            return { ...state, page: action.payload };

        case CoacheesActionTypes.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, page: 1 };

        case CoacheesActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };

        case CoacheesActionTypes.CHANGE_SEARCH_FIELD:
            return { ...state, search_field: action.payload };

        case CoacheesActionTypes.CHANGE_EDIT_IS_OPEN_COACHEEE:
            return { ...state, isOpenEditCoachee: action.payload };

        case CoacheesActionTypes.CHANGE_EDIT_IS_OPEN_COACHEE_USER:
            return { ...state, isOpenEditCoacheeUser: action.payload };

        case CoacheesActionTypes.CHANGE_COACHEE_DETAILS:
            return { ...state, currentCoacheesDetails: action.payload };

        case CoacheesActionTypes.CHANGE_ORDER:
            return { ...state, order: action.payload.order, order_mode: action.payload.order_mode };

        default:
            return state;
    };
};

//Actions
export const getCoachees = () => ({
    type: CoacheesActionTypes.ASYNC_GET_COACHEES,
});

export const getCoachee = (value: string) => ({
    type: CoacheesActionTypes.ASYNC_GET_COACHEE,
    payload: value
});

export const saveCoachApi = (payload: ICoacheeDetails) => ({
    type: CoacheesActionTypes.ASYNC_SAVE_COACHEE,
    payload,
});

export const changeLoading = (value: boolean) => ({
    type: CoacheesActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeCoacheeEditOpen = (value: boolean) => ({
    type: CoacheesActionTypes.CHANGE_EDIT_IS_OPEN_COACHEEE,
    payload: value
});

export const changeCoacheeUserEditOpen = (value: boolean) => ({
    type: CoacheesActionTypes.CHANGE_EDIT_IS_OPEN_COACHEE_USER,
    payload: value
});

export const changeCoaches = (coaches: ICoachee[]) => ({
    type: CoacheesActionTypes.CHANGE_COACHES,
    payload: coaches
});

export const changeTotal = (total: number) => ({
    type: CoacheesActionTypes.CHANGE_TOTAL,
    payload: total
});

export const changePage = (page: number) => ({
    type: CoacheesActionTypes.CHANGE_PAGE,
    payload: page
});

export const changePageSize = (pageSize: number) => ({
    type: CoacheesActionTypes.CHANGE_PAGE_SIZE,
    payload: pageSize
});

export const changeCoachEdit = (coach: Partial<ICoachee> | null) => ({
    type: CoacheesActionTypes.CHANGE_COACH_EDIT,
    payload: coach
});

export const changeLoadingModal = (value: boolean) => ({
    type: CoacheesActionTypes.CHANGE_MODAL_IS_LOADING,
    payload: value
});

export const changeSearchValue = (value: string) => ({
    type: CoacheesActionTypes.CHANGE_SEARCH_VALUE,
    payload: value
});

export const changeCoacheeDetails = (payload: ICoacheeDetails[]) => ({
    type: CoacheesActionTypes.CHANGE_COACHEE_DETAILS,
    payload,
});

export const changeSearchField = (fields: SearchFields[]) => ({
    type: CoacheesActionTypes.CHANGE_SEARCH_FIELD,
    payload: fields
});

export const changeOrder = (order: string, order_mode: string) => ({
    type: CoacheesActionTypes.CHANGE_ORDER,
    payload: { order, order_mode }
});

export const asyncConfirm = (payload: ICoacheeDetails) => ({
    type: CoacheesActionTypes.ASYNC_CONFIRM_COACHEE,
    payload,
});

export const asyncRetry = (payload: ICoacheeDetails) => ({
    type: CoacheesActionTypes.ASYNC_RETRY_COACHEE,
    payload,
});

export default reducer;
