import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleSection from "../../components/title-section/TitleSection";
import { ICategory } from "../../models/categories.model";
import { changeCategoryEdit, getCategories, saveCategoryApi, saveNewCategory } from "../../reducers/categories.reducer";
import { IState } from "../../reducers/reducers";
import CategoriesEdit from "./categories-edit/CategoriesEdit";
import CategoriesList from "./categories-list/CategoriesList";

const Categories = (): any => {

    const dispatch = useDispatch();

    const { categoryEdit, categories, isLoading, isLoadingSave } = useSelector((state: IState) => state.categories);
    
    function onSaveCategory(category: ICategory, isEdit: boolean) {
        if (isEdit) {
            dispatch(saveCategoryApi(category));
        } else {
            dispatch(saveNewCategory(category))
        }
    };

    function onCancel(){
        dispatch(changeCategoryEdit(null));
    };

    return (
        <section>
            <TitleSection title={"Categorias"}>
                <Button
                    onClick={() => dispatch(changeCategoryEdit({}))}
                    size="large"
                    type="primary"
                >
                    Adicionar Categoria
                </Button>
            </TitleSection>
            <CategoriesList
                onEdit={(category: ICategory) => dispatch(changeCategoryEdit(category))}
                getCategories={() => dispatch(getCategories())}
                categories={categories}
                loading={isLoading}
            />
            {Boolean(categoryEdit) && (
                <Modal
                    width={700}
                    title={ Boolean(categoryEdit && categoryEdit.id) ? "Editar categoria" : "Adicionar categoria" }
                    visible={true}
                    footer={false}
                    maskClosable={false}
                    onCancel={() => onCancel()}
                >
                    <CategoriesEdit
                        isEdit={Boolean(categoryEdit && categoryEdit.id)}
                        onCancel={() => onCancel()}
                        onOk={onSaveCategory}
                        category={categoryEdit as ICategory}
                        loading={isLoadingSave}
                    />
                </Modal>
                )
            }
        </section>
    )
};

export default Categories;