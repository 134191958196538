import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import PanelCoachingFilter from './panelcoaching-filter/PanelCoachingFilter';
import PanelCoachingList from './panelcoaching-list/PanelCoachingList';

const PanelCoaching = () => {

    return (
        <>
            <TitleSection title="COACHING" />

            <PanelCoachingFilter />

            <PanelCoachingList />
        </>
    )
}

export default PanelCoaching;