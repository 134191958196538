import React from "react";
import { useDispatch, useSelector } from "react-redux";

import TitleSection from "../../components/title-section/TitleSection";
import UserList from "./user-list/UserList";
import { IState } from "../../reducers/reducers";
import { IUser } from "../../models/user.model";

import {
    getUsers,
    changeUserEdit,
} from "../../reducers/users.reducer";

import UserEdit from '../../components/edit-user';

const User = (): any => {
    const { isLoading, users, total } = useSelector(
        (state: IState) => state.users
    );

    const dispatch = useDispatch();

    return (
        <section>
            <TitleSection title={"Todos os usuários"} />
            <UserList
                onEditUser={(user: IUser) => dispatch(changeUserEdit(user))}
                getUsers={() => dispatch(getUsers())}
                total={total}
                users={users}
                loading={isLoading}
            />
            <UserEdit/>
        </section>
    );
};

export default User;
