export interface IMessage {
    id: number,
    usuario: string,
    date: string,
    subject: string
}

export interface IContactStore {
    messages: IMessage[],
    messageComplet: any, 
    isLoading: boolean,
    search: string,
    total: number,
    page: number,
    pageSize: number
}

export interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string
}

export const queryStringList = ({ page, pageSize, search }: IQueryStringList) =>
`?page=${page-1}${pageSize && `&pageSize=${pageSize}`}${search && `&search=${window.encodeURIComponent(search)}`}` 

export const queryStringComplet = (id : number) =>
    `${id}`
