import React, { useState, useMemo, useEffect } from 'react';

import { Form, Select, Radio, Button } from 'antd';

import { PaymentType, IPaymentToCreate, ReferenceType, OperationType, PaymentMethod, IPayment } from '../../../models/payment.model';
import { ISOFromMoment} from '../../../utils/moment.functions';
import moment from 'moment-timezone';
import InputComponent from '../../../components/Input/Input';
import { IState } from '../../../reducers/reducers';
import { useSelector } from 'react-redux';
import TextArea from '../../../components/text-area/TextArea';
import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';

import './PaymentCreate.scss';

interface Option { label: string, value: PaymentType };

const _options: Option[] = [{
    label: 'Cielo', value: PaymentType.CIELO,
}, {
    label: 'Cielo Recorrente', value: PaymentType.CIELO_RECURRENT,
}, {
    label: 'PagSeguro', value: PaymentType.PAG_SEGURO,
}, {
    label: 'PagSeguro Recorrente', value: PaymentType.PAG_SEGURO_RECURRENT,
}, {
    label: 'PayPal', value: PaymentType.PAY_PAL,
}, {
    label: 'PayPal Recorrente', value: PaymentType.PAY_PAL_RECURRENT,
}, {
    label: 'Cartão', value: PaymentType.CARD,
}, {
    label: 'Boleto', value: PaymentType.BOLETO,
}, {
    label: 'Ajuste', value: PaymentType.ADJUSTMENT,
}, {
    label: 'Outro', value: PaymentType.OTHER,
}];

interface IProps {
    createPayment(payment: IPaymentToCreate): void,
    payments: IPayment[]
}

const PaymentCreate = ({ createPayment, payments }: IProps) => {
    const [payment, setPayment] = useState<Partial<IPaymentToCreate>>({});
    const { license_types } = useSelector((state: IState) => state.utils);

    useEffect(() => {
        let newPayment: any = { tipo: payment.tipo as PaymentType };

        if (newPayment.tipo === PaymentType.CIELO) {
            newPayment.referente = ReferenceType.CHARGE
        } else if (
            newPayment.tipo === PaymentType.PAG_SEGURO ||
            newPayment.tipo === PaymentType.ADJUSTMENT ||
            newPayment.tipo === PaymentType.OTHER ||
            newPayment.tipo === PaymentType.BOLETO ||
            newPayment.tipo === PaymentType.CARD ||
            newPayment.tipo === PaymentType.PAY_PAL) {

            newPayment.referente = ReferenceType.ACQUISITION

            if (newPayment.tipo === PaymentType.OTHER) {
                newPayment.forma = PaymentMethod.CHECK
            } else if (newPayment.tipo === PaymentType.ADJUSTMENT) {
                newPayment.detalhes = '';
            };
        };

        if (newPayment.tipo === PaymentType.CARD) {
            newPayment.parcelas = 1
            newPayment.operacao = OperationType.CREDIT
        } else {
            newPayment.parcelas = 0;
        };

        //Coloca data de hoje em todos os campos de data visivel;
        const datePickers = document.getElementsByClassName('datePicker');
        for (let x = 0; x < datePickers.length; x++) {
            const key: any = datePickers[x].id
            newPayment[key] = ISOFromMoment(moment());
        };

        setPayment({ ...newPayment });
    }, [payment.tipo])

    const paymentsRecurrent = useMemo(() => {
        let options: { id: string, label: string, date: string }[] = [];
        const allRecurrent = payments.filter((payment: IPayment) => Boolean(payment.recorrente));

        allRecurrent
            .sort((a: IPayment, b: IPayment) => a.recorrente.index < b.recorrente.index ? 1 : -1)
            .forEach(({ recorrente }: IPayment) => {
                const index = options.findIndex(({ id }) => id === recorrente.pagamentoRecorrente.id);
                if (index === -1) {
                    options.push({
                        id: recorrente.pagamentoRecorrente.id,
                        label: `${recorrente.index + 1}/${recorrente.pagamentoRecorrente.duracao} -
                        ${recorrente.pagamentoRecorrente.recorrenteId}`,
                        date: recorrente.pagamentoRecorrente.created.date,
                    });
                }
            });

        return options.sort((a, b) => a.date < b.date ? 1 : -1);
    }, [payments]);

    function onChangePayment(key: string, value: any) {
        setPayment({ ...payment, [key]: value });
    };

    function setDatePaymentNow(key: string){
        const momentDate: moment.Moment = moment(new Date(), 'DD/MM/YYYY');
        onChangePayment(key, ISOFromMoment(momentDate));
    }

    const installments = (): string[] => {
        let times: string[] = [];
        for (let x = 1; x <= 20; x++) {
            times.push(`${x}x`);
        };
        return times;
    };

    return (
        <Form className="payment-create-form">
            <label className="label-form">Tipo</label>
            <Select
                value={payment.tipo}
                onChange={(type: PaymentType) => setPayment({ tipo: type })}
                placeholder="Escolha um tipo de pagamento">
                {_options.map(({ label, value }) => (
                    <Select.Option key={value} value={value}>{label} </Select.Option>
                ))}
            </Select>

            {(
                payment.tipo === PaymentType.CIELO_RECURRENT ||
                payment.tipo === PaymentType.CIELO ||
                payment.tipo === PaymentType.PAG_SEGURO_RECURRENT ||
                payment.tipo === PaymentType.PAY_PAL_RECURRENT) &&
                <InputComponent
                    label={'ID da transação recorrente'}
                    value={payment.recorrenteId}
                    onChange={({ target }) => onChangePayment('recorrenteId', target.value)}
                    placeholder="ID da transação recorrente" />
            }
            {
                payment.tipo === PaymentType.CIELO_RECURRENT &&
                <InputComponent
                    label="Nome do Plano"
                    value={payment.plan}
                    onChange={({ target }) => onChangePayment('plan', target.value)}
                    placeholder="Nome do Plano" />
            }

            {
                payment.tipo && (

                        <div className="label-date-payments" >
                            <label className="label-payment" >Data do Pagamento</label>
                            <MaskedDatePicker 
                                onChange={onChangePayment} 
                                currentValue={payment.dataPagamento || setDatePaymentNow('dataPagamento')} 
                                stringType={"dataPagamento"} 
                                placeholder={'Data do Pagamento'} 
                            />
                        </div>
                )
            }
            {
                payment.tipo &&
                payment.tipo !== PaymentType.ADJUSTMENT && (
                    <InputComponent
                        label="Valor (R$)"
                        value={payment.valor}
                        onChange={({ target }) => onChangePayment('valor', target.value)}
                        placeholder="Valor (R$)" />
                )
            }
            {
                payment.tipo === PaymentType.OTHER &&
                <>
                    <label className="label-form">Forma</label>
                    <Select
                        value={payment.forma}
                        onChange={(type: string) => onChangePayment('forma', type)}
                        placeholder="Forma">

                        <Select.Option value={PaymentMethod.CASH} >
                            Cash
                        </Select.Option>
                        <Select.Option value={PaymentMethod.CHECK}>
                            Cheque
                        </Select.Option>
                        <Select.Option value={PaymentMethod.SPECIFY}>
                            Especificar
                        </Select.Option>
                        <Select.Option value={PaymentMethod.TRANSFER}>
                            Transferência
                        </Select.Option>
                    </Select>
                </>
            }

            {
                (payment.tipo === PaymentType.ADJUSTMENT ||
                    payment.forma === PaymentMethod.SPECIFY) &&
                <TextArea
                    label="Detalhes"
                    value={payment.detalhes}
                    onChange={({ target }) => onChangePayment('detalhes', target.value)}
                    placeholder="Detalhes"
                />
            }

            {
                payment.tipo === PaymentType.BOLETO &&
                <InputComponent
                    label="Código do Boleto"
                    value={payment.codigo}
                    onChange={({ target }) => onChangePayment('codigo', target.value)}
                    placeholder="Código do Boleto" />
            }

            {payment.tipo === PaymentType.CARD &&
                <InputComponent
                    label="Rede"
                    value={payment.rede}
                    onChange={({ target }) => onChangePayment('rede', target.value)}
                    placeholder="Rede" />
            }
            {(
                payment.tipo === PaymentType.CARD) &&
                <>
                    <label className="label-form">Operação</label>
                    <Select
                        value={payment.operacao}
                        onChange={(type: string) => onChangePayment('operacao', type)}
                        placeholder="Operação">
                        <Select.Option
                            value={OperationType.CREDIT}>
                            Crédito
                        </Select.Option>

                        <Select.Option
                            value={OperationType.DEBIT}>
                            Débito
                        </Select.Option>
                    </Select>
                </>
            }

            {(
                payment.operacao === OperationType.CREDIT) &&
                <>
                    <label className="label-form">Parcelas</label>
                    <Select
                        value={payment.parcelas ? `${payment.parcelas}x` : '1x'}
                        onChange={(times: string) => onChangePayment('parcelas', Number(times.replace('x', '')))}
                        placeholder="Parcelas">
                        {installments().map(times => (
                            <Select.Option
                                key={times}
                                value={times}>
                                {times}
                            </Select.Option>
                        ))}
                    </Select>
                </>
            }

            {
                (payment.tipo === PaymentType.CIELO ||
                    payment.tipo === PaymentType.PAY_PAL ||
                    payment.tipo === PaymentType.PAY_PAL_RECURRENT ||
                    payment.tipo === PaymentType.PAG_SEGURO ||
                    payment.tipo === PaymentType.PAG_SEGURO_RECURRENT ||
                    payment.tipo === PaymentType.CIELO_RECURRENT) &&
                <InputComponent
                    label="ID da transação"
                    value={payment.transactionId}
                    onChange={({ target }) => onChangePayment('transactionId', target.value)}
                    placeholder="ID da transação" />
            }
            {
                payment.tipo === PaymentType.CIELO_RECURRENT &&
                <InputComponent
                    label="Cupom"
                    value={payment.coupon}
                    onChange={({ target }) => onChangePayment('coupon', target.value)}
                    placeholder="Cupom" />
            }

            {
                (
                    payment.tipo === PaymentType.PAY_PAL ||
                    payment.tipo === PaymentType.CARD ||
                    payment.tipo === PaymentType.BOLETO ||
                    payment.tipo === PaymentType.OTHER ||
                    payment.tipo === PaymentType.ADJUSTMENT ||
                    payment.tipo === PaymentType.PAG_SEGURO) &&
                <Radio.Group
                    value={payment.referente}
                    onChange={({ target }) => onChangePayment('referente', target.value)}>
                    <label className="label-form">Referente</label>
                    <Radio style={{ display: 'block' }} value={ReferenceType.ACQUISITION}>
                        Aquisição de nova licença
                    </Radio>
                    <Radio value={ReferenceType.CHARGE}>
                        Nova cobrança de pagamento recorrente
                    </Radio>
                </Radio.Group>
            }

            {
                payment.referente === ReferenceType.CHARGE &&
                <>
                    <label className="label-form">Pagamento Recorrente</label>
                    <Select
                        value={payment.pagamentoRecorrenteId}
                        onChange={(id: string) => onChangePayment('pagamentoRecorrenteId', id)}
                        placeholder="Pagamento">
                        {paymentsRecurrent.map(payment => (
                            <Select.Option
                                key={payment.id}
                                value={payment.id}>
                                {payment.label}
                            </Select.Option>
                        ))}
                    </Select>
                </>
            }

            {
                (payment.tipo &&
                    payment.referente !== ReferenceType.CHARGE &&
                    payment.tipo !== PaymentType.CIELO_RECURRENT &&
                    payment.tipo !== PaymentType.PAG_SEGURO_RECURRENT &&
                    payment.tipo !== PaymentType.PAY_PAL_RECURRENT &&
                    payment.tipo !== PaymentType.CIELO) &&
                <>
                    <label className="label-form">Tipo de Licença</label>
                    <Select
                        onChange={(type: string) => onChangePayment('tipoLicencaId', type)}
                        placeholder="Tipo de licença">
                        {
                            license_types
                                .filter((license) => !license.recorrente)
                                .map(license => (
                                    <Select.Option
                                        key={license.id}
                                        value={license.id}>
                                        {license.name}
                                    </Select.Option>)
                                )}
                    </Select>
                </>
            }
            {
                (payment.tipo &&
                    payment.referente !== ReferenceType.CHARGE &&
                    (
                        payment.tipo === PaymentType.PAG_SEGURO_RECURRENT ||
                        payment.tipo === PaymentType.PAY_PAL_RECURRENT
                    )
                ) &&
                <>
                    <label className="label-form">Tipo de Licença Recorrente</label>
                    <Select
                        onChange={(type: string) => onChangePayment('tipoLicencaRecorrenteId', type)}
                        placeholder="Tipo de licença">
                        {
                            license_types
                                .filter((license) => license.recorrente)
                                .map(license => (
                                    <Select.Option
                                        key={license.id}
                                        value={license.id}>
                                        {license.name}
                                    </Select.Option>)
                                )}
                    </Select>
                </>
            }


            {
                (payment.tipo &&
                    payment.referente !== ReferenceType.CHARGE) && (
                        <div className="label-date-payments" >
                            <label className="label-payment" >Data de Ativação</label>
                            <MaskedDatePicker 
                                onChange={onChangePayment} 
                                currentValue={payment.dataAtivacao} 
                                stringType={"dataAtivacao"} 
                                placeholder={"Data de Ativação"} 
                            />
                        </div>
                    )
            }

            {
                payment.tipo === PaymentType.CIELO_RECURRENT &&
                <>
                    <div className="label-date-payments" >
                        <label className="label-payment" >Data de Expiração do 1º pagamento</label>
                        <MaskedDatePicker 
                            onChange={onChangePayment} 
                            currentValue={payment.dataExpiracaoPagto} 
                            stringType={"dataExpiracaoPagto"} 
                            placeholder={"Data de Expiração do 1º pagamento"} 
                        />
                    </div>

                    <div className="label-date-payments" >
                        <label className="label-payment" >Data de Expiração do Plano</label>
                        <MaskedDatePicker 
                            onChange={onChangePayment} 
                            currentValue={payment.dataExpiracaoPlano} 
                            stringType={"dataExpiracaoPlano"} 
                            placeholder={"Data de Expiração do Plano"} 
                        />
                    </div>
                </>
            }
            {
                payment.tipo === PaymentType.CIELO && (
                    <div className="label-date-payments" >
                        <label className="label-payment" >Data de Expiração</label>
                        <MaskedDatePicker 
                            onChange={onChangePayment} 
                            currentValue={payment.dataExpiracao} 
                            stringType={"dataExpiracao"} 
                            placeholder={"Data de Expiração"} 
                        />
                    </div>
                )
            }

            <Button
                onClick={() => createPayment(payment as IPaymentToCreate)}
                className="btn-add"
                type="primary"> Adicionar </Button>
        </Form >
    );
};

export default PaymentCreate;