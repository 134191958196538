import { call, all, takeEvery, put, select, takeLatest, delay} from 'redux-saga/effects';
import { ScisClassesActionTypes, changeLoading, changeCoaches, changeUnits, changeTotal, getAllClass, changeFilterUnit } from '../reducers/scis-classes.reducer';
import { getUnits, getClass, getCoachesScisClasses, getCoachesScisClassesXlsx } from '../utils/webApi';
import handleError from '../utils/handleError';
import { queryStringList,  IClassScisClasses } from '../models/scis-classes.model';
import { IState } from '../reducers/reducers';
import _ from 'lodash';
import moment from 'moment-timezone';

function* requestCoaches(isToDelay = false) {
    try {
        let { page, pageSize, search, search_field, order, order_mode, units, filter_unit, filter_class } = yield select((state: IState) => state.scisClasses);
        yield put(changeLoading(true));
        if (!units || (units.length === 0)) {
            const { centers } = yield call(getUnits);

            yield put(changeUnits([{
                id: '',
                name: 'Todas as unidades'
            }, {
                id: 'true',
                name: 'Tem unidade'
            }, {
                id: 'false',
                name: 'Não tem unidade'
            }, ..._.sortBy(centers, 'name')]));

            const result = centers && centers.find((item: any) => item.name === 'SCIS');

            yield put(changeFilterUnit([result.id]));
            filter_unit = [result.id];
        }
        if (typeof isToDelay === 'boolean' && isToDelay) {
            yield delay(700);
        }

        const { total, users } = yield call(getCoachesScisClasses, queryStringList({ page, pageSize, search, search_field, order, order_mode, filter_unit, filter_class }));
        yield put(changeCoaches(users));
        yield put(changeTotal(total));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
};

function* requestAllClass() {
    try {
        yield put(changeLoading(true));
        const { classes } = yield call(getClass);
        const notNullAbleClasses = classes.filter((e: IClassScisClasses) => e.startDate)
        const nullAbleClasses = classes.filter((e: IClassScisClasses) => !e.startDate).sort((a: IClassScisClasses, b: IClassScisClasses) => b.className.trim().localeCompare(a.className.trim()))
        let sortedClasses = notNullAbleClasses.sort((a: IClassScisClasses, b: IClassScisClasses) => (new Date(b.startDate).getTime()) - (new Date(a.startDate).getTime()));
        let newClasses = [...sortedClasses, ...nullAbleClasses];
        yield put(getAllClass(newClasses));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
};

function* requestXlsx() {
    try {
        let { page, pageSize, search, search_field, order, order_mode, filter_unit, filter_class } = yield select((state: IState) => state.scisClasses);
        let xlsx  = yield call(getCoachesScisClassesXlsx,  queryStringList({ page, pageSize, search, search_field, order, order_mode, filter_unit, filter_class }));

        const xlsxData = new Blob([Buffer.from(xlsx.buffer)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(xlsxData);
        const timestamp = moment().format('YYYYMMDD_HHmmss');

        link.download = `Relatório de Turmas_${timestamp}.xlsx`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    } catch (err) {
        handleError(err);
    }
    finally {
        yield put(changeLoading(false));
    }
};

export default function* MySaga(): any {
    yield all([
        yield takeEvery(ScisClassesActionTypes.ASYNC_GET_COACHES, requestCoaches),
        yield takeEvery(ScisClassesActionTypes.ASYNC_GET_CLASS, requestAllClass),
        yield takeLatest(ScisClassesActionTypes.CHANGE_ORDER, requestCoaches),
        yield takeEvery(ScisClassesActionTypes.ASYNC_GET_COACHES_XLSX , requestXlsx),
    ]);
}
