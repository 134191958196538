import { IAudit } from "../models/audit.model";

// Types
export enum AuditActions {
    ASYNC_GET_AUDITS = '@audit/ASYNC_GET_AUDITS',
    SET_AUDITS = '@audit/SET_AUDITS',
    SET_ACTIONS = '@audit/SET_ACTIONS',
    SET_IS_LOADING = '@audit/SET_IS_LOADING',
    SET_PAGE_SIZE = '@audit/SET_PAGE_SIZE',
    SET_CURRENT_PAGE = '@audit/SET_CURRENT_PAGE',
    CHANGE_PAGE_SIZE = '@audit/CHANGE_PAGE_SIZE',
    ASYNC_GET_ACTIONS = '@audit/CHANGE_PAGE_SIZE'
};

// Interfaces
export interface IAuditStore {
    audits: IAudit[],
    isLoading: boolean,
    page: number,
    pageSize: number,
    total: number,
    actions: string[],
};

export interface IAction {
    type: AuditActions,
    payload: any,
};

// Reducer
const INITIAL_STATE: IAuditStore = {
    audits: [],
    isLoading: false,
    page: 1,
    pageSize: 10,
    total: 0,
    actions: [],
};

export const reducer = (
    state: IAuditStore = INITIAL_STATE,
    action: IAction): IAuditStore => {
    switch (action.type) {
        case AuditActions.SET_AUDITS:
            return {...state, audits: action.payload.audits, total: action.payload.total};

        case AuditActions.SET_IS_LOADING:
            return {...state, isLoading: action.payload};

        case AuditActions.SET_PAGE_SIZE:
            return {...state, pageSize: action.payload};

        case AuditActions.SET_CURRENT_PAGE:
            return {...state, page: action.payload};

        case AuditActions.CHANGE_PAGE_SIZE:
            return {...state, pageSize: action.payload};

        case AuditActions.SET_ACTIONS:
            return {...state, actions: action.payload};

        default:
            return state;
    }
};

// Actions
export const asyncGetAudit = (search: string = '', action: string = '') => ({
    type: AuditActions.ASYNC_GET_AUDITS,
    payload: {search, action},
});

export const asyncGetActions = () => ({
    type: AuditActions.ASYNC_GET_ACTIONS
});

export const setActions = (actions: string[]) => ({
    type: AuditActions.SET_ACTIONS,
    payload: actions,
});

export const setAudits = (audits: IAudit, total: number) => ({
    type: AuditActions.SET_AUDITS,
    payload: {audits, total},
});

export const setIsLoading = (isLoading: boolean) => ({
    type: AuditActions.SET_IS_LOADING,
    payload: isLoading,
});

export const setPageSize = (pageSize: number) => ({
    type: AuditActions.SET_PAGE_SIZE,
    payload: pageSize,
});

export const setCurrentPage = (currentPage: number) => ({
    type: AuditActions.SET_CURRENT_PAGE,
    payload: currentPage,
});

export const changePage = (page: number) => ({
    type: AuditActions.SET_CURRENT_PAGE,
    payload: page
});

export const changePageSize = (pageSize: number) => ({
    type: AuditActions.CHANGE_PAGE_SIZE,
    payload: pageSize
});

export default reducer;
