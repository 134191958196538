import React, { useMemo, useState } from 'react';
import { Input, Select } from 'antd';
import { Gender, ICoache } from '../../../../models/coache.model';
import { ITimezone, ILocale } from '../../../../models/utils.model';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import MaskedDatePicker from '../../../../components/masked-date-picker/masked-date-picker';

import './ProfileForm.scss'

const { Option } = Select;

interface IProps {
    onChange(name: string, value: any): void,
    coachEdit: ICoache,
    timezones: ITimezone[],
    locales: ILocale[]
}

const ProfileForm = ({ onChange, coachEdit, timezones, locales }: IProps) => {

    const coachEditZona = useMemo(() => {
        const timezone = timezones.find((timezone: ITimezone) => timezone.code === coachEdit.zoneinfo);
        if (timezone) return timezone.name
        else return undefined
        // eslint-disable-next-line
    }, [coachEdit.zoneinfo])

    const coachLocale = useMemo(()=>{
        const locale = locales.find((element: ILocale)=> element.code === coachEdit.locale);
        if(locale) return locale.name
        else return undefined
    // eslint-disable-next-line
    },[coachEdit.locale])

    function onChangeSelectValue(key: string, value: any) {
        onChange(key, ['zoneinfo', 'locale', 'gender'].includes(key) ? (value || null) : value);
    };

    const [phoneNumber, setPhoneNumber] = useState<any>(coachEdit.phone_number ? coachEdit.phone_number : "");
    const [contry, setContry] = useState<any>(undefined);

    return (
        <>
            <Input
                className="coach-id"
                addonBefore="ID"
                value={coachEdit.id}
                readOnly={true}
                placeholder={'ID'}
            />

            <div className="form-group" >
                <Input
                    value={coachEdit.email}
                    onChange={({ target }) => onChange('email', target.value)}
                    placeholder={'E-mail'}
                />
                <PhoneInput
                    placeholder="Número de telefone"
                    country={contry || "BR"}
                    limitMaxLength={true}
                    maxLength={17}
                    countrySelectProps={{ unicodeFlags: true }}
                    className="coach-edit-phone-number"
                    value={phoneNumber}
                    onCountryChange={(currentContry) => setContry(currentContry)}
                    onChange={(value) => {
                        setPhoneNumber(value);
                        if (isValidPhoneNumber(value)) onChange('phone_number', value);
                    }} />
            </div>

            <Input
                value={coachEdit.nickname}
                onChange={({ target }) => onChange('nickname', target.value)}
                placeholder={'Nome completo'}
            />

            <div className="form-group">
                <Input
                    value={coachEdit.given_name}
                    onChange={({ target }) => onChange('given_name', target.value)}
                    placeholder={'Primeiro Nome'}
                />
                <Input
                    value={coachEdit.family_name}
                    onChange={({ target }) => onChange('family_name', target.value)}
                    placeholder={'Último Nome'}
                />
            </div>
            <div className="form-group input-date-birthdate">

                <Select
                    defaultValue={coachEdit.gender}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Gênero'}
                    onChange={(value: Gender) => onChangeSelectValue('gender', value)}>
                    <Option value={Gender.MALE}>Masculino</Option>
                    <Option value={Gender.FEMALE}>Feminino</Option>
                </Select>
                <MaskedDatePicker
                    onChange={onChange}
                    currentValue={coachEdit.birthdate}
                    stringType={"birthdate"}
                    classText={"input"}
                    placeholder={"Data de Nascimento"}
                />
            </div>
            <div className="form-group">
                <Select
                    value={coachEditZona}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Fuso horário'}
                    onChange={(value: string) => {
                        const timezone: ITimezone = timezones.find((timezone: ITimezone) => timezone.name === value) || {} as ITimezone
                        onChangeSelectValue('zoneinfo', timezone.code)
                    }}>
                    {timezones.map(timezone => (
                        <Option key={timezone.id}
                            value={timezone.name}>
                            {timezone.name}
                        </Option>
                    ))}
                </Select>
                <Select
                    placeholder={'Idioma'}
                    allowClear={true}
                    showSearch={true}
                    value={coachLocale}
                    onChange={(value: string) => {
                        const newLocale: ILocale = locales.find((element: ILocale)=> element.name === value) || {} as ILocale;
                        onChangeSelectValue('locale', newLocale.code);
                    }}>
                    {locales.map(locale => (
                        <Option
                            key={locale.code}
                            value={locale.name}>
                            {locale.name}
                        </Option>
                    ))}
                </Select>
            </div>
        </>
    );
};

export default ProfileForm;
