import React, { useCallback } from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducers';
import { getExperimentalSessionReports, changeParams, deleteReport, changeSetOpenModal } from '../../reducers/experimental-session-report.reducer';
import ReportList from '../../components/reports-list/ReportList';
import ExperimentalSessionReportModal from './experimental-session-report-modal';

function ExperimentalSessionReport() {
    const dispatch = useDispatch();

    const openModal = useSelector(({experimentalSessionReports}: IState) => experimentalSessionReports.openModal);
    const isLoading = useSelector(({experimentalSessionReports}: IState) => experimentalSessionReports.isLoading);
    const isLoadingGenerate = useSelector(({experimentalSessionReports}: IState) => experimentalSessionReports.isLoadingGenerate);
    const reports = useSelector(({experimentalSessionReports}: IState) => experimentalSessionReports.reports);
    const total = useSelector(({experimentalSessionReports}: IState) => experimentalSessionReports.total);

    const handleOpenModal = useCallback(() => dispatch(changeSetOpenModal(true)), [dispatch]);
    const handleCloseModal = useCallback(() => dispatch(changeSetOpenModal(false)), [dispatch]);

    return (
        <section>
            <TitleSection title={'Relatório de Sessão Experimental'} >
                <Button
                    onClick={handleOpenModal}
                    size="large"
                    loading={isLoadingGenerate}
                    disabled={isLoadingGenerate}
                    type="primary"
                >
                    Gerar relatório
                </Button>
            </TitleSection>

            <ReportList
                reports={reports}
                total={total}
                loading={isLoading}
                getReports={getExperimentalSessionReports}
                deleteReport={deleteReport}
                changeParams={changeParams}
                statusSelector={({ experimentalSessionReports }: IState) => experimentalSessionReports.status}
                toSelector={({ experimentalSessionReports }: IState) => experimentalSessionReports.to}
                fromSelector={({ experimentalSessionReports }: IState) => experimentalSessionReports.from}
                orderSelector={({ experimentalSessionReports }: IState) => experimentalSessionReports.order_by}
                pageSelector={({ experimentalSessionReports }: IState) => experimentalSessionReports.page}
                pageSizeSelector={({ experimentalSessionReports }: IState) => experimentalSessionReports.pageSize}
            />
            {openModal && (
                <ExperimentalSessionReportModal
                    loading={isLoadingGenerate}
                    onClose={handleCloseModal}
                />
            )
            };
        </section>
    );
};

export default ExperimentalSessionReport;
