import React from 'react';
import { Table, Icon, Pagination, Typography } from 'antd';

import { ICoachee } from '../../../models/coachee.model';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { changePageSize, changePage, getCoachee, changeOrder } from '../../../reducers/coachees.reducer';
import SearchFilter from './search-filter/SearchFilter';
import useCheckPermissions from '../../../hooks/useCheckPermissions';
import { permissionKeys } from '../../../utils/permissions';
import RestrictedComponent from '../../../components/restricted-component/RestrictedComponent';

import './CoacheeList.scss';
const { Text } = Typography;

const allActionPermissionKeys = [
    permissionKeys.USER_COACHEE_EDIT,
    permissionKeys.USER_COACHEE_USER,
];

interface ICoacheeListProps {
    coaches: ICoachee[],
    loading: boolean,
    total: number,
    onEditUser: (id: string) => void,
};

const CoacheeList = ({ coaches, loading, total, onEditUser }: ICoacheeListProps) => {
    const { page, pageSize } = useSelector(({ coachees }: IState) => ({ page: coachees.page, pageSize: coachees.pageSize }))
    const dispatch = useDispatch();
    const checkPermissions = useCheckPermissions()

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nickname',
            key: 'nickname',
            sorter: true,
            ellipsis: true,
            render: (_: any, record: any) => {
                const { given_name: givenName, family_name: familyName, nickname } = record;

                if (nickname && givenName && familyName) {
                    return `${nickname} (${givenName} ${familyName})`;
                } else if (nickname) {
                    if (givenName || familyName) {
                        return `${nickname} (${givenName || familyName})`;
                    } else {
                        return nickname;
                    }
                } else if (givenName && familyName) {
                    return `${givenName} ${familyName}`;
                } else if (givenName || familyName) {
                    return `${givenName || familyName}`;
                } else {
                    return '(sem nome)';
                }
            },
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ellipsis: true,
        }, {
            title: 'Telefone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: true,
            ellipsis: true,
            width: 200,
        }, {
            title: 'Ações',
            key: 'action',
            render: (coachee: ICoachee) => (
                <span className='icons-action'>
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_COACHEE_USER])}>
                        <Icon type='tool' onClick={() => {
                            dispatch(getCoachee(coachee.id));
                        }} />
                    </RestrictedComponent>
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_COACHEE_EDIT])}>
                        <Icon type='edit' onClick={() => {
                            onEditUser(coachee.id);
                        }} />
                    </RestrictedComponent>
                </span>
            )
        },
    ];

    if (!checkPermissions(allActionPermissionKeys, true)) {
        columns.pop();
    }

    return (
        <>
            <SearchFilter />
            <Table
                className='coach-list'
                pagination={false}
                loading={loading}
                columns={columns}
                dataSource={coaches.map((coache: ICoachee) => ({ ...coache, key: coache.id }))}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={(c1, c2, sorter) => dispatch(changeOrder(sorter.field, sorter.order.replace('end', ''))) }
            />
            <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                <Text><b>{total}</b> coachees encontrados</Text>
            </div>
            <Pagination
                className='coaches-pagination'
                showSizeChanger
                onShowSizeChange={(_, pageSize) => dispatch(changePageSize(pageSize))}
                defaultPageSize={pageSize}
                defaultCurrent={page}
                current={page}
                total={total > 10000 ? 10000 : total}
                pageSizeOptions={['10', '25', '50', '100']}
                onChange={page => dispatch(changePage(page))}
            />
        </>
    );
};

export default CoacheeList;