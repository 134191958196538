import { Input } from "antd";
import React from "react";
import ImageUpload from "../image-upload/ImageUpload";
import { ICategory } from "../../models/categories.model";

import "./PropertiesCategories.scss";

interface IProperties {
    currentEdit: ICategory,
    setCurrentEdit: (category:ICategory) => void,
    isEdit: boolean,
    types: string,
};

const PropertiesCategories = ({types, currentEdit, setCurrentEdit, isEdit}: IProperties) => {

    function onChangeCategory(key: string, language: string, value: string) {
        let item = {...currentEdit};
        item = {...item, [language]: {...item[language as keyof ICategory], [key]: value}};
        
        setCurrentEdit(item as ICategory);
    };

    return (
        <>
            <div className="group-input">
                <div className="input-group">
                    <Input
                        value={currentEdit[types as keyof ICategory].name}
                        onChange={({ target }) => onChangeCategory("name", types, target.value)}
                        placeholder={"Nome da categoria"}
                    />
                    <Input
                        value={currentEdit[types as keyof ICategory].description}
                        onChange={({ target }) => onChangeCategory("description", types, target.value)}
                        placeholder={"Descrição da categoria"}
                    />
                </div>
                <ImageUpload
                    isEdit={isEdit}
                    currentState={currentEdit}
                    setCurrentState={setCurrentEdit}
                    types={types}
                />
            </div>
        </>
    );
};

export default PropertiesCategories;