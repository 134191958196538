import { ISalesRegistrationItem, ISalesRegistrationStore, SearchFields } from "../models/sales-registration.model";

export enum SalesRegistrationActionTypes {
    ASYNC_SALES_REGISTRATIONS
    = '@salesregistrations/ASYNC_GET_REGISTRATIONS',
    SET_SALES_REGISTRATIONS
    = '@salesregistrations/SET_SALESREGISTRATIONS',
    SALES_REGISTRATIONS_ISLOADING
    = '@salesregistrations/SALESREGISTRATIONS_ISLOADING',
    SALES_REGISTRATIONS_SET_SEARCH
    = '@salesregistrations/SALESREGISTRATIONS_SET_SEARCH',
    SALES_REGISTRATIONS_SET_PAGE_SIZE
    = '@salesregistrations/SALESREGISTRATIONS_SET_PAGE_SIZE',
    SALES_REGISTRATIONS_SET_TOTAL
    = '@salesregistrations/SALESREGISTRATIONS_SET_TOTAL',
    UPDATE_SALES_REGISTRATION
    = '@salesregistrations/UPDATE_SALES_REGISTRATION',
    SET_SALES_INFO_REGISTRATIONS
    = '@salesregistrations/SET_SALES_INFO_REGISTRATIONS',
    CHANGE_FILTER_STATUSES
    = '@salesregistrations/CHANGE_FILTER_STATUSES',
    CHANGE_SEARCH_VALUE
    = '@salesregistrations/CHANGE_SEARCH_VALUE',
    CHANGE_SEARCH_FIELD
    = '@salesregistrations/CHANGE_SEARCH_FIELD',
    CHANGE_NEXT_PAGE
    = '@salesregistrations/CHANGE_NEXT_PAGE',
    CHANGE_PREVIOUS_PAGE
    = '@salesregistrations/CHANGE_PREVIOUS_PAGE',
    ASYNC_SALES_REGISTRATION
    = '@salesregistrations/ASYNC_GET_REGISTRATION',
    SET_SALES_REGISTRATION
    = '@salesregistrations/SET_SALESREGISTRATION',
    RESET_PAGE
    = '@salesregistrations/RESET_PAGE',
    TOOGLE_FIELD_ORDER
    = '@salesregistrations/TOOGLE_FIELD_ORDER',
}

export interface SalesRegistrationsState {
    salesRegistrations: ISalesRegistrationItem[];
    isLoading: boolean;
}

export interface SalesRegistrationAction {
    type: SalesRegistrationActionTypes;
    payload: any;
}

const INITIAL_STATE: ISalesRegistrationStore = {
    salesRegistrations: [],
    isLoading: false,
    search: '',
    search_field: [],
    pageSize: 10,
    total: 100,
    nextPage: undefined,
    previousPage: [undefined],
    orderBy: 'createdAt',
    orderMode: 'NEW',
    filter_statuses: undefined,
    salesRegistration: undefined,
};

const reducer = (state: ISalesRegistrationStore = INITIAL_STATE, action: SalesRegistrationAction) => {
    switch (action.type) {
        case SalesRegistrationActionTypes.SET_SALES_REGISTRATIONS:
            return { ...state, salesRegistrations: action.payload };
        case SalesRegistrationActionTypes.SET_SALES_REGISTRATION:
            return { ...state, salesRegistration: action.payload };
        case SalesRegistrationActionTypes.SET_SALES_INFO_REGISTRATIONS:
            return { ...state, [action.payload.key]: action.payload.value };
        case SalesRegistrationActionTypes.SALES_REGISTRATIONS_ISLOADING:
            return { ...state, isLoading: action.payload };
        case SalesRegistrationActionTypes.CHANGE_FILTER_STATUSES:
            return { ...state, filter_statuses: action.payload };
        case SalesRegistrationActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };
        case SalesRegistrationActionTypes.CHANGE_SEARCH_FIELD:
            return { ...state, search_field: action.payload };
        case SalesRegistrationActionTypes.CHANGE_NEXT_PAGE:
            return { ...state, nextPage: action.payload };
        case SalesRegistrationActionTypes.CHANGE_PREVIOUS_PAGE:
            return { ...state, previousPage: action.payload };
        case SalesRegistrationActionTypes.RESET_PAGE:
            return { ...state, nextPage: undefined, previousPage: [undefined] };
        case SalesRegistrationActionTypes.TOOGLE_FIELD_ORDER:
            return {
                ...state,
                orderBy: action.payload.field,
                orderMode: action.payload.order,
            };
        default:
            return state;
    }
};

export default reducer;

export const fetchSalesRegistrations = (isNext = true) => ({
    type: SalesRegistrationActionTypes.ASYNC_SALES_REGISTRATIONS,
    payload: { isNext }
});

export const fetchSalesRegistration = (id: string) => ({
    type: SalesRegistrationActionTypes.ASYNC_SALES_REGISTRATION,
    payload: { id }
});

export const setSalesRegistrations = (salesRegistrations: ISalesRegistrationItem[]) => ({
    type: SalesRegistrationActionTypes.SET_SALES_REGISTRATIONS,
    payload: salesRegistrations,
});

export const setSalesRegistration = (salesRegistration: ISalesRegistrationItem) => ({
    type: SalesRegistrationActionTypes.SET_SALES_REGISTRATION,
    payload: salesRegistration,
});

export const setLoading = (isLoading: boolean) => ({
    type: SalesRegistrationActionTypes.SALES_REGISTRATIONS_ISLOADING,
    payload: isLoading,
});

export const setSalesInfo = (key: string, value: any) => ({
    type: SalesRegistrationActionTypes.SET_SALES_INFO_REGISTRATIONS,
    payload: { key, value },
});

interface EditSalesRegistrationPayload {
    id: string;
    observation: string;
    status?: string;
}

export const editSalesRegistration = (id: string, observation: string, status?: string) => {
    const payload: EditSalesRegistrationPayload = { id, observation };
    if (status) {
        payload.status = status;
    }
    return {
        type: SalesRegistrationActionTypes.UPDATE_SALES_REGISTRATION,
        payload: payload
    };
};


export const changeFilterStatuses = (filter_statuses: string) => ({
    type: SalesRegistrationActionTypes.CHANGE_FILTER_STATUSES,
    payload: filter_statuses
});

export const changeSearchValue = (value: string) => ({
    type: SalesRegistrationActionTypes.CHANGE_SEARCH_VALUE,
    payload: value
});

export const changeSearchField = (fields: SearchFields[]) => ({
    type: SalesRegistrationActionTypes.CHANGE_SEARCH_FIELD,
    payload: fields
});

export const changePreviousPage = (payload: any[]) => ({
    type: SalesRegistrationActionTypes.CHANGE_PREVIOUS_PAGE,
    payload
});

export const changeNextPage = (payload: any) => ({
    type: SalesRegistrationActionTypes.CHANGE_NEXT_PAGE,
    payload
});

export const resetPage = () => ({
    type: SalesRegistrationActionTypes.RESET_PAGE,
});

export const toogleFieldOrder = (payload: any) => ({
    type: SalesRegistrationActionTypes.TOOGLE_FIELD_ORDER,
    payload,
});
