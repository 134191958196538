import { IRegisterUnitsStore, IUnits } from '../models/register-units.model';

export enum RegisterUnitsActionTypes {
    ASYNC_REGISTERUNITS_GET_UNITS = '@registerunits/ASYNC_REGISTERUNITS_GET_UNITS',
    ASYNC_REGISTERUNITS_SEND_NEW_UNITS = '@registerunits/ASYNC_REGISTERUNITS_SEND_NEW_UNITS',
    ASYNC_REGISTERUNITS_SEND_EDIT_UNITS = '@registerunits/ASYNC_REGISTERUNITS_SEND_EDIT_UNITS',
    REGISTERUNITS_UNITS = '@registerunits/REGISTERUNITS_UNITS',
    REGISTERUNITS_ISLOADING = '@registerunits/REGISTERUNITS_ISLOADING',
    REGISTERUNITS_PAGE = '@registerunits/REGISTERUNITS_PAGE',
    REGISTERUNITS_PAGE_SIZE = 'registerunits/REGISTERUNITS_PAGE_SIZE',
    REGISTERUNITS_TOTAL = 'registerunits/REGISTERUNITS_TOTAL',
    REGISTERUNITS_SEARCH = 'registerunits/REGISTERUNITS_SEARCH',
    REGISTERUNITS_MODALISLOADING = 'registerunits/REGISTERUNITS_MODALISLOADING',
    REGISTERUNITS_ISOPEN = 'registerunits/REGISTERUNITS_ISOPEN'
};

const INITIAL_STATE: IRegisterUnitsStore = {
    units: [],
    isLoading: false,
    isLoadingModal: false,
    page: 1,
    pageSize: 10,
    search: '',
    total: 0,
    isOpen: false
};

export interface IAction {
    type: string,
    payload?: any
};

const reducer = (state: IRegisterUnitsStore = INITIAL_STATE, action: IAction) => {
    switch(action.type) {
        case RegisterUnitsActionTypes.REGISTERUNITS_UNITS:
            return { ...state, units: action.payload }
        case RegisterUnitsActionTypes.REGISTERUNITS_ISLOADING:
            return { ...state, isLoading: action.payload }
        case RegisterUnitsActionTypes.REGISTERUNITS_MODALISLOADING:
            return { ...state, isLoadingModal: action.payload }
        case RegisterUnitsActionTypes.REGISTERUNITS_PAGE:
            return { ...state, page: action.payload }
        case RegisterUnitsActionTypes.REGISTERUNITS_PAGE_SIZE:
            return { ...state, pageSize: action.payload }
        case RegisterUnitsActionTypes.REGISTERUNITS_SEARCH:
            return { ...state, search: action.payload }
        case RegisterUnitsActionTypes.REGISTERUNITS_TOTAL:
            return { ...state, total: action.payload }
        case RegisterUnitsActionTypes.REGISTERUNITS_ISOPEN:
            return { ...state, isOpen: action.payload }
        default:
            return state
    }
}

export default reducer;

export const controlRegisterUnitsUnits = ( value: IUnits[] ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_UNITS,
    payload: value
});

export const controlRegisterUnitsIsLoading = ( value: boolean ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_ISLOADING,
    payload: value
});

export const controlRegisterUnitsModalIsLoading = ( value: boolean ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_MODALISLOADING,
    payload: value
});

export const controlRegisterUnitsPage = ( value: number ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_PAGE,
    payload: value
});

export const controlRegisterUnitsPageSize = ( value: number ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_PAGE_SIZE,
    payload: value
});

export const controlRegisterUnitsSearch = ( value: string ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_SEARCH,
    payload: value
});

export const controlRegisterUnitsTotal = ( value: number ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_TOTAL,
    payload: value
});

export const controlRegisterUnitsIsOpen = (value: boolean ) => ({
    type: RegisterUnitsActionTypes.REGISTERUNITS_ISOPEN,
    payload: value
});

export const asyncGetUnits = () => ({
    type: RegisterUnitsActionTypes.ASYNC_REGISTERUNITS_GET_UNITS
});

export const asyncSendNewUnits = (value: any) => ({
    type: RegisterUnitsActionTypes.ASYNC_REGISTERUNITS_SEND_NEW_UNITS,
    payload: value
});

export const asyncSendEditUnits = (value: any) => ({
    type: RegisterUnitsActionTypes.ASYNC_REGISTERUNITS_SEND_EDIT_UNITS,
    payload: value
});