import React from 'react';

interface IProps {
    results: number,
    textIndex: number,
    titleText: string
}

const MiniCard = ({results, textIndex, titleText}: IProps) => (
    <div key={results+textIndex} className="container-panecontrol">
        <span className="panecontrol-results" >{results}</span>
        <span className="panecontrol-title" >{textIndex<=1 ? '# coaches' : ""} <br/>{titleText}</span>
    </div>
) 

export default MiniCard;