import React, { useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Button, Table, Input, Tooltip, Icon } from 'antd';
import './SalesRegistrationDetail.scss';
import TitleSection from '../../../components/title-section/TitleSection';
import { SellerStatus, contractStatusTranslate } from '../../../utils/utils.constants';
import { useDispatch, useSelector } from 'react-redux';
import { editSalesRegistration, fetchSalesRegistration, resetPage } from '../../../reducers/sales-registrations.reducer';
import { history } from '../../../config/Store.config';
import { IState } from '../../../reducers/reducers';
import Loading from '../../../components/loading/Loading';
import { formatDateWithHours } from '../../../utils/moment.functions';

interface Document {
    name: string;
    url: string;
}

interface DetailState {
    detail: any;
    id: string;
}

type SalesRegistrationDetailProps = RouteComponentProps<DetailState>;

const getStatus = (status: string) => {
    const statusKey = status as keyof typeof contractStatusTranslate;
    return contractStatusTranslate[statusKey] || '';
};

const addDocumentIfExists = (documentsArray: Document[], doc: { documentType: string, document: string }, name: string) => {
    if (doc && doc.document) {
        documentsArray.push({
            name: name,
            url: doc.document
        });
    }
};

const SalesRegistrationDetail: React.FC<SalesRegistrationDetailProps> = ({ location, match }) => {
    const id = match.params.id as string;
    const locate = location.state && location.state.detail;
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('');
    const dispatch = useDispatch();
    const { salesRegistration, loading } = useSelector(({ salesRegistrations }: IState) => ({
        salesRegistration: salesRegistrations.salesRegistration,
        loading: salesRegistrations.isLoading,
    }));

    useEffect(() => {
        dispatch(fetchSalesRegistration(id));
    }, [id, dispatch]);

    useEffect(() => {
        return () => {
            dispatch(resetPage());
        };
    }, [dispatch]);

    const detail = useMemo(() => {
        return salesRegistration && salesRegistration.id ? salesRegistration : locate || {};
    }, [salesRegistration, locate]);

    const documentsList: Document[] = useMemo(() => {
        if (!detail.id) {
            return [];
        }
        setNotes(detail.observation);
        setStatus(getStatus(detail.status));

        const documentsArray: Document[] = [];

        if (detail.documents) {
            addDocumentIfExists(documentsArray, { documentType: 'certificateMei', document: detail.documents.certificateMei }, 'Certificado MEI');
            addDocumentIfExists(documentsArray, { documentType: 'cnpjCard', document: detail.documents.cnpjCard }, 'Cartão CNPJ');
        }

        if (detail.documents && detail.documents.documents) {
            detail.documents.documents.forEach((doc: { documentType: string; document: string }, index: number) => {
                addDocumentIfExists(documentsArray, doc, `RG/CNH ${index + 1}`);
            });
        }

        return documentsArray;
    }, [detail]);

    const renderActionButtons = () => {
        const status = detail.status;
        const buttons = [];

        if (status !== SellerStatus.Rejected) {
            buttons.push(<Button key='1' type='primary' onClick={() => handleStatusChange(SellerStatus.Rejected)}>Reprovar cadastro</Button>);
        }

        switch (status) {
            case SellerStatus.Wait:
                buttons.push(<Button key='2' type='primary' onClick={() => handleStatusChange(SellerStatus.Analysis)}>Iniciar análise</Button>);
                break;
            case SellerStatus.Analysis:
                buttons.push(<Button key='3' type='primary' onClick={() => handleStatusChange(SellerStatus.WaitCispay)}>Enviar CISPAY</Button>);
                buttons.push(<Button key='4' type='primary' onClick={() => handleStatusChange(SellerStatus.WaitCorrection)}>Solicitar correção</Button>);
                break;
            case SellerStatus.WaitCispay:
                buttons.push(<Button key='5' type='primary' onClick={() => handleStatusChange(SellerStatus.WaitSigned)}>Gerar contrato</Button>);
                buttons.push(<Button key='6' type='primary' onClick={() => handleStatusChange(SellerStatus.WaitCorrection)}>Solicitar correção</Button>);
                break;
            case SellerStatus.Signed:
                buttons.push(<Button key='7' type='primary' onClick={() => handleStatusChange(SellerStatus.Approved)}>Aprovar cadastro</Button>);
                break;
        }
        return buttons;
    };

    const handleSubmit = (observation: string, status?: string) => {
        if (status) {
            dispatch(editSalesRegistration(detail.id, observation, status));
        } else {
            dispatch(editSalesRegistration(detail.id, observation));
        }
    };

    const handleSaveObservation = () => {
        handleSubmit(notes);
    };

    const handleStatusChange = (newStatus?: string) => {
        setStatus(newStatus || status);
        handleSubmit(notes, newStatus);
    };


    const handleDownload = (fileUrl: string): void => {
        window.open(fileUrl, '_blank');
    };

    const columns = [
        {
            title: 'Documento',
            dataIndex: 'name',
            key: 'name',
            width: '100%',
        },
        {
            title: 'Ação',
            key: 'action',
            render: (_: any, record: Document): JSX.Element => (
                <Tooltip title='Baixar registro' className='report-action'>
                    <Button type='primary' icon='download' onClick={() => handleDownload(record.url)}>
                        Baixar
                    </Button>
                </Tooltip>
            ),
        }
    ];

    const generateAddress = () => {
        let items = [];
        if (detail.addressInfo && detail.addressInfo.street) {
            items.push(detail.addressInfo.street || 'Rua não informada');
            items.push(detail.addressInfo.number || 'Número não informado');
            items.push(detail.addressInfo.district || 'Bairro não informado');
            if (detail.addressInfo.complement) {
                items.push(detail.addressInfo.complement);
            }
            items.push(detail.addressInfo.city || 'Cidade não informado');
            items.push(detail.addressInfo.state || 'Estado não informada');
            items.push(detail.addressInfo.postalCode || 'CEP não informada');
            return items.join(', ');
        } else {
            return undefined;
        }
    };

    const generateBank = () => {
        let items = [];

        if (detail.bankDetail && detail.bankDetail.bank) {
            items.push(detail.bankDetail.bank || 'Banco não informado');
            let branchDigit = detail.bankDetail.branchDigit || 'Dígito não informado';
            items.push(detail.bankDetail.branch ? 'Ag: ' + detail.bankDetail.branch + '-' + branchDigit : 'Agência não informada');
            let digit = detail.bankDetail.accountDigit || 'Dígito não informado';
            items.push(detail.bankDetail.accountNumber ? 'CC: ' + detail.bankDetail.accountNumber + '-' + digit : 'Conta não informada');
            return items.join(', ');
        } else {
            return undefined;
        }
    };

    return (
        <>
            <div className='sales-registration-detail-header'>
                <Button type='link' onClick={() => history.goBack()} style={{ marginBottom: 16, color: '#FFF' }}>
                    <Icon type='left' style={{ fontSize: '18px' }} />
                </Button>
                <TitleSection title='Detalhes do cadastro' />
            </div>
            <div className='sales-registration-detail'>
                {
                    !detail || loading
                        ? <Loading />
                        : (
                            <>
                                <div className='input-container'>
                                    <h1 className='detail-subheader'>Dados da empresa</h1>
                                    <div className='duo-input-row'>
                                        <div className='input-row'>
                                            <label htmlFor='name'>Nome</label>
                                            <Input
                                                id='name'
                                                value={detail.companyInfo && detail.companyInfo.name}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='companyName'>Razão Social</label>
                                            <Input
                                                id='companyName'
                                                placeholder='Razão social'
                                                value={detail.companyInfo && detail.companyInfo.corporateReason}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className='duo-input-row'>
                                        <div className='input-row'>
                                            <label htmlFor='fantasyName'>Nome fantasia</label>
                                            <Input
                                                id='fantasyName'
                                                placeholder='Nome fantasia'
                                                value={detail.companyInfo && detail.companyInfo.fantasyName}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='documentType'>{detail.companyInfo && detail.companyInfo.documentType}</label>
                                            <Input
                                                id='documentType'
                                                value={detail.companyInfo && detail.companyInfo.documentValue}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className='duo-input-row'>
                                        <div className='input-row'>
                                            <label htmlFor='email'>E-mail</label>
                                            <Input
                                                id='email'
                                                value={detail.companyInfo && detail.companyInfo.email}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='bankDetails'>Dados Bancários</label>
                                            <Input
                                                id='bankDetails'
                                                placeholder='Dados Bancários (ex: Banco XYZ, Ag: 0001, CC: 12345-6)'
                                                value={generateBank()}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className='input-row'>
                                        <label htmlFor='companyAddress'>Endereço da Empresa</label>
                                        <Input
                                            id='companyAddress'
                                            value={generateAddress()}
                                            disabled
                                        />
                                    </div>
                                    <h1 className='detail-subheader'>Dados do contrato</h1>
                                    <div className='duo-input-row'>
                                        <div className='input-row'>
                                            <label htmlFor='contractStatus'>Status do Contrato</label>
                                            <Input
                                                id='contractStatus'
                                                placeholder='Status do Contrato (ex: Criado)'
                                                value={getStatus(detail.contract.status)}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='status'>Status do Cadastro</label>
                                            <Input
                                                id='status'
                                                placeholder='Status do Cadastro'
                                                value={getStatus(detail.status)}
                                                disabled
                                            />
                                        </div>
                                        <div className='input-row'>
                                            <label htmlFor='status'>Última modificação</label>
                                            <Input
                                                id='status'
                                                placeholder='Status do Cadastro'
                                                value={formatDateWithHours(detail.updateAt)}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                                <Table
                                    dataSource={documentsList}
                                    rowKey={(e, index) => `${e.name}${index}`}
                                    columns={columns}
                                    pagination={false}
                                    className='document-table'
                                />

                                {status === SellerStatus.WaitSigned && (detail.contract && detail.contract.companyUrl) && (
                                    <a href={detail.contract.companyUrl} target='_blank' rel='noopener noreferrer'>Contrato</a>
                                )}

                                <div className='input-row observations-row'>
                                    <label htmlFor='notes'>Observações</label>
                                    <Input.TextArea
                                        id='notes'
                                        rows={4}
                                        placeholder='Observações'
                                        value={notes}
                                        onChange={e => setNotes(e.target.value)}
                                    />
                                </div>
                                <div className='action-buttons'>
                                    {renderActionButtons()}
                                    <Button type='primary' onClick={handleSaveObservation}>Salvar observações</Button>
                                </div>
                            </>
                        )
                }
            </div>
        </>
    );
};

export default React.memo(SalesRegistrationDetail);
