import { call, all, takeEvery, put, select } from 'redux-saga/effects';
import { CoacheesActionTypes, changeLoading, changeCoaches, changeTotal, changeCoacheeDetails, changeCoacheeEditOpen, changeLoadingModal } from '../reducers/coachees.reducer';
import { getCoachees, getCoachee, saveCoachee, retryCoachee, confirmCoachee } from '../utils/webApi';
import handleError from '../utils/handleError';
import { queryStringList } from '../models/coachee.model';
import { IState } from '../reducers/reducers';
import { ICoacheeDetails } from '../models/coachee.model';
import Notification, { NOTIFICATION_TYPE } from '../components/notification/Notification';

export function* requestCoachees() {
    try {
        const {
            page,
            pageSize,
            search,
            search_field,
            order,
            order_mode,
        } = yield select((state: IState) => state.coachees);
        yield put(changeLoading(true));
        const { total, users } = yield call(
            getCoachees,
            queryStringList({ page, pageSize, search, search_field, order, order_mode }),
        );
        yield put(changeCoaches(users));
        yield put(changeTotal(total));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
};

function* getCoacheeDetails({payload: id, isModal = false}: any) {
    const func = isModal ? changeLoadingModal : changeLoading;
    try {
        yield put(func(true));
        const results = yield call(getCoachee, id);
        yield put(changeCoacheeDetails(results.coachees));
        yield put(changeCoacheeEditOpen(true));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(func(false));
    };
}

function* saveCoacheeDetails({payload}: any) {
    try {
        yield put(changeLoadingModal(true));
        const {CoacheeId, CoachId, Habilitado, NomeCompleto, NomeUsual, Telefone} = payload as ICoacheeDetails;
        const update = {Habilitado, NomeCompleto, NomeUsual, Telefone};
        yield call(saveCoachee, CoacheeId, CoachId, update);
        yield getCoacheeDetails({payload: payload.CoacheeId, isModal: true});
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Salvo com Sucesso', 'Coachee salvo com sucesso');
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoadingModal(false));
    };
}

function* retryCoacheeDetails({payload}: any) {
    try {
        const {CoachId, CoacheeId} = payload;
        yield put(changeLoadingModal(true));
        yield call(retryCoachee, CoacheeId, CoachId);
        yield getCoacheeDetails({payload: payload.CoacheeId, isModal: true});
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Retentativa com Sucesso', 'retentativa com sucesso');
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoadingModal(false));
    };
}

function* confirmCoacheeDetails({payload}: any) {
    try {
        const {CoachId, CoacheeId} = payload;
        yield put(changeLoadingModal(true));
        yield call(confirmCoachee, CoacheeId, CoachId);
        yield getCoacheeDetails({payload: payload.CoacheeId, isModal: true});
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Confirmado com Sucesso', 'Confirmado com sucesso');
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoadingModal(false));
    };
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(CoacheesActionTypes.ASYNC_GET_COACHEES, requestCoachees),
        yield takeEvery(CoacheesActionTypes.ASYNC_GET_COACHEE, getCoacheeDetails),
        yield takeEvery(CoacheesActionTypes.ASYNC_SAVE_COACHEE, saveCoacheeDetails),
        yield takeEvery(CoacheesActionTypes.ASYNC_RETRY_COACHEE, retryCoacheeDetails),
        yield takeEvery(CoacheesActionTypes.ASYNC_CONFIRM_COACHEE, confirmCoacheeDetails),
    ]);
}