import React from "react";
import Input from "../../../components/Input/Input";
import { IInvoice } from "../../../models/payment.model";
import { formatDate } from "../../../utils/moment.functions";

import './Invoice.scss';

const Invoice = (props: IInvoice) => {
    return (
        <div className="invoice-container" >
            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.id}
                        readOnly={true}
                        placeholder={'ID'}
                        label="ID:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.email}
                        readOnly={true}
                        placeholder={'email'}
                        label="email:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.name}
                        readOnly={true}
                        placeholder={'Nome'}
                        label="Nome:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.address.state}
                        readOnly={true}
                        placeholder={'Estado'}
                        label="Estado:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.documentType}
                        readOnly={true}
                        placeholder={'Tipo Documento'}
                        label="Tipo Documento:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.documentValue}
                        readOnly={true}
                        placeholder={'Documento'}
                        label="Documento:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.paymentId}
                        readOnly={true}
                        placeholder={'ID Pagamento'}
                        label="ID Pagamento:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.cnpj}
                        readOnly={true}
                        placeholder={'Empresa'}
                        label="Empresa:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.emissionId}
                        readOnly={true}
                        placeholder={'ID Emissão'}
                        label="ID Emissão:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.message}
                        readOnly={true}
                        placeholder={'Messagem Emissão'}
                        label="Messagem Emissão:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.status}
                        readOnly={true}
                        placeholder={'Status Emissão'}
                        label="Status Emissão:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.invoice.situacao}
                        readOnly={true}
                        placeholder={'Situação Emissão'}
                        label="Situação Emissão:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.invoice.lote}
                        readOnly={true}
                        placeholder={'Lote Emissão'}
                        label="Lote Emissão:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.invoice.numero}
                        readOnly={true}
                        placeholder={'Número Emissão'}
                        label="Número Emissão:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.invoice.numeroNfse}
                        readOnly={true}
                        placeholder={'NumeroNfse Emissão'}
                        label="NumeroNfse Emissão:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.invoice.emissao}
                        readOnly={true}
                        placeholder={'Emissão'}
                        label="Emissão:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.service.aliquota}
                        readOnly={true}
                        placeholder={'Aliquota'}
                        label="Aliquota:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.service.value ? `R$: ${props.service.value}`.replace('.', ',') : props.service.value}
                        readOnly={true}
                        placeholder={'Valor'}
                        label="Valor:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={props.service.paymentDate}
                        readOnly={true}
                        placeholder={'Data do Pagamento'}
                        label="Data do Pagamento:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={props.service.description}
                        readOnly={true}
                        placeholder={'Descrição'}
                        label="Descrição:"
                    />
                </div>
            </div>

            <div className="double-item" >
                <div className="container-label" >
                    <Input
                        value={ formatDate(props.createdAt)}
                        readOnly={true}
                        placeholder={'Gerado'}
                        label="Gerado:"
                    />
                </div>
                <div className="container-label" >
                    <Input
                        value={ formatDate(props.updatedAt)}
                        readOnly={true}
                        placeholder={'Atualizado'}
                        label="Atualizado:"
                    />
                </div>
            </div>
        </div>
    )
}

export default Invoice;