import React, { useEffect, useState } from 'react';
import { Table, Pagination, Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { asyncGetAudit, changePage, changePageSize } from '../../../reducers/audit.reducer';
import { formatDate } from '../../../utils/moment.functions';
import { IAudit } from '../../../models/audit.model';
import Details from '../Details/details';

import './list.scss';

const UrlShortenersList = () => {
    
    const [ audit, setAudit ] = useState<IAudit|undefined>(undefined);
    const dispatch = useDispatch();
    const { isLoading, audits, pageSize, page, total } = useSelector(({ audits }: IState )=> ({
        isLoading: audits.isLoading,
        audits: audits.audits,
        pageSize: audits.pageSize,
        page: audits.page,
        total: audits.total,
    }));

    useEffect(()=>{
        dispatch(asyncGetAudit());
        // eslint-disable-next-line 
    },[page, pageSize]);

    
    const columns = [
        {
            title: 'Ação',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Data de Criação',
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (data: string) => (
                <span>{data ? formatDate(new Date(data), 'DD/MM/YYYY HH:mm'): '-'}</span>
            )
        },
        {
            title: 'Nome do auditor',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email do auditor',
            dataIndex: 'email',
            key: 'email'
        },
        {
            title: 'Realms',
            dataIndex: 'realms',
            key: 'realms'
        },
        {
            title: 'Serviço',
            dataIndex: 'service',
            key: 'service'
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description'
        },
        {
            title: 'Ações',
            key: 'ações',
            render: (audit: IAudit)=> {
                return (
                    <>
                        <Icon type='fullscreen' onClick={() => setAudit(audit)}/>
                    </>
                )
            }
        }
    ]
    
    return (
        <div className='container-table-audit' >
            {audit && <Details {...audit} onClose={() => setAudit(undefined)}/>}
            <Pagination
                showSizeChanger
                onShowSizeChange={(_, pageSize) => dispatch(changePageSize(pageSize))}
                defaultPageSize={pageSize}
                defaultCurrent={page}
                current={page}
                total={total}
                pageSizeOptions={['10', '25', '50', '100']}
                onChange={page => dispatch(changePage(page))}
            />
            <Table
                dataSource={audits}
                rowKey={(_, index)=> `${index}`}
                columns={columns}
                loading={isLoading}
                pagination={false} />
        </div>
    )
};

export default UrlShortenersList;