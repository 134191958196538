import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { queryStringList } from "../models/categories.model";
import { allParents, CategoriesActionTypes, changeCategory, changeCategoryEdit, changeLoading, changeLoadingSave, setPageList } from "../reducers/categories.reducer";
import { IState } from "../reducers/reducers";
import handleError from "../utils/handleError";
import { getAllCategories, getCategories, newCategory, saveCategory } from "../utils/webApi";

//Get
function* requestCategories() {
    try {
        yield put(changeLoading(true));
        const { page, pageSize, search, pageList } = yield select ((state:IState) => state.categories);
        const { nextPage, results } = yield call(getCategories, queryStringList({pageSize, search, nextPage: pageList[page]}));

        const newPageList = [...pageList];
        if (nextPage) {
            newPageList.push(nextPage);
            yield put(setPageList(newPageList));
        } else if (page === 0 && !nextPage) {
            yield put(setPageList([]));
        } else {
            newPageList.push(null);
            yield put(setPageList(newPageList));
        }
        yield put(changeCategory(results));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    }
};

//Get Parents
function* requestParents() {
    try {
        yield put(changeLoading(true));

        const allCategories = yield call(getAllCategories);
        const parents: any = [];
        
        allCategories.categories.map((cat: any) =>
            parents.push({name: cat.name, id: cat.id})
        );

        parents.sort((a: any, b: any) => {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
                return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
                return 1;
            }
            return 0;
        });
        yield put(allParents(parents))
    } catch(err) {
        handleError(err)
    } finally {
        yield put(changeLoading(false));
    }
};

//Put 
function* saveCategoryApi({payload}: any) {
    try {
        yield put(changeLoadingSave(true));
        const categoryData = {
            index: payload.index || 1,
            active: payload.active || false,
            properties_pt: {
                name: payload.properties_pt.name,
                description: payload.properties_pt.description,
                thumbnailUrl: payload.properties_pt.thumbnailUrl,
            },
            properties_es: payload.properties_es ? {
                name: payload.properties_es.name,
                description: payload.properties_es.description,
                thumbnailUrl: payload.properties_es.thumbnailUrl,
            } : undefined,
            properties_en: payload.properties_en ? {
                name: payload.properties_en.name,
                description: payload.properties_en.description,
                thumbnailUrl: payload.properties_en.thumbnailUrl,
            } : undefined,
        };

        yield call(saveCategory, categoryData, payload.id);
        yield requestCategories();
        yield put(changeCategoryEdit(null));
    } catch(err) {
        handleError(err)
    } finally {
        yield put(changeLoadingSave(false));
    }
};

//Create
function* saveNewCategory({payload}: any) {
    try {
        yield put(changeLoadingSave(true));

        const categoryData = {
            parent: payload.parent || undefined,
            index: payload.index || 1,
            active: payload.active || false,
            properties_pt: {
                name: payload.properties_pt.name,
                description: payload.properties_pt.description,
                thumbnailUrl: payload.properties_pt.thumbnailUrl,
            },
            properties_es: payload.properties_es ? {
                name: payload.properties_es.name,
                description: payload.properties_es.description,
                thumbnailUrl: payload.properties_es.thumbnailUrl,
            } : undefined,
            properties_en: payload.properties_en ? {
                name: payload.properties_en.name,
                description: payload.properties_en.description,
                thumbnailUrl: payload.properties_en.thumbnailUrl,
            } : undefined,
        };

        yield call(newCategory, categoryData);
        yield requestCategories();
        yield put(changeCategoryEdit(null));
    } catch(err) {
        handleError(err)
    } finally {
        yield put(changeLoadingSave(false));
    }
};

//Enable Disable Category
function* enableDisableCategory({payload}: any) {
   
    const categoryData = {
        active: payload.active,
    };
    
    try {
        yield put(changeLoading(true));
        yield call(saveCategory, categoryData, payload.category.id);
        yield requestCategories();
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    }
};

export default function* MySaga(): any {
    yield all([
        yield takeEvery(CategoriesActionTypes.ASYNC_GET_CATEGORIES, requestCategories),
        yield takeEvery(CategoriesActionTypes.ASYNC_GET_PARENTS, requestParents),
        yield takeEvery(CategoriesActionTypes.ASYNC_SAVE_CATEGORY, saveCategoryApi),
        yield takeEvery(CategoriesActionTypes.ASYNC_SAVE_NEW_CATEGORY, saveNewCategory),
        yield takeEvery(CategoriesActionTypes.ASYNC_ENABLE_DISABLE_CATEGORY, enableDisableCategory),
    ]);
};