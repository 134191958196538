import React, { useState } from 'react';
import {Input, DatePicker, Icon } from 'antd';
import {ISOFromMoment, validateDate, momentFromISO, formatDate, maskDatePicker } from '../../utils/moment.functions'
import moment from 'moment-timezone';

import './masked-date-picker.scss';

interface IMaskedDateInput {
    onChange: Function,
    currentValue?: any,
    stringType: string,
    placeholder: string,
    classText?: string,
    disabled?: boolean,
    onClean?: Function,
    minDate?: string,
};

const MaskedDateInput = ({
    onChange,
    currentValue,
    stringType,
    placeholder,
    classText,
    onClean,
    disabled,
    minDate,
}: IMaskedDateInput) => {

    const [ value, setValue ] = useState(currentValue ? formatDate(currentValue) : '');

    const handleClean = () => {
        if(onClean) {
            onClean();
            setValue('');
        }
    };

    return (
        <>
            <Input
                className={classText ? "input-masked-date-"+classText : "input-masked-date" }
                value={value}
                disabled={disabled || false}
                placeholder={ placeholder }
                onChange={({ target }: any)=>{
                        let newDate = maskDatePicker(target.value);
                        setValue(newDate);
                        if(validateDate(newDate)) {
                            const momentDate: moment.Moment = moment(newDate, 'DD/MM/YYYY');
                            onChange(stringType, momentDate ? ISOFromMoment(momentDate) : null);
                        } else {
                            onChange(stringType, undefined);
                        }
                    }
                }
            />
        {(onClean && value) && <Icon type='close-circle' className="date-picker-clean-icon" onClick={handleClean}/>}
            <DatePicker
                disabledDate={d => minDate ? !d || moment(d).isBefore(minDate) : false}
                className={classText ? "date-picker-masked-date-"+classText : "date-picker-masked-date" }
                placeholder={ placeholder }
                format="DD/MM/YYYY"
                disabled={disabled || false}
                defaultValue={currentValue ? momentFromISO(currentValue) : undefined}
                onChange={(moment, dateString) => {
                    setValue(dateString);
                    onChange(stringType, moment ? ISOFromMoment(moment) : null)
            }} />
        </>
    )
};

export default MaskedDateInput;
