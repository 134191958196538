import { put, all, takeEvery, call } from 'redux-saga/effects';

import { panelcontrolIsLoading, PanelControlActioTypes, controlPanelcontrol, controlPanelcontrolCoaches } from '../reducers/panelcontrol.reducer';
import handleError from '../utils/handleError';
import { getPanelControl } from '../utils/webApi';
import { queryStringList } from '../models/panelcontrol.model';

function* requestPanelcontrol() {

    try{
        yield put(panelcontrolIsLoading(true));
        let data: any = []
        
        const data1Intermediaria: any[] = yield all([
            call(getPanelControl, queryStringList('totalCoaches')),
            call(getPanelControl, queryStringList('totalCoachesInactives')),
            call(getPanelControl, queryStringList('totalCoachesCreatedByThisYear')),
            call(getPanelControl, queryStringList('totalCoachesCreatedByThisMonth')),
            call(getPanelControl, queryStringList('totalCoachesAccessByThisMonth')),
            call(getPanelControl, queryStringList('totalCoachesAccessByThisDay')),
        ])

        const data2Intermediaria: any[] = yield all([
            call(getPanelControl, queryStringList('totalCoachesActives')),
            call(getPanelControl, queryStringList('totalCoachesActivesByTipoLicencaPagavel')),
            call(getPanelControl, queryStringList('totalCoachesActivesByTipoLicencaGratuidade')),
            call(getPanelControl, queryStringList('totalCoachesActivesByTipoLicencaExperimental')),
            call(getPanelControl, queryStringList('totalCoachesExpiredOrReady')),
            call(getPanelControl, queryStringList('totalCoachesActivesByTipoLicencaLegado')),
        ])
        data.push(data1Intermediaria)
        data.push(data2Intermediaria)
        yield put(controlPanelcontrol(data));

    } catch (err){
        handleError(err);
    } finally {
        yield put(panelcontrolIsLoading(false));
    }

};

function* requestPanelcontrolCoaches() {

    try{
        yield put(panelcontrolIsLoading(true));
        
        let data: any = []

        const data1Intermediaria: any[] = yield all([
            call(getPanelControl, queryStringList('totalCoaches')),
            call(getPanelControl, queryStringList('totalCoachesInactives')),
            call(getPanelControl, queryStringList('totalCoachesCreatedByCurrentYear')),
            call(getPanelControl, queryStringList('totalCoachesCreatedByLast3Months')),
            call(getPanelControl, queryStringList('totalCoachesCreatedByCurrentMonth')),
            call(getPanelControl, queryStringList('totalCoachesCreatedByLast30Days')),
            call(getPanelControl, queryStringList('totalCoachesByMaster')),
            call(getPanelControl, queryStringList('totalCoachesByExecutive')),
            call(getPanelControl, queryStringList('totalCoachesByBusiness')),
            call(getPanelControl, queryStringList('totalCoachesByMicrofranchisee')),
            call(getPanelControl, queryStringList('totalCoachesByMindfulness')),
            call(getPanelControl, queryStringList('totalCoachesByHour500')),
            call(getPanelControl, queryStringList('totalCoachesByHour1000')),
            call(getPanelControl, queryStringList('totalCoachesByHour2000')),
        ])

        const data2Intermediaria: any[] = yield all([
            call(getPanelControl, queryStringList('coachees')),
            call(getPanelControl, queryStringList('sessoes')),
            call(getPanelControl, queryStringList('ciclos')),
            call(getPanelControl, queryStringList('horas'))
        ])
        data.push(data1Intermediaria);
        data.push(data2Intermediaria);

        yield put(controlPanelcontrolCoaches(data));

    } catch (err){
        handleError(err);
    } finally {
        yield put(panelcontrolIsLoading(false));
    }

};

export default function* mySaga() {
    yield all([
        takeEvery(PanelControlActioTypes.ASYNC_GET_PANELCONTROL, requestPanelcontrol),
        takeEvery(PanelControlActioTypes.ASYNC_GET_PANELCONTROLCOACHES, requestPanelcontrolCoaches)
    ])
}