import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleSection from '../../components/title-section/TitleSection';
import { Card } from 'antd';
import PanelControlCard from '../../components/panelcontrol-card/PanelControlCard';
import { asyncGetPanelcontrolCoaches } from '../../reducers/panelcontrol.reducer';
import Loading from '../../components/loading/Loading';
import { IState } from '../../reducers/reducers';

const PanelCoaches = () => {

    const { panelControlCoaches, isLoading } = useSelector(({ panelcontrol }: IState)=> panelcontrol)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(asyncGetPanelcontrolCoaches())
    },[dispatch])

    return (
        <>
            {isLoading ? <Loading/> : (
                <>
                    <TitleSection title="COACHES" />
                    <Card title="ESTATÍSTICAS GERAIS" className='mini-card' >
                        <PanelControlCard cardList={panelControlCoaches[0] || []} textIndex={2} />
                    </Card>
                    <Card title="ESTATÍSTICAS DE COACHING" className='mini-card' >
                        <PanelControlCard cardList={panelControlCoaches[1] || []} textIndex={3} />
                    </Card>
                </>
            ) }
        </>
    )
}

export default PanelCoaches;
