import { IReport } from "../models/report.model";

export enum InvoiceReportsActionTypes {
    CHANGE_LOADING = '@invoices-reports/CHANGE_LOADING',
    SET_OPEN_MODAL = '@invoices-reports/SET_OPEN_MODAL',
    CHANGE_LOADING_GENERATE = '@invoices-reports/CHANGE_LOADING_GENERATE',
    CHANGE_PARAMS = '@invoices-reports/CHANGE_PARAMS',
    CHANGE_INVOICES_REPORTS = '@invoices-reports/CHANGE_INVOICES_REPORTS',
    POLLING_FOR_STATUS = '@invoices-reports/POLLING_FOR_STATUS',

    ASYNC_GENERATE_INVOICES_REPORT = '@invoices-reports/ASYNC_GENERATE_INVOICES_REPORT',
    ASYNC_GET_INVOICES_REPORTS = '@invoices-reports/ASYNC_GET_INVOICES_REPORTS',
    ASYNC_DELETE_REPORT = '@invoices-reports/ASYNC_DELETE_REPORT',
};

export interface InvoiceReportsAction {
    type: InvoiceReportsActionTypes,
    payload: any,
};

//Interfaces
export interface IInvoicesReportsStore {
    reports: IReport[],
    isLoading: boolean,
    openModal: boolean,
    isLoadingGenerate: boolean,
    page: number,
    pageSize: number,
    total: number,
    order_by: string,
    status: string | null,
    statusGenerate: string | null |undefined,
    to: string,
    from: string,
    initialData: string | undefined,
    endData: string | undefined,
};

//Reducer
const INITIAL_STATE: IInvoicesReportsStore = {
    reports: [],
    isLoading: false,
    openModal: false,
    isLoadingGenerate: false,
    page: 1,
    pageSize: 10,
    total: 0,
    order_by: 'asc',
    status: null,
    statusGenerate: undefined,
    to: '',
    from: '',
    initialData: undefined,
    endData: undefined,
};

export const reducer = (state: IInvoicesReportsStore = INITIAL_STATE, action: InvoiceReportsAction): IInvoicesReportsStore => {
    switch (action.type) {
        case InvoiceReportsActionTypes.CHANGE_INVOICES_REPORTS:
            return { ...state, reports: action.payload };
        case InvoiceReportsActionTypes.CHANGE_LOADING:
            return {...state, isLoading: action.payload};
        case InvoiceReportsActionTypes.CHANGE_LOADING_GENERATE:
            return { ...state, isLoadingGenerate: action.payload };
        case InvoiceReportsActionTypes.SET_OPEN_MODAL:
            return {...state, openModal: action.payload}
        case InvoiceReportsActionTypes.CHANGE_PARAMS:
            return {...state, ...action.payload};
        default:
            return state;
    }
};

// Actions
export const changeReports = (reports: IReport[]) => ({
    type: InvoiceReportsActionTypes.CHANGE_INVOICES_REPORTS,
    payload: reports,
});

export const changeLoading = (value: boolean) => ({
    type: InvoiceReportsActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeLoadingGenerate = (value: boolean) => ({
    type: InvoiceReportsActionTypes.CHANGE_LOADING_GENERATE,
    payload: value
});

export const changeSetOpenModal = (openModal: boolean) => ({
    type: InvoiceReportsActionTypes.SET_OPEN_MODAL,
    payload: openModal
});

export const changeParams = (params: {}) => ({
    type: InvoiceReportsActionTypes.CHANGE_PARAMS,
    payload: params,
});

export const deleteReport = (reportId: string) => ({
    type: InvoiceReportsActionTypes.ASYNC_DELETE_REPORT,
    payload: reportId,
});

export const generateInvoicesReport = () => ({
    type: InvoiceReportsActionTypes.ASYNC_GENERATE_INVOICES_REPORT,
});

export const getInvoicesReports = () => ({
    type: InvoiceReportsActionTypes.ASYNC_GET_INVOICES_REPORTS,
});

export const reportsPolling = () => ({
    type: InvoiceReportsActionTypes.POLLING_FOR_STATUS,
});

export default reducer;