import { IPreviewInformation, IImportResult, ICoachImport, IImportationForm, ICoachesImportResult } from "../models/import.model";
import { ImportationComponent } from "../containers/integrations/Integrations";

//ResourceTypes
export enum ImportActionsType {
    ASYNC_SEND_FORM_PREVIEW = '@importation/ASYNC_SEND_FORM_PREVIEW',
    ASYNC_REVALIDATE = '@importation/ASYNC_REVALIDATE',
    ASYNC_IMPORT_COACHES = '@importation/ASYNC_IMPORT_COACHES',
    CHANGE_LOADING = '@importation/CHANGE_LOADING',
    CHANGE_PREVIEW_INFORMATIONS = '@importation/CHANGE_PREVIEW_INFORMATIONS',
    CHANGE_RESULT = '@importation/CHANGE_RESULT',
    CHANGE_COMPONENT = '@importation/CHANGE_COMPONENT',
    CHANGE_INDEX_OF_COACH_IN_REQUEST = '@importation/CHANGE_INDEX_OF_COACH_IN_REQUEST',
    CHANGE_COACHES_IMPORT_RESULT = '@importation/CHANGE_COACHES_IMPORT_RESULT',
    CLEAR_IMPORT_STORE = '@importation/CLEAR_IMPORT_STORE'

};

//Interfaces
export interface IImportationStore {
    isLoading: boolean,
    previewInformation: Partial<IPreviewInformation>,
    result: IImportResult | null,
    currentComponent: ImportationComponent,
    indexOfCoachInRequest: number,
    coachesImportResult: ICoachesImportResult
};

export interface IImportationAction {
    type: ImportActionsType,
    payload: any
};

//Reducer
const INITIAL_STATE: IImportationStore = {
    previewInformation: {},
    isLoading: false,
    result: null,
    currentComponent: ImportationComponent.FORM,
    indexOfCoachInRequest: -1,
    coachesImportResult: { error: [], success: [] }
};

export const reducer = (state: IImportationStore = INITIAL_STATE, action: IImportationAction): IImportationStore => {
    switch (action.type) {

        case ImportActionsType.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case ImportActionsType.CHANGE_PREVIEW_INFORMATIONS:
            return { ...state, previewInformation: action.payload };

        case ImportActionsType.CHANGE_RESULT:
            return { ...state, result: action.payload };

        case ImportActionsType.CHANGE_COMPONENT:
            return { ...state, currentComponent: action.payload };

        case ImportActionsType.CHANGE_INDEX_OF_COACH_IN_REQUEST:
            return { ...state, indexOfCoachInRequest: action.payload };

        case ImportActionsType.CHANGE_COACHES_IMPORT_RESULT:
            return { ...state, coachesImportResult: action.payload };

        case ImportActionsType.CLEAR_IMPORT_STORE:
            return INITIAL_STATE;

        default:
            return state;
    };
};

//Actions
export const sendFormPreview = (form: IImportationForm) => ({
    type: ImportActionsType.ASYNC_SEND_FORM_PREVIEW,
    payload: form
});

export const revalidateCoach = (coach: ICoachImport, index: number) => ({
    type: ImportActionsType.ASYNC_REVALIDATE,
    payload: { coach, index }
});

export const importCoaches = (coachesImport: IPreviewInformation) => ({
    type: ImportActionsType.ASYNC_IMPORT_COACHES,
    payload: coachesImport
});

export const changeLoading = (value: boolean) => ({
    type: ImportActionsType.CHANGE_LOADING,
    payload: value
});

export const changePreviewInformation = (previewImformation: IPreviewInformation) => ({
    type: ImportActionsType.CHANGE_PREVIEW_INFORMATIONS,
    payload: previewImformation
});

export const changeResult = (result: IImportResult) => ({
    type: ImportActionsType.CHANGE_RESULT,
    payload: result
});

export const changeComponent = (component: ImportationComponent) => ({
    type: ImportActionsType.CHANGE_COMPONENT,
    payload: component
});

export const changeIndexOfCoachInRequest = (index: number) => ({
    type: ImportActionsType.CHANGE_INDEX_OF_COACH_IN_REQUEST,
    payload: index
});

export const changeCoachesImportResult = (result: ICoachesImportResult) => ({
    type: ImportActionsType.CHANGE_COACHES_IMPORT_RESULT,
    payload: result
});

export const clearImportStore = () => ({
    type: ImportActionsType.CLEAR_IMPORT_STORE,
});

export default reducer;
