import React from 'react';
import './Footer.scss';

const Footer = () => (
    <footer className="footer-container">
        Copyright © 2019
    </footer>
);

export default Footer;
