import { IReport } from "../models/report.model";

// Types
export enum CoachingCycleReportsActionTypes {
    ASYNC_GET_COACHING_CYCLE_REPORTS = '@coaching-cycle-reports/ASYNC_GET_COACHING_CYCLE_REPORTS',
    ASYNC_GENERATE_COACHING_CYCLE_REPORT = '@coaching-cycle-reports/ASYNC_GENERATE_COACHING_CYCLE_REPORT',
    ASYNC_DELETE_REPORT = '@coaching-cycle-reports/ASYNC_DELETE_REPORT',
    CHANGE_COACHING_CYCLE_REPORTS = '@coaching-cycle-reports/CHANGE_COACHING_CYCLE_REPORTS',
    CHANGE_LOADING = '@coaching-cycle-reports/CHANGE_LOADING',
    CHANGE_PARAMS = '@coaching-cycle-reports/CHANGE_PARAMS',
    CHANGE_LOADING_GENERATE = '@coaching-cycle-reports/CHANGE_LOADING_GENERATE',
    POLLING_FOR_STATUS = '@coaching-cycle-reports/POLLING_FOR_STATUS',
    SET_REPORT_DATA = '@coaching-cycle-reports/SET_REPORT_DATA'
};

//Interfaces
export interface ICoachingCycleReportsStore {
    reports: IReport[],
    isLoading: boolean,
    isLoadingGenerate: boolean,
    order_by: string,
    status: string | null,
    to: string,
    from: string,
    page: number,
    pageSize: number,
    total: number,
    initialMonth: string,
    endMonth: string,
    openModal: boolean,
};

export interface CoachingCycleReportsAction {
    type: CoachingCycleReportsActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: ICoachingCycleReportsStore = {
    reports: [],
    isLoading: false,
    isLoadingGenerate: false,
    order_by: 'asc',
    status: null,
    to: '',
    from: '',
    page: 1,
    pageSize: 10,
    total: 0,
    initialMonth: '',
    endMonth: '',
    openModal: false,
};

export const reducer = (state: ICoachingCycleReportsStore = INITIAL_STATE, action: CoachingCycleReportsAction): ICoachingCycleReportsStore => {
    switch (action.type) {
        case CoachingCycleReportsActionTypes.CHANGE_COACHING_CYCLE_REPORTS:
            return { ...state, reports: action.payload };

        case CoachingCycleReportsActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case CoachingCycleReportsActionTypes.CHANGE_LOADING_GENERATE:
            return { ...state, isLoadingGenerate: action.payload };

        case CoachingCycleReportsActionTypes.CHANGE_PARAMS:
            return {...state, ...action.payload};

        case CoachingCycleReportsActionTypes.SET_REPORT_DATA:
            return {
                ...state,
                reports: action.payload.reports,
                total: action.payload.total
            }

        default:
            return state;
    };
};

//Actions
export const getCoachingCycleReports = () => ({
    type: CoachingCycleReportsActionTypes.ASYNC_GET_COACHING_CYCLE_REPORTS,
});

export const generateCoachingCycleReport = () => ({
    type: CoachingCycleReportsActionTypes.ASYNC_GENERATE_COACHING_CYCLE_REPORT,
});

export const changeLoading = (value: boolean) => ({
    type: CoachingCycleReportsActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeReports = (reports: IReport[]) => ({
    type: CoachingCycleReportsActionTypes.CHANGE_COACHING_CYCLE_REPORTS,
    payload: reports,
});

export const changeLoadingGenerate = (value: boolean) => ({
    type: CoachingCycleReportsActionTypes.CHANGE_LOADING_GENERATE,
    payload: value
});

export const reportsPolling = () => ({
    type: CoachingCycleReportsActionTypes.POLLING_FOR_STATUS,
});

export const deleteReport = (reportId: string) => ({
    type: CoachingCycleReportsActionTypes.ASYNC_DELETE_REPORT,
    payload: reportId,
});

export const changeParams = (params: {}) => ({
    type: CoachingCycleReportsActionTypes.CHANGE_PARAMS,
    payload: params,
});

export const setReportData = (reports: {}, total: Number) => ({
    type: CoachingCycleReportsActionTypes.SET_REPORT_DATA,
    payload: {reports, total},
});

export default reducer;
