import { all, call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import {
    changeLoading,
    changeLoadingGenerate,
    changeParams,
    changeReports,
    CoachingCycleReportsActionTypes,
    reportsPolling,
    setReportData
} from "../reducers/coaching-cycle-reports.reducer";

import { IChangedReports, IReport, ReportStatus } from "../models/report.model";
import { deleteReport, generateCoachingCycleReport, getCoachingCycleReports, } from "../utils/webApi";
import { IState } from "../reducers/reducers";
import handleError from "../utils/handleError";
import { isEmpty } from "../utils/utils.functions";
import { requestUserData } from "../reducers/reports.reducer";

function* requestReports() {
    try {
        const {
            status,
            order_by,
            page,
            pageSize,
            to,
            from
        } = yield select((state: IState) => state.coachingCycleReports);

        yield put(changeLoading(true));

        const { reports, total } = yield call(getCoachingCycleReports, {
            status: status ? [status] : undefined,
            page,
            pageSize,
            order_by,
            to: to ? new Date(to) : undefined,
            from: from ? new Date(from) : undefined,
        });

        yield put(setReportData(reports, total));
        yield put(reportsPolling());
        yield put(requestUserData());
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    }
}

function* requestGenerateReport() {
    try {
        yield put(changeLoadingGenerate(true));
        const { reports, pageSize, isPolling, total, initialMonth, endMonth } =
            yield select((state: IState) => state.coachingCycleReports);
        const report: IReport = yield call(
            generateCoachingCycleReport,
            initialMonth,
            endMonth
        );
        const newReports = [{ ...report, isLocal: true }, ...reports].slice(0, pageSize);

        yield put(changeReports(newReports));
        yield put(changeParams({ total: total + 1 }));

        if (!isPolling) {
            yield put(reportsPolling());
        }
    } catch (err) {
        handleError(err);
    } finally {
        yield put(changeLoadingGenerate(false));
    }
}

function* pollingForStatus() {
    try {
        const processingKeys = [ReportStatus.CREATED, ReportStatus.PROCESSING];

        const { reports } = yield select((state: IState) => state.coachingCycleReports);
        const processingLocalReports = reports.filter((r: IReport) =>
            processingKeys.includes(r.status)
        );

        if (processingLocalReports.length > 0) {
            const response: { reports: IReport[] } = yield call(getCoachingCycleReports,{status: processingKeys, page: 1, pageSize: 1000 });
            let changedReports: IChangedReports = {};
            for (let report of processingLocalReports) {
                const rep = response.reports.find((r: IReport) => r.id === report.id);

                if (rep && rep.status !== report.status) {
                    changedReports = {...changedReports, [rep.id]: rep.status};
                } else if (!rep && !report.isLocal) {
                    changedReports = {...changedReports, [report.id]: ReportStatus.READY};
                }
            }

            if (!isEmpty(changedReports)) {
                yield put(
                    changeReports(
                        reports.map((r: IReport) => ({
                            ...r,
                            isLocal: false,
                            status: changedReports[r.id] || r.status,
                        }))
                    )
                );
            }
            yield put(reportsPolling());
        }
    } catch (err) {
        handleError(err);
    }
}

function* requestDelete({ payload }: { type: string; payload: string }) {
    const { reports } = yield select(
        (state: IState) => state.coachingCycleReports
    );
    try {
        const filteredReports = reports.filter((el: IReport) => el.id !== payload);
        yield put(changeReports(filteredReports));
        yield call(deleteReport, payload);
    } catch (err) {
        yield put(changeReports(reports));
        handleError(err);
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(
            CoachingCycleReportsActionTypes.ASYNC_GET_COACHING_CYCLE_REPORTS,
            requestReports
        ),
        yield takeLatest(
            CoachingCycleReportsActionTypes.ASYNC_GENERATE_COACHING_CYCLE_REPORT,
            requestGenerateReport
        ),
        yield takeLatest(
            CoachingCycleReportsActionTypes.POLLING_FOR_STATUS,
            pollingForStatus
        ),
        yield takeEvery(
            CoachingCycleReportsActionTypes.ASYNC_DELETE_REPORT,
            requestDelete
        ),
    ]);
}
