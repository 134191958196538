import React from 'react';
import { Descriptions, Tag } from 'antd';
import { formatDate } from '../../../../utils/moment.functions';
import { ICoache } from '../../../../models/profile.modal';
import { noExists } from '../../../../utils/utils.functions';
import { ICenter } from '../../../../models/utils.model';

interface props {
    currentProfile: ICoache,
    currentCenter: ICenter
};

const ProfileModalCoach = ({
    currentProfile,
    currentCenter
}: props) => {
    return (
        <Descriptions title='Informações do Coach' className='labs-profile-user' layout='vertical' bordered >

            <Descriptions.Item label='Status'>{ currentProfile.status 
                ? (<Tag key={currentProfile.status} className={`tags-status ${currentProfile.status}`}>
                    {currentProfile.status.toUpperCase()}
                    </Tag>) 
                : noExists }</Descriptions.Item>

            <Descriptions.Item label='Especializações'>{
                (currentProfile.expertises ? currentProfile.expertises.split(',') : []).map((tag: string) => (
                        <Tag key={tag} className={`tags-status ${tag}`}>
                            {tag.toUpperCase()}
                        </Tag>
                    )) || noExists
            }</Descriptions.Item>

            <Descriptions.Item label='Total Horas'>{ currentProfile.total_hours || noExists }</Descriptions.Item>
            <Descriptions.Item label='Total Coachees'>{ currentProfile.total_coachees || noExists }</Descriptions.Item>

            <Descriptions.Item label='Data Expiração'>{ 
                currentProfile.date_expiration ? formatDate(currentProfile.date_expiration) : noExists }
            </Descriptions.Item>

            <Descriptions.Item label='Total Sessões'>{ currentProfile.total_sessions || noExists }</Descriptions.Item>
            <Descriptions.Item label='Ciclos Completados'>{ currentProfile.complete_cycles || noExists }</Descriptions.Item>
            <Descriptions.Item label='Avaliação'>{ currentProfile.rating || noExists }</Descriptions.Item>

            <Descriptions.Item label='Núcleo'>{ currentCenter ? currentCenter.name : noExists }</Descriptions.Item>
        </Descriptions>
    )
};

export default ProfileModalCoach;
