export interface IReport {
    id: string,
    status: ReportStatus,
    username: string,
    createdAt: string,
    created_by: string,
}

export enum ReportStatus {
    CREATED = 'created',
    PROCESSING = 'processing',
    READY = 'ready',
    REMOVED = 'removed',
    ERROR = 'error'
};

export const reportTypes = {
    COACHES: 'coaches',
    COACHING_HOURS: 'coaches_hours',
    COACHING_CYCLE: 'coaching_cycle',
    COACHING_EVALUATION: 'evaluations_count',
    EXPERIMENTAL_SESSION: 'experimental_session',
    INVOICES: 'invoices_list',
    MONTHLY_COACHING: 'monthly_coaching',
}

export interface IReportQueryStringList {
    status: string | string[],
    order_by: string,
    page: number,
    pageSize: number,
}

export interface IDownloadRequest {
    download_url: string
}

export interface ILoadingKey {
    [key: string]: boolean;
}

export interface IChangedReports {
    [key: string]: string;
}
