import { call, all, put, takeEvery } from 'redux-saga/effects';
import handleError from '../utils/handleError';
import { getCenters, listPermissions, getLicenseTypes, getCountries, getTimeZones, getLocales } from '../utils/webApi';
import { UtilsActionTypes, changeCenters, changePermissions, changeLicenseTypes, changeCountries, changeTimezones, changeLocales, setIsPermissionsLoading } from '../reducers/utils.reducer';
import { Permissions, ICountry, ITimezone, ILocale, ICenter } from '../models/utils.model';

function* serializeGetsUtils() {
    try {
        const { centers } = yield call(getCenters);
        const ordenedCenters = centers
            .sort((a: ICenter, b: ICenter) => a.name < b.name ? -1 : 1);

        yield put(changeCenters(ordenedCenters));

        yield call(requestPermissions);

        const { tipo_licencas } = yield call(getLicenseTypes);
        yield put(changeLicenseTypes(tipo_licencas));

        const countries: ICountry[] = yield call(getCountries);
        yield put(changeCountries(countries));

        const timezones: ITimezone[] = yield call(getTimeZones);
        yield put(changeTimezones(timezones));

        const locales: ILocale[] = yield call(getLocales);
        yield put(changeLocales(locales));

    } catch (error) {
        handleError(error);
    }
}

function* requestPermissions() {
    try {
        const permissions: Permissions[] = yield call(listPermissions);
        yield put(changePermissions(permissions));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsPermissionsLoading(false));
    }
}

export default function* MySaga() {
    yield all([
        yield takeEvery(UtilsActionTypes.ASYNC_GET_SERIALIZE_UTILS, serializeGetsUtils)
    ]);
}