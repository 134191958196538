import React from 'react';
import {useSelector} from 'react-redux';

import {ICountry} from '../../../models/utils.model';
import StepsComponent, {IStep} from '../../../components/steps/steps';

import {IState} from '../../../reducers/reducers';
import Loading from '../../../components/loading/Loading';
import {IAdmin} from '../../../models/administrator.model';

import AdminEmail from './admin-email/AdminEmail';
import AdminInfo from './admin-infos/AdminInfos';

import '../admin-edit/AdminEdit.scss';

interface AdminCreate {
    onChange(name: string, value: any): void,
    adminToEdit: IAdmin,
    countries: ICountry[]
}

function AdminCreate({onChange, adminToEdit, countries}: AdminCreate) {
    const steps: IStep[] = [{
        description: 'Informe o email do usuário',
        title: 'Email'
    }, {
        description: 'Cadastro',
        title: 'informações de cadastro'
    }];

    const { currentSteps, loading } = useSelector(({ createAdmin }:IState)=> ({ currentSteps: createAdmin.currentSteps, loading: createAdmin.isLoadingModal }));

    return (
        <>
            { loading && <Loading/> }
            
            <StepsComponent classType={'remove-cursor'} current={currentSteps} onChange={()=>{}} steps={steps} isChange={false} />

            { currentSteps === 0 && <AdminEmail adminToEdit={adminToEdit} onChange={onChange} />}

            { currentSteps === 1 && <AdminInfo onChange={onChange} adminToEdit={adminToEdit} countries={countries} />}

        </>
    )
};

export default AdminCreate;