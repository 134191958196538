import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import InternalLinksList from './internal-links-list/InternalLinksList';
import SearchFilter from './SearchFIlter/search-filter';

function InternalLinks() {

    return (
        <>
            <TitleSection title={"Moderação de links"} />
                <SearchFilter />
            <InternalLinksList />
        </>
    );
}

export default InternalLinks;