import { put, all, takeEvery, select, call } from 'redux-saga/effects';
import {
    RegisterUnitsActionTypes, controlRegisterUnitsIsLoading,
    controlRegisterUnitsUnits, controlRegisterUnitsModalIsLoading, controlRegisterUnitsTotal,
    controlRegisterUnitsIsOpen
} from '../reducers/register-units.reducer';
import { IState } from '../reducers/reducers';
import handleError from '../utils/handleError';
import { insertUnits, getListUnits, editUnits } from '../utils/webApi';
import { queryStringList } from '../models/register-units.model';
import Notification, { NOTIFICATION_TYPE } from '../components/notification/Notification';

function* requestDataUnits() {
    try {
        const { page, pageSize, search } = yield select(({ registerunits }: IState)=> registerunits);

        yield put(controlRegisterUnitsIsLoading(true));

        const request = yield call(getListUnits, queryStringList({ page, pageSize, search }));

        yield put(controlRegisterUnitsUnits(request.centers));
        yield put(controlRegisterUnitsTotal(parseInt(request.total)));
    } catch (err) {
        handleError(err);
    } finally {
        yield put(controlRegisterUnitsIsLoading(false));
    }
};

function* postInsertUnits({ payload }: any){
    try {
        yield put(controlRegisterUnitsModalIsLoading(true));

        const newUnits = { ...payload };
        delete newUnits.active;
        delete newUnits.id;
        yield call(insertUnits, newUnits);

        Notification(NOTIFICATION_TYPE.SUCCESS, 'Sucesso', 'adcionado com sucesso');

        yield put(controlRegisterUnitsIsOpen(false));
        yield requestDataUnits();


    } catch (err) {
        handleError(err);
    } finally {
        yield put(controlRegisterUnitsModalIsLoading(false));
    }
};

function* postEditUnits({ payload }: any){
    try {
        yield put(controlRegisterUnitsModalIsLoading(true));

        const newUnits = { ...payload };
        delete newUnits.id;

        yield call(editUnits, `${payload.id}`, newUnits);

        Notification(NOTIFICATION_TYPE.SUCCESS, 'Sucesso', 'atualizado com sucesso');
            
        yield put(controlRegisterUnitsIsOpen(false));
        yield requestDataUnits();

    } catch (err) {
        handleError(err);
    } finally {
        yield put(controlRegisterUnitsModalIsLoading(false));
    }
};

export default function* mySaga(){
    yield all([
        takeEvery(RegisterUnitsActionTypes.ASYNC_REGISTERUNITS_GET_UNITS, requestDataUnits),
        takeEvery(RegisterUnitsActionTypes.ASYNC_REGISTERUNITS_SEND_NEW_UNITS, postInsertUnits),
        takeEvery(RegisterUnitsActionTypes.ASYNC_REGISTERUNITS_SEND_EDIT_UNITS, postEditUnits)
    ])
};