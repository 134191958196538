
//ResourceTypes
export enum AuthActionTypes {
    ASYNC_DO_LOGIN = '@auth/ASYNC_DO_LOGIN',
    ASYNC_DO_LOGOUT = '@auth/ASYNC_DO_LOGOUT',
};

export interface IAuthStore {
    permissions: Permissions[]
};

export interface IAuthAction {
    type: AuthActionTypes,
    payload: any
};

//Actions
export const doLogin = (code: string) => ({
    type: AuthActionTypes.ASYNC_DO_LOGIN,
    payload: code
});

export const doLogout = () => ({
    type: AuthActionTypes.ASYNC_DO_LOGOUT
});
