import { currentYear } from '../models/panelcontrol.graph.model';

const authUrls: any = {
    dev: 'https://dev.login.scisapp.com/v2',
    alpha: 'https://login.febracis.com.br.staging.fortalsistemas.com.br/v2',
    beta: '',
    prod: 'https://login.febracis.com.br/v2',
};
const apiUrls: any = {
    dev: 'https://dev.api.scisapp.com.dev.fortalsistemas.com.br/v2',
    alpha: 'https://alpha.api.scisapp.com.staging.fortalsistemas.com.br/v2',
    beta: '',
    prod: 'https://prod.api.scisapp.com/v2',
};
const apiUrlsV1: any = {
    dev: 'https://api.scisapp.com.dev.fortalsistemas.com.br/v1/dev',
    alpha: 'https://api.scisapp.com.staging.fortalsistemas.com.br/v1/alpha',
    beta: '',
    prod: 'https://api.scisapp.com/v1/prod',
};

const assineUrls: any = {
    dev: 'https://debug.scisapp.com/assine',
    alpha: 'https://staging.scisapp.com/assine',
    beta: '',
    prod: 'https://www.scisapp.com/assine',
};

const clientIdStages: any = {
    dev: 'pkEWT4gSAsUcm4r7',
    alpha: '5QU4n3gQ2gSTA6DL',
    beta: '',
    prod: 'KmZzmvJgJa9CU47q',
};

export const urlAssine = assineUrls[process.env.REACT_APP_STAGE || 'dev'];
export const urlApi = apiUrls[process.env.REACT_APP_STAGE || 'dev'];
export const urlApiV1 = apiUrlsV1[process.env.REACT_APP_STAGE || 'dev'];
export const urlAuth = authUrls[process.env.REACT_APP_STAGE || 'dev'];
export const client_id = clientIdStages[process.env.REACT_APP_STAGE || 'dev']
export const settingsAuth = {
    client_id,
    redirect_uri_login: `${window.location.origin}/login`,
    redirect_uri_logout: `${window.location.origin}/logout`
};
export const externalUrls = {
    LOGIN: `${urlAuth}/oauth2/authorize?client_id=${settingsAuth.client_id}&redirect_uri=${settingsAuth.redirect_uri_login}&response_type=code&scope=admin`,
    LOGOUT: `${urlAuth}/oauth2/logout?client_id=${settingsAuth.client_id}&redirect_uri=${settingsAuth.redirect_uri_logout}`
};

const utilsLinks: any = {
    dev: {
        urlShortenersRedirect: 'https://dev.g.scis.app/',
        myCoach: 'https://oseucoach.com.dev.fortalsistemas.com.br',
        spreadsheetImport: 'https://dev.admin.scisapp.com/planilha-padrao.xlsx'
    },
    alpha: {
        urlShortenersRedirect: 'https://staging.g.scis.app/',
        myCoach: 'https://oseucoach.com.staging.fortalsistemas.com.br',
        spreadsheetImport: 'https://admin.scisapp.com.staging.fortalsistemas.com.br/planilha-padrao.xlsx'
    },
    prod:{
        urlShortenersRedirect: 'https://g.scis.app/',
        myCoach: 'https://oseucoach.com',
        spreadsheetImport: 'https://admin.scisapp.com/planilha-padrao.xlsx'
    }
};

export const linksUtils = (type: string): string => utilsLinks[process.env.REACT_APP_STAGE || 'dev'][type];

export const urls = {
    RESOURCES_LIST: `${urlApi}/resources`,
    FILES: `${urlApi}/resources/files`,
    PAYMENTS: (id_coache: string) => `${urlApi}/admin/coaches/${id_coache}/payments?order=desc`,
    PAYMENTS_ALL: `${urlApi}/admin/coaches/payments`,
    PAYMENT_CANCEL: (id_coache: string, id_payment: string) => `${urlApi}/admin/coaches/${id_coache}/payments/${id_payment}/cancel`,
    BLOCKLIST: `${urlApi}/admin/blocklist`,
    COACHES: `${urlApi}/admin/coaches`,
    SCIS_CLASSES: `${urlApi}/admin/classes`,
    SCIS_CLASSES_EXPORT: `${urlApi}/admin/classes/export`,
    COACHEES: `${urlApi}/admin/coachees`,
    COACHES_CSV: `${urlApi}/admin/coaches/csv`,
    USERS: `${urlApi}/admin/users`,
    IMPORT_VALIDATE: `${urlApi}/admin/coaches/import/validate`,
    IMPORT_PREVIEW: `${urlApi}/admin/coaches/import/preview`,
    IMPORT_SAVE: `${urlApi}/admin/coaches/import/save`,
    PERMISSIONS: `${urlApi}/admin/me/permissions`,
    CENTERS: `${urlApi}/admin/centers`,
    TOKEN: `${urlAuth}/oauth2/token`,
    LICENSE_TYPES: `${urlApi}/admin/license-types`,
    COUNTRIES: `${urlApiV1}/types/countries`,
    TIMEZONES: `${urlApiV1}/types/timezones`,
    LOCALES: `${urlApiV1}/types/locales`,
    CONTACTS_US: `${urlApi}/admin/messages/fale`,
    NOTIFICATIONS_LIST: `${urlApi}/admin/notification`,
    ADMINS: `${urlApi}/admin/admins`,

    REGISTER_UNITS: `${urlApi}/admin/centers`,

    PANEL_CONTROL: `${urlApi}`,
    PANEL_CONTROL_GRAPH_BY_MOUTH: `${urlApi}/admin/dashboard?type=totalCoachesCreatedByMonth`,
    PANEL_CONTROL_GRAPH_ALL_YEAR: `${urlApi}/admin/dashboard?type=totalCoachesCreatedByYear`,
    PANEL_CONTROL_GRAPH_LAST_YEAR: `${urlApi}/admin/dashboard?type=totalCoachesCreatedByMonth&year=${currentYear}`,
    PANEL_CONTROL_COACHING: `${urlApi}/admin/coaches/stats`,
    PANEL_CONTROL_CLASSES: `${urlApi}/admin/classes/dashboard`,
    PANEL_CONTROL_CLASSES_GET_UNITS: `${urlApi}/admin/centers`,

    PROFILE_COACHES: `${urlApi}/admin/coaches/profiles`,

    TOTAL_COACHES: `${urlApi}/admin/dashboard?type=totalCoaches`,
    TOTAL_COACHES_ACESSARAM_ESTE_MES: `${urlApi}/admin/dashboard?type=totalCoachesAccessByThisMonth`,
    TOTAL_COACHES_ACESSARAM_HOJE: `${urlApi}/admin/dashboard?type=totalCoachesAccessByThisDay`,
    TOTAL_COACHES_CRIADOS_ESTE_ANO: `${urlApi}/admin/dashboard?type=totalCoachesCreatedByThisYear`,
    TOTAL_COACHES_CRIADOS_ESTE_MES: `${urlApi}/admin/dashboard?type=totalCoachesCreatedByThisMonth`,
    TOTAL_COACHES_DESATIVADOS: `${urlApi}/admin/dashboard?type=totalCoachesInactives`,
    TOTAL_COACHES_PAGAVEIS: `${urlApi}/admin/dashboard?type=totalCoachesActivesByTipoLicencaPagavel`,
    TOTAL_COACHES_GRATUIDADE: `${urlApi}/admin/dashboard?type=totalCoachesActivesByTipoLicencaGratuidade`,
    TOTAL_COACHES_EXPERIMENTAIS: `${urlApi}/admin/dashboard?type=totalCoachesActivesByTipoLicencaExperimental`,
    TOTAL_COACHES_LEGADO: `${urlApi}/admin/dashboard?type=totalCoachesActivesByTipoLicencaLegado`,
    TOTAL_COACHES_ATIVOS: `${urlApi}/admin/dashboard?type=totalCoachesActives`,
    TOTAL_COACHES_EXPIRADOS_PRONTOS: `${urlApi}/admin/dashboard?type=totalCoachesExpiredOrReady`,
    TOTAL_COACHEES: `${urlApi}/admin/dashboard?type=coachees`,
    TOTAL_SESSOES_FINALIZADAS: `${urlApi}/admin/dashboard?type=sessoes`,
    TOTAL_CICLOS_REALIZADOS: `${urlApi}/admin/dashboard?type=ciclos`,
    TOTAL_HORAS_COACHING: `${urlApi}/admin/dashboard?type=horas`,

    MY_COACH_URL: `${linksUtils('myCoach')}`,

    GET_URL_SHORTENERS: `${urlApi}/admin/url-shorteners`,

    REPORTS: `${urlApi}/admin/reports`,
    USERNAMES: `${urlApi}/admin/users`,
    AUDIT: `${urlApi}/admin/audit`,
    CATEGORIES: `${urlApi}/admin/videos/category`,
    VIDEOS: `${urlApi}/admin/videos`,
    UPLOAD: `${urlApi}/admin/videos/upload`,
    CLASSES: `${urlApi}/admin/ead/classes`,

    SALES_INTERNAL: `${urlApi}/admin/vendas/seller`,

    INTERNAL_LINKS: `${urlApi}/admin/vendas/links`,

    LOOSE_PAYMENTS: `${urlApi}/admin/pagamentos`,
    PAYMENTS_EXPORT: `${urlApi}/admin/coaches/payments/export`,
};
