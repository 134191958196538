import React, { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import { ICountry, AddressModel, IStates, ICity } from '../../../../models/utils.model';
import { IUser } from '../../../../models/user.model';
import handleError from '../../../../utils/handleError';
import { getStates } from '../../../../utils/webApi';

const { Option } = Select;

interface IProps {
    countries: ICountry[],
    onChange(name: string, value: any): void,
    userEdit: IUser,
};

const AddressForm = ({ countries, userEdit, onChange }: IProps) => {
    const [states, setStates] = useState<IStates[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);

    useEffect(() => {
        if (!userEdit.address) return;
        const country: ICountry | undefined = countries.find(({ name }) => userEdit.address.country === name);

        async function fetchState(currentCountry: ICountry) {
            try {
                const statesOrCities = await getStates(currentCountry.url);
                if (currentCountry.model === AddressModel.STATES_CITIES) {
                    setStates(statesOrCities);
                    setCities([]);
                } else {
                    setStates([]);
                    setCities(statesOrCities)
                };
            } catch (error) {
                handleError(error);
            };
        };

        if (country) {
            fetchState(country);
        }

        // eslint-disable-next-line
    }, [userEdit.address && userEdit.address.country, countries, getStates]);

    useEffect(() => {
        if (userEdit.address && userEdit.address.region) {
            const state: IStates | undefined = states.find(({ name }) => userEdit.address.region === name);
            if (state) {
                setCities(state.cities);
            };
        };
        // eslint-disable-next-line
    }, [userEdit.address && userEdit.address.region, states]);


    function onChangeAddress(key: string, value: any) {
        if (key === 'country') {
            onChange('address', {
                ...userEdit.address, ...{
                    country: value,
                    region: undefined,
                    locality: undefined
                }
            })
        } else if (key === 'region') {
            onChange('address', {
                ...userEdit.address, ...{
                    region: value,
                    locality: undefined
                }
            })
        } else {
            onChange('address', { ...userEdit.address, [key]: value })
        }
    };

    return (
        <>
            <div className="form-group">
                <Select
                    value={userEdit.address ? userEdit.address.country : undefined}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'País'}
                    onChange={(country: string) => onChangeAddress('country', country)}>
                    {countries.map((country: ICountry) => (
                        <Option key={country.url} value={country.name}>{country.name}</Option>
                    ))}
                </Select>
                <Select
                    value={userEdit.address ? userEdit.address.region : undefined}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Estado'}
                    disabled={states.length <= 0}
                    onChange={(state: string) => onChangeAddress('region', state)}>
                    {states.map((state: IStates) => (
                        <Option
                            key={state.name}
                            value={state.name}>{state.name}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group">
                <Select
                    placeholder={'Cidade'}
                    allowClear={true}
                    showSearch={true}
                    disabled={cities.length <= 0}
                    value={userEdit.address ? userEdit.address.locality : undefined}
                    onChange={(cityName: string) => onChangeAddress('locality', cityName)}>
                    {cities.map((city: ICity) => (
                        <Option
                            key={city.name}
                            value={city.name}>{city.name}</Option>
                    ))}
                </Select>
                <Input
                    value={userEdit.address ? userEdit.address.postal_code : ''}
                    onChange={({ target }) => onChangeAddress('postal_code', target.value)}
                    placeholder={'CEP'}
                />
            </div>
            <Input
                value={userEdit.address ? userEdit.address.street_address : ''}
                onChange={({ target }) => onChangeAddress('street_address', target.value)}
                placeholder={'Logradouro'}
            />
        </>
    );
};

export default AddressForm;