import { call,all, takeEvery, put, select, takeLatest} from 'redux-saga/effects';
import { 
    ExperimentalSessionReportsTypes,
    changeLoading,
    changeReports,
    changeLoadingGenerate,
    updateExperimentalSessionReports,
    reportsPolling,
    updateReports,
    changeParams,
    changeSetOpenModal,
} from '../reducers/experimental-session-report.reducer';
import { ReportStatus, IChangedReports, IReport } from '../models/report.model';
import { getExperimentalSessionReports, deleteReport, generateExperimentalSessionReport } from '../utils/webApi';
import handleError from '../utils/handleError';
import { IState } from '../reducers/reducers';
import { isEmpty } from '../utils/utils.functions';
import { requestUserData } from '../reducers/reports.reducer';

function* requestExperimentalReports() {
    try {
        const { status, order_by, page, pageSize, to, from } = yield select((state: IState) => state.experimentalSessionReports);
        yield put(changeLoading(true));
        const { reports, total } = yield call(getExperimentalSessionReports, {
            status: status ? [status] : undefined,
            page,
            pageSize,
            order_by,
            to: to ? new Date(to) : undefined,
            from: from ? new Date(from) : undefined
        });

        yield put(changeReports(reports));
        yield put(changeParams({total}));

        yield put(reportsPolling());
        yield put(requestUserData());
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
};

function* requestGenerateReport() {
    try {
        yield put(changeLoadingGenerate(true));
        const { reports, pageSize, isPolling, total, from, to } = yield select((state: IState) => state.experimentalSessionReports);
        const report: IReport = yield call(generateExperimentalSessionReport, {from: from ? from.replace('/', '-') : undefined, to: from ? to.replace('/', '-') : undefined});

        const newReports = [{...report, isLocal: true}, ...reports].slice(0, pageSize);

        yield put(changeReports(newReports));
        yield put(changeParams({total: total + 1}));
        yield put(changeSetOpenModal(false));

        if(!isPolling) {
            yield put(reportsPolling());
        };

    } catch (err) {
        handleError(err);
    } finally {
        yield put(changeLoadingGenerate(false));
    };
};

function* pollingForStatus() {
    try {
        const processingKeys = [ReportStatus.CREATED, ReportStatus.PROCESSING];

        const { reports } = yield select((state: IState) => state.experimentalSessionReports);
        const processingLocalReports = reports.filter((r: IReport) => processingKeys.includes(r.status));

        if(processingLocalReports.length > 0) {
            const response: {reports: IReport[]} = yield call(getExperimentalSessionReports, {status: processingKeys, page:1, pageSize: 1000});
            let changedReports: IChangedReports = {};
            for (let report of processingLocalReports) {
                const rep = response.reports.find((r: IReport) => r.id === report.id);

                if(rep && rep.status !== report.status) {
                    changedReports = {
                        ...changedReports,
                        [rep.id]: rep.status,
                    }
                } else if(!rep && !report.isLocal) {
                    changedReports = {
                        ...changedReports,
                        [report.id]: ReportStatus.READY
                    }
                }
            }

            if(!isEmpty(changedReports)) {
                yield put(updateExperimentalSessionReports(reports.map((r: IReport) => ({
                    ...r,
                    isLocal: false,
                    status: changedReports[r.id] || r.status,
                }))));
            }
            yield put(reportsPolling());
        }


    } catch (err) {
        handleError(err);
    }
}

function* requestDelete({payload}: {type: string, payload: string}) {
    const { reports } = yield select((state: IState) => state.experimentalSessionReports);
    try {
        const filteredReports = reports.filter((el :IReport) => el.id !== payload);
        
        yield put(updateReports(filteredReports));
        yield call(deleteReport, payload);
    } catch (err) {
        yield put(updateReports(reports));
        handleError(err);
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(ExperimentalSessionReportsTypes.ASYNC_GET_EXPERIMENTAL_SESSION_REPORTS, requestExperimentalReports),
        yield takeLatest(ExperimentalSessionReportsTypes.ASYNC_GENERATE_EXPERIMENTAL_SESSION_REPORT, requestGenerateReport),
        yield takeLatest(ExperimentalSessionReportsTypes.POLLING_FOR_STATUS, pollingForStatus),
        yield takeEvery(ExperimentalSessionReportsTypes.ASYNC_DELETE_EXPERIMENTAL_SESSION_REPORT, requestDelete),
    ]);
}
