
import React, { useEffect, useMemo, useCallback } from 'react';
import { IReport, ILoadingKey } from '../../models/report.model';
import { Table, Icon, Tag, Tooltip, Spin, Pagination, Typography, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import SearchFilter from './search-filter/SearchFilter';
import { formatDateWithHours } from '../../utils/moment.functions';

import { IState } from '../../reducers/reducers';
import { SortOrder } from 'antd/lib/table';
import { downloadReport } from '../../reducers/reports.reducer';

import './ReportList.scss';
interface IReportStatusTranslate {
    created: string;
    processing: string;
    ready: string;
    removed: string;
    error: string;
};

const reportStatusTranslate: IReportStatusTranslate  = {
    created: 'Criado',
    processing: 'Processando',
    ready: 'Pronto',
    removed: 'Removido',
    error: 'Erro',
};

interface IReportListProps {
    reports: IReport[],
    loading: boolean,
    total: number,
    getReports(): void,
    deleteReport(p: string): void,
    changeParams(p: any): void,
    statusSelector(p: IState): string | null,
    toSelector(p: IState): string | null,
    fromSelector(p: IState): string | null,
    orderSelector(p: IState): string | null,
    pageSelector(p: IState): number,
    pageSizeSelector(p: IState): number,
    showSelect?: boolean,
};

const pageSizeOptions = ['10', '25', '50', '100'];

const loader = <Icon type='loading' style={{ fontSize: 24 }} spin />;

const ReportList = ({
    reports,
    total,
    loading,
    getReports,
    deleteReport,
    changeParams,
    statusSelector,
    toSelector,
    fromSelector,
    orderSelector,
    pageSelector,
    pageSizeSelector,
    showSelect = true,
}: IReportListProps) => {
    const dispatch = useDispatch();
    const order = useSelector(orderSelector);
    const page = useSelector(pageSelector);
    const pageSize = useSelector(pageSizeSelector);

    const usernames = useSelector(({ reports }: IState) => reports.usernames);
    const isDownloading = useSelector(({ reports }: IState) => reports.loadingDownload);

    useEffect(() => {
        dispatch(getReports());

    }, [order, page, pageSize]); //eslint-disable-line

    const columns = useMemo(() => {
        return [
            {
                title: 'Data de criação',
                dataIndex: 'created_at',
                key: 'created_at',
                sorter: true,
                width: '35%',
                render: (text: string) => text ? formatDateWithHours(text) : ''

            }, {
                title: 'Gerado por',
                dataIndex: 'created_by',
                key: 'created_by',
                sorter: false,
                ellipsis: true,
                width: '35%',
                render: (text: string) => text ? usernames[text] : ''
            }, {
                title: 'Status',
                dataIndex: 'status',
                key: 'status',
                sorter: false,
                width: '15%',
                render: (text: keyof IReportStatusTranslate) => text ? (
                    <Tag className={`report-status ${text}`}>
                        {reportStatusTranslate[text]}
                    </Tag>
                ) : null
            }, {
                title: 'Ações',
                key: 'action',
                width: '15%',
                render: (report: IReport) => {
                    const key: keyof ILoadingKey = report.id;
                    return (
                        <div className="report-actions">
                            <Tooltip title='Baixar relatório' className='report-action'>
                                <span className='icons-action'>
                                    {report.status === 'ready' &&
                                        <>
                                            {isDownloading[key]
                                                ? <Spin indicator={loader} />
                                                : <Icon type='download' onClick={() => dispatch(downloadReport(report.id))} />
                                            }
                                        </>
                                    }
                                </span>
                            </Tooltip>
                                <span className='icons-action report-action'>
                                    <Popconfirm
                                        title={'Tem certeza que deseja remover esse relatório?'}
                                        okText='Sim'
                                        cancelText='Não'
                                        onConfirm={() => dispatch(deleteReport(report.id))}
                                        placement='left'
                                    >
                                        <Tooltip title='Excluir relatório'>
                                            <Icon type='delete'/>
                                        </Tooltip>
                                    </Popconfirm>
                                </span>
                        </div>
                    )
                }
            },
        ];
    }, [dispatch, deleteReport , isDownloading, usernames]);

    const reportData = useMemo(() => reports.map((report: IReport) => ({ ...report, key: report.id })), [reports]);

    const sortDirections: SortOrder[] = useMemo(() => ['ascend', 'descend', 'ascend'], []);

    const orderChange = useCallback((c1, c2, sorter) => dispatch(changeParams({order_by: sorter.order.replace('end', '')})), [changeParams, dispatch]);

    const changePageSize = useCallback((_, pageSize) => dispatch(changeParams({pageSize})), [changeParams, dispatch]);

    const pageChange = useCallback((page: number) => dispatch(changeParams({page})), [changeParams, dispatch]);

    return (
        <>
            {   showSelect &&
                <SearchFilter
                    statusSelector={statusSelector}
                    toSelector={toSelector}
                    fromSelector={fromSelector}
                    onSearchAction={getReports}
                    changeParams={changeParams}
                />
            }
            <Table
                className="coach-report-list"
                pagination={false}
                loading={loading}
                columns={columns}
                dataSource={reportData}
                sortDirections={sortDirections}
                onChange={orderChange}
            />
            <div className="found-coach-report">
                <Typography><b>{total}</b> relatórios encontrados</Typography>
            </div>
            <Pagination
                className="coaches-pagination"
                showSizeChanger
                onShowSizeChange={changePageSize}
                defaultPageSize={pageSize}
                defaultCurrent={page}
                current={page}
                total={total}
                pageSizeOptions={pageSizeOptions}
                onChange={pageChange}
            />
        </>
    );
};

export default ReportList;
