import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../reducers/reducers';
import { changeParams, deleteReport, generateCoachReport, getCoachReports } from '../../reducers/coach-reports.reducer';
import ReportList from '../../components/reports-list/ReportList';

const CoachReport = (): any => {
    const isLoading = useSelector(({coachReports}: IState) => coachReports.isLoading);
    const isLoadingGenerate = useSelector(({coachReports}: IState) => coachReports.isLoadingGenerate);
    const reports = useSelector(({coachReports}: IState) => coachReports.reports);
    const total = useSelector(({coachReports}: IState) => coachReports.total);

    const dispatch = useDispatch();

    return (
        <section>
            <TitleSection title={'Relatório de Coaches'} >
                <Button
                    onClick={() => dispatch(generateCoachReport())}
                    size="large"
                    loading={isLoadingGenerate}
                    type="primary">Gerar relatório</Button>
            </TitleSection>

            <ReportList
                reports={reports}
                total={total}
                loading={isLoading}
                getReports={getCoachReports}
                deleteReport={deleteReport}
                changeParams={changeParams}
                statusSelector={({ coachReports }: IState) => coachReports.status}
                toSelector={({ coachReports }: IState) => coachReports.to}
                fromSelector={({ coachReports }: IState) => coachReports.from}
                orderSelector={({ coachReports }: IState) => coachReports.order_by}
                pageSelector={({ coachReports }: IState) => coachReports.page}
                pageSizeSelector={({ coachReports }: IState) => coachReports.pageSize}
            />
        </section >
    );
}

export default CoachReport;