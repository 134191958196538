import React, { useEffect, useState, useCallback } from 'react';
import ResourcesList from './resources-list/ResourcesList';
import { useDispatch, useSelector } from 'react-redux';
import { getResources } from '../../reducers/resources.reducer';
import { IState } from '../../reducers/reducers';
import { Modal } from 'antd';
import ResourceDetail from './resource-detail/ResourceDetail';
import { IResource } from '../../models/resources.model';
import TitleSection from '../../components/title-section/TitleSection';
import ResourceEdit from './resource-edit/ResourceEdit';

function Resources() {
    const { resources, isLoading } = useSelector(({ resources }: IState) => ({
        isLoading: resources.isLoading,
        resources: resources.resources
    }));
    const [currentResource, setCurrentResource] = useState<IResource | null>(null)
    const [editResource, setEditResource] = useState<IResource | null>(null)

    const dispatch = useDispatch();

    const changeCurrentResource = useCallback((resource: IResource | null) => {
        setCurrentResource(resource)
    }, [])

    useEffect(() => {
        dispatch(getResources());
        // eslint-disable-next-line
    }, []);

    return (
        <section>
            <TitleSection title={'Resources'} />

            <ResourcesList
                onSelectResource={changeCurrentResource}
                isLoading={isLoading}
                resources={resources} />


            {Boolean(currentResource) && (
                <Modal
                    title="Detalhes do Resource"
                    visible={true}
                    maskClosable={false}
                    onOk={console.log}
                    onCancel={() => changeCurrentResource(null)}>
                    <ResourceDetail
                        onEdit={(resource: IResource) => {
                            setEditResource(resource);
                            setCurrentResource(null);
                        }}
                        id={(currentResource as IResource).Id} />
                </Modal>
            )}
            {Boolean(editResource) && (
                <Modal
                    title="Detalhes do Resource"
                    visible={true}
                    onOk={console.log}
                    onCancel={() => setEditResource(null)}>
                    <ResourceEdit resource={editResource as IResource} />
                </Modal>
            )}
        </section>
    );
};

export default Resources;