import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleSection from '../../components/title-section/TitleSection';
import { asyncGetActions, asyncGetAudit, changePage } from '../../reducers/audit.reducer';
import { IState } from '../../reducers/reducers';
import Filters from './Filters/filters';
import List from './List/list';


const Audit = () => {
    const [search, setSearch] = useState('');
    const [action, setAction] = useState('');
    const dispatch = useDispatch();
    const { actions } = useSelector(({ audits }: IState )=> ({
        actions: audits.actions,
        pageSize: audits.pageSize,
        page: audits.page,
    }));

    useEffect(() => {
        dispatch(asyncGetActions());
    }, [dispatch]);

    const onSearch = ({cAction}: any = {}) => {
        dispatch(changePage(1));
        dispatch(asyncGetAudit(search, cAction || cAction === '' ? cAction : action));
    };

    const onChangeAction = (value: string) => {
        setAction(value);
        onSearch({cAction: value ? value : ''});
    }

    return (
        <>
            <TitleSection title='Auditoria' />
            <Filters
                actions={actions}
                onSearch={onSearch}
                search={search}
                setAction={onChangeAction}
                setSearch={setSearch}
                action={action}
            />
            <List />
        </>
        
    );
};


export default Audit;