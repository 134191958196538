import React, { useEffect, useState } from 'react';
import { ICoacheScisClasses } from '../../../models/scis-classes.model';
import { Table, Pagination, Tag, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { changePageSize, changePage, changeOrder } from '../../../reducers/scis-classes.reducer';
import SearchFilter from './search-filter/SearchFilter';
import NewNotificationModal from '../../notifications-list/NewNotificationModal';
import { findClass, formatDate } from '../../../utils/moment.functions';

import './ScisClassesList.scss';

const { Text } = Typography;

interface IScisClassesListProps {
    coaches: ICoacheScisClasses[],
    loading: boolean,
    total: number,
    getCoaches(): void,
    getClass(): void,
};

interface INotificationModal {
    showModal: boolean,
    coachId: string,
}

const ScisClassesList = ({ coaches, loading, total, getCoaches, getClass }: IScisClassesListProps) => {
    const [notificationModal, setNotificationModal] = useState<INotificationModal>({
        showModal: false,
        coachId: "",
    });
    const { page, pageSize, allClasses} = useSelector(({ scisClasses }: IState) => ({ page: scisClasses.page, pageSize: scisClasses.pageSize, allClasses: scisClasses.allClass }))
    const dispatch = useDispatch();

    useEffect(() => {
        getCoaches();
        getClass()
        // eslint-disable-next-line
    }, [page, pageSize]);

    useEffect(() => {
        getCoaches();
        // eslint-disable-next-line
    }, []);

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'nickname',
            key: 'nickname',
            sorter: true,
            ellipsis: true,
            width: 140,
            render: (_: any, record: any) => {
                const { given_name: givenName, family_name: familyName, nickname } = record;

                if (nickname && givenName && familyName) {
                    return `${nickname} (${givenName} ${familyName})`;
                } else if (nickname) {
                    if (givenName || familyName) {
                        return `${nickname} (${givenName || familyName})`;
                    } else {
                        return nickname;
                    }
                } else if (givenName && familyName) {
                    return `${givenName} ${familyName}`;
                } else if (givenName || familyName) {
                    return `${givenName || familyName}`;
                } else {
                    return '(sem nome)';
                }
            },
        }, {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            sorter: true,
            ellipsis: true,
            width: 120,
        }, {
            title: 'Telefone',
            dataIndex: 'phone_number',
            key: 'phone_number',
            sorter: true,
            width: 140,
        }, {
            title: 'Unidade',
            dataIndex: 'coach.unit',
            key: 'coach.unit',
            sorter: true,
            width: 130,
        }, {
            title: 'Turma',
            key: 'turma',
            width: 100,
            render: (coache: ICoacheScisClasses) => {
                return findClass(allClasses, coache.coach.enrollment);
            },
        }, {
            title: 'Data último acesso ao SCIS',
            dataIndex: 'coach.scis20_last_access',
            key: 'coach.scis20_last_access',
            sorter: true,
            width: 130,
            render: (text: string) => text ? formatDate(text) : ''
        }, {
            title: 'Qtd de horas de coaching',
            dataIndex: 'coach.total_hours',
            key: 'coach.total_hours',
            sorter: true,
            width: 130,
            render: (hours: number) => hours ? parseInt(hours.toString()) : ''
        }, {
            title: 'Data de expiração',
            dataIndex: 'coach.license_expiration',
            key: 'coach.license_expiration',
            sorter: true,
            width: 120,
            render: (text: string) => text ? formatDate(text) : ''
        }, {
            title: 'Tipo da licença',
            dataIndex: 'coach.license_name',
            key: 'coach.license_name',
            sorter: true,
            width: 130,
        }, {
            title: 'Status',
            dataIndex: 'coach.status',
            key: 'coach.status',
            sorter: true,
            width: 120,
            render: (text: string) => text ? (
                <Tag className={`tags-status ${text}`}>
                    {text === 'RECORRENTE_PERIODO_EXTRA' ? 'Dias Adicionais' : text.toUpperCase()}
                </Tag>
            ) : null
        },
    ];

    return (
        <>
            <SearchFilter classes={allClasses}/>
            <Table
                className="coach-list"
                pagination={false}
                loading={loading}
                rowClassName={(coach: ICoacheScisClasses) => (coach.coach && (coach.coach.status && coach.coach.status === "INATIVO")) ? 'row-canceled' : 'row'}
                columns={columns}
                dataSource={coaches.map((coache: ICoacheScisClasses) => ({ ...coache, key: coache.id }))}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={(c1, c2, sorter) => dispatch(changeOrder(sorter.field, sorter.order.replace('end', '')))}
            />
            <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                <Text><b>{total}</b> coaches encontrados</Text>
            </div>
            <Pagination
                className="coaches-pagination"
                showSizeChanger
                onShowSizeChange={(_, pageSize) => dispatch(changePageSize(pageSize))}
                defaultPageSize={pageSize}
                defaultCurrent={page}
                current={page}
                total={total > 10000 ? 10000 : total}
                pageSizeOptions={['10', '25', '50', '100']}
                onChange={page => dispatch(changePage(page))}
            />

            {notificationModal.showModal &&
                <NewNotificationModal
                    closeModal={() => setNotificationModal({...notificationModal, showModal: false})}
                    recipient={notificationModal.coachId}
                />
            }
        </>
    );
};

export default ScisClassesList;
