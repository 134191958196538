import { CoachingType } from '../models/coache.model';

export const keyMapEmail: any = {
    Permanent: 'Permanente',
    Permanent_General: 'O provedor de e-mail do destinatário enviou uma mensagem de devolução definitiva, mas não especificou o motivo da devolução definitiva.',
    Permanent_NoEmail: 'O endereço de e-mail não existe.',
    Permanent_Suppressed: 'O endereço de e-mail do destinatário está na lista de supressões do Amazon SES.',
    Permanent_OnAccountSuppressionList: 'O Amazon SES suprimiu o envio para este endereço porque ele está na lista de supressão no nível da conta.',

    Transient: 'Transitório',
    Transient_General: 'O provedor de e-mail do destinatário enviou uma mensagem de devolução genérica.',
    Transient_MailboxFull: 'A caixa de entrada do destinatário está cheia.',
    Transient_MessageTooLarge: 'A mensagem que você enviou era muito grande.',
    Transient_ContentRejected: 'O conteúdo da mensagem que você enviou não é permitido pelo provedor',
    Transient_AttachmentRejected: 'A mensagem continha um anexo inaceitável. ',

    Undetermined: 'Indeterminado',
    Undetermined_Undetermined: 'Não foi possivel indentificar o ocorrido',

    OK: 'OK',
    OK_OK: 'Email não se encontra bloqueado.',
};

export const emailAllErros: string[] = [
    'Permanent',
    'Transient',
    'Undetermined',
    'OK',
];

export const emailAllSubErros: string[] = [
    'General',
    'NoEmail',
    'OnAccountSuppressionList',
    'Suppressed',
    'MailboxFull',
    'MessageTooLarge',
    'ContentRejected',
    'AttachmentRejected',
    'Undetermined',
    'OK',
];
export const SpecsOptions: { label: string; value: CoachingType | string }[] = [
    { label: 'Master', value: CoachingType.MASTER },
    { label: 'Executive', value: CoachingType.EXECUTIVE },
    { label: 'Business', value: CoachingType.BUSINESS },
    { label: 'Career', value: CoachingType.CAREER },
    { label: 'Microfranchisee', value: CoachingType.MICROFRANCHISEE },
    { label: 'Mindfulness', value: CoachingType.MINDFULNESS },
];

export const scisUnitID = 'bc6062d4-0ac6-11e9-92bd-040150a2cf01';

export const contractStatusTranslate = {
    PENDING: 'Pendente',
    REJECTED: 'Rejeitado',
    WAITING_ANALYSIS: 'Aguardando análise',
    WAITING_CISPAY: 'Aguardando Cispay',
    WAITING_SIGNED: 'Aguardando assinatura',
    WAITING_CORRECTION: 'Aguardando correção',
    SIGNED: 'Assinado',
    ANALYSIS: 'Em análise',
    APPROVED: 'Aprovado',
    WAITING_GENERATE: 'Aguardando geração',
};


export const SellerStatus = Object.freeze({
    Pending: 'PENDING', //Pendente o formulario do coach, permite editar;
    Wait: 'WAITING_ANALYSIS', // Finalizou o formulario, esperando analise, permite editar;
    Analysis: 'ANALYSIS', // em análise;
    WaitCispay: 'WAITING_CISPAY', // em analise pela cispay;
    WaitSigned: 'WAITING_SIGNED', //admin gerou contrato e está aguardando assinatura do responsável da empresa;
    Signed: 'SIGNED', // responsável da empresa assinou contrato;
    WaitCorrection: 'WAITING_CORRECTION', //admin solicitou correção de informações e/ou documentação do cadastro, permite editar;
    Approved: 'APPROVED', // APROVADO;
    Rejected: 'REJECTED', // REIJEITADO;
});
