import React from 'react';
import { Input as InputWrapper, } from 'antd';
import { InputProps } from 'antd/lib/input';

interface IProps extends InputProps {
    label?: string,
}

function Input(props: IProps) {
    return (
        <>
            {props.label && <label className="label-form">{props.label}</label>}
            <InputWrapper {...props} />
        </>
    );
}


export default Input;
