export interface IUnits {
    name: string,
    city: string,
    state: string,
    country: string,
    id: number,
    active: boolean
};

export interface IRequestUnits {
    centers: IUnits[],
    total: string
};

export  interface IRegisterUnitsStore {
    isLoading: boolean,
    isLoadingModal: boolean,
    units: IUnits[],
    page: number,
    pageSize: number,
    total: number,
    search: string,
    isOpen: boolean
};

interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string
}

export const queryStringList = ({ page, pageSize, search }: IQueryStringList) =>
`?page=${page-1}${pageSize && `&pageSize=${pageSize}`}${search && `&search=${window.encodeURIComponent(search)}`}` 
