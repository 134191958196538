import { IReport } from "../models/report.model";

//Types
export enum ExperimentalSessionReportsTypes {
    ASYNC_GET_EXPERIMENTAL_SESSION_REPORTS = '@experimentalsessionreport/ASYNC_GET_EXPERIMENTAL_SESSION_REPORTS',
    ASYNC_GENERATE_EXPERIMENTAL_SESSION_REPORT = '@experimentalsessionreport/ASYNC_GENERATE_EXPERIMENTAL_SESSION_REPORT',
    ASYNC_DELETE_EXPERIMENTAL_SESSION_REPORT = '@experimentalsessionreport/ASYNC_DELETE_REPORT_EXPERIMENTAL_SESSION_REPORT',
    CHANGE_EXPERIMENTAL_SESSION_REPORTS = '@experimentalsessionreport/CHANGE_EXPERIMENTAL_SESSION_REPORTS',
    CHANGE_LOADING_EXPERIMENTAL_SESSION_REPORT = '@experimentalsessionreport/CHANGE_LOADING_EXPERIMENTAL_SESSION_REPORT',
    CHANGE_PARAMS_EXPERIMENTAL_SESSION_REPORT = '@experimentalsessionreport/CHANGE_PARAMS_EXPERIMENTAL_SESSION_REPORT',
    CHANGE_LOADING_GENERATE_EXPERIMENTAL_SESSION_REPORT = '@experimentalsessionreport/CHANGE_LOADING_GENERATE_EXPERIMENTAL_SESSION_REPORT',
    POLLING_FOR_STATUS = '@experimentalsessionreport/POLLING_FOR_STATUS',
    UPDATE__EXPERIMENTAL_SESSION_REPORT = '@experimentalsessionreport/UPDATE_COACH_REPORTS',
    SET_USERNAMES = '@experimentalsessionreport/SET_USERNAMES',
    SET_OPEN_MODAL =  '@experimentalsessionreport/SET_OPEN_MODAL',
};

//Interfaces
export interface IExperimentalSessionReportsStore {
    reports: IReport[],
    isLoading: boolean,
    isLoadingGenerate: boolean,
    order_by: string,
    status: string | null,
    page: number,
    pageSize: number,
    total: number,
    openModal: false,
    from: string | null,
    to: string | null
};

export interface IExtraArgsEs {
    from: string | null,
    to: string | null
};

export interface ExperimentalSessionReportsAction {
    type: ExperimentalSessionReportsTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: IExperimentalSessionReportsStore = {
    reports: [],
    isLoading: false,
    isLoadingGenerate: false,
    order_by: 'asc',
    status: null,
    page: 1,
    pageSize: 10,
    total: 0,
    openModal: false,
    from: null,
    to: null,
};

export const reducer = (state: IExperimentalSessionReportsStore = INITIAL_STATE, action: ExperimentalSessionReportsAction): IExperimentalSessionReportsStore => {
    switch (action.type) {
        case ExperimentalSessionReportsTypes.CHANGE_EXPERIMENTAL_SESSION_REPORTS:
            return { ...state, reports: action.payload };

        case ExperimentalSessionReportsTypes.CHANGE_LOADING_EXPERIMENTAL_SESSION_REPORT:
            return { ...state, isLoading: action.payload };

        case ExperimentalSessionReportsTypes.CHANGE_LOADING_GENERATE_EXPERIMENTAL_SESSION_REPORT:
            return { ...state, isLoadingGenerate: action.payload };

        case ExperimentalSessionReportsTypes.UPDATE__EXPERIMENTAL_SESSION_REPORT:
            return { ...state, reports: action.payload };

        case ExperimentalSessionReportsTypes.CHANGE_PARAMS_EXPERIMENTAL_SESSION_REPORT:
            return {...state, ...action.payload};

        case ExperimentalSessionReportsTypes.SET_OPEN_MODAL:
        return {...state, openModal: action.payload}

        default:
            return state;
    };
};

//Actions
export const getExperimentalSessionReports = () => ({
    type: ExperimentalSessionReportsTypes.ASYNC_GET_EXPERIMENTAL_SESSION_REPORTS,
});

export const generateExperimentalSessionReport = () => ({
    type: ExperimentalSessionReportsTypes.ASYNC_GENERATE_EXPERIMENTAL_SESSION_REPORT,
});

export const changeLoading = (value: boolean) => ({
    type: ExperimentalSessionReportsTypes.CHANGE_LOADING_EXPERIMENTAL_SESSION_REPORT,
    payload: value,
});

export const changeReports = (reports: IReport[]) => ({
    type: ExperimentalSessionReportsTypes.CHANGE_EXPERIMENTAL_SESSION_REPORTS,
    payload: reports,
});

export const changeLoadingGenerate = (value: boolean) => ({
    type: ExperimentalSessionReportsTypes.CHANGE_LOADING_GENERATE_EXPERIMENTAL_SESSION_REPORT,
    payload: value,
});

export const updateExperimentalSessionReports = (reports: IReport[]) => ({
    type: ExperimentalSessionReportsTypes.CHANGE_EXPERIMENTAL_SESSION_REPORTS,
    payload: reports,
});

export const reportsPolling = () => ({
    type: ExperimentalSessionReportsTypes.POLLING_FOR_STATUS,
});

export const deleteReport = (reportId: string) => ({
    type: ExperimentalSessionReportsTypes.ASYNC_DELETE_EXPERIMENTAL_SESSION_REPORT,
    payload: reportId,
});

export const updateReports = (reports: IReport[]) => ({
    type: ExperimentalSessionReportsTypes.UPDATE__EXPERIMENTAL_SESSION_REPORT,
    payload: reports,
});

export const changeParams = (params: {}) => ({
    type: ExperimentalSessionReportsTypes.CHANGE_PARAMS_EXPERIMENTAL_SESSION_REPORT,
    payload: params,
});

export const changeSetOpenModal = (openModal: boolean) => ({
    type: ExperimentalSessionReportsTypes.SET_OPEN_MODAL,
    payload: openModal,
});

export default reducer;