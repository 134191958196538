import React, {useState, useEffect} from 'react';
import {useSelector} from 'react-redux';
import {Tabs} from 'antd';

import {FormTypeValue, IAdmin} from '../../../models/administrator.model';

import {IState} from '../../../reducers/reducers';
import AdminCreate from '../admin-create/AdminCreate';

import PermissionForm from './permission-form/PermissionForm';
import ButtonsSave from './button-save/ButtonSave';
import {PasswordMode} from '../../../models/coache.model';

import './AdminEdit.scss';

const {TabPane} = Tabs;

interface IAdminEditProps {
    admin: IAdmin,
    isEdit: boolean,
    loading: boolean
    onOk(admin: IAdmin): void,
    onCancel(): void,
}

const defaultPassword = `febracis${new Date().getFullYear()}`;

function AdminEdit({admin, isEdit, onOk, onCancel, loading}: IAdminEditProps) {
    const [adminToEdit, setAdminToEdit] = useState<IAdmin>(admin);
    const {countries} = useSelector(({ utils }: IState) => utils);

    const currentSteps = useSelector(({createAdmin}: IState) => createAdmin.currentSteps);

    useEffect(() => {
        if(!isEdit) {
            const item = {...adminToEdit, password: defaultPassword, password_mode: PasswordMode.PERM};
            setAdminToEdit(item as IAdmin);
        };
    // eslint-disable-next-line
    }, []);

    function onChangeCoachEdit(key?: string, value?: FormTypeValue) {
        let item = {...adminToEdit};
        let changed = false;

        if (key) {
            item = {...item, [key]: value};
            changed = true;
        }

        if (changed === true) {
            setAdminToEdit(item as IAdmin);
        }
    };

    return (
        <form className='admin-edit-container'>
            <div className='edit-main'>
                {isEdit
                    ? (
                        <Tabs defaultActiveKey='1'>
                            <TabPane
                                key='1'
                                tab={<span>Permissões</span>}
                            >
                                <PermissionForm adminToEdit={adminToEdit} onChange={onChangeCoachEdit}/>
                            </TabPane>
                        </Tabs>
                    )
                    : (
                        <AdminCreate
                            countries={countries}
                            onChange={onChangeCoachEdit}
                            adminToEdit={adminToEdit}
                        />
                    )
                }
            </div>

            {(isEdit || currentSteps !== 0) && (
                <ButtonsSave
                    currentSteps={currentSteps}
                    adminToEdit={adminToEdit}
                    loading={loading}
                    isEdit={isEdit}
                    onCancel={onCancel}
                    onOk={onOk}
                />
            )}
        </form>
    );
};

export default AdminEdit;
