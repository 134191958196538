import React, { useMemo, useState } from 'react';
import { IUser } from '../../../../models/user.model';
import { Table, Icon, Input, Divider, Popconfirm } from 'antd';

import './PhoneNumbersForm.scss'
import Notification, { NOTIFICATION_TYPE } from '../../../../components/notification/Notification';

interface IProps {
    userEdit: IUser,
    onChange: (key: string, value: any) => void
}

interface IPhone {
    phone_number: string,
    id: number
}

interface IModificationPhone {
    newPhone?: string,
    editPhone?: string
}

const PhoneNumbersForm = ({
    userEdit,
    onChange
}: IProps) => {

    const [ currentEditPhone, setCurrentEditPhone ] = useState<number | undefined>(undefined);
    const [ modificationPhone, setModificationPhone ] = useState<IModificationPhone>({newPhone: undefined, editPhone: undefined});

    const dataSource = useMemo(()=>{
        return userEdit.phones ? userEdit.phones.map((phone: string, index: number)=>({ phone_number: phone, id: index })) : []
    }, [userEdit.phones])

    useMemo(()=>{
        if(currentEditPhone || currentEditPhone === 0) setModificationPhone({ ...modificationPhone, editPhone: userEdit.phones[currentEditPhone]})
        else setModificationPhone({ ...modificationPhone, editPhone: undefined })
    // eslint-disable-next-line
    },[currentEditPhone])

    const columns = [
        {
            title: 'telefone',
            key: 'phone_number',
            render: (phone: IPhone) => {
                const isEdit = phone.id === currentEditPhone;

                return phone.phone_number
                ? (
                    <>
                        {isEdit ? 
                            (<>
                                <Input placeholder="Atualizar Telefone" value={modificationPhone.editPhone} onChange={({ target })=> setModificationPhone({ ...modificationPhone, editPhone: target.value }) } />
                            </>)
                            :(
                            <>
                                <span>{phone.phone_number}</span>
                            </>
                        )}
                    </>
                )
                :
                (
                    <>
                        <Input placeholder="Novo Telefone" value={modificationPhone.newPhone} onChange={({ target })=> setModificationPhone({ ...modificationPhone, newPhone: target.value }) } />
                    </>
                )

            }
        },
        {
            title: 'ações',
            render: (phone: IPhone) => {
                const isEdit = phone.id === currentEditPhone;
                return phone.phone_number
                ? (
                    <>
                        {isEdit ? 
                            (<>
                                <Icon type="check" onClick={()=>{
                                    if(modificationPhone.editPhone) {
                                        let newPhones = [ ...userEdit.phones ];
                                        if(currentEditPhone || currentEditPhone === 0 ) newPhones[currentEditPhone] = modificationPhone.editPhone || "";
                                        onChange('phones', newPhones);
                                        setCurrentEditPhone(undefined);
                                    }
                                    else Notification(NOTIFICATION_TYPE.WARNING, "Campo Vazio", "Telefone deve ser preenchido");
                                }}/>
                                <Divider type="vertical" />
                                <Icon type="close-circle" theme="filled" onClick={()=>setCurrentEditPhone(undefined)} />
                            </>)
                            :(
                            <>
                                <Icon type="edit" onClick={()=>setCurrentEditPhone(phone.id)} />
                                <Divider type="vertical" />

                                <Popconfirm 
                                    title="você realmente gostaria de remover？" 
                                    okText="Sim"
                                    cancelText="Não"
                                    onConfirm={()=>{
                                        let newPhones = [ ...userEdit.phones ];
                                        newPhones.splice(phone.id,1);
                                        onChange('phones', newPhones);
                                    }}>
                                    <Icon type="delete"/>
                                </Popconfirm>
                            </>
                        )}
                    </>
                )
                :
                (
                    <>
                        <Icon type="plus" onClick={()=>{
                            if(modificationPhone.newPhone){
                                let newPhones = [ ...userEdit.phones ];
                                newPhones.push(modificationPhone.newPhone || '');
                                onChange('phones', newPhones);
                                setModificationPhone({ ...modificationPhone, newPhone: undefined });
                            } else Notification(NOTIFICATION_TYPE.WARNING, "Campo Vazio", "Telefone deve ser preenchido");
                        }} />
                    </>
                )
            }
        }
    ]

    return (
        <>
            <Table
                columns={columns}
                className='table_phone_numbers-form'
                rowKey={(element, index)=> `${index}`}
                dataSource={[...dataSource, {phone_number: undefined, id: undefined}]}
                pagination={false} />
        </>
    )
}

export default PhoneNumbersForm;