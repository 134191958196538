import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Form, Button } from "antd";

import Search from "antd/lib/input/Search";
import { IState } from "../../../../reducers/reducers";
import {
    getVideos,
    changePageVideos,
    changeSearchValue,
    changeFilterVideos,
    changeFilterScopes,
} from "../../../../reducers/videos.reducer";
import { SpecsOptions } from "../../../../utils/utils.constants";

const { Option } = Select;

const SearchFilter = ({options}: any) => {
    const { search, filter_videos, scope } = useSelector(
        ({ videos }: IState) => ({
            search: videos.search,
            filter_videos: videos.filter_videos,
            scope: videos.scope,
        })
    );

    const dispatch = useDispatch();

    const onSearch = () => {
        dispatch(changePageVideos(0));
        dispatch(getVideos());
    };

    return (
        <Form className="filter-content3">
            <div className="input-filter">
                <label>Buscar</label>
                <Search
                    value={search}
                    className="search-input"
                    placeholder="Buscar"
                    onSearch={onSearch}
                    onChange={({ target }) =>
                        dispatch(changeSearchValue(target.value))
                    }
                />
            </div>

            <div className="input-filter">
                <label>Filtrar Por Categoria</label>
                <Select
                    mode="multiple"
                    placeholder="Todas"
                    maxTagCount={1}
                    allowClear={true}
                    showSearch
                    optionFilterProp="children"
                    value={filter_videos && filter_videos.length > 0 ? filter_videos : undefined}
                    onChange={(filter_videos: string[]) => dispatch(changeFilterVideos(filter_videos))}
                >
                    {options.map(({id, name}: any) => (
                        <Option key={id} value={id}>
                            {name}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="input-filter">
                <label>Filtrar Por Permissão</label>
                <Select
                    mode="multiple"
                    placeholder="Todas"
                    maxTagCount={1}
                    allowClear={true}
                    value={scope && scope.length > 0 ? scope : undefined}
                    onChange={(scope: string[]) => dispatch(changeFilterScopes(scope))}
                >
                    {SpecsOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>

            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search"
            >
                Pesquisar
            </Button>
        </Form>
    );
};

export default SearchFilter;
