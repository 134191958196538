import React, { useEffect } from 'react';
import {Button, Modal} from 'antd';
import {isEmpty} from 'lodash';

import TitleSection from '../../components/title-section/TitleSection';
import AdminList from './admin-list/AdminList';
import {IAdmin} from '../../models/administrator.model';
import {useDispatch, useSelector} from 'react-redux';
import {asyncRequestAllPermissions, asyncSaveAdmin, setAdminToEdit} from '../../reducers/administrators.reducer';
import {IState} from '../../reducers/reducers';
import {controlCurrentSteps, controlCurrentUser} from '../../reducers/create-admin.reducer';
import useCheckPermissions from '../../hooks/useCheckPermissions';

import AdminEdit from './admin-edit/AdminEdit';
import {permissionKeys} from '../../utils/permissions';

function Administrators() {
    const {isLoading, adminList, total, adminToEdit, isLoadingSave} = useSelector((state: IState) => state.administrators);
    const checkPermissions = useCheckPermissions();

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(asyncRequestAllPermissions());
    }, [dispatch]);

    function onSaveAdmin(admin: IAdmin) {
        dispatch(asyncSaveAdmin(admin));
    }

    function onCancel(){
        dispatch(setAdminToEdit({}));
        dispatch(controlCurrentSteps(0));
        dispatch(controlCurrentUser({}));
    }

    return (
        <section>
            <TitleSection title='Administradores'>
                <Button
                    onClick={() => dispatch(setAdminToEdit({isNew: true}))}
                    size='large'
                    type='primary'
                    hidden={!checkPermissions([permissionKeys.USER_ADMIN_CREATE])}
                >
                    Adicionar Administrador
                </Button>
            </TitleSection>

            <AdminList
                total={total}
                adminList={adminList}
                loading={isLoading}
                checkPermissions={checkPermissions}
            />

            {!isEmpty(adminToEdit) && (
                <Modal
                    width={700}
                    title={adminToEdit.id ? 'Editar Administrador' : 'Adicionar Administrador'}
                    visible={true}
                    footer={false}
                    maskClosable={false}
                    onCancel={onCancel}
                >
                    <AdminEdit
                        onOk={onSaveAdmin}
                        onCancel={onCancel}
                        admin={adminToEdit as IAdmin}
                        loading={isLoadingSave}
                        isEdit={Boolean(adminToEdit.id)}
                    />
                </Modal>
            )}
        </section >
    );
}

export default Administrators;
