export interface ICategory {
    id: string,
    name: string,
    active: boolean,
    created_at: string,
    updated_at: string,
    parent: string,
    originId: string,
    index: number,
    description: string,
    thumbnailUrl: string | null,
    properties_pt: any,
    properties_es: any | undefined,
    properties_en: any | undefined,
};

interface IQueryStringList {
    pageSize: number,
    search: string,
    nextPage?: string,
};

export enum OrderMode {
    ASC = 'asc',
    DESC = 'desc',
};

export const queryStringList = ({ nextPage, pageSize, search }: IQueryStringList) => {
    let params = [];

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }

    if (nextPage) {
        params.push(`nextPage=${nextPage}`);
    }

    if (search) {
        params.push(`search=${window.encodeURIComponent(search)}`);
    }

    return `?${params.join('&')}`;
};