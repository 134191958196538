import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleSection from '../../components/title-section/TitleSection';
import { Card } from 'antd';
import PanelControlCard from '../../components/panelcontrol-card/PanelControlCard';
import { asyncGetPanelcontrol } from '../../reducers/panelcontrol.reducer';
import Loading from '../../components/loading/Loading';
import { IState } from '../../reducers/reducers';

const PanelControl = () => {

    const { panelControl, isLoading } = useSelector(({ panelcontrol }: IState)=> panelcontrol)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(asyncGetPanelcontrol())
    },[dispatch])

    return (
        <>
            {isLoading ? <Loading/> : (
                <>
                    <TitleSection title="VISÃO GERAL" />

                    <Card title="ESTATÍSTICAS GERAIS" className='mini-card' >
                        <PanelControlCard cardList={panelControl[0] || []} textIndex={0} />
                    </Card>

                    <Card title="ESTATÍSTICAS DAS LICENÇAS" className='mini-card' >
                        <PanelControlCard cardList={panelControl[1] || []} textIndex={1} />
                    </Card>
                </>
            ) }
        </>
    )
}

export default PanelControl;