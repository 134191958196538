import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import { EProfileCoacheActionsTypes, controlProfileIsLoading, controlProfileCoaches, controlProfileTotal } from '../reducers/profile.reducer';
import { getProfilesCoaches } from '../utils/webApi';
import { queryStringList, IRequestProfile } from '../models/profile.modal';
import { IState } from '../reducers/reducers';
import handleError from '../utils/handleError';

function* requestProfileCoaches(){
    try{
        yield put(controlProfileIsLoading(true));

        const { page, pageSize, search } = yield select(({ profile }: IState) => profile);

        const request:IRequestProfile = yield call(getProfilesCoaches, queryStringList({ page, pageSize, search }));
        
        yield put(controlProfileTotal(request.total));
        yield put(controlProfileCoaches(request.profiles));
        

    }catch(err) {
        handleError(err);
    }finally{
        yield put(controlProfileIsLoading(false));
    }
};

export default function* mySaga(){
    yield all([
        takeEvery(EProfileCoacheActionsTypes.ASYNC_GET_COACHES, requestProfileCoaches)
    ]);
};