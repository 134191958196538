import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TitleSection from '../../components/title-section/TitleSection';
import { Card } from 'antd';
import Loading from '../../components/loading/Loading';
import { IState } from '../../reducers/reducers';
import SearchFilterPanelClasses from './search-filter/SearchFilterPanelClasses';
import { asyncFilter, asyncGetAllClasses, asyncGetAllUnits } from '../../reducers/panelcontrolclasses.reducer';
import MiniCard from './mini-card/MiniCard';

import './panelClasses.scss';

const PanelClasses = () => {
    const panelControlClasses = useSelector(({ panelcontrolclasses }: IState)=> panelcontrolclasses)
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(asyncGetAllUnits());
        dispatch(asyncGetAllClasses());
    },[dispatch])

    useEffect(() => {
        dispatch(asyncFilter());
    },[dispatch]);

    return (
        <div className='panel-control-classes'>
            {panelControlClasses.isLoading ? <Loading/> : (
                <>
                    <TitleSection title="TURMAS" />
                    <SearchFilterPanelClasses classes={panelControlClasses.allClasses} />
                    <Card title="TOTAL DE COACHES NAS TURMAS" className='mini-card' >
                        <Card.Grid className='mini-card'>
                            <MiniCard
                                titleText='Total'
                                results={`${panelControlClasses.coachesTotal}`} />
                        </Card.Grid>
                        <Card.Grid className='mini-card'>
                            <MiniCard
                                titleText='Acessaram o SCIS'
                                results={`${panelControlClasses.coachesAccessSCIS} (${((panelControlClasses.coachesAccessSCIS / panelControlClasses.coachesTotal) * 100).toFixed(1)}%)`} />
                        </Card.Grid>
                    </Card>
                    <Card title="TOTAL DE COACHES POR STATUS" className='mini-card' >
                        {panelControlClasses.coachesByStatus.map((status, index) => (
                            <Card.Grid className='mini-card' key={`${status}${index}`}>
                                <MiniCard
                                    titleText={status.key === 'sem_licenca' ? 'Sem Licença' : status.key}
                                    results={`${status.count} (${((status.count / panelControlClasses.coachesTotal) * 100).toFixed(1)}%)`} />
                            </Card.Grid>
                        ))}
                    </Card>
                    <Card title="TOTAL DE COACHES POR TIPO DE LICENÇA" className='mini-card' >
                        {panelControlClasses.coachesByLicenseType.map((license, index) => (
                            <Card.Grid className='mini-card' key={`${license}${index}`}>
                                <MiniCard
                                    titleText={license.key}
                                    results={`${license.count} (${((license.count / panelControlClasses.coachesTotal) * 100).toFixed(1)}%)`} />
                            </Card.Grid>
                        ))}
                    </Card>
                </>
            ) }
        </div>
    )
}

export default PanelClasses;
