import { INotificationsList, IPostNotification } from '../models/notificationsList.model';

// Types
export enum NotificationsListActionTypes {
    ASYNC_GET_NOTIFICATIONS_LIST = '@notificationsList/ASYNC_GET_NOTIFICATIONS_LIST',
    ASYNC_POST_NOTIFICATION = '@notificationsList/ASYNC_POST_NOTIFICATION',
    SET_NOTIFICATION_LIST = '@notificationsList/SET_NOTIFICATION_LIST',
    SET_IS_LOADING = '@notificationsList/SET_IS_LOADING',
    SET_PAGELIST = '@notificationsList/SET_PAGELIST',
    SET_CURRENT_PAGE = '@notificationsList/SET_CURRENT_PAGE',

    HANDLE_SET_TYPE_FORM = '@notificationsList/HANDLE_SET_TYPE_FORM',
    HANDLE_SET_SCOPE_FORM = '@notificationsList/HANDLE_SET_SCOPE_FORM',
    HANDLE_SET_REALM_FORM = '@notificationsList/HANDLE_SET_REALM_FORM',
    HANDLE_SET_SEARCH_FORM = '@notificationsList/HANDLE_SET_SEARCH_FORM',
    GET_TYPE_FORM = '@notificationsList/GET_TYPE_FORM',
    GET_SCOPE_FORM = '@notificationsList/GET_SCOPE_FORM',
    GET_REALM_FORM = '@notificationsList/GET_REALM_FORM',
    GET_SEARCH_FORM = '@notificationsList/GET_SEARCH_FORM',
    SUBMIT_SEARCH_FILTERS = '@notificationsList/SUBMIT_SEARCH_FILTERS',
}

// Interfaces

export interface INotificationsFilter {
    type: string | undefined,
    scope: string | undefined,
    realm: string | undefined,
    search: string | undefined,
}

export interface INotificationsListStore {
    notificationsList: INotificationsList[];
    isLoading: boolean;
    pageList: any[];
    currentPage: number;
    formFilter: INotificationsFilter,
}

export interface INotificationsListAction {
    type: NotificationsListActionTypes;
    payload: any;
}

const INITIAL_STATE: INotificationsListStore = {
    notificationsList: [],
    isLoading: false,
    pageList: [undefined],
    currentPage: 0,
    formFilter: {
        type: undefined,
        scope: undefined,
        realm: undefined,
        search: undefined,
    }
};

export const reducer = (
    state: INotificationsListStore = INITIAL_STATE,
    action: INotificationsListAction
): INotificationsListStore => {
    switch (action.type) {
        case NotificationsListActionTypes.SET_NOTIFICATION_LIST:
            return { ...state, notificationsList: action.payload };

        case NotificationsListActionTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };

        case NotificationsListActionTypes.SET_PAGELIST:
            return { ...state, pageList: action.payload };

        case NotificationsListActionTypes.SET_CURRENT_PAGE:
            return { ...state, currentPage: action.payload };

        case NotificationsListActionTypes.HANDLE_SET_TYPE_FORM: {
            return { ...state, formFilter: {...state.formFilter, type: action.payload}}
        }
        case NotificationsListActionTypes.HANDLE_SET_SCOPE_FORM: {
            return { ...state, formFilter: {...state.formFilter, scope: action.payload}}
        }
        case NotificationsListActionTypes.HANDLE_SET_REALM_FORM: {
            return { ...state, formFilter: {...state.formFilter, realm: action.payload}}
        }
        case NotificationsListActionTypes.HANDLE_SET_SEARCH_FORM: {
            return { ...state, formFilter: {...state.formFilter, search: action.payload}}
        }
        default:
            return state;
    }
};

// Actions
export const asyncGetNotificationsList = () => ({
    type: NotificationsListActionTypes.ASYNC_GET_NOTIFICATIONS_LIST,
});

export const setNotificationsList = (
    notificationsList: INotificationsList
) => ({
    type: NotificationsListActionTypes.SET_NOTIFICATION_LIST,
    payload: notificationsList,
});

export const setIsLoading = (isLoading: boolean) => ({
    type: NotificationsListActionTypes.SET_IS_LOADING,
    payload: isLoading,
});

export const setPageList = (pageList: any[]) => ({
    type: NotificationsListActionTypes.SET_PAGELIST,
    payload: pageList,
});

export const setCurrentPage = (currentPage: number) => ({
    type: NotificationsListActionTypes.SET_CURRENT_PAGE,
    payload: currentPage,
});

export const asyncSetNotification = (payload: IPostNotification) => ({
    type: NotificationsListActionTypes.ASYNC_POST_NOTIFICATION,
    payload,
});

export const handleSetFormType = (payload: string) => ({
    type: NotificationsListActionTypes.HANDLE_SET_TYPE_FORM,
    payload,
});

export const handleSetFormScope = (payload: string) => ({
    type: NotificationsListActionTypes.HANDLE_SET_SCOPE_FORM,
    payload,
});

export const handleSetFormRealm = (payload: string) => ({
    type: NotificationsListActionTypes.HANDLE_SET_REALM_FORM,
    payload,
});

export const handleSetFormSearch = (payload: string) => ({
    type: NotificationsListActionTypes.HANDLE_SET_SEARCH_FORM,
    payload,
});

export const handleSubmitFilters = () => ({
    type: NotificationsListActionTypes.SUBMIT_SEARCH_FILTERS,
});

export default reducer;
