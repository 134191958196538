import React from 'react';

import { Button, Select } from 'antd';
import Search from 'antd/lib/input/Search';

import './filters.scss';

const {Option} = Select;

interface IProps {
    search: string | undefined;
    setSearch: (value: string) => void;
    action: string | undefined;
    setAction: (value: string) => void;
    onSearch: (o: any) => void;
    actions: string[];
}

const Filters = ({search, setSearch, action, setAction, onSearch, actions}: IProps) => {
    return (
        <div className='container-filters'>
            <div className='input-filter'>
                <label>Buscar</label>
                <Search
                    value={search}
                    className='search-input'
                    placeholder='Pesquisar'
                    onSearch={onSearch}
                    onChange={({ target }) => setSearch(target.value)}
                />
            </div>

            <div className='input-filter'>
                <label>Filtro por ação</label>
                <Select
                    className='select-action'
                    value={action}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Ações'}
                    onChange={(myAction: string) => setAction(myAction)}
                >
                    {actions.map((action: string) => (
                        <Option key={action} value={action}>{action}</Option>
                    ))}
                </Select>
            </div>

            <div className='container-audit-search' >
                <Button 
                    onClick={onSearch} 
                    className='btn-search' 
                    type='primary'
                    icon='search'
                > 
                    Pesquisar
                </Button>
            </div>

        </div>
    )
};

export default Filters;