import {call, all, takeEvery, put, select, takeLatest} from 'redux-saga/effects';

import {AdminActionTypes, setIsLoading, setAdminList, setTotalAdmins, setAdminToEdit, changeLoadingSave, setAllPermissions, IAdminAction, asyncRequestAdminList} from '../reducers/administrators.reducer';
import {controlCurrentSteps, controlIsLoadingModal, controlCurrentUser} from '../reducers/create-admin.reducer';
import {getAdminByEmail, getAdminsInfo, getAdminById, getAllPermissions, editAdmim, saveAdmin, makeAdmin, deleteAdmin} from '../utils/webApi';
import handleError from '../utils/handleError';
import {IAdmin, queryStringList} from '../models/administrator.model';
import {IState} from '../reducers/reducers';
import {isEmpty} from '../utils/utils.functions';
import Notification, {NOTIFICATION_TYPE} from '../components/notification/Notification';
import {CreateAdminActionTypes} from '../reducers/create-admin.reducer';

function* requestAdminsList() {
    try {
        const {page, pageSize, search, search_field, order, order_mode} = yield select((state: IState) => state.administrators);
        yield put(setIsLoading(true));

        const {total, users} = yield call(getAdminsInfo, queryStringList({page, pageSize, search, search_field, order, order_mode}));

        yield put(setAdminList(users || []));
        yield put(setTotalAdmins(total));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    };
};

function* checkAdminByEmail({payload: email}: IAdminAction) {
    try {
        yield put(controlIsLoadingModal(true));
        const {user} = yield call(getAdminByEmail, email);

        if(user) {
            throw new Error('Usuário admin já existe');
        }
    } catch (err) {
        if(err.response && err.response.status === 404){
            yield put(controlCurrentUser({}));
            yield put(controlCurrentSteps(1));
        } else if(err.response && err.response.status === 409){
            yield put(controlCurrentUser({email}));
            yield put(controlCurrentSteps(1));
        } else {
            handleError(err);
        }
    } finally {
        yield put(controlIsLoadingModal(false));
    };
};

function* requestAdminToEdit({payload}: IAdminAction) {
    try {
        yield put(setIsLoading(true));
        const {admin} = yield call(getAdminById, payload);
        yield put(setAdminToEdit(admin));
    }catch(err) {
        handleError(err);
    } finally {
        yield put(setIsLoading(false));
    }
}

function* requestSaveAdmin({payload}: IAdminAction) {
    try {
        yield put(changeLoadingSave(true));

        const currentUser = yield select(({createAdmin}: IState) => createAdmin.currentUser);

        if(currentUser.email){
            yield call(makeAdmin, currentUser.email, {permissions: payload.permissions});
        } else {
            const adminToSend: Partial<IAdmin> | any = {...payload};
            if (isEmpty(adminToSend)) { 
                return Notification(NOTIFICATION_TYPE.WARNING, 'Atenção', 'Nenhum dado do novo admin foi preenchido');
            }
            if (adminToSend.id) {
                yield call(editAdmim, adminToSend.id, {permissions: adminToSend.permissions});
            } else {
                delete adminToSend.isNew;
                yield call(saveAdmin, adminToSend);
            };
        }
        yield put(setAdminToEdit({}));
        yield put(controlCurrentUser({}));
        yield put(controlCurrentSteps(0));

        yield put(asyncRequestAdminList());
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoadingSave(false));
    };
};

function* removeAdmin({payload}: IAdminAction) {
    try {
        yield put(setIsLoading(true));
        yield call(deleteAdmin, payload);
        yield put(asyncRequestAdminList());
    } catch (error) {
        handleError(error);
    } finally {
        yield put(setIsLoading(false));
    }
}

function* requestAllPermissions() {
    try {
        const {permissions} = yield call(getAllPermissions);
        yield put(setAllPermissions(permissions || []));
    } catch (error) {
        handleError(error);
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(AdminActionTypes.ASYNC_REQUEST_ADMIN_LIST, requestAdminsList),
        yield takeLatest(AdminActionTypes.CHANGE_ORDER, requestAdminsList),
        yield takeEvery(AdminActionTypes.ASYNC_SAVE_ADMIN, requestSaveAdmin),
        yield takeEvery(AdminActionTypes.ASYNC_GET_ADMIN_TO_EDIT, requestAdminToEdit),
        yield takeEvery(CreateAdminActionTypes.ASYNC_GET_INFO_REALM, checkAdminByEmail),
        yield takeEvery(AdminActionTypes.ASYNC_REQUEST_ALL_PERMISSIONS, requestAllPermissions),
        yield takeEvery(AdminActionTypes.ASYNC_REMOVE_ADMIN, removeAdmin),
    ]);
}