import React, { useState, useEffect } from 'react';
import { Descriptions, Avatar, Button } from 'antd';
import { ICoache } from '../../../../models/profile.modal';
import { urls } from '../../../../utils/urls.constants';
import { noExists } from '../../../../utils/utils.functions';
import { IState } from '../../../../reducers/reducers';

import './ProfileModalProfile.scss';
import { useSelector } from 'react-redux';

const isStatus = ['ATIVO', 'EXPIRANDO', 'RECORRENTE_PERIODO_EXTRA'];

interface props {
    currentProfile: ICoache
}

export const isLink = (currentProfile: ICoache) =>{
    const { published, status, username } = currentProfile;
    if(published && isStatus.includes(status)) {
        return (<a target="_blank" rel="noopener noreferrer" href={`${urls.MY_COACH_URL}/${username}`}>{username}</a>)
    };
    return false
};

const ProfileModalProfile = ({
    currentProfile
}: props) => {

    const isOpen = useSelector(({ profile }: IState)=>profile.isOpen);

    const [copy, setCopy] = useState<string>('Copiar');

    useEffect(()=>{
        if(!isOpen){
            setCopy('Copiar');
        };
    },[isOpen]);

    return (
        <Descriptions title="Informações do Perfil" className="labs-profile-profile" layout="vertical" bordered >
            <Descriptions.Item className="photo-url-modal-profile" label="Foto" >
                <Button onClick={() => {
                    navigator.clipboard.writeText(currentProfile.photoUrl);
                    setCopy('Copiado!')
                    }} >{copy}</Button>
                <Avatar src={currentProfile.photoUrl} alt={currentProfile.photoUrl} size="large" />
            </Descriptions.Item>
            <Descriptions.Item label="Nome">{ currentProfile.firstName || noExists}</Descriptions.Item>
            <Descriptions.Item label="Sobrenome">{ currentProfile.secondName || noExists }</Descriptions.Item>
            <Descriptions.Item label="Usuário">{ isLink(currentProfile) || currentProfile.username || noExists}</Descriptions.Item>
            <Descriptions.Item label="Publicado">{ currentProfile.published ? "SIM" : 'NÃO' }</Descriptions.Item>
            <Descriptions.Item label="Telefone do perfil">{ currentProfile.openid_phone_number || noExists }</Descriptions.Item>
            <Descriptions.Item label="Endereço">{ currentProfile.openid_address 
                ? `${currentProfile.openid_address.country ? `${currentProfile.openid_address.country}` : '' }${currentProfile.openid_address.region ? ` - ${currentProfile.openid_address.region}`: ''} ${currentProfile.city ? ` - ${currentProfile.city}` : ''}` 
                : noExists }
            </Descriptions.Item>
            <Descriptions.Item label="Email do perfil">{ currentProfile.email || noExists }</Descriptions.Item>
      </Descriptions>
    )
};
export default ProfileModalProfile;