import React from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import { controlCurrentSteps } from '../../../../reducers/create-coache.reducer';

import './ButtonSave.scss';
import '../CoacheEdit.scss';
interface IProps {
    onCancel: ()=>void,
    loading: boolean,
    coachEdit: any,
    onOk: (coachEdit: any)=>void,
    isEdit: any,
    currentSteps: number
}

const ButtonsSave = ({
    onCancel,
    loading,
    coachEdit,
    onOk,
    currentSteps,
    isEdit,
}: IProps)=>{
    const dispatch = useDispatch();
    return (
        <>
            <div className={`buttons-bottom ${currentSteps === 0 ? '' : 'two-div'} `}>
                { currentSteps === 1 && 
                    <div className='back-button buttons-bottom' >
                        <Button onClick={()=> dispatch(controlCurrentSteps(0))} type='default'> Voltar </Button>
                    </div>
                }
                <div className="buttons-bottom">
                    <Button
                        className="btn-cancel"
                        onClick={onCancel}>
                        Cancelar
                    </Button>
                    <Button
                        disabled={
                            loading ||
                            (!isEdit &&
                            (!coachEdit.coach || !coachEdit.coach.activation_date || !coachEdit.coach.expiration_date))
                        }
                        type="primary"
                        onClick={() => onOk(coachEdit)}>
                        {loading ? 'Salvando...' : 'Salvar'}
                    </Button>
                </div>
            </div>
        </>
    )
};

export default ButtonsSave;