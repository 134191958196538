import { Icon, Pagination, Switch, Table, Tag, Tooltip } from "antd";
import React, { useEffect, useMemo } from "react";
import SearchFilter from "./search-filter/SearchFilter";
import {IVideo} from "../../../models/video.model";
import { changeOrder, changePageVideos, changeVideoModal, enableDisableVideo } from "../../../reducers/videos.reducer";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../../reducers/reducers";
import { findCategory, formatDateWithHours, formatSecondsToTime } from "../../../utils/moment.functions";
import { getCategories, getParents } from "../../../reducers/categories.reducer";
import { ICategory } from "../../../models/categories.model";

import "./VideosList.scss";

interface IVideoListProps {
    videos: IVideo[],
    loading: boolean,
    getVideos(): void,
    onEdit(video: IVideo): void,
};

const VideosList = ({videos, loading, getVideos}: IVideoListProps) => {

    const { page, pageSize, pageList } = useSelector(({videos}: IState) => ({
        page: videos.page,
        pageSize: videos.pageSize,
        pageList: videos.pageList,
    }));

    const totalPages = useMemo<number>(() => {
        const hasNextPage = Boolean(pageList[page + 1]);

        return (page + (hasNextPage ? 2 : 1)) * pageSize;
     // eslint-disable-next-line
    }, [page, pageList]);

    const dispatch = useDispatch();

    const { parents } = useSelector(({categories}: IState) => ({
        parents: categories.parents,
    }));

    useEffect(() => {
        getVideos();
        dispatch(getCategories());
        dispatch(getParents());
        // eslint-disable-next-line
    }, [page, pageSize, dispatch]);

    const columns = [
        {
            title: "Thumbnail",
            key: "thumbnailUrl",
            width: 150,
            render: (video: IVideo) => <img className={"thumb"} src={video.properties_pt.thumbnailUrl} alt="thumbnail" />
        },
        {
            title: "Título",
            key: "title",
            width: 200,
            render: (vid: IVideo) => {
                const title = vid.properties_pt.title;
                return title;
            },
        },
        {
            title: "Categoria",
            dataIndex: "categoryId",
            key: "categoryId",
            width: 200,
            render: (cat: ICategory) => {
                return findCategory(parents, cat);
            },
        },
        {
            title: 'Data de criação',
            key: 'createdAt',
            with: 200,
            render: (vid: IVideo) => {
                const dateTime = vid.updated_at;
                return formatDateWithHours(dateTime);
            },
        },
        {
            title: 'Duração',
            key: 'duration',
            render: (vid: IVideo) => {
                const duration = vid.duration;
                return formatSecondsToTime(duration);
            },
        },
        {
            title: 'Dias para liberação ',
            key: 'daysRequiredToWatch',
            render: (vid: IVideo) => {
                const days = vid.daysRequiredToWatch;
                return days;
            },
        },
        {
            title: "Permissões",
            key: "scopes",
            width: 170,
            render: (video: IVideo) => {
                return <>
                    {(video.scopes || []).filter(s => !s.startsWith("hour")).map(tag => (
                        <Tooltip key={`tooltip-${tag}}`} title={tag.toUpperCase()}>
                            <Tag key={`tag-${tag}}`} className={`tags-status ${tag}`}>
                                {tag[0].toUpperCase()}
                            </Tag>
                        </Tooltip>
                    ))}
                </>
            },
        },
        {
            title: "Ações",
            key: "action",
            width: 100,
            render: (video: IVideo) => (
                <span className="icons-action">
                    <Tooltip title="Editar vídeo">
                        <Icon
                            type="edit"
                            onClick={() => {
                                dispatch(changeVideoModal(video));
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={video.active ? "Desabilitar vídeo" : "Habilitar vídeo"}>
                        <Switch
                            checked={video.active}
                            onChange={() => dispatch(enableDisableVideo(video, !video.active))}
                        />
                    </Tooltip>
                </span>
            ),
        },
    ];

    return (
        <>
            <SearchFilter
                options={parents}
            />
            <Table
                className="video-list"
                pagination={false}
                loading={loading}
                columns={columns}
                dataSource={videos.map((vid: IVideo) => ({
                    ...vid,
                    key: vid.id,
                }))}
                sortDirections={["ascend", "descend", "ascend"]}
                onChange={(c1, c2, sorter) => dispatch(changeOrder(sorter.field, sorter.order.replace("end", ""))) }
            />
            <Pagination
                className="pagination"
                pageSize={pageSize}
                total={totalPages}
                current={page + 1}
                onChange={(nextPage) => dispatch(changePageVideos(nextPage - 1))}
                style={{ marginTop: "25px" }}
            />
        </>
    )
};

export default VideosList;
