import React from 'react'
import MaskedDatePicker from '../../../../components/masked-date-picker/masked-date-picker';
import { ICoache } from '../../../../models/coache.model'
import './EnrollmentForm.scss';

interface IProps {
    onChange: Function,
    coachEdit: ICoache
}

const EnrollmentForm = ({
    onChange,
    coachEdit
}: IProps) => {

    return (
        <>
            <div className="div-enrollment" >
                <span className="span-enrollment">Turma na Importação:</span>
                <span>{(coachEdit.enrollment) ? coachEdit.enrollment : '-'}</span>
            </div>
            <div className="div-enrollment" >
                <span>Data de Matrícula:</span>
                <MaskedDatePicker
                    disabled={!Boolean(coachEdit.coach)}
                    onChange={onChange} 
                    currentValue={(coachEdit.enrollment_date) ? coachEdit.enrollment_date : ''} 
                    stringType={'enrollment_date'}
                    classText="enrollment-date"
                    placeholder="" />
            </div>
            <p className="text-enrollment" >
                Instruções:
                    <span>- Para coaches ativos, os vídeos sempre estarão disponíveis.</span>
                    <span>- Para coaches ativos, os vídeos sempre estarão disponíveis. </span>
                    <span>- Para coaches sem licença e criados antes de <strong>START_DATE</strong>, os vídeos não estarão disponíveis.</span>
                    <span>- Para coaches sem licença e criados depois de <strong>START_DATE</strong>, os vídeos estarão disponíveis por 1 ano a partir da data de matrícula registrada acima.</span>
                    <span>- O valor de START_DATE é <strong>13/07/2020.</strong></span>
                    <span className='small-text' >* A turma é usado apenas para fins de auditoria e rastreamento. Se a data de matrícula for alterada, a turma será apagada.</span>
            </p>
        </>
    )
};

export default EnrollmentForm;
