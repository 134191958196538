import React, { useEffect } from 'react';

import TitleSection from '../../components/title-section/TitleSection';
import CoacheeList from './coachee-list/CoacheeList';
import CoacheeEdit from './coachee-edit';
import { useDispatch, useSelector } from 'react-redux';
import { getCoachees } from '../../reducers/coachees.reducer';
import { IState } from '../../reducers/reducers';
import { getUser } from '../../reducers/users.reducer';
import UserEdit from '../../components/edit-user';

import {requestCoachees} from '../../sagas/coachees.saga';


const Coachee = (): any => {
    const {
        isLoading,
        coaches,
        total,
        page,
        pageSize,
        order,
        order_mode,
    } = useSelector((state: IState) => state.coachees);

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getCoachees());
    }, [dispatch, page, pageSize, order, order_mode]);

    return (
        <section>
            <TitleSection title='Coachees' />
            <UserEdit onSaveListUpdate={requestCoachees} />
            <CoacheeEdit/>
            <CoacheeList
                total={total}
                coaches={coaches}
                loading={isLoading}
                onEditUser={(id: string) => dispatch(getUser(id))}
            />
        </section >
    );
}

export default Coachee;