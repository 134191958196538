import React, { useEffect } from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import RegisterUnitsFilter from './register-units-filter/RegisterUnitsFilter';
import RegisterUnitsList from './register-units-list/RegisterUnitsList';
import { asyncGetUnits } from '../../reducers/register-units.reducer';
import { useDispatch } from 'react-redux';

const RegisterUnits = () => {
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(asyncGetUnits());
    // eslint-disable-next-line
    },[])

    return (
        <>
            <TitleSection title='Cadastro de Núcleos' />
            <RegisterUnitsFilter/>
            <RegisterUnitsList/>
        </>
    )
};

export default RegisterUnits;