import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import PanelGraphSelect from './panelgraphselect/PanelGraphSelect';
import { useSelector } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { Card } from 'antd';
import { transformText, maxDomain, all } from '../../../models/panelcontrol.graph.model';
import './PanelGraphBar.scss';


const PanelGraphBar = () => {

    const graphConvert = useSelector(({ panelcontrolgraph }: IState) => panelcontrolgraph.graphConvert);
    const graphAllYear = useSelector(({ panelcontrolgraph }: IState) => panelcontrolgraph.graphAllYear);
    const selectYear = useSelector(({ panelcontrolgraph }: IState) => panelcontrolgraph.selectYear);
    const maxRange = useSelector(({ panelcontrolgraph }: IState) => panelcontrolgraph.maxRange);

    const maxNumberDomain: any = selectYear === all ? maxDomain(selectYear === all ? graphAllYear : graphConvert) : maxRange;

    const renderTooltip = ({ active, payload, label }: any) => {
        if (active) {
            const desc = payload[0].name;
            const totalCadastros = payload[0].payload.totalCadastros;

            return (
                <Card bordered={false} className='card-tooltip' title={transformText()[label]} >
                    <p className='desc'>{desc + ': ' + totalCadastros}</p>
                </Card>
            );
        }
        return null
    };
    return (
        <div className='PanelGraphBar-div'>
            <div className='select-panelgraph' ><PanelGraphSelect /></div>
                <BarChart
                    width={1200}
                    height={600}
                    data={ selectYear === all ? graphAllYear : graphConvert}
                    margin={{
                        top: 5, right: 50, left: 20, bottom: 5,
                    }}
                >
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey={selectYear === all ? 'ano' : 'yearMonth'} interval={'preserveEnd'} />
                    <YAxis type="number" domain={[0, maxNumberDomain]}/>
                    <Tooltip content={renderTooltip} />
                    <Legend />
                    <Bar name='Total de coaches criados' dataKey='totalCadastros' fill='#8884d8' />
            </BarChart>
        </div>
    )
}

export default PanelGraphBar;