import {ICreateCoachesStore} from '../models/create-admin.model';

export enum CreateAdminActionTypes {
    ASYNC_GET_INFO_REALM = '@create-admins/ASYNC_GET_INFO_REALM',
    CONTROL_IS_LOADING_MODAL = '@create-admins/CONTROL_IS_LOADING_MODAL',
    CONTROL_CURRENT_STEPS = '@create-admins/CONTROL_CURRENT_STEPS',
    CONTROL_CURRENT_USER = '@create-admins/CONTROL_CURRENT_USER'
}

export interface IAction {
    type: CreateAdminActionTypes,
    payload: any
};


const INITIAL_STATE: ICreateCoachesStore = {
    isLoadingModal: false,
    currentUser: {},
    currentSteps: 0,
}

export const reducer = (state: ICreateCoachesStore = INITIAL_STATE, action: IAction ) => {
    switch(action.type){
        case CreateAdminActionTypes.CONTROL_CURRENT_STEPS:
            return { ...state, currentSteps: action.payload }
        case CreateAdminActionTypes.CONTROL_CURRENT_USER:
            return { ...state, currentUser: action.payload }
        case CreateAdminActionTypes.CONTROL_IS_LOADING_MODAL:
            return { ...state, isLoadingModal: action.payload }
        default:
            return state
    }
}

export const asyncGetUserInfo = (email: string) => ({
    type: CreateAdminActionTypes.ASYNC_GET_INFO_REALM,
    payload: email ? email.trim() : email,
})

export const controlCurrentSteps = (value: number) => ({
    type: CreateAdminActionTypes.CONTROL_CURRENT_STEPS,
    payload: value,
})

export const controlCurrentUser = (value: any) => ({
    type: CreateAdminActionTypes.CONTROL_CURRENT_USER,
    payload: value,
});

export const controlIsLoadingModal = (value: boolean) => ({
    type: CreateAdminActionTypes.CONTROL_IS_LOADING_MODAL,
    payload: value,
})

export default reducer;