import { call, all, takeEvery, put, select, takeLatest, delay} from 'redux-saga/effects';
import { CoachesActionTypes, changeLoading, changeCoaches, changeUnits, changeTotal, changeCoachEdit, changeLoadingSave, getAllClass } from '../reducers/coaches.reducer';
import { controlCurrentSteps, controlIsLoadingModal, controlCurrentUser } from '../reducers/create-coache.reducer';
import { getCoaches, getUnits, getCoachesForEmail, saveCoach, editCoach, getCoach, getCoachesCsv, getClass, listAllPaymentsXlsx } from '../utils/webApi';
import handleError from '../utils/handleError';
import { queryStringList,  ICoache, IClass, IPaymentXlsxRequest } from '../models/coache.model';
import { IState } from '../reducers/reducers';
import { difference, isEmpty, sanitize } from '../utils/utils.functions';
import Notification, { NOTIFICATION_TYPE } from '../components/notification/Notification';
import { setISOToDayStart, setISOToDayEnd } from '../utils/moment.functions';
import { CreateCoachesActionTypes } from '../reducers/create-coache.reducer';
import _ from 'lodash';
import moment from 'moment-timezone';

let vector_keys_noDiff = ['address', 'personal_ids', 'phones'];

function* requestCoaches(isToDelay = false) {
    try {
        const { page, pageSize, search, search_field, order, order_mode, units, filter_unit, filter_specs, filter_statuses, filter_class } = yield select((state: IState) => state.coaches);
        yield put(changeLoading(true));
        if (!units || (units.length === 0)) {
            const { centers } = yield call(getUnits);

            yield put(changeUnits([{
                id: '',
                name: 'Todas as unidades'
            }, {
                id: 'true',
                name: 'Tem unidade'
            }, {
                id: 'false',
                name: 'Não tem unidade'
            }, ..._.sortBy(centers, 'name')]));
        }
        if (typeof isToDelay === 'boolean' && isToDelay) {
            yield delay(700);
        }
        const { total, users } = yield call(getCoaches, queryStringList({ page, pageSize, search, search_field, order, order_mode, filter_unit, filter_specs, filter_statuses, filter_class }));
        yield put(changeCoaches(users));
        yield put(changeTotal(total));

    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
};

function* requestAllClass() {
    try {
        yield put(changeLoading(true));
        const { classes } = yield call(getClass);
        const notNullAbleClasses = classes.filter((e: IClass) => e.startDate)
        const nullAbleClasses = classes.filter((e: IClass) => !e.startDate).sort((a: IClass, b: IClass) => b.className.trim().localeCompare(a.className.trim()))
        let sortedClasses = notNullAbleClasses.sort((a: IClass, b: IClass) => (new Date(b.startDate).getTime()) - (new Date(a.startDate).getTime()));
        let newClasses = [...sortedClasses, ...nullAbleClasses];
        yield put(getAllClass(newClasses));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
};

function* requestCsv({payload: {callback}}: any) {
    let csvContent = '';
    let error = false;
    try {
        const { page, search, search_field, pageSize, order, order_mode, filter_unit, filter_specs, filter_statuses, filter_class } = yield select((state: IState) => state.coaches);
        let CSV  = yield call(getCoachesCsv, queryStringList({ page, pageSize, search, search_field, order, order_mode, filter_unit, filter_specs, filter_statuses, filter_class }));
        csvContent = 'data:text/csv;charset=utf-8,' + CSV;
    } catch (err) {
        error = true;
        handleError(err);
    } finally {
        callback && callback(encodeURI(csvContent), error);
    }
};

function* requestCoachesForEmail({ payload }: any) {
    try {
        const email = payload;
        yield put(controlIsLoadingModal(true));
        const user = yield call(getCoachesForEmail, email);

        if(user && user.coach) throw new Error('Usuário coach já existe');

        yield put(controlCurrentUser(user));
        yield put(controlCurrentSteps(1));
    } catch (err) {
        if(err.response && err.response.status === 404){
            yield put(controlCurrentUser(0));
            yield put(controlCurrentSteps(1));
        } else {
            handleError(err);
        }
    } finally {
        yield put(controlIsLoadingModal(false));
    };
};

function* requestCoacheEdit({ payload }: any) {
    try {
        yield put(changeLoading(true));
        const request = yield call(getCoach, payload);
        yield put(changeCoachEdit(request));
    }catch(err) {
        handleError(err);
    } finally {
        yield put(changeLoading(false));
    }
}

function sanitizeCoachInfo(coachInfo: any) {
    // trim strings e converte strings vazias para null
    coachInfo = sanitize(coachInfo);

    if (typeof coachInfo.birthdate !== 'undefined') {
        coachInfo.birthdate = setISOToDayStart(coachInfo.birthdate as string);
    }

    if (coachInfo.coach){
        const coach: any = coachInfo.coach;

        if (typeof coach.activation_date !== 'undefined') {
            coach.activation_date = setISOToDayStart(coach.activation_date as string);
        }

        if (typeof coach.expiration_date !== 'undefined') {
            coach.expiration_date = setISOToDayEnd(coach.expiration_date as string);
        }
    }

    return coachInfo;
}

function* requestSaveCoach({ payload }: any) {
    try {
        yield put(changeLoadingSave(true));

        let currentUser = yield select(( { CreateCoaches }: IState) => CreateCoaches.currentUser);

        if(currentUser){
            yield call(editCoach, currentUser.id, { coach: { ...payload.coach }, email: currentUser.email, convert_coach: true });
        } else {
            let coachToSend: ICoache|any = { ...payload };

            coachToSend = sanitizeCoachInfo(coachToSend);
            if (isEmpty(coachToSend)) {
                return Notification(NOTIFICATION_TYPE.WARNING, 'Atenção', 'Nenhum dado do novo coach foi preenchido');
            }

            if (coachToSend.id) {
                let { coachEdit } = yield select((state: IState) => state.coaches);

                coachEdit = sanitizeCoachInfo(coachEdit);
                let diff: Partial<ICoache> = difference(coachToSend, coachEdit);
                if (isEmpty(diff)) {
                    return Notification(NOTIFICATION_TYPE.WARNING, 'Atenção', 'Nenhuma alteração feita');
                }

                const fullObjDiff: any = {};

                let sendFullDiff: any = Object.keys(diff).map((element: string) => {
                    if(vector_keys_noDiff.includes(element)) {
                        fullObjDiff[element] = coachToSend[element];
                        return true
                    }
                    return false
                });

                if(sendFullDiff.includes(true)) {
                    yield call(editCoach, coachToSend.id, { ...diff, ...fullObjDiff });
                } else {

                    yield call(editCoach, coachToSend.id, diff);
                }

            } else {
                yield call(saveCoach, coachToSend);
            };

        }
        yield call(requestCoaches, true);
        yield put(changeCoachEdit(null));
        yield put(controlCurrentUser(undefined));
        yield put(controlCurrentSteps(0));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoadingSave(false));
    };
};

function* requestPaymentsXlsx({payload}: any) {
    try {
        const data = payload as IPaymentXlsxRequest;
        let xlsx  = yield call(listAllPaymentsXlsx, {...data, callback: undefined});

        const xlsxData = new Blob([Buffer.from(xlsx.buffer)], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(xlsxData);
        const timestamp = moment().format('YYYYMMDD_HHmmss');

        link.download = `Todos os Pagamentos_${timestamp}.xlsx`;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    } catch (_) {
        handleError({ message: 'Não foi possivel gerar a planilha, dados excederam o limite'});
    } finally {
        if (payload.callback) {
            payload.callback();
        }
    }
};

export default function* MySaga(): any {
    yield all([
        yield takeEvery(CoachesActionTypes.ASYNC_GET_COACHES, requestCoaches),
        yield takeEvery(CoachesActionTypes.ASYNC_GET_CLASS, requestAllClass),
        yield takeLatest(CoachesActionTypes.CHANGE_ORDER, requestCoaches),
        yield takeEvery(CoachesActionTypes.ASYNC_SAVE_COACH, requestSaveCoach),
        yield takeEvery(CoachesActionTypes.ASYNC_GET_COACHE, requestCoacheEdit),
        yield takeEvery(CreateCoachesActionTypes.ASYNC_GET_INFO_REALM, requestCoachesForEmail),
        yield takeEvery(CoachesActionTypes.ASYNC_GET_COACHES_CSV , requestCsv),
        yield takeEvery(CoachesActionTypes.ASYNC_GET_COACHES_PAYMENTS_XLSX , requestPaymentsXlsx),
    ]);
}
