import React, { useEffect } from 'react';
import { externalUrls } from '../../utils/urls.constants';
import { useDispatch } from 'react-redux';
import { doLogin } from '../../reducers/auth.reducer';

const Login = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        const code: string | null = new URLSearchParams(window.location.search).get('code');
        if (code) {
            dispatch(doLogin(code));
        } else {
            window.location.href = externalUrls.LOGIN;
        }
        // eslint-disable-next-line
    }, []);

    return <p>Redirecionando...</p>;
};

export default Login;