import { IReport } from "../models/report.model";

//Types
export enum CoachingHoursReportsActionTypes {
    ASYNC_GET_COACHING_HOURS_REPORTS = '@coaching-hours-reports/ASYNC_GET_COACHING_HOURS_REPORTS',
    ASYNC_GENERATE_COACHING_HOURS_REPORT = '@coaching-hours-reports/ASYNC_GENERATE_COACHING_HOURS_REPORT',
    ASYNC_DELETE_REPORT = '@coaching-hours-reports/ASYNC_DELETE_REPORT',
    CHANGE_COACHING_HOURS_REPORTS = '@coaching-hours-reports/CHANGE_COACHING_HOURS_REPORTS',
    CHANGE_LOADING = '@coaching-hours-reports/CHANGE_LOADING',
    CHANGE_PARAMS = '@coaching-hours-reports/CHANGE_PARAMS',
    CHANGE_LOADING_GENERATE = '@coaching-hours-reports/CHANGE_LOADING_GENERATE',
    POLLING_FOR_STATUS = '@coaching-hours-reports/POLLING_FOR_STATUS',
    SET_OPEN_MODAL = '@coaching-hours-reports/SET_OPEN_MODAL',
};

//Interfaces
export interface ICoachingHoursReportsStore {
    reports: IReport[],
    isLoading: boolean,
    isLoadingGenerate: boolean,
    order_by: string,
    status: string | null,
    to: string,
    from: string,
    page: number,
    pageSize: number,
    total: number,
    initialMonth: string,
    endMonth: string,
    openModal: boolean,
};

export interface CoachingHoursReportsAction {
    type: CoachingHoursReportsActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: ICoachingHoursReportsStore = {
    reports: [],
    isLoading: false,
    isLoadingGenerate: false,
    order_by: 'asc',
    status: null,
    to: '',
    from: '',
    page: 1,
    pageSize: 10,
    total: 0,
    initialMonth: '',
    endMonth: '',
    openModal: false,
};

export const reducer = (state: ICoachingHoursReportsStore = INITIAL_STATE, action: CoachingHoursReportsAction): ICoachingHoursReportsStore => {
    switch (action.type) {
        case CoachingHoursReportsActionTypes.CHANGE_COACHING_HOURS_REPORTS:
            return { ...state, reports: action.payload };

        case CoachingHoursReportsActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case CoachingHoursReportsActionTypes.CHANGE_LOADING_GENERATE:
            return { ...state, isLoadingGenerate: action.payload };

        case CoachingHoursReportsActionTypes.CHANGE_PARAMS:
            return {...state, ...action.payload};

        case CoachingHoursReportsActionTypes.SET_OPEN_MODAL:
            return {...state, openModal: action.payload}

        default:
            return state;
    };
};

//Actions
export const getCoachingHoursReports = () => ({
    type: CoachingHoursReportsActionTypes.ASYNC_GET_COACHING_HOURS_REPORTS,
});

export const generateCoachingHoursReport = () => ({
    type: CoachingHoursReportsActionTypes.ASYNC_GENERATE_COACHING_HOURS_REPORT,
});

export const changeLoading = (value: boolean) => ({
    type: CoachingHoursReportsActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeReports = (reports: IReport[]) => ({
    type: CoachingHoursReportsActionTypes.CHANGE_COACHING_HOURS_REPORTS,
    payload: reports,
});

export const changeLoadingGenerate = (value: boolean) => ({
    type: CoachingHoursReportsActionTypes.CHANGE_LOADING_GENERATE,
    payload: value
});

export const reportsPolling = () => ({
    type: CoachingHoursReportsActionTypes.POLLING_FOR_STATUS,
});

export const deleteReport = (reportId: string) => ({
    type: CoachingHoursReportsActionTypes.ASYNC_DELETE_REPORT,
    payload: reportId,
});

export const changeParams = (params: {}) => ({
    type: CoachingHoursReportsActionTypes.CHANGE_PARAMS,
    payload: params,
});

export const changeSetOpenModal = (openModal: boolean) => ({
    type: CoachingHoursReportsActionTypes.SET_OPEN_MODAL,
    payload: openModal
})

export default reducer;
