import { IDataCoaching, IPanelControlCoachingStore } from '../models/panelcontrol.coaching.model';

export enum PanelcontrolCoachingActionTypes {
    ASYNC_PANELCONTROLCOACHING_GET_COACHING 
        = '@panelcontrolcoaching/ASYNC_GET_COACHING',
    PANELCONTROLCOACHING_ISLOADING
        = '@panelcontrolcoaching/PANELCONTROLCOACHING_ISLOADING',
    PANELCONTROLCOACHING_SET_COACHING
        = '@panelcontrolcoaching/PANELCONTROLCOACHING_SET_COACHING',
    PANELCONTROLCOACHING_SET_SEARCH
        = '@panelcontrolcoaching/PANELCONTROLCOACHING_SET_SEARCH',
    PANELCONTROLCOACHING_SET_PAGE
        = '@panelcontrolcoaching/PANELCONTROLCOACHING_SET_PAGE',
    PANELCONTROLCOACHING_SET_PAGE_SIZE
        = '@panelcontrolcoaching/PANELCONTROLCOACHING_SET_PAGE_SIZE',
    PANELCONTROLCOACHING_SET_TOTAL
        = '@panelcontrolcoaching/PANELCONTROLCOACHING_SET_TOTAL'
};

const INITIAL_STATE: IPanelControlCoachingStore = {
    dataCoaching: [],
    isLoading: false,
    page: 1,
    search: '',
    pageSize: 10,
    total: 100
};

interface PanelcontrolCoachingAction {
    type: string,
    payload: any
};

const reducer = (state: IPanelControlCoachingStore = INITIAL_STATE, action: PanelcontrolCoachingAction) => {
    switch(action.type){
        case PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_ISLOADING:
            return { ...state, isLoading: action.payload };
        case PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_COACHING:
            return { ...state, dataCoaching: action.payload };
        case PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_PAGE:
            return { ...state, page: action.payload };
        case PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_PAGE_SIZE:
            return { ...state, pageSize: action.payload };
        case PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_SEARCH:
            return { ...state, search: action.payload };
        case PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_TOTAL:
            return { ...state, total: action.payload };
        default:
            return state;
    }
}

export default reducer;

export const asyncGetCoaching = () => ({
    type: PanelcontrolCoachingActionTypes.ASYNC_PANELCONTROLCOACHING_GET_COACHING
});

export const controlCoaching = (value: IDataCoaching[]) => ({
    type: PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_COACHING,
    payload: value
});

export const controlIsLoading = (value: boolean) => ({
    type: PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_ISLOADING,
    payload: value
});

export const controlPage = (value: number) => ({
    type: PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_PAGE,
    payload: value
});

export const controlPageSize = (value: number) => ({
    type: PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_PAGE_SIZE,
    payload: value
});

export const controlSearch = (value: string) => ({
    type: PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_SEARCH,
    payload: value
});

export const controlTotal = (value: number) => ({
    type: PanelcontrolCoachingActionTypes.PANELCONTROLCOACHING_SET_TOTAL,
    payload: value
});
