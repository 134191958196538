import React from 'react';
import { Descriptions } from 'antd';
import { ICoache } from '../../../../models/profile.modal';
import { noExists } from '../../../../utils/utils.functions';

interface props {
    currentProfile: ICoache
};

const ProfileModalUser = ({
    currentProfile
}: props) => {

    const gender: any = {
        male: "Homem",
        female: "Mulher"
    } || "outro";

    return (
        <Descriptions title='Informações do Usuário' className='labs-profile-user' layout='vertical' bordered >
            <Descriptions.Item label='Nome'>{ currentProfile.openid_given_name || noExists }</Descriptions.Item>
            <Descriptions.Item label='Usuário'>{ currentProfile.openid_nickname || noExists }</Descriptions.Item>
            <Descriptions.Item label='Sexo'>{ gender[`${currentProfile.openid_gender}`] || noExists }</Descriptions.Item>
            <Descriptions.Item label='Família'>{ currentProfile.openid_family_name || noExists }</Descriptions.Item>
            <Descriptions.Item label='Email'>{ currentProfile.openid_email || noExists }</Descriptions.Item>
            <Descriptions.Item label='Ativo'>{ currentProfile.openid_enabled ? "Ativo" : "Inativo" || noExists }</Descriptions.Item>
      </Descriptions>
    )
};

export default ProfileModalUser;