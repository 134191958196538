import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { IState } from '../../reducers/reducers';
import { asyncPanelGraphGetValue, asyncPanelGraphGetAllYearValues, controlPanelGraphGraphConvert } from '../../reducers/panelcontrol.graph.reducer';
import { transformGraph, all } from '../../models/panelcontrol.graph.model';
import TitleSection from '../../components/title-section/TitleSection';
import PanelGraphBar from './panelgraphbar/PanelGraphBar';

import Loading from '../../components/loading/Loading';


const currentYear = new Date().getFullYear();

const PanelGraph = () => {

    const isLoading = useSelector(({ panelcontrolgraph }: IState)=> panelcontrolgraph.isLoading);
    const selectYear = useSelector(({ panelcontrolgraph }: IState) => panelcontrolgraph.selectYear);
    const graph = useSelector(({ panelcontrolgraph }: IState) => panelcontrolgraph.graph);
    const totalYear = useSelector(({ panelcontrolgraph }: IState) => panelcontrolgraph.totalYear);

    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch(asyncPanelGraphGetValue());
        dispatch(asyncPanelGraphGetAllYearValues());
    },[dispatch])

    useEffect(()=>{
        if(selectYear === all){
            dispatch(asyncPanelGraphGetAllYearValues());
        }else if (parseInt(selectYear) === currentYear){
            dispatch(asyncPanelGraphGetValue());
        } else {
            const newGraph = transformGraph(selectYear, graph, totalYear);
            dispatch(controlPanelGraphGraphConvert(newGraph.yearMonth));
        }
        // eslint-disable-next-line
    },[selectYear])

    return (
        <>
            <TitleSection title="Gráfico de criação de Coaches" />
            {isLoading ? <Loading/> : <PanelGraphBar />}
        </>
    )
}

export default PanelGraph;