import React from 'react';
import JSONViewer from 'react-json-viewer';
import { prettyPrintJson } from 'pretty-print-json';

import './json-viewer.scss';


interface IProps { 
    json: JSON;
    isTable?: boolean;
}

const JsonViewer = ({json, isTable = true}: IProps) => {
    const html = prettyPrintJson.toHtml(json, {lineNumbers: true, indent: 4});
    return (
        <div className='container-json-viewer'>
            <div className={isTable ? 'container-json-viewer-colors' : 'container-json-viewer-pretty'} >
                {
                    isTable
                        ? <JSONViewer
                            json={json}
                        />
                        : <pre className='dark-mode' dangerouslySetInnerHTML={{__html: html}} />
                }
            </div>
        </div>
      );
};

export default JsonViewer;