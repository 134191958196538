export const permissionKeys = Object.freeze({
    USER_PROFILE: 'USER:PROFILE',
    USER_PROFILE_OPEN: 'USER:PROFILE:OPEN',
    USER_COACHES: 'USER:COACHES',
    USER_COACHES_CREATE: 'USER:COACHES:CREATE',
    USER_COACHES_EDIT: 'USER:COACHES:EDIT',
    USER_COACHES_NOTIFICATION: 'USER:COACHES:NOTIFICATION',
    USER_PAYMENT: 'USER:PAYMENT',
    USER_PAYMENT_REFUNDEND: 'USER:PAYMENT:REFUNDEND',
    USER_PAYMENT_CREATE: 'USER:PAYMENT:CREATE',
    USER_USERS: 'USER:USERS',
    USER_USERS_EDIT: 'USER:USERS:EDIT',
    USER_COACHEE: 'USER:COACHEE',
    USER_COACHEE_EDIT: 'USER:COACHEE:EDIT',
    USER_COACHEE_USER: 'USER:COACHEE:USER',
    USER_BLOCKLIST: 'USER:BLOCKLIST',
    USER_BLOCKLIST_DELETE: 'USER:BLOCKLIST:DELETE',
    USER_IMPORT: 'USER:IMPORT',
    USER_ADMIN: 'USER:ADMIN',
    USER_AUDIT: 'USER:AUDIT',
    USER_ADMIN_CREATE: 'USER:ADMIN:CREATE',
    USER_ADMIN_EDIT: 'USER:ADMIN:EDIT',
    USER_ADMIN_DELETE: 'USER:ADMIN:DELETE',
    COACHES: 'coaches',
    PAYMENTS: 'payments',
    CONTENT: 'content',
    COMERCIAL: 'comercial',
    REPORT: 'report',
    SYSTEM: 'system',
    CONTENT_CATEGORY: 'CONTENT:CATEGORY',
    CONTENT_CATEGORY_CREATE: 'CONTENT:CATEGORY:CREATE',
    CONTENT_CATEGORY_EDIT: 'CONTENT:CATEGORY:EDIT',
    CONTENT_VIDEO: 'CONTENT:VIDEO',
    CONTENT_VIDEO_UPLOAD: 'CONTENT:VIDEO:UPLOAD',
    CONTENT_VIDEO_CREATE: 'CONTENT:VIDEO:CREATE',
    CONTENT_VIDEO_EDIT: 'CONTENT:VIDEO:EDIT',
    REPORT_INVOICES: 'REPORT:INVOICES',
    REPORT_INVOICES_CREATE: 'REPORT:INVOICES:CREATE',
    SCIS_CLASSES_LIST: 'CLASSES:LIST',
    SCIS_CLASSES_EXPORT: 'CLASSES:EXPORT',
    FINANCEIRO_LIST: 'FINANCEIRO_LIST',
});
