import React, { useState } from 'react';
import { Select, Form, Button } from 'antd';
import { CancellationReason } from '../../../models/payment.model';
import TextArea from '../../../components/text-area/TextArea';

interface IProps {
    cancelPayment({ motivo_cancelamento, detalhes_cancelamento }: {
        motivo_cancelamento: CancellationReason | undefined,
        detalhes_cancelamento: string
    }): void
};

function PaymentCancel({ cancelPayment }: IProps) {

    const [motivo_cancelamento, setMotivoCancelamento] = useState<CancellationReason>();
    const [detalhes_cancelamento, setDetalhesCancelamento] = useState<string>('');

    return (
        <Form className="payment-cancel-content">
            <label className="label-form">Motivo do cancelamento</label>
            <Select
                value={motivo_cancelamento}
                onChange={(type: CancellationReason) => setMotivoCancelamento(type)}>
                <Select.Option value={CancellationReason.REGISTRATION_ERROR}>
                    Erro de Cadastro
                </Select.Option>

                <Select.Option value={CancellationReason.CUSTOMER_REQUEST}>
                    Solicitação do Cliente
                </Select.Option>

                <Select.Option value={CancellationReason.FREE_REVOCATION}>
                    Revogação de licença gratuita
                </Select.Option>

                <Select.Option value={CancellationReason.EXPERIMENTAL_REVOCATION}>
                    Revogação de licença experimental
                </Select.Option>

                <Select.Option value={CancellationReason.LEGACY_DEACTIVATION}>
                    Revogação de licença legado
                </Select.Option>

                <Select.Option value={CancellationReason.OTHER}>
                    Outro (Favor detalhar)
                </Select.Option>
            </Select>

            <TextArea
                label="Motivo do cancelamento"
                value={detalhes_cancelamento}
                onChange={({ target }) => setDetalhesCancelamento(target.value)}
                placeholder="Escreva aqui..."
            />

            <Button
                style={{
                    marginTop: 30,
                    display: 'flex',
                    marginLeft: 'auto',
                }}
                type="primary"
                onClick={() => cancelPayment({ detalhes_cancelamento, motivo_cancelamento })}
            > Cancelar Pagamento</Button>
        </Form>
    );
};

export default PaymentCancel;
