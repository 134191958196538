import Storage from "./storage";

const CredentialsHelper = {
    update: function (accessToken: string, refreshToken: string): void {
        Storage.setItem(ACCESS_TOKEN_ID, accessToken);
        Storage.setItem(REFRESH_TOKEN_ID, refreshToken);
    },
    clear: function (): void {
        Storage.removeItem(ACCESS_TOKEN_ID);
        Storage.removeItem(REFRESH_TOKEN_ID);
    },
    accessToken: (): string => Storage.getItem(ACCESS_TOKEN_ID),
    refreshToken: (): string => Storage.getItem(REFRESH_TOKEN_ID),
    isLoggedIn: (): boolean => Boolean(CredentialsHelper.accessToken())
};

export default CredentialsHelper;

const REFRESH_TOKEN_ID = 'refresh_token';
const ACCESS_TOKEN_ID = 'access_token';
