import React, { useState, useEffect } from 'react'
import { formatDate } from '../../../utils/moment.functions'
import { ICenter } from '../../../models/utils.model';
import { useSelector } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { IImportationForm } from '../../../models/import.model';

import './ImportInfo.scss';

type IProps = Omit<IImportationForm, 'content'>

const textHelp = {
    INVALID_VALUE : 'Qualquer linha com campos com erro "Campo está incorretamente preenchido" deve ser corrigidos antes de serem importados. Não esquecer de validar. O sistema não deixa importar com esse tipo de erro.',
    CONFLICT_EMAIL : 'Qualquer linha com campo email com erro "Este email já está cadastrado para outro usuário. Se você continuar a importação, esse outro usuário será adicionado à turma" podem ser importados, mas apenas o usuário detentor deste email será adicionado à turma. Os demais campos da linha serão ignorados.',
    CONFLICT_PHONE_NUMBER: 'Qualquer linha com campo telefone com erro "Este telefone já está cadastrado para outro usuário. Se você continuar a importação, esse outro usuário será adicionado à turma" podem ser importados, mas apenas o usuário detentor deste telefone será adicionado à turma. Os demais campos da linha serão ignorados.',
    CONFLICT_EMAIL_PHONE_NUMBER: 'Qualquer linha com campos email e telefone cada qual com erro "Este email/telefone já está cadastrado para outro usuário. Se você continuar a importação, esse outro usuário será adicionado à turma" podem ser importados, mas apenas se o mesmo usuário for detentor deste email e telefone que será adicionado à turma. Caso contrário, o sistema retornará erro para esta linha. Os demais campos serão ignorados.',
    CPF: 'CPF',
    CPF_INFO: 'O CPF é obrigatório e deve ser válido se país for Brasil. Se país for branco ou diferente de Brasil, o CPF deve ser obrigatoriamente vazio.'
};

const vectorPopver = [ textHelp.INVALID_VALUE ,  textHelp.CONFLICT_EMAIL ,  textHelp.CONFLICT_PHONE_NUMBER,  textHelp.CONFLICT_EMAIL_PHONE_NUMBER, textHelp.CPF, textHelp.CPF_INFO ];

function ImportInfo({ class_id, activation_date, expiration_date, unit_id, renderHelp }: IProps) {

    const [center, setCenter] = useState<ICenter>();
    const centers = useSelector((state: IState) => state.utils.centers);

    useEffect(() => {
        const center = centers.find(({ id }) => id === unit_id);
        if (center) {
            setCenter(center);
        }
    }, [unit_id, centers]);

    return (
        <div className="import-info-help" >
            <article className="import-info">
                <div className="info-item">
                    <label>Núcleo</label>
                    <p>{center && center.name}</p>
                </div>
                <div className="info-item">
                    <label>Turma</label>
                    <p>{class_id}</p>
                </div>
                <div className="info-item">
                    <label>Data de ativação</label>
                    <p>{formatDate(activation_date)}</p>
                </div>
                <div className="info-item">
                    <label>Data de Expiração</label>
                    <p>{formatDate(expiration_date)}</p>
                </div>
            </article >

            { renderHelp 
                && (<fieldset className="info-item">
                        <legend>Instruções</legend>
                        <div className="div-help" >
                            { vectorPopver.map((element)=>(<p key={element} >{element}</p>)) }
                        </div>
                    </fieldset>
                )}
        </div>
    )
};

export default ImportInfo; 
