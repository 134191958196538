
import Axios from 'axios';
import CredentialsHelper from './credentials-helper';

const baseHeaders = (othersHeaders: any = {}) => ({
    headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${CredentialsHelper.accessToken()}`,
        ...othersHeaders
    }
});

export const Get = (url: string, headers?: any, params?: any): Promise<any> => {
    return Axios.get(url, {params, ...baseHeaders(headers)})
        .then(data => data.data)
        .catch(error => {
            throw error;
        });
};

export const Post = (url: string, data?: any, headers?: any): Promise<any> => {
    return Axios.post(url, data, baseHeaders(headers))
        .then(data => data.data)
        .catch(error => {
            throw error;
        });
};

export const Put = (url: string, data?: any, headers?: any): Promise<any> => {
    return Axios.put(url, data, baseHeaders(headers))
        .then(data => data.data)
        .catch(error => {
            throw error;
        });
};

export const Delete = (url: string, headers?: any): Promise<any> => {
    return Axios.delete(url, baseHeaders(headers))
        .then(data => data.data)
        .catch(error => {
            throw error;
        });
};

export const Patch = (url: string, data?: any, headers?: any): Promise<any> => {
    return Axios.patch(url, data, baseHeaders(headers))
        .then(data => data.data)
        .catch(error => {
            throw error;
        });
};