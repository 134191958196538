import React, { useEffect, useState } from "react";
import { Button, Form, Select } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import Search from "antd/lib/input/Search";

import { changeFilterStatuses, changeSearchValue, setInternalLinkInfo } from "../../../reducers/internal-links.reducer";
import { IState } from "../../../reducers/reducers";

import "./search-filter.scss";

import { statusTranslations } from "../internal-links-list/InternalLinksList";

const { Option } = Select;

const StatusOptions: { label: string, value?: string }[] = Object.entries(statusTranslations).map(e => {
    return {
        label: e[1],
        value: e[0],
    }
});

const SearchFilter = () => {
    const { search: mySearch } = useSelector(({ internalLinks }: IState) => ({
        search: internalLinks.search,
    }));
    const {filter_statuses } = useSelector(({ internalLinks }: any) => ({
        filter_statuses: internalLinks.filter_statuses
    }));
    const [search, setSearch] = useState('');

    const dispatch = useDispatch();
    const onSearch = () => {
        dispatch(setInternalLinkInfo('currentPage', 1));
        dispatch(changeSearchValue(search))
    }

    useEffect(() => {
        if (mySearch !== search) {
            setSearch(mySearch);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Form className="filter-content2">
            <div className="input-filter">
                <label>Filtrar Por Status</label>
                <Select
                    mode="default"
                    placeholder="Todos"
                    allowClear={true}
                    maxTagCount={1}
                    value={filter_statuses && filter_statuses.length > 0 ? filter_statuses : undefined}
                    onChange={(statuses: string) => {
                        dispatch(changeFilterStatuses(statuses));
                        onSearch()
                    }}>
                    {StatusOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}

                </Select>
            </div>

            <div className="search-content">
                <div className="input-filter">
                    <label>Buscar</label>
                    <Search
                        value={search}
                        className="search-input"
                        placeholder="Buscar"
                        style={{ width: 250 }}
                        onSearch={onSearch}
                        onChange={({ target }) => setSearch(target.value)} />
                </div>
                <div className="button-search-container" >
                    <Button
                        onClick={onSearch}
                        className="btn-search"
                        type="primary"
                        icon="search">
                        Pesquisar
                    </Button>
                </div>
            </div>


        </Form>
    );
}
export default SearchFilter;