export interface IResource {
    Id: string
    IsObsolete: boolean
    Scopes: any[],
    Rev: string,
    Type: ResourceTypes,
    Attachments?: IResourceAttachments[],
    Properties: IResourceProperties
};

export interface IResourceProperties {
    Name: string,
    Type: ExerciseTypes | FileTypes,
    Thumbnail: string,
    Description?: string,
    Index?: number,
    Author?: string,
    Isbn?: string
    Link?: string
    Pages?: string
    PublicationDate?: string,
    Publisher?: string,
    Synopse?: string,
    file?: string
};

export interface IFile {
    Uri: string,
    ContentLength: number,
    Hash: string
}

export interface IResourceAttachments {
    Label: string,
    Uri: string
};

export enum ResourceTypes {
    EXERCISES = "Exercise",
    FILE = "File"
}

export enum ExerciseTypes {
    TVPV = "TvPv",
    BOOK = "Book",
    MINDFULNESS = "Mindfulness",
    CLASS = "Class"
};

export enum FileTypes {
    JPG = "Jpg"
};