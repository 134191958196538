import React, { useMemo } from 'react';
import { Select, Checkbox, Row, Col } from 'antd';
import { CoachingType, ICoache } from '../../../../models/coache.model';
import { ICenter } from '../../../../models/utils.model';
import MaskedDatePicker from '../../../../components/masked-date-picker/masked-date-picker';

import './CoacheInfoForm.scss';


const { Option } = Select;

interface ICoacheInfoFormProps {
    isEdit: boolean,
    centers: ICenter[],
    onChange(name: string, value: any): void,
    coachEdit: ICoache,
    canBecomeCoach: boolean
}

const CoacheInfoForm = ({ isEdit, centers, onChange, coachEdit, canBecomeCoach }: ICoacheInfoFormProps) => {

    function onChangeCoachInfo(key: string, value: any) {
        onChange('coach', { ...coachEdit.coach, [key]: key === 'unit_id' ? (value || null) : value })
    };

    function becomeCoach() {
        onChange('convert_coach', !coachEdit.convert_coach);
    };

    const nameId = useMemo(()=>{
        if(coachEdit.coach) {
            const results = centers.find((element): any => element.id === coachEdit.coach.unit_id);
            if(results) return results.name
        } else return ""
    // eslint-disable-next-line
    },[])

    const disableFields = useMemo((): boolean => {
        return Boolean(isEdit && canBecomeCoach && !coachEdit.convert_coach)
    }, [canBecomeCoach, coachEdit.convert_coach, isEdit]);

    return (
        <>
            {
                Boolean(canBecomeCoach) &&
                <Checkbox onClick={() => becomeCoach()}><b>Tornar Coach</b></Checkbox>
            }

            <Checkbox.Group
                style={{ width: '100%' }}
                disabled={disableFields}
                value={coachEdit.coach ? (coachEdit.coach.specializations ? coachEdit.coach.specializations : []) : []}
                onChange={items => onChangeCoachInfo('specializations', items)}>
                <h3>Especializações</h3>
                <Row>
                    {Object.keys(CoachingType).map((key: any) => (
                        <Col span={8} key={key}>
                            <Checkbox value={(CoachingType as any)[key]}>{key}</Checkbox>
                        </Col>
                    ))}
                </Row>
            </Checkbox.Group>
            <Select
                disabled={disableFields}
                allowClear={true}
                showSearch={true}
                className="centers-select"
                // eslint-disable-next-line
                defaultValue={coachEdit.coach ? nameId : undefined}
                placeholder={'Núcleo'}
                onChange={(value: string) => {
                        const newCenter: any = centers.find((element): any => element.name === value );
                        onChangeCoachInfo('unit_id', newCenter.id);
                    }}>
                {centers.map(({ id, name }: ICenter) => (
                    <Option key={id} value={name}>{name}</Option>
                ))}
            </Select>
            {
                (!isEdit || canBecomeCoach) && <div className="form-group input-activation">
                    <MaskedDatePicker 
                        onChange={onChangeCoachInfo} 
                        currentValue={(coachEdit.coach && coachEdit.coach.activation_date) ? coachEdit.coach.activation_date : '' } 
                        stringType={"activation_date"} 
                        classText={"date-activation"}
                        placeholder={"Data de Ativação"} 
                    />
                    <MaskedDatePicker 
                        onChange={onChangeCoachInfo} 
                        currentValue={(coachEdit.coach && coachEdit.coach.expiration_date) ? coachEdit.coach.expiration_date : '' } 
                        stringType={"expiration_date"} 
                        classText={"date-expiration"}
                        placeholder={"Data de Expiração"} 
                    />
                </div>
            }
        </>
    );
};

export default CoacheInfoForm;