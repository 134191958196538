export interface INotificationsList {
    id: string,
    title: string;
}

export enum ETypes {
    SATISFACTION_SURVEY = "Pesquisa de satisfação",
    ADMIN = "Admin",
    ALL = "Todos"
}

export const OTypes = {
    SATISFACTION_SURVEY: 'SATISFACTION_SURVEY',
    ADMIN: 'ADMIN'
}

export enum EScopes {
    SCIS = "SCIS",
    APP = "APP",
    ALL = "Todos os tipos"
}

export const OScopes = {
    SCIS: "SCIS",
    APP: "APP",
    ALL: "Todos os tipos"
}

export enum ERealms {
    coach = "coach",
    coachee = "coachee",
    ALL = 'Todos'
}

export const ORealms = {
    coach: "coach",
    coachee: "coachee",
    ALL: 'Todos'
}

export interface IPostNotification {
    title: string;
    message: string;
    user_id: string;
    expires_at: string | null;
    type: string;
    extras: object;
    scopes: EScopes[];
    realms: ERealms[];
}

interface IQueryStringList {
    pageSize?: number;
    nextPage?: string;
    search?: string,
    type?: string,
    realm?: string,
    scope?: string,
}

export const queryStringList = ({
    pageSize,
    nextPage,
    search,
    type,
    realm,
    scope
}: IQueryStringList) => {
    const params = [];

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }

    if (nextPage) {
        params.push(`nextPage=${nextPage}`);
    }

    if (search) {
        params.push(`search=${encodeURI(search)}`);
    }

    if (type) {
        params.push(`type=${type}`);
    }

    if (scope) {
        params.push(`scope=${scope}`);
    }

    if (realm) {
        params.push(`realm=${realm}`);
    }

    return `?${params.join('&')}`;
};
