import React, { useMemo, useState } from 'react';
import countries from 'i18n-iso-countries';
import { Select, Table, Input, Icon, Popconfirm } from 'antd';
import { IUser, IPersonalIDs, IDocsInfo } from '../../../../models/user.model';
import moment from 'moment-timezone';

import './PersonalIDs.scss';
import Notification, { NOTIFICATION_TYPE } from '../../../../components/notification/Notification';

const { Option, OptGroup } = Select;
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
countries.registerLocale(require("i18n-iso-countries/langs/pt.json"));

interface IProps {
    onChange: Function,
    userEdit: IUser
}

const PersonalIDs = ({
    onChange, userEdit
}: IProps) => {

    const currentLang = moment.locale().split('-')[0];
    const allCountries = countries.getNames(currentLang || 'pt');

    const [currentCountry, setCurrentCountry] = useState<string | undefined>(userEdit.personal_ids[0]
        ? countries.getName(userEdit.personal_ids[0].country, currentLang || 'pt')
        : undefined)

    const [addPersonalIDs, setAddPersonalIDs] = useState<any>({});

    const collums = [
        {
            title: 'Tipo',
            dataIndex: 'name',
            key: 'doc',
            render: (name?: string) => name ? (<span>{name}</span>)
                : (<Input
                    value={addPersonalIDs.name ? addPersonalIDs.name : undefined}
                    onChange={({ target }) => setAddPersonalIDs({ ...addPersonalIDs, name: target.value })}
                    placeholder="documento" />)
        },
        {
            title: 'Número/Código',
            dataIndex: 'value',
            key: 'doc_value',
            render: (value?: string) => value ? (<span>{value}</span>)
                : (<Input
                    value={addPersonalIDs.value ? addPersonalIDs.value : undefined}
                    onChange={({ target }) => setAddPersonalIDs({ ...addPersonalIDs, value: target.value })}
                    placeholder="número do documento" />)
        },
        {
            title: 'ação',
            render: (data: any) => {
                return (data.name && data.value) 
                    ? (<Popconfirm 
                            title="você realmente gostaria de remover？" 
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={()=>removeDoc(data.id)}>
                            <Icon type="delete"/> 
                        </Popconfirm>) 
                    : (<Icon type="plus" onClick={()=> {
                        addNewDoc();
                        setAddPersonalIDs({});
                    }} />)
            }
        }
    ]

    const addNewDoc = () => {
        if(addPersonalIDs.name && addPersonalIDs.value){
            const newPersonal_ids = [...userEdit.personal_ids];
            const currentIndex = newPersonal_ids.findIndex((element: IPersonalIDs) => countries.getName(element.country, currentLang || 'pt') === currentCountry);
            
            if (currentIndex === -1) {
                newPersonal_ids.push({
                    country: countries.getAlpha3Code(currentCountry ? currentCountry : '', currentLang || 'pt'),
                    docs: [addPersonalIDs]
                })
            } else {
                newPersonal_ids[currentIndex] = { ...newPersonal_ids[currentIndex], docs: [...newPersonal_ids[currentIndex].docs] }
                newPersonal_ids[currentIndex].docs.push(addPersonalIDs);
            }
            onChange("personal_ids", newPersonal_ids);
        } else {
            Notification(NOTIFICATION_TYPE.WARNING,"Campo Vazio", "Todos os campos devem está preenchidos");
        }
    }

    const removeDoc = (index: number) => {
        const newPersonal_ids = [...userEdit.personal_ids];
        const currentIndex = newPersonal_ids.findIndex((element: IPersonalIDs) => countries.getName(element.country, currentLang || 'pt') === currentCountry);

        newPersonal_ids[currentIndex] = { ...newPersonal_ids[currentIndex], docs: [...newPersonal_ids[currentIndex].docs] }
        newPersonal_ids[currentIndex].docs.splice(index, 1);

        if (newPersonal_ids[currentIndex].docs.length === 0) newPersonal_ids.splice(currentIndex, 1);

        onChange("personal_ids", newPersonal_ids);
    };

    const renderSortContries = useMemo(() => {
        const newAllCountries = { ...allCountries };
        const optionsRender =
            userEdit.personal_ids
                .map((element: IPersonalIDs) => {
                    delete newAllCountries[countries.alpha3ToAlpha2(element.country)];
                    return countries.getName(element.country, currentLang || 'pt');
                });
        return [[...optionsRender], [...Object.values(newAllCountries)]]
        // eslint-disable-next-line
    }, [userEdit.personal_ids])

    const dataSource = useMemo(() => {
        return userEdit.personal_ids
            .filter((element: IPersonalIDs) => countries.getName(element.country, currentLang || 'pt') === currentCountry)
            .flatMap((element: IPersonalIDs) => element.docs)
            .map((element: IDocsInfo, index: number) => ({ name: element.name, value: element.value, id: index }))
        // eslint-disable-next-line
    }, [currentCountry, userEdit.personal_ids])

    return (
        <>
            <Select
                value={currentCountry}
                allowClear={true}
                showSearch={true}
                placeholder={'País'}
                onChange={(value: string) => {
                    setCurrentCountry(value);
                    setAddPersonalIDs({});
                }}>
                <OptGroup label="Documentos Cadastrados">
                    {renderSortContries[0].map((country: string) => (
                        <Option key={country} value={country}>{country}</Option>
                    ))}
                </OptGroup>

                <OptGroup label="Não Cadastrados" >
                    {renderSortContries[1].map((country: string) => (
                        <Option key={country} value={country}>{country}</Option>
                    ))}
                </OptGroup>
            </Select>

            <Table
                className='table-personal_ids'
                columns={collums}
                rowKey={(element, index) => `${index}`}
                dataSource={currentCountry ? [...dataSource, { name: undefined, value: undefined }] : []}
                pagination={false}
            />

        </>
    )
};

export default PersonalIDs;