import { Button, Form, Icon, Pagination, Switch, Table, Tooltip } from "antd";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Search from "antd/lib/input/Search";
import { IState } from "../../../reducers/reducers";
import { changeCategoryEdit, changeOrder, changePageCategories, changeSearchValue, enableDisableCategory, getParents } from "../../../reducers/categories.reducer";
import { ICategory } from "../../../models/categories.model";
import { findCategory, formatDateWithHours } from "../../../utils/moment.functions";

interface ICategoryListProps {
    categories: ICategory[],
    loading: boolean,
    getCategories(): void,
    onEdit(category: ICategory): void,
};

const CategoriesList = ({categories, getCategories, loading}: ICategoryListProps) => {

    const dispatch = useDispatch();

    const { search, page, pageSize, parents, pageList } = useSelector(({categories}: IState) => ({
        search: categories.search,
        page: categories.page,
        pageSize: categories.pageSize,
        parents: categories.parents,
        pageList: categories.pageList,
    }));

    const totalPages = useMemo<number>(() => {
        const hasNextPage = Boolean(pageList[page + 1]);
        return (page + (hasNextPage ? 2 : 1)) * pageSize;
     // eslint-disable-next-line
    }, [page, pageList]);

    useEffect(() => {
        getCategories();
        dispatch(getParents());
        // eslint-disable-next-line
    }, [page, pageSize]);

    const columns = [
        {
            title: "Nome",
            key: "name",
            width: 250,
            render: (cat: ICategory) => {
                const name = cat["properties_pt"].name;
                return name;
            },
        },
        {
            title: "Parente",
            key: "parent",
            width: 170,
            render: (category: ICategory) => {
                return findCategory(parents, category.parent);
            },
        },
        {
            title: "Data de criação",
            dataIndex: "created_at",
            key: "created_at",
            width: 170,
            render: (category: ICategory) => {
                return formatDateWithHours(category.created_at);
            },
        },
        {
            title: "Última atualização",
            dataIndex: "updated_at",
            key: "updated_at",
            width: 170,
            render: (category: ICategory) => {
                return formatDateWithHours(category.updated_at);
            },
        },
        {
            title: "Ações",
            key: "action",
            width: 100,
            render: (category: ICategory) => (
                <span className="icons-action">
                    <Tooltip title={"Editar categoria"}>
                        <Icon
                            type="edit"
                            onClick={() => {
                                dispatch(changeCategoryEdit(category));
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={category.active ? "Desabilitar categoria" : "Habilitar categoria"}>
                        <Switch
                            checked={category.active}
                            onChange={() => dispatch(enableDisableCategory(category, !category.active))}
                        />
                    </Tooltip>
                </span>
            ),
        },
    ];

    const onSearch = () => {
        dispatch(changePageCategories(0));
        dispatch(getCategories());
    };

    return (
        <>
            <Form className="filter-content3">
                <div className="input-filter">
                    <label>Buscar</label>
                    <Search
                        value={search}
                        className="search-input"
                        placeholder="Buscar"
                        onSearch={onSearch}
                        onChange={({ target }) =>
                            dispatch(changeSearchValue(target.value))
                        }
                    />
                </div>
                <Button
                    onClick={onSearch}
                    className="btn-search"
                    type="primary"
                    icon="search"
                >
                    Pesquisar
                </Button>
            </Form>
            <Table
                className="categories-list"
                pagination={false}
                loading={loading}
                columns={columns}
                dataSource={categories.map((cat: ICategory) => ({
                    ...cat,
                    key: cat.id,
                }))}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={(c1, c2, sorter) => dispatch(changeOrder(sorter.field, sorter.order.replace('end', ''))) }
            />
            <Pagination
                className="pagination"
                pageSize={pageSize}
                total={totalPages}
                current={page + 1}
                onChange={(nextPage) => dispatch(changePageCategories(nextPage - 1))}
                style={{ marginTop: "25px" }}
            />
        </>
    )
};

export default CategoriesList;