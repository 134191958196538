import React, { useEffect } from "react";
import { Table, Icon, Pagination, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";

import { IUser } from "../../../models/user.model";
import { IState } from "../../../reducers/reducers";
import { changePageSize, changePage, getUser, changeOrder } from "../../../reducers/users.reducer";
import SearchFilter from "./search-filter/SearchFilter";
import useCheckPermissions from "../../../hooks/useCheckPermissions";
import { permissionKeys } from "../../../utils/permissions";
import RestrictedComponent from "../../../components/restricted-component/RestrictedComponent";

import "./UserList.scss";
const { Text } = Typography;

const allActionPermissionKeys = [
    permissionKeys.USER_USERS_EDIT,
];

interface IUserListProps {
    users: IUser[];
    loading: boolean;
    total: number;
    getUsers(): void;
    onEditUser(user: IUser): void;
}

const UserList = ({
    users,
    loading,
    total,
    getUsers,
}: IUserListProps) => {
    const { page, pageSize } = useSelector(({ users }: IState) => ({
        page: users.page,
        pageSize: users.pageSize,
    }));
    const dispatch = useDispatch();
    const checkPermissions = useCheckPermissions();

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line
    }, [page, pageSize]);

    const columns = [
        {
            title: "Nome",
            dataIndex: "nickname",
            key: "nickname",
            sorter: true,
            ellipsis: true,
            render: (_: any, record: any) => {
                const { given_name: givenName, family_name: familyName, nickname } = record;

                if (nickname && givenName && familyName) {
                    return `${nickname} (${givenName} ${familyName})`;
                } else if (nickname) {
                    if (givenName || familyName) {
                        return `${nickname} (${givenName || familyName})`;
                    } else {
                        return nickname;
                    }
                } else if (givenName && familyName) {
                    return `${givenName} ${familyName}`;
                } else if (givenName || familyName) {
                    return `${givenName || familyName}`;
                } else {
                    return '(sem nome)';
                }
            },
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: true,
            ellipsis: true,
        },
        {
            title: "Telefone",
            dataIndex: "phone_number",
            key: "phone_number",
            sorter: true,
            ellipsis: true,
            width: 200,
        },
        {
            title: "Ações",
            key: "action",
            width: 100,
            render: (user: IUser) => (
                <span className="icons-action">
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_USERS_EDIT])}>
                        <Icon
                            type="edit"
                            onClick={() => {
                                dispatch(getUser(user.id));
                            }}
                        />
                    </RestrictedComponent>
                </span>
            ),
        },
    ];

    if (!checkPermissions(allActionPermissionKeys, true)) {
        columns.pop();
    }

    return (
        <>
            <SearchFilter />
            <Table
                className="user-list"
                pagination={false}
                loading={loading}
                columns={columns}
                dataSource={users.map((user: IUser) => ({
                    ...user,
                    key: user.id,
                }))}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={(c1, c2, sorter) => dispatch(changeOrder(sorter.field, sorter.order.replace('end', ''))) }
            />
            <div style={{ textAlign: 'right', marginTop: 10, marginBottom: -15 }}>
                <Text><b>{total}</b> usuários encontrados</Text>
            </div>
            <Pagination
                className="users-pagination"
                showSizeChanger
                onShowSizeChange={(_, pageSize) =>
                    dispatch(changePageSize(pageSize))
                }
                defaultPageSize={pageSize}
                defaultCurrent={page}
                current={page}
                total={total > 10000 ? 10000 : total}
                pageSizeOptions={["10", "25", "50", "100"]}
                onChange={(page) => dispatch(changePage(page))}
            />
        </>
    );
};

export default UserList;
