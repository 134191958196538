import React from 'react';
import {Input, Select} from 'antd';
import {useSelector} from 'react-redux';

import PasswordForm from '../../admin-edit/password-form/PasswordForm';
import {ICountry} from '../../../../models/utils.model';
import {IState} from '../../../../reducers/reducers';
import {IAdmin} from '../../../../models/administrator.model';
import PermissionForm from '../../admin-edit/permission-form/PermissionForm';

import '../../admin-edit/AdminEdit.scss';

const {Option} = Select;

interface IAdminInfoProps {
    onChange(name: string, value: any): void,
    adminToEdit: IAdmin,
    countries: ICountry[]
}

function AdminInfo({onChange, adminToEdit, countries }: IAdminInfoProps) {
    const currentUser = useSelector(({ createAdmin }:IState)=> createAdmin.currentUser);

    function onChangeCountry(key: string, value: any) {
        if (key === 'country') {
            onChange('address', {
                ...adminToEdit.address, ...{
                    country: value,
                    region: undefined,
                    locality: undefined
                }
            })
        }
    };

    return (
        <>
            {!currentUser.email
                ? (
                    <>
                        <fieldset>
                            <legend className='legend-fieldset'>Usuário</legend>
                            <Input
                                value={adminToEdit.nickname}
                                onChange={({ target }) => onChange('nickname', target.value)}
                                placeholder={'Nome completo'}
                            />

                            <div className='form-group trio-group'>
                                <Input
                                    className='email-create-admin'
                                    value={adminToEdit.email}
                                    onChange={({ target }) => onChange('email', target.value)}
                                    placeholder={'E-mail'}
                                />
                                <Input
                                    className='input-cpf'
                                    value={adminToEdit.cpf}
                                    disabled={adminToEdit.address ? !(adminToEdit.address.country === 'Brasil') : true}
                                    onChange={({ target }: any) => onChange('cpf', target.value)}
                                    placeholder={'CPF'}
                                />
                                <Select
                                    className='select-contry'
                                    value={adminToEdit.address ? adminToEdit.address.country : undefined}
                                    allowClear={true}
                                    showSearch={true}
                                    placeholder={'País'}
                                    onChange={(country: string) => onChangeCountry('country', country)}>
                                    {countries.map((country: ICountry) => (
                                        <Option key={country.url} value={country.name}>{country.name}</Option>
                                    ))}
                                </Select>
                            </div>
                            <p className='text-cpf-create-admin' >
                                *CPF só é obrigatório se o país for Brasil
                            </p>
                        </fieldset>
                        
                        <fieldset>
                            <legend className='legend-fieldset'>Permissões</legend>
                            <PermissionForm adminToEdit={adminToEdit} onChange={onChange} />                 
                        </fieldset>

                        <fieldset >
                            <legend className='legend-fieldset'>Senha</legend>
                            <PasswordForm 
                                isEdit={Boolean(adminToEdit && adminToEdit.id)} 
                                onChange={onChange} 
                                adminToEdit={adminToEdit} 
                            />
                        </fieldset>
                    </>
                )
                : (
                    <>
                        <fieldset>
                            <legend className='legend-fieldset'>Permissões</legend>
                            <PermissionForm adminToEdit={adminToEdit} onChange={onChange}/>
                        </fieldset>

                        <h6 className='h6-exist-user'>
                            O usuário já existe na base de dados, mas não é admin. Preencha os campos acima e clique em salvar para transformá-lo em admin.
                        </h6>
                    </>
                )
            }
        </>
    )
};

export default AdminInfo;
