import React, { useEffect, useState } from 'react';
import { IResource, ResourceTypes } from '../../../models/resources.model';
import { fetchResource } from '../../../utils/webApi';
import ThumbnailAsync from '../../../components/thumbnail-async/ThumbnailAsync';

import './ResourceDetail.scss';
import { Button } from 'antd';
import Notification, { NOTIFICATION_TYPE } from '../../../components/notification/Notification';

interface IResourceDetailProps {
    id: string,
    onEdit(resource: IResource): void
};

const ResourceDetail = ({ id, onEdit }: IResourceDetailProps) => {

    const [resource, setResource] = useState<IResource | {}>({});
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        async function getResource(id: string) {
            try {
                const resource: IResource = await fetchResource(id);
                setResource(resource);
            } catch (error) {
                Notification(NOTIFICATION_TYPE.ERROR, 'Error', error.message)
            } finally {
                setLoading(false)
            }
        };
        getResource(id);
    }, [id]);

    const { Properties, Type, Id } = (resource as IResource);
    const { Thumbnail, Name, Description } = Properties || {}

    return loading ?
        <h2>loading...</h2> :
        <section className="resource-detail-content">
            {Type && <Button
                className="edit-button"
                onClick={() => onEdit(resource as IResource)}>Editar</Button>
            }

            {!Type && <h2>Error ao consultar Resource</h2>}

            {Type === ResourceTypes.EXERCISES &&
                <>
                    {Thumbnail && <ThumbnailAsync className="resource-thumb" fileName={Thumbnail} />}
                    <h2>{Name}</h2>
                    <h4>{Description}</h4>
                </>
            }
            {Type === ResourceTypes.FILE &&
                <>
                    <h2>{Id}</h2>
                    <p>Desmembrar File</p>
                </>
            }
        </section>

}

export default React.memo(ResourceDetail);