import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import CoacheList from './coache-list/CoacheList';
import { ICoache } from '../../models/coache.model';
import { Button, Modal } from 'antd';
import CoacheEdit from './coache-edit/CoacheEdit';
import { useDispatch, useSelector } from 'react-redux';
import { getCoaches, saveCoachApi, changeCoachEdit, getClass } from '../../reducers/coaches.reducer';
import { IState } from '../../reducers/reducers';
import { controlCurrentSteps, controlCurrentUser } from '../../reducers/create-coache.reducer';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { permissionKeys } from '../../utils/permissions';

const Coache = (): any => {
    const { isLoading, coaches, total, coachEdit, isLoadingSave } = useSelector((state: IState) => state.coaches);
    const checkPermissions = useCheckPermissions();

    const dispatch = useDispatch();

    function onSaveCoach(coach: ICoache) {
        dispatch(saveCoachApi(coach));
    }

    function onCancel(){
        dispatch(changeCoachEdit(null));
        dispatch(controlCurrentSteps(0));
        dispatch(controlCurrentUser(undefined));
    }

    return (
        <section>
            <TitleSection title={'Coaches'} >
                <Button
                    onClick={() => dispatch(changeCoachEdit({ } as any))}
                    size="large"
                    type="primary"
                    hidden={!checkPermissions([permissionKeys.USER_COACHES_CREATE])}
                >
                    Adicionar Coach
                </Button>
            </TitleSection>
            <CoacheList
                onEditCoach={(coach: ICoache) => dispatch(changeCoachEdit(coach))}
                getCoaches={() => dispatch(getCoaches())}
                getClass={() => dispatch(getClass())}
                total={total}
                coaches={coaches}
                loading={isLoading}
                checkPermissions={checkPermissions}
            />

            {Boolean(coachEdit) && (
                <Modal
                    width={700}
                    title={ Boolean(coachEdit && coachEdit.id) ? "Editar Coach" : "Adicionar Coach"}
                    visible={true}
                    footer={false}
                    maskClosable={false}
                    onCancel={() => onCancel()}>
                    <CoacheEdit
                        onOk={onSaveCoach}
                        onCancel={() => onCancel()}
                        coache={coachEdit as ICoache}
                        loading={isLoadingSave}
                        isEdit={Boolean(coachEdit && coachEdit.id)}
                    />
                </Modal>
            )
            }

        </section >
    );
}

export default Coache;