import React from 'react';
import { Button, Input, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    ERealms,
    EScopes,
    ETypes,
    ORealms,
    OScopes,
    OTypes,
} from '../../models/notificationsList.model';
import {
    handleSetFormRealm,
    handleSetFormScope,
    handleSetFormSearch,
    handleSetFormType,
    handleSubmitFilters,
} from '../../reducers/notificationsList.reducer';
import { IState } from '../../reducers/reducers';

const { Option } = Select;

export default function NotificationsFilters() {
    const dispatch = useDispatch();
    const search = useSelector(
        ({ notificationsList }: IState) => notificationsList.formFilter.search
    );

    return (
        <div className='notifications-filters-container'>
            <div className='input-filter'>
                <label>Filtrar por tipo</label>
                <Select
                    className='type-notification'
                    placeholder='Tipo'
                    style={{ width: 180 }}
                    onChange={(field: string) =>
                        dispatch(handleSetFormType(field))
                    }
                >
                    <Option key={ETypes.ALL} value={undefined}>
                        {ETypes.ALL}
                    </Option>
                    <Option key={ETypes.ADMIN} value={OTypes.ADMIN}>
                        {ETypes.ADMIN}
                    </Option>
                    <Option
                        key={ETypes.SATISFACTION_SURVEY}
                        value={OTypes.SATISFACTION_SURVEY}
                    >
                        {ETypes.SATISFACTION_SURVEY}
                    </Option>
                </Select>
            </div>

            <div className='input-filter'>
                <label>Filtrar por plataforma</label>
                <Select
                    className='type-notification'
                    placeholder='Tipo'
                    style={{ width: 180 }}
                    onChange={(field: string) =>
                        dispatch(handleSetFormScope(field))
                    }
                >
                    <Option value={undefined}>{EScopes.ALL}</Option>
                    <Option value={OScopes.APP}>{EScopes.APP}</Option>
                    <Option value={OScopes.SCIS}>{EScopes.SCIS}</Option>
                </Select>
            </div>

            <div className='input-filter'>
                <label>Filtrar tipo de usuário</label>
                <Select
                    className='type-notification'
                    placeholder='Tipo'
                    style={{ width: 180 }}
                    onChange={(field: string) =>
                        dispatch(handleSetFormRealm(field))
                    }
                >
                    <Option value={undefined}>{ERealms.ALL}</Option>
                    <Option value={ORealms.coach}>{ERealms.coach}</Option>
                    <Option value={ORealms.coachee}>{ERealms.coachee}</Option>
                </Select>
            </div>

            <div className='input-filter'>
                <label>Pesquisar</label>
                <Input
                    className='search-input'
                    placeholder='Buscar'
                    value={search}
                    onChange={(event) =>
                        dispatch(handleSetFormSearch(event.target.value))
                    }
                />
            </div>

            <Button
                type='primary'
                className='new-notification-button'
                onClick={() => dispatch(handleSubmitFilters())}
            >
                Aplicar filtro
            </Button>
        </div>
    );
}
