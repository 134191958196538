import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { fetchSalesRegistrations, resetPage } from "../../reducers/sales-registrations.reducer";
import TitleSection from "../../components/title-section/TitleSection";
import { IState } from "../../reducers/reducers";
import SalesRegistrationsList from "./sales-registrations-list/SalesRegistrationsList";

function SalesRegistrations() {
    const { isLoading, salesRegistrations } = useSelector((state: IState) => ({
        isLoading: state.salesRegistrations.isLoading,
        salesRegistrations: state.salesRegistrations.salesRegistrations
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetPage());
        dispatch(fetchSalesRegistrations());
    }, [dispatch]);

    return (
        <>
            <TitleSection title={"Registro de Vendas"} />
            <SalesRegistrationsList isLoading={isLoading} salesRegistrations={salesRegistrations} />
        </>
    );
}

export default SalesRegistrations;
