import React from 'react';
import ImportInfo from '../import-info/ImportInfo';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { IPreviewInformation, ICoachImport } from '../../../models/import.model';
import { Collapse, Table, Button, Tooltip } from 'antd';

import './ImportResult.scss';
import { clearImportStore, changePreviewInformation, changeComponent } from '../../../reducers/importation.reducer';
import { ImportationComponent } from '../Integrations';

const transformError = (value: string) => ({ bad_request: 'Valor(es) inválido(s) ou incompleto(s)', conflict: 'Conflito' } as any )[value] || 'Erro Interno'

const transformErrorDescription = (value: string) => {
    if (!value) {
        value = '';
    }

    return value
        .replace('fullname', 'Nome Completo')
        .replace('cpf', 'CPF')
        .replace('gender', 'Sexo')
        .replace('phone_number', 'Telefone')
        .replace('state', 'Estado')
        .replace('country', 'País');
}

const transformStatus = (value: string) => ({ CREATED: 'Criado e matriculado', ENROLLED: 'Matriculado' } as any)[value] || 'Erro Interno';

const transformStatusDescription = (value: string) => ({ CREATED: 'Usuário foi criado e matriculado na turma', ENROLLED: 'Usuário já existe, mas foi matriculado na turma' } as any)[value] || 'Erro Interno';

const transformGender = (value: string) => ({ male: 'Masculino', female: 'Feminino' } as any)[value] || value;

function ImportResult() {

    const { importInfo, importResult } = useSelector((state: IState) => ({
        importResult: state.importation.coachesImportResult,
        importInfo: state.importation.previewInformation as IPreviewInformation
    }));

    const dispatch = useDispatch();

    const fields = [{
        title: 'Nome completo',
        dataIndex: 'coach.fullname',
        key: 'fullname'
    }, {
        title: 'CPF',
        dataIndex: 'coach.cpf',
        key: 'cpf'
    }, {
        title: 'Sexo',
        dataIndex: 'coach.gender',
        key: 'gender',
        render: (item: any) => {
            return transformGender(item);
        }
    }, {
        title: 'Email',
        dataIndex: 'coach.email',
        key: 'email'
    }, {
        title: 'Telefone',
        dataIndex: 'coach.phone_number',
        key: 'phone_number'
    }, {
        title: 'País',
        dataIndex: 'coach.country',
        key: 'country'
    }, {
        title: 'Estado',
        dataIndex: 'coach.state',
        key: 'state'
    }, {
        title: 'Unidade',
        dataIndex: 'coach.unit',
        key: 'state'
    }, {
        title: 'Turma',
        dataIndex: 'coach.class',
        key: 'state'
    }, {
        title: 'Executive',
        dataIndex: 'coach.executive',
        key: 'executive',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }, {
        title: 'Business',
        dataIndex: 'coach.business',
        key: 'business',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }, {
        title: 'Career',
        dataIndex: 'coach.career',
        key: 'career',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }, {
        title: 'Trainer',
        dataIndex: 'coach.trainer',
        key: 'trainer',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }, {
        title: 'livingFamily',
        dataIndex: 'coach.livingFamily',
        key: 'livingFamily',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }, {
        title: 'Microfranchisee',
        dataIndex: 'coach.microfranchisee',
        key: 'microfranchisee',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }, {
        title: 'Master',
        dataIndex: 'coach.master',
        key: 'master',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }, {
        title: 'mindfulness',
        dataIndex: 'coach.mindfulness',
        key: 'mindfulness',
        render: (item: any) => {
            return (
                <span style={{ textAlign: 'center' }}>{item && typeof item === 'boolean' ? item ? 'sim' : 'não' : 'padrão'}</span>
            )            
        }
    }];

    const statusColumn = ({
        title: 'Status',
        dataIndex: 'result.status',
        key: 'status',
        render: (item: any) => {
            return (
            <Tooltip title={transformStatusDescription(item)} >
                <span style={{ color: 'red' }}>{transformStatus(item)}</span>
            </Tooltip>
            )            
        }
    });

    const errorColumn = ({
        title: 'Erro',
        dataIndex: 'error',
        key: 'error',
        render: (item: any) => {
            return (
            <Tooltip title={transformErrorDescription(item.error_description)} >
                <span style={{ color: 'red' }}>{transformError(item.error)}</span>
            </Tooltip>
            )
            
        }
    });

    return (
        <section className="import-result-container">
            <ImportInfo
                activation_date={importInfo.activation_date}
                expiration_date={importInfo.expiration_date}
                unit_id={importInfo.unit_id}
                class_id={importInfo.class_id}
            />

            <Collapse
                className="collapse-success">

                <Collapse.Panel
                    header={`${importResult.success.length} coach(es) importados com sucesso`}
                    key="1">

                    <Table columns={[...fields, statusColumn]} rowKey={(coachImport: ICoachImport, index)=> `${index}`} dataSource={importResult.success as any} />
                </Collapse.Panel>

                <Collapse.Panel
                    className="collapse-error"
                    header={`${importResult.error.length} coach(es) com erros na importação`}
                    key="2">

                    <Table columns={[...fields, errorColumn]} rowKey={(coachImport: ICoachImport, index)=> `${index}`} dataSource={importResult.error as any} />
                </Collapse.Panel>

            </Collapse>

            <div className="buttons-import-result">
                {
                    importResult.error.length &&
                    <Button
                        className='button-color-import'
                        onClick={() => {
                            dispatch(changePreviewInformation({ ...importInfo, coaches: importResult.error }))
                            dispatch(changeComponent(ImportationComponent.PREVIEW));
                        }}
                    >
                        Tratar coaches com erro
                </Button>
                }
                <Button
                    type="primary"
                    onClick={() => dispatch(clearImportStore())}>
                    Concluído
                </Button>
            </div>
        </section >
    );
};

export default ImportResult;
