export interface ICoacheScisClasses {
    id: string,
    email: string,
    phone_number: string,
    password?: string,
    password_mode?: PasswordMode,
    given_name: string,
    cpf: string,
    family_name: string,
    nickname: string,
    gender: GenderScisClasses,
    birthdate: string,
    address: IAddressScisClasses,
    locale: string,
    zoneinfo: string,
    enable: boolean,
    center_id: string,
    trainer_id: string,
    email_verified?: boolean,
    enabled?: boolean,
    phone_number_verified?: boolean,
    created_at?: string,
    updated_at?: string,
    personal_ids: IPersonalIDsScisClasses[],
    coach: ICoacheInfoScisClasses,
    convert_coach?: boolean,
    enrollment?: string,
    enrollment_date?: string,
    phones: string[],
    license_name: string,
    scis20_last_access: string,
    total_hours: string,
};

export interface IClassScisClasses {
    centerId: string,
    classId: string,
    className: string,
    courseId: string,
    description: string
    endDate: string,
    id: string,
    picture: string,
    startDate: string,
    trainerId: string,
    type: string,
};

export interface IUnitScisClasses {
    id: string,
    name: string,
};

export interface IPersonalIDsScisClasses {
    country: string,
    docs: IDocsInfoScisClasses[],
}

export interface IDocsInfoScisClasses {
    name: string
    value: string
}

export enum PasswordMode {
    PERM = 'permanent',
    TEMP = 'temporary',
    LINK = 'confirm_link'
};

export const getDefaultPassword = () => `febracis${new Date().getFullYear()}`;

export interface ICoacheInfoScisClasses {
    unit_id?: string,
    unit?: string,
    activation_date?: string,
    expiration_date?: string,
    status?: string
    recurrent_ids?: string[],
    enrollment?: string,
};

export enum SearchFieldsScisClasses {
    ID = 'id',
    EMAIL = 'email',
    PHONE_NUMBER = 'phone_number',
    NICKNAME = 'nickname',
    GIVEN_NAME = 'given_name',
    FAMILY_NAME = 'family_name',
    RECURRENT_IDS = 'coach.recurrent_ids',
    COUPONS = 'coach.coupons.coupon'
};

export enum OrderModeScisClasses {
    ASC = 'asc',
    DESC = 'desc',
};

export interface IAddressScisClasses {
    street_address: string,
    locality: string,
    region: string,
    postal_code: string,
    country: string
};

export enum GenderScisClasses {
    MALE = 'male',
    FEMALE = 'female'
};

export interface ICouponsScisClasses {
    payment_id: string,
    created_at: string,
    coupon: string,
    recurrent_id: string,
}

interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string,
    search_field: SearchFieldsScisClasses[],
    order: SearchFieldsScisClasses,
    order_mode: OrderModeScisClasses,
    filter_unit: string[],
    filter_class: string[],
}

export const queryStringList = ({ page, pageSize, search, search_field, order, order_mode, filter_unit, filter_class }: IQueryStringList) => {
    let params = [];

    if (page) {
        params.push(`page=${page - 1}`);
    }

    if (pageSize) {
        params.push(`page_size=${pageSize}`);
    }

    if (search) {
        params.push(`search=${window.encodeURIComponent(search)}`);

        if (!search_field || search_field.length === 0) {
            search_field = Object.values(SearchFieldsScisClasses);
        }

        for (const field of search_field) {
            params.push(`search_field=${field}`);
        }
    }

    params.push(`order=${order}`);
    params.push(`order_mode=${order_mode}`);

    if (filter_unit[0] !== '') {
        for (const unit of filter_unit) {
            params.push(`unit=${unit}`);
        }
    }

    if (filter_class && filter_class.length > 0) {
        for (const classes of filter_class) {
            params.push(`enrollment=${classes}`);
        }
    }

    return `?${params.join('&')}`;
};
