import React from 'react';
import { Modal, Card } from 'antd'
import { useSelector } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import './ContactDetails.scss';

interface IContactDetails {
    isOpen: boolean,
    setIsOpen: Function
}

const ContactDetails = ({
    isOpen,
    setIsOpen
}: IContactDetails ) => {

    const messageComplet  = useSelector(({ contact }: IState  ) => contact.messageComplet );

    return (
        <Modal
            className='contact-details-modal' 
            visible={isOpen}
            onCancel={()=>setIsOpen(false)}
            maskClosable={false}
            footer={false} >
                <Card title={messageComplet.subject} className='mini-card' >
                    <p>{messageComplet.details}</p>
                </Card>
        </Modal>
    )
}

export default ContactDetails;