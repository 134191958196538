import React, { useState } from 'react';
import Search from 'antd/lib/input/Search';
import { Button } from 'antd';
import { asyncGetCoaches, controlProfileSearch, controlProfilePage } from '../../../reducers/profile.reducer';
import { useDispatch } from 'react-redux';

import './ProfileFilter.scss';

const ProfileFilter = () => {

    const [ search, setSearch ] = useState('');
    const dispatch = useDispatch();

    return (
        <div className="container-profile-filter" >
            <Search
            onSearch={()=>{
                dispatch(controlProfileSearch(search));
                dispatch(controlProfilePage(1));
                dispatch(asyncGetCoaches());
            }}
            value={search}
            onChange={({ target })=> setSearch(target.value)} 
            placeholder="Pesquisar" />
            <Button
                onClick={()=>{
                    dispatch(controlProfileSearch(search));
                    dispatch(controlProfilePage(1));
                    dispatch(asyncGetCoaches());
                }}
                type="primary">Pesquisar</Button>
        </div>
    )
};

export default ProfileFilter;