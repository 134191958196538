import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { ICoache } from '../../../../models/coache.model';


interface props {
    onChange: Function,
    coachEdit: ICoache
}

const EnableForm = ({ onChange, coachEdit }: props) => {

    const [checked, setChecked] = useState(coachEdit.enabled || false);

    const changeCheck = (e: any) => {
        setChecked(e.target.checked);
        onChange('enabled', e.target.checked);
    }
    return (
        <Checkbox 
        onChange={changeCheck}
        checked={checked}>Ativo</Checkbox>
    )
};

export default EnableForm;