import React from 'react';
import { Route, Redirect, Switch } from "react-router-dom";
import Dashboard from './containers/dashboard/Dashboard';
import { routes } from './utils/routers.constants';
import Login from './containers/login/Login';
import CredentialsHelper from './utils/credentials-helper';

import './App.scss';

const App: React.FC = () => {

  const isLogged: boolean = CredentialsHelper.isLoggedIn();

  return (
    <Switch>
      <Route path={routes.DASHBOARD} component={Dashboard} />
      <Route path={routes.LOGIN} component={Login} />
      <Redirect from={routes.ROOT} to={isLogged ? routes.DASHBOARD : routes.LOGIN} />
    </Switch>
  );
}

export default App;
