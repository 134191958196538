import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Pagination, Table } from 'antd';
import DOMPurify from 'dompurify';

import TitleSection from '../../components/title-section/TitleSection';
import NewNotificationModal from './NewNotificationModal';
import { EScopes, INotificationsList } from '../../models/notificationsList.model';

import {
    asyncGetNotificationsList,
    setCurrentPage,
} from '../../reducers/notificationsList.reducer';
import { IState } from '../../reducers/reducers';

import './NotificationsList.scss';
import NotificationsFilters from './NotificationsFilters';

const cleanData = (string: string) => ({
    __html: DOMPurify.sanitize(string),
});

function NotificationsList() {
    const [openModal, setOpenModal] = useState<boolean>(false);

    const notifications = useSelector(
        ({ notificationsList }: IState) => notificationsList.notificationsList
    );
    const isLoading = useSelector(
        ({ notificationsList }: IState) => notificationsList.isLoading
    );
    const currentPage = useSelector(
        ({ notificationsList }: IState) => notificationsList.currentPage
    );
    const pageList = useSelector(
        ({ notificationsList }: IState) => notificationsList.pageList
    );

    const pageSize = 10;
    const totalPages = useMemo<number>(() => {
        const hasNextPage = Boolean(pageList[currentPage + 1]);

        return (currentPage + (hasNextPage ? 2 : 1)) * pageSize;
    }, [currentPage, pageList]);
    const dispatch = useDispatch();

    const columns = [
        {
            title: 'Título',
            dataIndex: 'title',
            key: 'title',
            width: '15%',
        },
        {
            title: 'Texto',
            dataIndex: 'message',
            key: 'message',
            render: (message: string) => (
                <span dangerouslySetInnerHTML={cleanData(message)} />
            )
        },
        {
            title: 'Plataforma',
            dataIndex: 'scopes',
            key: 'scopes',
            render: (scopes: EScopes[]) => (
                scopes.map((scope, index) => (
                    <span
                        key={index}
                        style={{margin: '0 3px'}}
                    >
                        {scope}
                    </span>
                ))
            )
        },
        {
            title: 'Destinatário',
            dataIndex: 'user_id',
            key: 'user_id',
        },
    ];

    const handleNextPage = (nextPage: number) => {
        dispatch(setCurrentPage(nextPage - 1));
    };

    useEffect(() => {
        dispatch(asyncGetNotificationsList());
    }, [currentPage, dispatch]);

    return (
        <main className='notificationslist-container'>
            <TitleSection title='Notificações' />
            <div className='notifications-filters-bar'>
                <NotificationsFilters />
                <Button
                    type='primary'
                    className='new-notification-button'
                    onClick={() => setOpenModal(true)}
                >
                    Criar notificação
                </Button>
            </div>
            <Table
                columns={columns}
                dataSource={notifications}
                pagination={false}
                loading={isLoading}
                rowKey={(element: INotificationsList) => element.id}
            />

            <Pagination
                pageSize={pageSize}
                total={totalPages}
                current={currentPage + 1}
                onChange={handleNextPage}
                className='pagination'
                style={{ marginTop: '25px' }}
            />

            {openModal && (
                <NewNotificationModal closeModal={() => setOpenModal(false)} />
            )}
        </main>
    );
}

export default NotificationsList;
