import React from 'react';
import { Select } from 'antd';
import { IState } from '../../../../reducers/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { controlPanelGraphSelectYear  } from '../../../../reducers/panelcontrol.graph.reducer';

const { Option } = Select;

const PanelGraphSelect = () => {

    const totalYear = useSelector(({ panelcontrolgraph }: IState)=> panelcontrolgraph.totalYear);
    const selectYear = useSelector(({ panelcontrolgraph }: IState)=> panelcontrolgraph.selectYear);
    const dispatch = useDispatch();

    const handleChange = (value: string) => {
        dispatch(controlPanelGraphSelectYear(value));
    };

    return (
        <Select defaultValue={selectYear} style={{ width: 135 }} onChange={handleChange}>
            { totalYear.map(element=>(<Option key={ element } value={element}>{element}</Option>)) }
        </Select>
    )
};

export default PanelGraphSelect;