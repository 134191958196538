import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import Search from 'antd/lib/input/Search';
import { Button } from 'antd';
import { controlRegisterUnitsSearch, controlRegisterUnitsIsOpen, controlRegisterUnitsPage } from '../../../reducers/register-units.reducer';

import './RegisterUnitsFilter.scss';

const RegisterUnitsFilter = () => {

    const [search, setSearch] = useState('');

    const dispatch = useDispatch();

    return (
        <div className='register-units-filter' >

            <Button 
                onClick={()=>dispatch(controlRegisterUnitsIsOpen(true))}
                type='primary' >Adicionar
            </Button>
            
            <div className='container-register-units-search' >

                <Search
                    value={search}
                    onChange={({ target })=> setSearch(target.value)}
                    placeholder='Pesquisar'
                    onSearch={()=> {
                        dispatch(controlRegisterUnitsPage(1));
                        dispatch(controlRegisterUnitsSearch(search));
                    } } />

                <Button
                    onClick={()=> {
                        dispatch(controlRegisterUnitsPage(1));
                        dispatch(controlRegisterUnitsSearch(search));
                    }} 
                    type='primary' > Pesquisar
                </Button>

            </div>
            
        </div>
    )
};

export default memo(RegisterUnitsFilter);