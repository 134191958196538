import React, { useState } from 'react';
import { Button, Icon } from 'antd';
import Search from 'antd/lib/input/Search';
import { useDispatch } from 'react-redux';
import { controlSearch, asyncGetCoaching } from '../../../reducers/panelcontrol.coaching.reducer';

import './PanelCoachingFilter.scss'

const PanelCoachingFilter = () => {
    const [search, setSearch] = useState('')
    const dispatch = useDispatch()

    const dispatchActions = () => {
        dispatch(controlSearch(search));
        dispatch(asyncGetCoaching());
    };

    return (
        <div className="container-search-panelcoachingfilter" >
            <Search onChange={(event)=>setSearch(event.target.value)} onSearch={()=>dispatchActions()} value={search} className="search-panelcoaching" placeholder="Pesquisar" />
            <Button onClick={()=>dispatchActions()} type="primary" ><Icon type="search" />Pesquisar</Button>
        </div>
    )
}
export default PanelCoachingFilter;