import {CheckboxValueType} from 'antd/lib/checkbox/Group';
import {PasswordMode} from './coache.model';

export interface IAdmin {
    address: IAddress,
    admin?: {permissions: string[]},
    created_at?: string,
    email: string,
    email_verified?: boolean,
    enable: boolean,
    family_name: string,
    given_name: string,
    id: string,
    locale: string,
    nickname: string,
    phone_number_verified?: boolean,
    phone_number: string,
    phones: string[],
    status?: string,
    updated_at?: string,
    password?: string,
    password_mode?: PasswordMode,
    cpf: string,
    permissions?: string[],
    isNew?: boolean,
};

export enum SearchFields {
    ID = 'id',
    EMAIL = 'email',
    NICKNAME = 'nickname',
    GIVEN_NAME = 'given_name',
    FAMILY_NAME = 'family_name',
};

export enum OrderMode {
    ASC = 'asc',
    DESC = 'desc',
};

export interface IAddress {
    street_address: string,
    locality: string,
    region: string,
    postal_code: string,
    country: string
};

export interface IPermissionType {
    category: string,
    permission: string,
    description: string,
    name: string,
};

export type FormTypeValue = string | string[] | CheckboxValueType[];

export interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string,
    search_field: SearchFields[],
    order: SearchFields,
    order_mode: OrderMode,
}

function sanitizedSearchField(search_field: SearchFields[], search: string): SearchFields[] | undefined {
    if (search) {
        if (search_field.length > 0) {
            return search_field;
        }
        return Object.values(SearchFields);
    }

    return undefined;
}

export function queryStringList({page, pageSize, search, search_field, order, order_mode}: IQueryStringList) {
    return {
        page: page ? page - 1 : page,
        page_size: pageSize ? pageSize : undefined,
        search: search ? encodeURIComponent(search) : undefined,
        search_field: sanitizedSearchField(search_field, search),
        order,
        order_mode,
    };
};
