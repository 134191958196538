import { ICoache, IUnit, SearchFields, CoachingType, IClass, IPaymentXlsxRequest } from "../models/coache.model";

//Types
export enum CoachesActionTypes {
    ASYNC_GET_COACHES = '@coaches/ASYNC_GET_COACHES',
    ASYNC_GET_COACHES_CSV = '@coaches/ASYNC_GET_COACHES_CSV',
    ASYNC_GET_COACHE = '@coaches/ASYNC_GET_COACHE',
    CHANGE_COACHES = '@coaches/CHANGE_COACHES',
    CHANGE_UNITS = '@coaches/CHANGE_UNITS',
    CHANGE_LOADING = '@coaches/CHANGE_LOADING',
    CHANGE_TOTAL = '@coaches/CHANGE_TOTAL',
    ASYNC_SAVE_COACH = '@coaches/ASYNC_SAVE_COACH',
    CHANGE_PAGE = '@coaches/CHANGE_PAGE',
    CHANGE_PAGE_SIZE = '@coaches/CHANGE_PAGE_SIZE',
    CHANGE_COACH_EDIT = '@coaches/CHANGE_COACH_EDIT',
    CHANGE_LOADING_SAVE = '@coaches/CHANGE_LOADING_SAVE',
    CHANGE_SEARCH_VALUE = '@coaches/CHANGE_SEARCH_VALUE',
    CHANGE_SEARCH_FIELD = '@coaches/CHANGE_SEARCH_FIELD',
    CHANGE_FILTER_SPECS = '@coaches/CHANGE_FILTER_SPECS',
    CHANGE_FILTER_STATUSES = '@coaches/CHANGE_FILTER_STATUSES',
    CHANGE_ORDER = '@coaches/CHANGE_ORDER',
    CHANGE_ORDER_MODE = '@coaches/CHANGE_ORDER_MODE',
    CHANGE_FILTER_UNIT = '@coaches/CHANGE_UNIT',
    ASYNC_GET_CLASS = '@coaches/ASYNC_GET_CLASS',
    CHANGE_FILTER_CLASS = '@coaches/CHANGE_FILTER_CLASS',
    GET_CLASS = '@coaches/GET_CLASS',
    ASYNC_GET_COACHES_PAYMENTS_XLSX = '@coaches/ASYNC_GET_COACHES_PAYMENTS_XLSX'
};

//Interfaces
export interface ICoachesStore {
    coaches: ICoache[],
    isLoading: boolean,
    isLoadingSave: boolean,
    total: number,
    page: number,
    pageSize: number,
    coachEdit: Partial<ICoache> | null
    search: string,
    search_field: SearchFields[],
    order: string,
    order_mode: string,
    units: IUnit[],
    filter_unit: string,
    filter_specs: (CoachingType | string)[],
    filter_statuses: string[],
    filter_class: string[],
    allClass: IClass[],
};

export interface CoachesAction {
    type: CoachesActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: ICoachesStore = {
    coaches: [],
    isLoading: false,
    isLoadingSave: false,
    total: 0,
    page: 1,
    pageSize: 10,
    coachEdit: null,
    search: '',
    search_field: [],
    order: 'email',
    order_mode: 'asc',
    units: [],
    filter_unit: '',
    filter_specs: [],
    filter_statuses: [],
    filter_class: [],
    allClass: [],
};

export const reducer = (state: ICoachesStore = INITIAL_STATE, action: CoachesAction): ICoachesStore => {
    switch (action.type) {
        case CoachesActionTypes.CHANGE_COACHES:
            return { ...state, coaches: action.payload };

        case CoachesActionTypes.CHANGE_UNITS:
            return { ...state, units: action.payload };

        case CoachesActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case CoachesActionTypes.CHANGE_TOTAL:
            return { ...state, total: action.payload };

        case CoachesActionTypes.CHANGE_COACH_EDIT:
            return { ...state, coachEdit: action.payload };

        case CoachesActionTypes.CHANGE_LOADING_SAVE:
            return { ...state, isLoadingSave: action.payload };

        case CoachesActionTypes.CHANGE_PAGE:
            return { ...state, page: action.payload };

        case CoachesActionTypes.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload, page: 1 };

        case CoachesActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };

        case CoachesActionTypes.CHANGE_SEARCH_FIELD:
            return { ...state, search_field: action.payload };

        case CoachesActionTypes.CHANGE_FILTER_SPECS:
            return { ...state, filter_specs: action.payload };

        case CoachesActionTypes.CHANGE_FILTER_STATUSES:
            return { ...state, filter_statuses: action.payload };

        case CoachesActionTypes.CHANGE_ORDER:
            return { ...state, order: action.payload.order, order_mode: action.payload.order_mode };

        /* case CoachesActionTypes.CHANGE_ORDER_MODE:
            return { ...state, order_mode: action.payload }; */
        
        case CoachesActionTypes.CHANGE_FILTER_UNIT:
            return { ...state, filter_unit: action.payload };

        case CoachesActionTypes.GET_CLASS:
            return {...state, allClass: action.payload}

        case CoachesActionTypes.CHANGE_FILTER_CLASS:
            return { ...state, filter_class: action.payload };

        default:
            return state;
    };
};

//Actions
export const getCoaches = () => ({
    type: CoachesActionTypes.ASYNC_GET_COACHES,
});

export const getCoachesCsv = (callback: (a: string, b: boolean) => void) => ({
    type: CoachesActionTypes.ASYNC_GET_COACHES_CSV,
    payload: {callback}
});


export const getCoache = (value: string) => ({
    type: CoachesActionTypes.ASYNC_GET_COACHE,
    payload: value
});

export const saveCoachApi = (coach: ICoache) => ({
    type: CoachesActionTypes.ASYNC_SAVE_COACH,
    payload: coach
});

export const changeLoading = (value: boolean) => ({
    type: CoachesActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeCoaches = (coaches: ICoache[]) => ({
    type: CoachesActionTypes.CHANGE_COACHES,
    payload: coaches
});

export const changeUnits = (units: IUnit[]) => ({
    type: CoachesActionTypes.CHANGE_UNITS,
    payload: units
});

export const changeTotal = (total: number) => ({
    type: CoachesActionTypes.CHANGE_TOTAL,
    payload: total
});

export const changePage = (page: number) => ({
    type: CoachesActionTypes.CHANGE_PAGE,
    payload: page
});

export const changePageSize = (pageSize: number) => ({
    type: CoachesActionTypes.CHANGE_PAGE_SIZE,
    payload: pageSize
});

export const changeCoachEdit = (coach: Partial<ICoache> | null) => ({
    type: CoachesActionTypes.CHANGE_COACH_EDIT,
    payload: coach
});

export const changeLoadingSave = (value: boolean) => ({
    type: CoachesActionTypes.CHANGE_LOADING_SAVE,
    payload: value
});

export const changeSearchValue = (value: string) => ({
    type: CoachesActionTypes.CHANGE_SEARCH_VALUE,
    payload: value
});

export const changeSearchField = (fields: SearchFields[]) => ({
    type: CoachesActionTypes.CHANGE_SEARCH_FIELD,
    payload: fields
});

export const changeFilterSpecs = (filter_specs: (CoachingType | string)[]) => ({
    type: CoachesActionTypes.CHANGE_FILTER_SPECS,
    payload: filter_specs
});

export const changeFilterStatuses = (filter_statuses: string[]) => ({
    type: CoachesActionTypes.CHANGE_FILTER_STATUSES,
    payload: filter_statuses
});

export const changeOrder = (order: string, order_mode: string) => ({
    type: CoachesActionTypes.CHANGE_ORDER,
    payload: { order, order_mode }
});

export const changeFilterClass = (filter_class: string[]) => ({
    type: CoachesActionTypes.CHANGE_FILTER_CLASS,
    payload: filter_class
});

export const changeFilterUnit = (filter_unit: string) => ({
    type: CoachesActionTypes.CHANGE_FILTER_UNIT,
    payload: filter_unit,
});

export const getClass = () => ({
    type: CoachesActionTypes.ASYNC_GET_CLASS,
});

export const getAllClass = (allClass: any) => ({
    type: CoachesActionTypes.GET_CLASS,
    payload: allClass
});

export const asyncRequestXslxCoachesPayments = ({from, to, search, types, callback}: IPaymentXlsxRequest) => ({
    type: CoachesActionTypes.ASYNC_GET_COACHES_PAYMENTS_XLSX,
    payload: {from, to, search, types, callback},
});


export default reducer;
