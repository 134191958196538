export interface IBlockList {
    status: 'OK' | 'FAILED',
    email: string,
    error: string,
    createdAt: string,
}

interface IQueryStringList {
    pageSize: number,
    nextPage: string,
    search: string,
}

export const queryStringList = ({ pageSize, nextPage, search }: IQueryStringList) => {
    const params = [];

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }

    if (nextPage) {
        params.push(`nextPage=${nextPage}`);
    }

    if (search) {
        params.push(`search=${search}`);
    }

    return `?${params.join('&')}`;
};
