import axios from "axios";
import { all, call, put, select, takeEvery } from "redux-saga/effects";
import Notification, { NOTIFICATION_TYPE } from "../components/notification/Notification";
import { queryStringList } from "../models/video.model";
import { IState } from "../reducers/reducers";
import { changeLoading, VideosActionTypes, changeVideo, changeLoadingSave, setPageList, changeVideoModal } from "../reducers/videos.reducer";
import handleError from "../utils/handleError";
import { setISOToDayStart } from "../utils/moment.functions";
import { convertAttachments } from "../utils/utils.functions";
import { getUpload, getVideos, newVideo, saveVideo } from "../utils/webApi";

function* requestVideos() {
    try {
        yield put(changeLoading(true));
        const { pageSize, search, filter_videos, pageList, page, scope } = yield select((state: IState) => state.videos);
        const params = {pageSize, search, filter_videos, nextPage: pageList[page], scope};

        const { nextPage, results } = yield call(getVideos, queryStringList(params));

        const newPageList = [...pageList];

        if (nextPage) {
            newPageList.push(nextPage);
            yield put(setPageList(newPageList));
        } else if (page === 0 && !nextPage) {
            yield put(setPageList([]));
        } else {
            newPageList.push(null);
            yield put(setPageList(newPageList));
        }
        yield put(changeVideo(results));

    } catch (err) {
        handleError(err);
    } finally {
        yield put(changeLoading(false));
    }
}

function* saveVideoApi({payload}: any) {
    try {
        yield put(changeLoadingSave(true));
        const videoData = {
            video: {
                active: payload.active || false,
                daysRequiredToWatch: payload.daysRequiredToWatch || 0,
                dateExpiration: payload.dateExpiration,
                dateRelease: payload.dateRelease ? setISOToDayStart(payload.dateRelease) : undefined,
                index: payload.index || 1,
                scopes: payload.scopes,
                categoryId: payload.categoryId || undefined,
            },
            properties_pt: {
                title: payload.properties_pt.title,
                description: payload.properties_pt.description,
                thumbnailUrl: payload.properties_pt.thumbnailUrl,
                attachments: convertAttachments(payload.properties_pt.attachments),
            },
            properties_es: payload.properties_es ?{
                title: payload.properties_es.title,
                description: payload.properties_es.description,
                thumbnailUrl: payload.properties_es.thumbnailUrl,
                attachments: convertAttachments(payload.properties_es.attachments),
            } : undefined,
            properties_en: payload.properties_en ?{
                title: payload.properties_en.title,
                description: payload.properties_en.description,
                thumbnailUrl: payload.properties_en.thumbnailUrl,
                attachments: convertAttachments(payload.properties_en.attachments)
            } : undefined,
        };

        yield call(saveVideo, videoData, payload.id);
        yield requestVideos();
        yield put(changeVideoModal(null));
    } catch (err) {
        handleError(err);
    } finally {
        yield put(changeLoadingSave(false));

    }
};

function* saveNewVideo({payload}: any) {
    const {uploadedVideo} = payload;
    try {
        yield put(changeLoadingSave(true));

        const data = yield getUpload({entryName: uploadedVideo.name});
        yield put(changeLoadingSave(false));
        yield put(changeVideoModal(null));

        //Lógica do upload de vídeo
        yield uploadVideo({uploadedVideo, data});
        const videoData = {
            video: {
                active: payload.active || false,
                daysRequiredToWatch: payload.daysRequiredToWatch || 0,
                duration: payload.duration && payload.duration !== Infinity ? payload.duration : 0,
                categoryId: payload.categoryId,
                dateExpiration: payload.dateExpiration,
                dateRelease: payload.dateRelease ? setISOToDayStart(payload.dateRelease) : undefined,
                index: payload.index || 1,
                scopes: payload.scopes,
            },
            name: uploadedVideo.name,
            entryId: data.entry_id,

            properties_pt: {
                title: payload.properties_pt.title,
                description: payload.properties_pt.description,
                thumbnailUrl: payload.properties_pt.thumbnailUrl,
                attachments: convertAttachments(payload.properties_pt.attachments),
            },
            properties_es: payload.properties_es ? {
                title: payload.properties_es.title,
                description: payload.properties_es.description,
                thumbnailUrl: payload.properties_es.thumbnailUrl,
                attachments: convertAttachments(payload.properties_es.attachments),
            } : undefined,
            properties_en: payload.properties_en ? {
                title: payload.properties_en.title,
                description: payload.properties_en.description,
                thumbnailUrl: payload.properties_en.thumbnailUrl,
                attachments: convertAttachments(payload.properties_en.attachments),
            } : undefined,
        };

        yield call(newVideo, videoData);
        Notification(NOTIFICATION_TYPE.SUCCESS, 'Atenção', `Upload do video ${uploadedVideo.name} concluído!`);
        yield requestVideos();
    } catch (err) {
        handleError(err);
        Notification(NOTIFICATION_TYPE.ERROR, 'Atenção', `Upload do video ${uploadedVideo.name} falhou!`);
    }
};

function* uploadVideo(payload: any) {
    const {data, uploadedVideo} = payload;
    Notification(NOTIFICATION_TYPE.INFO, 'Atenção', 'O processo de upload do vídeo foi iniciado. Você será notificado quando for concluído');

    try {
        let chunkPosition = 1;
        const blockSize = 10240000;
        const chunks = Math.ceil(uploadedVideo.size / blockSize);
        let slice;
        const param = {"Content-Type": "multipart/form-data", params: {"format": 1}};

        const uploadUrl = data.url;
        if (uploadUrl.includes("format=")) {
            delete param.params.format;
        }

        while (chunkPosition <= chunks) {
            const startBlock = (chunkPosition - 1) * blockSize;
            slice = uploadedVideo.slice(startBlock, chunkPosition * blockSize, uploadedVideo.type);
            const formData = getFormData({
                "fileData": [slice, uploadedVideo.name],
                "ks": [data.ks],
                "uploadTokenId": [data.upload_token_id],
                "resume": [chunkPosition !== 1],
                "resumeAt": [chunkPosition === 1 ? "0" : "-1"],
                "finalChunk": [chunkPosition === chunks],
            });

            const response = yield axios.post(uploadUrl, formData, param);

            if (!response.data || response.data.objectType === "KalturaAPIException" || response.data.objectType !== "KalturaUploadToken") {
                Notification(NOTIFICATION_TYPE.ERROR, 'Atenção', `Erro ao fazer upload do vídeo: ${JSON.stringify(response.data)}`);
            }

            chunkPosition += 1;
        }
    } catch (err) {
        handleError(err);
    }
};

function* enableDisableVideo({payload}: any) {

    const videoData = {
        video: {
            active: payload.active,
        },
    };

    try {
        yield put(changeLoading(true));
        yield call(saveVideo, videoData, payload.video.id);
        yield requestVideos();
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    }
};

export default function* MySaga(): any {
    yield all([
        yield takeEvery(VideosActionTypes.ASYNC_GET_VIDEOS, requestVideos),
        yield takeEvery(VideosActionTypes.ASYNC_SAVE_VIDEO, saveVideoApi),
        yield takeEvery(VideosActionTypes.ASYNC_NEW_VIDEO, saveNewVideo),
        yield takeEvery(VideosActionTypes.ASYNC_ENABLE_DISABLE_VIDEO, enableDisableVideo),
    ])
}

function getFormData(obj: any, form = new FormData()) {
    Object.keys(obj).forEach(key => form.append(key, ...obj[key] as [string, string]));
    return form;
};
