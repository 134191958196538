import { Button, Modal } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import TitleSection from "../../components/title-section/TitleSection";
import { IVideo } from "../../models/video.model";
import { IState } from "../../reducers/reducers";
import { changeVideoModal, getVideos, saveNewVideo, saveVideoApi } from "../../reducers/videos.reducer";
import VideoEdit from "./video-edit/VideoEdit";
import VideosList from "./videos-list/VideosList";

const Videos = (): any => {

    const dispatch = useDispatch();

    const { videoModal, isLoadingSave, isLoading, videos } = useSelector((state: IState) => state.videos);

    function onSaveVideo(video: IVideo, isEdit: boolean) {

        if (isEdit) {
            dispatch(saveVideoApi(video));
        }
        else {
            dispatch(saveNewVideo(video));
        }
    };

    function onCancel(){
        dispatch(changeVideoModal(null));
    };

    return (
        <section>
            <TitleSection title={"Vídeos"}>
                <Button
                    onClick={() => dispatch(changeVideoModal({}))}
                    size="large"
                    type="primary"
                >
                    Adicionar Video
                </Button>
            </TitleSection>
            <VideosList
                onEdit={(video: IVideo) => dispatch(changeVideoModal(video))}
                getVideos={() => dispatch(getVideos())}
                videos={videos}
                loading={isLoading}
            />
            {Boolean(videoModal) && (
                <Modal
                    width={700}
                    title={Boolean(videoModal && videoModal.id) ? "Editar Vídeo" : "Adicionar Vídeo" }
                    visible={true}
                    footer={false}
                    maskClosable={false}
                    onCancel={() => onCancel()}
                >
                    <VideoEdit
                        isEdit={Boolean(videoModal && videoModal.id)}
                        onCancel={() => onCancel()}
                        onOk={onSaveVideo}
                        video={videoModal as IVideo}
                        loading={isLoadingSave}
                    />
                </Modal>
                )
            }
        </section>
    );
};

export default Videos;
