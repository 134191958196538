export interface ICoachByStatus {
    key: string;
    count: number;
}

export interface ICoachByLicenseType {
    key: string;
    count: number;
}

export interface IClass {
    centerId: string,
    classId: string,
    className: string,
    courseId: string,
    description: string
    endDate: string,
    id: string,
    picture: string,
    startDate: string,
    trainerId: string,
    type: string,
};

export interface IPanelControlClassesStore {
    coachesTotal: number;
    coachesAccessSCIS: number;
    coachesByStatus: ICoachByStatus[];
    coachesByLicenseType: ICoachByLicenseType[];
    allClasses: IClass[];
    allUnits: IUnit[];
    filterUnit: string | undefined;
    filterClass: string[];
    isLoading: boolean;
}

export interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string
}

export enum OrderMode {
    ASC = 'asc',
    DESC = 'desc',
};

export interface IUnit {
    id: string,
    name: string,
};

interface IQueryStringListFilter {
    filterUnit: IUnit | null | boolean,
    filterClass: string[],
}

interface IQueryStringListFilterClass {
    filterClass: string[],
}

export interface IRequestClasses {
    totalCoaches: number,
    totalAccess: number,
    coachesByLicenseType: [],
    coachesByStatus: [],
}

export interface IClassUnit {
    id: string,
    name: string,
};

export interface IClass {
    centerId: string,
    classId: string,
    className: string,
    courseId: string,
    description: string
    endDate: string,
    id: string,
    picture: string,
    startDate: string,
    trainerId: string,
    type: string,
};

export const queryStringGetUnitAndClass = ({ filterUnit, filterClass }: IQueryStringListFilter) => {
    let params = [];

    if (filterUnit) {
        params.push(`unit=${filterUnit}`);
    }

    if (filterClass) {
        params.push(`enrollment=${filterClass}`);
    }

    return `?${params.join('&')}`;
};

export const queryStringGetClass = ({ filterClass }: IQueryStringListFilterClass) => {
    let params = [];

    if (filterClass) {
        params.push(`enrollment=${filterClass}`);
    }

    return `?${params.join('&')}`;
};
