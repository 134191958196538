import React, { useState } from 'react';
import { Form, Button } from 'antd';
import Search from 'antd/lib/input/Search';
import { useDispatch } from 'react-redux';
import { getContactUsMessage, controlContactUsSearch } from '../../../reducers/contact.reducer';
import './ContactFilter.scss';

const ContactFilter = () => {
    const [ search, setSearch ] = useState("")
    const dispatch = useDispatch()

    const dispatchActions = () => {
        dispatch(controlContactUsSearch(search))
        dispatch(getContactUsMessage())
    }

    return (
        <Form className="filter-content-contact-us" >
            <div className="input-filter">
                <div className="container-search-button" >
                    <Search
                        value={search}
                        className="search-input"
                        placeholder="Pesquisar"
                        onSearch={()=>dispatchActions()}
                        onChange={({ target }) => setSearch(target.value)} />
                    <Button 
                        onClick={()=>dispatchActions()} 
                        className="btn-search" 
                        type="primary"
                        icon="search"> 
                        Pesquisar
                    </Button>
                </div>
            </div>
        </Form>
    )
}

export default ContactFilter;