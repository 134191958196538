const Storage = {
    setItem: function (key: string, value: any): void {
        localStorage.setItem(key, value);
    },
    removeItem: function (key: string): void {
        localStorage.removeItem(key);
    },

    getItem: (key: string): any => localStorage.getItem(key),

    clear: function (): void {
        localStorage.clear();
    }
};

export default Storage;
