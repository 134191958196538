import Notification, { NOTIFICATION_TYPE } from "../components/notification/Notification"
import Store from "../config/Store.config";
import { doLogout } from "../reducers/auth.reducer";

function handleError(error: any) {
    const { status, data } = error.response || {};

    let message_error = error.message;

    if (data) {
        message_error = data.error_description;

        switch (message_error) {
            case 'Campo activation_date está ausente':
                message_error = 'Data de Ativação na aba Coaching deve ser fornecida';
                break;
            case 'Campo expiration_date está ausente':
                message_error = 'Data de Expiração na aba Coaching deve ser fornecida';
                break;
            case '"value" must contain at least one of [email, phone_number]':
                message_error = 'No mínimo um email ou um telefone deve ser fornecido';
                break;
            case 'Name already exists':
                message_error = 'Nome de núcleo já existente';
                break;
        }
    }

    if (status === 401) {
        Store.dispatch(doLogout());
        return;
    };

    Notification(NOTIFICATION_TYPE.ERROR, 'error', message_error);
};

export default handleError;