import { IUrlShortenersStore, IUrlShorteners, ECurrentModalUrlShorteners, IUrlsResults } from '../models/url-shorteners.model';

export enum ETypesUrlShorteners {
    URL_SHORTENERS_ASYNC_GET_URLS = '@url-shorteners/URL_SHORTENERS_ASYNC_GET_URLS',
    URL_SHORTENERS_ISLOADING = '@url-shorteners/URL_SHORTENERS_ISLOADING',
    URL_SHORTENERS_PAGE_LIST = '@url-shorteners/URL_SHORTENERS_PAGE_LIST',
    URL_SHORTENERS_URLS = '@url-shorteners/URL_SHORTENERS_URLS',
    URL_SHORTENERS_SEARCH = '@url-shorteners/URL_SHORTENERS_SEARCH',
    URL_SHORTENERS_PAGE = '@url-shorteners/URL_SHORTENERS_PAGE',
    URL_SHORTENERS_ISOPEN = '@url-shorteners/URL_SHORTENERS_ISOPEN',
    URL_SHORTENERS_ASYNC_ADD_EDIT_URL = '@url-shorteners/URL_SHORTENERS_ASYNC_ADD_EDIT_URL',
    URL_SHORTENERS_ASYNC_EDIT_URL = '@url-shorteners/URL_SHORTENERS_ASYNC_EDIT_URL',
    URL_SHORTENERS_ASYNC_DELETE_URL = '@url-shorteners/URL_SHORTENERS_ASYNC_DELETE_URL',
    URL_SHORTENERS_LOADING_MODAL = '@url-shorteners/URL_SHORTENERS_LOADING_MODAL',
    URL_SHORTENERS_ASYNC_READ_CODE = '@url-shorteners/URL_SHORTENERS_ASYNC_READ_CODE',
    URL_SHORTENERS_CURRENT_MODAL = '@url-shorteners/URL_SHORTENERS_CURRENT_MODAL',
    URL_SHORTENERS_URLS_RESULTS = '@url-shorteners/URL_SHORTENERS_URLS_RESULTS',
    URL_SHORTENERS_UR_SHORTENERS_CLEAN_STORE = '@url-shorteners/URL_SHORTENERS_UR_SHORTENERS_CLEAN_STORE'
}

const INITIAL_STATE: IUrlShortenersStore = {
    isLoading: false,
    search: '',
    pagesList: [null],
    page: 0,
    urls: [],
    isOpen: false,
    loadingModal: { isLoading: false, total: 0, finished: 0 },
    currentModal: ECurrentModalUrlShorteners.ModalUrlShortenersCreate,
    urlsResults: {} as IUrlsResults
};

interface IAction {
    type: string,
    payload: any
};

const reducer = ( state: IUrlShortenersStore = INITIAL_STATE, action: IAction ) => {
    const { type } = action;
    switch(type){
        case ETypesUrlShorteners.URL_SHORTENERS_URLS:
            return { ...state, urls: action.payload }
        case ETypesUrlShorteners.URL_SHORTENERS_PAGE:
                return { ...state, page: action.payload }
        case ETypesUrlShorteners.URL_SHORTENERS_SEARCH:
            return { ...state, search: action.payload }
        case ETypesUrlShorteners.URL_SHORTENERS_PAGE_LIST:
            return { ...state, pagesList: action.payload }
        case ETypesUrlShorteners.URL_SHORTENERS_ISLOADING:
            return { ...state, isLoading: action.payload }
        case ETypesUrlShorteners.URL_SHORTENERS_ISOPEN:
            return { ...state, isOpen: action.payload }
        case ETypesUrlShorteners.URL_SHORTENERS_URLS_RESULTS:
            return { ...state, urlsResults: action.payload }
        case ETypesUrlShorteners.URL_SHORTENERS_CURRENT_MODAL:
            return { ...state, currentModal: action.payload }
            case ETypesUrlShorteners.URL_SHORTENERS_LOADING_MODAL:
                return { ...state, loadingModal: { ...state.loadingModal, ...action.payload} }
        case ETypesUrlShorteners.URL_SHORTENERS_UR_SHORTENERS_CLEAN_STORE:
            return INITIAL_STATE
        default:
            return state
    }
};

export default reducer;

export const controlIsLoading = (value: boolean) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_ISLOADING,
    payload: value
});

export const controlIsOpen = (value: boolean) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_ISOPEN,
    payload: value
});

export const controlCurrentModal = (value: ECurrentModalUrlShorteners) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_CURRENT_MODAL,
    payload: value
});

export const controlUrlsResults = (value: IUrlsResults) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_URLS_RESULTS,
    payload: value
});

export const controlLoadingModal= (value: any) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_LOADING_MODAL,
    payload: value
});

export const controlPage = (value: number) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_PAGE,
    payload: value
});


export const controlUrls = (value: IUrlShorteners[]) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_URLS,
    payload: value
});

export const controlSearch = (value: string) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_SEARCH,
    payload: value
});

export const controlPageList = (value: string[] | any[]) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_PAGE_LIST,
    payload: value
});

export const asyncGetUrls = () => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_ASYNC_GET_URLS
});

export const asyncAddOrEditUrl = ( value: any ) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_ASYNC_ADD_EDIT_URL,
    payload: value
});

export const asyncEditUrl = ( value: string ) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_ASYNC_EDIT_URL,
    payload: value
});

export const asyncDeleteUrl = ( value: any ) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_ASYNC_DELETE_URL,
    payload: value
});

export const asyncReadCode = ( value: any ) => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_ASYNC_READ_CODE,
    payload: value
});

export const controlCleanStore = () => ({
    type: ETypesUrlShorteners.URL_SHORTENERS_UR_SHORTENERS_CLEAN_STORE
});