import React from 'react';
import { Descriptions } from 'antd';
import { ICoache } from '../../../../models/profile.modal';
import { formatDate } from '../../../../utils/moment.functions';
import { noExists } from '../../../../utils/utils.functions';

interface props {
    currentProfile: ICoache
};

const ProfileModalAccess = ({
    currentProfile
}: props) => {
    return (
        <Descriptions title='Informações de Acesso' className='labs-profile-user' layout='vertical' bordered >
            <Descriptions.Item label='Último acesso ao SCIS'>{ 
                currentProfile.scis20_last_access ? formatDate(currentProfile.scis20_last_access, 'DD/MM/YYYY HH:mm') : noExists }
            </Descriptions.Item>

            <Descriptions.Item label='Último acesso ao site'>{ 
                currentProfile.last_session ? formatDate(currentProfile.last_session, 'DD/MM/YYYY HH:mm') : noExists }
            </Descriptions.Item>

        </Descriptions>
    )
};

export default ProfileModalAccess;