import { ICenter, ILicenseTypes, ICountry, ITimezone, ILocale } from "../models/utils.model";
import { Permissions } from "../models/utils.model";

//ResourceTypes
export enum UtilsActionTypes {
    CHANGE_CENTERS = '@utils/CHANGE_CENTERS',
    CHANGE_PERMISSIONS = '@utils/CHANGE_PERMISSIONS',
    CHANGE_LICENSE_TYPES = '@utils/CHANGE_LICENSE_TYPES',
    CHANGE_COUNTRIES = '@utils/CHANGE_COUNTRIES',
    CHANGE_TIMEZONES = '@utils/CHANGE_TIMEZONES',
    CHANGE_LOCALES = '@utils/CHANGE_LOCALES',
    ASYNC_GET_SERIALIZE_UTILS = '@utils/ASYNC_GET_SERIALIZE_UTILS',
    SET_IS_PERMISSIONS_LOADING = '@utils/SET_IS_PERMISSIONS_LOADING',
};

// interface
export interface IUtilsStore {
    centers: ICenter[],
    permissions: string[],
    license_types: ILicenseTypes[],
    countries: ICountry[],
    timezones: ITimezone[],
    locales: ILocale[],
    isPermissionsLoading: boolean,
};

export interface IAuthAction {
    type: UtilsActionTypes,
    payload: any
};

const INITIAL_STATE: IUtilsStore = {
    centers: [],
    permissions: [],
    license_types: [],
    countries: [],
    timezones: [],
    locales: [],
    isPermissionsLoading: true,
};


export const reducer = (state: IUtilsStore = INITIAL_STATE, action: IAuthAction): IUtilsStore => {
    switch (action.type) {

        case UtilsActionTypes.CHANGE_CENTERS:
            return { ...state, centers: action.payload };

        case UtilsActionTypes.CHANGE_PERMISSIONS:
            return { ...state, permissions: action.payload };

        case UtilsActionTypes.CHANGE_LICENSE_TYPES:
            return { ...state, license_types: action.payload };

        case UtilsActionTypes.CHANGE_COUNTRIES:
            return { ...state, countries: action.payload };

        case UtilsActionTypes.CHANGE_TIMEZONES:
            return { ...state, timezones: action.payload };

        case UtilsActionTypes.CHANGE_LOCALES:
            return { ...state, locales: action.payload };

        case UtilsActionTypes.SET_IS_PERMISSIONS_LOADING:
            return {...state, isPermissionsLoading: action.payload};
        default:
            return state;
    };
};

//Actions
export const changeCenters = (centers: ICenter[]) => ({
    type: UtilsActionTypes.CHANGE_CENTERS,
    payload: centers
});

export const changePermissions = (permissions: Permissions[]) => ({
    type: UtilsActionTypes.CHANGE_PERMISSIONS,
    payload: permissions
});

export const getSerializeUtils = () => ({
    type: UtilsActionTypes.ASYNC_GET_SERIALIZE_UTILS
});

export const changeLicenseTypes = (license_types: ILicenseTypes[]) => ({
    type: UtilsActionTypes.CHANGE_LICENSE_TYPES,
    payload: license_types
});

export const changeCountries = (countries: ICountry[]) => ({
    type: UtilsActionTypes.CHANGE_COUNTRIES,
    payload: countries
});

export const changeTimezones = (timeZones: ITimezone[]) => ({
    type: UtilsActionTypes.CHANGE_TIMEZONES,
    payload: timeZones
});

export const changeLocales = (locales: ILocale[]) => ({
    type: UtilsActionTypes.CHANGE_LOCALES,
    payload: locales
});

export const setIsPermissionsLoading = (payload: boolean) => ({
    type: UtilsActionTypes.SET_IS_PERMISSIONS_LOADING,
    payload,
});

export default reducer;