import {IAdmin, SearchFields, IPermissionType} from '../models/administrator.model';

export enum AdminActionTypes {
    ASYNC_REQUEST_ADMIN_LIST = '@administrators/ASYNC_REQUEST_ADMIN_LIST',
    ASYNC_GET_ADMIN_TO_EDIT = '@administrators/ASYNC_GET_ADMIN_TO_EDIT',
    SET_ADMIN_LIST = '@administrators/SET_ADMIN_LIST',
    SET_IS_LOADING = '@administrators/SET_IS_LOADING',
    SET_TOTAL_ADMINS = '@administrators/SET_TOTAL_ADMINS',
    ASYNC_SAVE_ADMIN = '@administrators/ASYNC_SAVE_ADMIN',
    CHANGE_PAGE = '@administrators/CHANGE_PAGE',
    CHANGE_PAGE_SIZE = '@administrators/CHANGE_PAGE_SIZE',
    SET_ADMIN_TO_EDIT = '@administrators/SET_ADMIN_TO_EDIT',
    CHANGE_LOADING_SAVE = '@administrators/CHANGE_LOADING_SAVE',
    CHANGE_SEARCH_VALUE = '@administrators/CHANGE_SEARCH_VALUE',
    CHANGE_SEARCH_FIELD = '@administrators/CHANGE_SEARCH_FIELD',
    CHANGE_ORDER = '@administrators/CHANGE_ORDER',
    SET_ALL_PERMISSIONS = '@administrators/SET_ALL_PERMISSIONS',
    ASYNC_REQUEST_ALL_PERMISSIONS = '@administrators/ASYNC_REQUEST_ALL_PERMISSIONS',
    ASYNC_REMOVE_ADMIN = '@administrators/ASYNC_REMOVE_ADMIN',
};

export interface IAdminStore {
    adminList: IAdmin[],
    isLoading: boolean,
    isLoadingSave: boolean,
    total: number,
    page: number,
    pageSize: number,
    adminToEdit: Partial<IAdmin>
    search: string,
    search_field: SearchFields[],
    order: string,
    order_mode: string,
    allPermissions: IPermissionType[];
};

export interface IAdminAction {
    type: AdminActionTypes,
    payload: any
};

const INITIAL_STATE: IAdminStore = {
    adminList: [],
    isLoading: false,
    isLoadingSave: false,
    total: 0,
    page: 1,
    pageSize: 10,
    adminToEdit: {},
    search: '',
    search_field: [],
    order: 'email',
    order_mode: 'asc',
    allPermissions: [],
};

export const reducer = (state: IAdminStore = INITIAL_STATE, {type, payload}: IAdminAction): IAdminStore => {
    switch (type) {
        case AdminActionTypes.SET_ADMIN_LIST:
            return { ...state, adminList: payload };

        case AdminActionTypes.SET_IS_LOADING:
            return { ...state, isLoading: payload };

        case AdminActionTypes.SET_TOTAL_ADMINS:
            return { ...state, total: payload };

        case AdminActionTypes.SET_ADMIN_TO_EDIT:
            return { ...state, adminToEdit: payload };

        case AdminActionTypes.CHANGE_LOADING_SAVE:
            return { ...state, isLoadingSave: payload };

        case AdminActionTypes.CHANGE_PAGE:
            return { ...state, page: payload };

        case AdminActionTypes.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: payload, page: 1 };

        case AdminActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: payload };

        case AdminActionTypes.CHANGE_SEARCH_FIELD:
            return { ...state, search_field: payload };

        case AdminActionTypes.CHANGE_ORDER:
            return { ...state, order: payload.order, order_mode: payload.order_mode };

        case AdminActionTypes.SET_ALL_PERMISSIONS:
            return {...state, allPermissions: payload};

        default:
            return state;
    };
};

export function asyncRequestAdminList() {
    return {
        type: AdminActionTypes.ASYNC_REQUEST_ADMIN_LIST,
    };
}

export function asyncGetAdminToEdit(id: string) {
    return {
        type: AdminActionTypes.ASYNC_GET_ADMIN_TO_EDIT,
        payload: id,
    };
}

export function asyncSaveAdmin(admin: IAdmin) {
    return {
        type: AdminActionTypes.ASYNC_SAVE_ADMIN,
        payload: admin,
    };
}

export function setIsLoading(value: boolean) {
    return {
        type: AdminActionTypes.SET_IS_LOADING,
        payload: value,
    };
}

export function setAdminList(adminList: IAdmin[]) {
    return {
        type: AdminActionTypes.SET_ADMIN_LIST,
        payload: adminList,
    };
}

export function setTotalAdmins(total: number) {
    return {
        type: AdminActionTypes.SET_TOTAL_ADMINS,
        payload: total,
    };
}

export function changePage(page: number) {
    return {
        type: AdminActionTypes.CHANGE_PAGE,
        payload: page,
    };
}

export function changePageSize(pageSize: number) {
    return {
        type: AdminActionTypes.CHANGE_PAGE_SIZE,
        payload: pageSize,
    };
}

export function setAdminToEdit(admin: Partial<IAdmin> | null) {
    return {
        type: AdminActionTypes.SET_ADMIN_TO_EDIT,
        payload: admin,
    };
}

export function changeLoadingSave(value: boolean) {
    return {
        type: AdminActionTypes.CHANGE_LOADING_SAVE,
        payload: value,
    };
}

export function changeSearchValue(value: string) {
    return {
        type: AdminActionTypes.CHANGE_SEARCH_VALUE,
        payload: value,
    };
}

export function changeSearchField(fields: SearchFields[]){
    return {
        type: AdminActionTypes.CHANGE_SEARCH_FIELD,
        payload: fields,
    };
}

export function changeOrder(order: string, order_mode: string) {
    return {
        type: AdminActionTypes.CHANGE_ORDER,
        payload: {order, order_mode},
    };
}

export function setAllPermissions(payload: IPermissionType[]) {
    return {
        type: AdminActionTypes.SET_ALL_PERMISSIONS,
        payload,
    };
}

export function asyncRequestAllPermissions() {
    return {
        type: AdminActionTypes.ASYNC_REQUEST_ALL_PERMISSIONS,
    };
}

export function asyncRemoveAdmin(payload: string) {
    return {
        type: AdminActionTypes.ASYNC_REMOVE_ADMIN,
        payload,
    };
}

export default reducer;
