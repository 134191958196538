import React, { useState } from 'react';
import { Form, Select, Input, Button, Icon, Upload } from 'antd';
import { IImportationForm } from '../../../models/import.model';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { ICenter } from '../../../models/utils.model';
import { sendFormPreview } from '../../../reducers/importation.reducer';
import { linksUtils } from '../../../utils/urls.constants';
import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';

import './ImportFileForm.scss';

const ImportFileForm = () => {

    const [formValue, setFormValue] = useState<Partial<IImportationForm>>({});
    const [currentFile, setCurrentFile] = useState<File | null>(null);
    const centers = useSelector((state: IState) => state.utils.centers);
    const dispatch = useDispatch();

    const [ currentCenter, setCurrentCenter ] = useState<string | undefined>(undefined);

    function readCsv(file: File) {
        var reader = new FileReader();
        reader.readAsText(file);
        reader.onload = ({ target }) => {
            setCurrentFile(file);
            if (target) {
                const { result } = target as FileReader;
                onChangeForm('content', result);
            }
        };
    };

    function onChangeForm(key: string, value: any) {
        setFormValue({ ...formValue, [key]: value });
    };

    return (
        <Form className="import-file-form">
            <Select
                value ={currentCenter}
                onChange={(value: string) =>{
                    setCurrentCenter(value);
                    const currentCenter: any = centers.find((center: ICenter) => center.name === value);
                    if(currentCenter) onChangeForm('unit_id', currentCenter.id);
                }}
                allowClear={true}
                showSearch={true}
                placeholder="Núcleo">
                {centers.map((center: ICenter) => (
                    <Select.Option
                        key={center.id}
                        value={center.name}>{center.name}
                    </Select.Option>
                ))}
            </Select>

            <Input
                onChange={({ target }) => onChangeForm('class_id', target.value)}
                placeholder="Turma"
            />
                <MaskedDatePicker 
                    onChange={onChangeForm} 
                    currentValue={formValue.activation_date || ''} 
                    stringType={"activation_date"} 
                    classText={"form-date"}
                    placeholder={"Data de início"} 
                />

                <MaskedDatePicker 
                    onChange={onChangeForm} 
                    currentValue={formValue.expiration_date || ''} 
                    stringType={"expiration_date"} 
                    classText={"form-termino"}
                    placeholder={"Data de término"} 
                />

            <Upload
                accept={'.csv'}
                className="upload-file"
                showUploadList={false}
                multiple={false}
                beforeUpload={(file: File) => {
                    readCsv(file)
                    return false;
                }}>
                <Button>
                    <Icon type="upload" /> Escolha o arquivo
                </Button>

                {(currentFile && currentFile.name) && (
                    <>
                        <span className="file-name">{currentFile.name}</span>
                        <Icon className="icon-delete-upload" onClick={()=> {
                            setCurrentFile(null);
                            onChangeForm('content', null);
                        }} type="delete" />
                    </>
                )
                }
            </Upload>

            <p className="file-observation">
                Certifique-se que tenha colunas: nome completo, cpf, sexo, telefone, email, país e estado.
                    <br /><a href={linksUtils('spreadsheetImport')} >Clique aqui</a> para baixar planilha modelo.
                </p>

            <Button
                onClick={() => dispatch(sendFormPreview(formValue as IImportationForm))}
                type="primary"
                className="btn-send">
                Enviar
            </Button>
        </Form >
    );
};

export default ImportFileForm;