import {
  IUser,
  SearchFields,
  SortField,
  OrderMode
} from "../models/user.model";

//Types
export enum UsersActionTypes {
    ASYNC_GET_USERS = '@users/ASYNC_GET_USERS',
    ASYNC_GET_USER = '@users/ASYNC_GET_USER',
    CHANGE_USERS = '@users/CHANGE_USERS',
    CHANGE_LOADING = '@users/CHANGE_LOADING',
    CHANGE_TOTAL = '@users/CHANGE_TOTAL',
    ASYNC_SAVE_USER = '@users/ASYNC_SAVE_USER',
    CHANGE_PAGE = '@users/CHANGE_PAGE',
    CHANGE_PAGE_SIZE = '@users/CHANGE_PAGE_SIZE',
    CHANGE_USER_EDIT = '@users/CHANGE_USER_EDIT',
    CHANGE_LOADING_SAVE = '@users/CHANGE_LOADING_SAVE',
    CHANGE_SEARCH_VALUE = '@users/CHANGE_SEARCH_VALUE',
    CHANGE_SEARCH_FIELD = '@users/CHANGE_SEARCH_FIELD',
    CHANGE_ORDER = '@users/CHANGE_ORDER',
};

//Interfaces
export interface IUsersStore {
    users: IUser[],
    isLoading: boolean,
    isLoadingSave: boolean,
    total: number,
    page: number,
    pageSize: number,
    userEdit: Partial<IUser> | null
    search: string,
    search_field: SearchFields[],
    order: SortField,
    order_mode: OrderMode,
};

export interface UsersAction {
    type: UsersActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: IUsersStore = {
    users: [],
    isLoading: false,
    isLoadingSave: false,
    total: 0,
    page: 1,
    pageSize: 10,
    userEdit: null,
    search: '',
    search_field: [],
    order: SortField.EMAIL,
    order_mode: OrderMode.ASC,
};

export const reducer = (state: IUsersStore = INITIAL_STATE, action: UsersAction): IUsersStore => {
    switch (action.type) {

        case UsersActionTypes.CHANGE_USERS:
            return { ...state, users: action.payload };

        case UsersActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case UsersActionTypes.CHANGE_TOTAL:
            return { ...state, total: action.payload };

        case UsersActionTypes.CHANGE_USER_EDIT:
            return { ...state, userEdit: action.payload };

        case UsersActionTypes.CHANGE_LOADING_SAVE:
            return { ...state, isLoadingSave: action.payload };

        case UsersActionTypes.CHANGE_PAGE:
            return { ...state, page: action.payload };

        case UsersActionTypes.CHANGE_PAGE_SIZE:
            return { ...state, pageSize: action.payload };

        case UsersActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };

        case UsersActionTypes.CHANGE_SEARCH_FIELD:
            return { ...state, search_field: action.payload };

        case UsersActionTypes.CHANGE_ORDER:
            return { ...state, order: action.payload.order, order_mode: action.payload.order_mode };

        default:
            return state;
    };
};

//Actions
export const getUsers = () => ({
    type: UsersActionTypes.ASYNC_GET_USERS,
});

export const getUser = (value: string) => ({
    type: UsersActionTypes.ASYNC_GET_USER,
    payload: value
});

export const saveUserApi = (user: IUser, onSaveListUpdate?: () => void) => ({
    type: UsersActionTypes.ASYNC_SAVE_USER,
    payload: {user, onSaveListUpdate}
});


export const changeLoading = (value: boolean) => ({
    type: UsersActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeUsers = (users: IUser[]) => ({
    type: UsersActionTypes.CHANGE_USERS,
    payload: users
});

export const changeTotal = (total: number) => ({
    type: UsersActionTypes.CHANGE_TOTAL,
    payload: total
});

export const changePage = (page: number) => ({
    type: UsersActionTypes.CHANGE_PAGE,
    payload: page
});

export const changePageSize = (pageSize: number) => ({
    type: UsersActionTypes.CHANGE_PAGE_SIZE,
    payload: pageSize
});

export const changeUserEdit = (user: Partial<IUser> | null) => ({
    type: UsersActionTypes.CHANGE_USER_EDIT,
    payload: user
});

export const changeLoadingSave = (value: boolean) => ({
    type: UsersActionTypes.CHANGE_LOADING_SAVE,
    payload: value
});

export const changeSearchValue = (value: string) => ({
    type: UsersActionTypes.CHANGE_SEARCH_VALUE,
    payload: value
});

export const changeSearchField = (fields: SearchFields[]) => ({
    type: UsersActionTypes.CHANGE_SEARCH_FIELD,
    payload: fields
});

export const changeOrder = (order: string, order_mode: string) => ({
    type: UsersActionTypes.CHANGE_ORDER,
    payload: { order, order_mode }
});

export default reducer;
