import React, { useState, useMemo, memo, useEffect } from 'react';
import { Modal, Button, Checkbox } from 'antd';
import { IState } from '../../../reducers/reducers';
import { useSelector, useDispatch } from 'react-redux';
import { controlRegisterUnitsIsOpen, asyncSendNewUnits, asyncSendEditUnits } from '../../../reducers/register-units.reducer';
import handleError from '../../../utils/handleError';
import Loading from '../../../components/loading/Loading';
import Input from '../../../components/Input/Input';

import './RegisterUnitsModal.scss'

interface props {
    isEdit: boolean,
    id: number,
    setEdit: Function,
    setId: Function
};

const INITIAL_INPUTS = {
    name: '',
    city: '',
    state: '',
    country: '',
    id: null,
    active: true
}

const ModalRegisterUnits = ({ isEdit, id, setEdit, setId }: props) => {

    const [unitsEdit, setUnitsEdit] = useState({
        ...INITIAL_INPUTS
    });

    const isOpen = useSelector(({ registerunits }: IState)=> registerunits.isOpen);
    const isLoadingModal = useSelector(({ registerunits }: IState)=> registerunits.isLoadingModal);
    const units = useSelector(({ registerunits }: IState)=> registerunits.units);

    const dispatch = useDispatch();

    useMemo(()=>{
        const newUnits: any = isEdit ? units.find(element => element.id === id) : { ...unitsEdit } ;
        setUnitsEdit(newUnits);
    // eslint-disable-next-line
    },[units, id])

    useEffect(()=>{
        if(!isOpen) {
            setUnitsEdit({
                ...INITIAL_INPUTS
            });
            setId(null);
            setEdit(false);
        };
    // eslint-disable-next-line
    },[isOpen])

    const validateCamps = () => {
        let validate = true;
        if(unitsEdit.name === '' || unitsEdit.city === '' || unitsEdit.state === '' || unitsEdit.country === '') {
            handleError({ message: 'Não pode deixar nenhum campo vazio'});
            validate = false;
        };
        if(validate){
            if(isEdit){
                dispatch(asyncSendEditUnits(unitsEdit));
            } else {
                dispatch(asyncSendNewUnits(unitsEdit));
            }
        };
    };

    return (
        <Modal
            title={ isEdit ? 'Editar núcleo' : 'Adicionar novo núcleo' }
            footer={false}
            destroyOnClose={true}
            maskClosable={false}
            onCancel={() =>{
                dispatch(controlRegisterUnitsIsOpen(false));
                setEdit(false);
                setId(null);
            }}
            visible={isOpen} >

                { isLoadingModal && <Loading/> }

                <div className='content-input-modal-units' >
                    <Input placeholder='Nome do núcleo (ex: Fortaleza (matriz))' label="NOME" value={unitsEdit.name } onChange={({ target }) => setUnitsEdit({ ...unitsEdit, name: target.value })} />
                    <Input placeholder='Cidade (ex: Fortaleza)' label="CIDADE" value={unitsEdit.city} onChange={({ target }) => setUnitsEdit({ ...unitsEdit, city: target.value })} />
                    <Input placeholder='Estado (ex: Ceará)' label= "ESTADO" value={unitsEdit.state} onChange={({ target }) => setUnitsEdit({ ...unitsEdit, state: target.value })} />
                    <Input placeholder='País (ex: Brasil)' label="PAÍS" value={unitsEdit.country} onChange={({ target }) => setUnitsEdit({ ...unitsEdit, country: target.value })} />
                    {
                        isEdit &&
                    <>
                        <Checkbox checked={unitsEdit.active} onChange={(e)=> setUnitsEdit({ ...unitsEdit, active: e.target.checked})}>ativo</Checkbox>
                    </>
                    }
                </div>

                <div className='button-modal-units'>
                    <Button type='primary' onClick={()=>validateCamps()}>SALVAR</Button>
                </div>

        </Modal>
    )
};

export default memo(ModalRegisterUnits);