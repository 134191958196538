import { Input } from "antd";
import React from "react";
import ImageUpload from "../image-upload/ImageUpload";
import { IVideo } from "../../models/video.model";
import AttachmentsList from "../attachment/AttachmentList";

import "./PropertiesVideos.scss";

interface IProperties {
    currentEdit: IVideo,
    setCurrentEdit: (video:IVideo) => void,
    isEdit: boolean,
    types: string,
};

const PropertiesVideos = ({types, currentEdit, setCurrentEdit, isEdit}: IProperties) => {

    function onChangeVideo(key: string, language: string, value: string) {
        let item = {...currentEdit};
        item = {...item, [language]: {...item[language as keyof IVideo], [key]: value}};
        
        setCurrentEdit(item as IVideo);
    };

    return (
        <>
            <div className="group-input">
                <div className="input-group">
                    <Input
                        value={currentEdit[types as keyof IVideo].title}
                        onChange={({ target }) => onChangeVideo("title", types, target.value)}
                        placeholder={"Nome do vídeo"}
                    /> 
                    <Input
                        value={currentEdit[types as keyof IVideo].description}
                        onChange={({ target }) => onChangeVideo("description", types, target.value)}
                        placeholder={"Descrição do vídeo"}
                    />
                </div>
                <ImageUpload
                    isEdit={isEdit}
                    currentState={currentEdit}
                    setCurrentState={setCurrentEdit}
                    types={types}
                />
            </div>
            <div className="attachments">
                <span>Anexos:</span>
                <AttachmentsList
                    types={types}
                    values={currentEdit[types as keyof IVideo].attachments || []}
                    vidEdit={currentEdit}
                    setVidEdit={setCurrentEdit}
                />
            </div>
        </>
    );
};

export default PropertiesVideos;