import { IBlockList } from "../models/blockList.model";

// Types
export enum BlockListActionTypes {
    ASYNC_GET_BLOCKLIST = '@blockList/ASYNC_GET_BLOCKLIST',
    ASYNC_REMOVE_EMAIL = '@blockList/ASYNC_REMOVE_EMAIL',
    SET_BLOCKLIST = '@blockList/SET_BLOCKLIST',
    SET_IS_LOADING = '@blockList/SET_IS_LOADING',
    SET_PAGELIST = '@blockList/SET_PAGELIST',
    SET_CURRENT_PAGE = '@blockList/SET_CURRENT_PAGE',
};

// Interfaces
export interface IBlockListStore {
    blockList: IBlockList[],
    isLoading: boolean,
    pageList: any[],
    currentPage: number,
};

export interface IBlockListAction {
    type: BlockListActionTypes,
    payload: any,
};

// Reducer
const INITIAL_STATE: IBlockListStore = {
    blockList: [],
    isLoading: false,
    pageList: [undefined],
    currentPage: 0,
};

export const reducer = (
    state: IBlockListStore = INITIAL_STATE,
    action: IBlockListAction): IBlockListStore => {
    switch (action.type) {
        case BlockListActionTypes.SET_BLOCKLIST:
            return {...state, blockList: action.payload};

        case BlockListActionTypes.SET_IS_LOADING:
            return {...state, isLoading: action.payload};

        case BlockListActionTypes.SET_PAGELIST:
            return {...state, pageList: action.payload};

        case BlockListActionTypes.SET_CURRENT_PAGE:
            return {...state, currentPage: action.payload};

        default:
            return state;
    }
};

// Actions
export const asyncGetBlockList = (search: string = '') => ({
    type: BlockListActionTypes.ASYNC_GET_BLOCKLIST,
    payload: search,
});

export const asyncRemoveEmail = (email: string) => ({
    type: BlockListActionTypes.ASYNC_REMOVE_EMAIL,
    payload: email,
});

export const setBlockList = (blockList: IBlockList) => ({
    type: BlockListActionTypes.SET_BLOCKLIST,
    payload: blockList,
});

export const setIsLoading = (isLoading: boolean) => ({
    type: BlockListActionTypes.SET_IS_LOADING,
    payload: isLoading,
});

export const setPageList = (pageList: any[]) => ({
    type: BlockListActionTypes.SET_PAGELIST,
    payload: pageList,
});

export const setCurrentPage = (currentPage: number) => ({
    type: BlockListActionTypes.SET_CURRENT_PAGE,
    payload: currentPage,
});

export default reducer;
