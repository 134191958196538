import React from 'react';
import {useDispatch} from 'react-redux';
import {Button} from 'antd';

import {controlCurrentSteps} from '../../../../reducers/create-admin.reducer';
import {IAdmin} from '../../../../models/administrator.model';

import './ButtonSave.scss';
import '../AdminEdit.scss';

interface IProps {
    loading: boolean,
    adminToEdit: IAdmin,
    isEdit: boolean,
    currentSteps: number
    onOk: (adminToEdit: IAdmin) => void,
    onCancel: () => void,
}

function ButtonsSave({onCancel, loading, adminToEdit, onOk, currentSteps}: IProps) {
    const dispatch = useDispatch();

    return (
        <div className={`buttons-bottom ${currentSteps === 0 ? '' : 'two-div'} `}>
            {currentSteps === 1 && 
                <div className='back-button buttons-bottom' >
                    <Button onClick={()=> dispatch(controlCurrentSteps(0))} type='default'>
                        Voltar
                    </Button>
                </div>
            }
            <div className='buttons-bottom'>
                <Button
                    className='btn-cancel'
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={loading}
                    type='primary'
                    onClick={() => onOk(adminToEdit)}>
                    {loading ? 'Salvando...' : 'Salvar'}
                </Button>
            </div>
        </div>
    );
};

export default ButtonsSave;
