import React, {useState} from 'react';
import { Modal, Switch, Tabs } from 'antd';

import { IAudit } from '../../../models/audit.model';
import JsonViewer from '../../../components/json-viewer/json-viewer';

import './details.scss';

interface IProps extends IAudit {
    onClose: () => void;
}

const styles = {
    width: '100%',
    overflow: 'auto',
}

const Details = (props: IProps) => {
    const [checked, setChecked] = useState<boolean>(true);

    const onChangeSwitch = (e: boolean) => {
        setChecked(e);
    }
    return (
        <Modal
            className='content-modal-audit'
            visible={true}
            footer={false}
            maskClosable={false}
            title='Detalhes'
            onCancel={props.onClose}
        >
            <div className='container-switch-pretty' >
                <Switch checked={checked} onChange={onChangeSwitch} /> Pretty
            </div>
            <Tabs defaultActiveKey='1' className='tabs-container' >
                <Tabs.TabPane
                    key='1'
                    style={styles}
                    tab={<span>{'Request'}</span>}>
                    <JsonViewer isTable={!checked} json={JSON.parse(props.request)} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    key='2'
                    style={styles}
                    tab={<span>{'Context'}</span>}>
                    <JsonViewer isTable={!checked} json={JSON.parse(props.context)} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    key='3'
                    style={styles}
                    tab={<span>{'Extras'}</span>}>
                        <JsonViewer isTable={!checked} json={JSON.parse(props.extras)} />
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};

export default Details;