import React from 'react'
import {Route} from 'react-router-dom';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import NotAllowedScreen from '../not-allowed-screen/NotAllowedScreen';

interface IRestrictedRouteProps {
    path: string;
    exact: boolean;
    component: any;
    permissions: string[];
}

function RestrictedRoute({path, exact = false, component, permissions}: IRestrictedRouteProps) {
    const checkPermission = useCheckPermissions();

    const hasPermission = checkPermission(permissions);

    if (hasPermission) {
        return <Route path={path} component={component} exact={exact} />
    } else {
        return <Route path={path} component={NotAllowedScreen} exact={exact} />
    }
}

export default RestrictedRoute