export interface ICoache {
    id: string,
    email: string,
    phone_number: string,
    password?: string,
    password_mode?: PasswordMode,
    given_name: string,
    cpf: string,
    family_name: string,
    nickname: string,
    gender: Gender,
    birthdate: string,
    address: IAddress,
    locale: string,
    zoneinfo: string,
    enable: boolean,
    center_id: string,
    trainer_id: string,
    email_verified?: boolean,
    enabled?: boolean,
    phone_number_verified?: boolean,
    created_at?: string,
    updated_at?: string,
    personal_ids: IPersonalIDs[],
    coach: ICoacheInfo,
    convert_coach?: boolean,
    enrollment?: string,
    enrollment_date?: string,
    phones: string[],
};

export interface IClass {
    centerId: string,
    classId: string,
    className: string,
    courseId: string,
    description: string
    endDate: string,
    id: string,
    picture: string,
    startDate: string,
    trainerId: string,
    type: string,
};

export interface IUnit {
    id: string,
    name: string,
};

export interface IPersonalIDs {
    country: string,
    docs: IDocsInfo[],
}

export interface IDocsInfo {
    name: string
    value: string
}

export enum PasswordMode {
    PERM = 'permanent',
    TEMP = 'temporary',
    LINK = 'confirm_link'
};

export const getDefaultPassword = () => `febracis${new Date().getFullYear()}`;

export interface ICoacheInfo {
    specializations: CoachingType[],
    unit_id?: string,
    unit?: string,
    activation_date?: string,
    expiration_date?: string,
    status?: string
    recurrent_ids?: string[],
    enrollment?: string,
};

export enum SearchFields {
    ID = 'id',
    EMAIL = 'email',
    PHONE_NUMBER = 'phone_number',
    NICKNAME = 'nickname',
    GIVEN_NAME = 'given_name',
    FAMILY_NAME = 'family_name',
    RECURRENT_IDS = 'coach.recurrent_ids',
    COUPONS = 'coach.coupons.coupon',
    CPF = 'personalIds.docs'

};

export enum OrderMode {
    ASC = 'asc',
    DESC = 'desc',
};

export interface IAddress {
    street_address: string,
    locality: string,
    region: string,
    postal_code: string,
    country: string
};

export enum CoachingType {
    MASTER = 'master',
    EXECUTIVE = 'executive',
    BUSINESS = 'business',
    MICROFRANCHISEE = 'microfranchisee',
    MINDFULNESS = 'mindfulness',
    LIVING_FAMILY = 'livingFamily',
    CAREER = 'career',
    TRAINER = 'trainer',
    HOUR500 = 'hour500',
    HOUR1000 = 'hour1000',
    HOUR2000 = 'hour2000',
};

export enum Gender {
    MALE = 'male',
    FEMALE = 'female'
};

export interface ICoupons {
    payment_id: string,
    created_at: string,
    coupon: string,
    recurrent_id: string,
}

interface IQueryStringList {
    page: number,
    pageSize: number,
    search: string,
    search_field: SearchFields[],
    order: SearchFields,
    order_mode: OrderMode,
    filter_unit: IUnit | null | boolean,
    filter_specs: (CoachingType | string)[],
    filter_statuses: string[],
    filter_class: string[],
}

export interface IPaymentXlsxRequest {
    from?: string;
    to?: string;
    search?: string;
    types?: string[];
    callback?: () => void;
}

export const queryStringList = ({ page, pageSize, search, search_field, order, order_mode, filter_unit, filter_specs, filter_statuses, filter_class }: IQueryStringList) => {
    let params = [];

    if (page) {
        params.push(`page=${page - 1}`);
    }

    if (pageSize) {
        params.push(`page_size=${pageSize}`);
    }

    if (search) {
        params.push(`search=${window.encodeURIComponent(search)}`);

        if (!search_field || search_field.length === 0) {
            search_field = Object.values(SearchFields);
        }

        for (const field of search_field) {
            params.push(`search_field=${field}`);
        }
    }

    params.push(`order=${order}`);
    params.push(`order_mode=${order_mode}`);

    if (filter_unit) {
        params.push(`unit=${filter_unit}`);
    }

    if (filter_specs && filter_specs.length > 0) {
        for (const spec of filter_specs) {
            params.push(`specialization=${spec}`);
        }
    }

    if (filter_statuses && filter_statuses.length > 0) {
        for (const status of filter_statuses) {
            params.push(`status=${status}`);
        }
    }

    if (filter_class && filter_class.length > 0) {
        for (const classes of filter_class) {
            params.push(`enrollment=${classes}`);
        }
    }

    return `?${params.join('&')}`;
};