import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import { useDispatch, useSelector } from 'react-redux';
import { getCoaches, getClass } from '../../reducers/scis-classes.reducer';
import { IState } from '../../reducers/reducers';
import ScisClassesList from './scis-classes-list/ScisClassesList';

const ScisClasses = (): any => {
    const { isLoading, coaches, total } = useSelector((state: IState) => state.scisClasses);

    const dispatch = useDispatch();

    return (
        <section>
            <TitleSection title={'Turmas'} >
            </TitleSection>
            <ScisClassesList
                getCoaches={() => dispatch(getCoaches())}
                getClass={() => dispatch(getClass())}
                total={total}
                coaches={coaches}
                loading={isLoading}
            />

        </section >
    );
}

export default ScisClasses;
