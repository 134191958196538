import React, { useState, useMemo, useEffect } from 'react';
import { ICoache } from '../../../models/coache.model';
import ProfileForm from './profile-form/ProfileForm';
import { Tabs } from 'antd';
import { PasswordMode } from '../../../models/coache.model';

import EnableForm from './enable-form/EnableForm';
import AddressForm from './address-form/AddressForm';
import CoacheInfoForm from './coache-info-form/CoacheInfoForm';
import PasswordForm from './password-form/PasswordForm';
import { IState } from '../../../reducers/reducers';
import { useSelector } from 'react-redux';
import CoacheCreate from '../coache-create/CoacheCreate';
import EnrollmentForm from './enrollment-form/EnrollmentForm';
import PersonalIDs from './personal-ids/PersonalIDs';
import PhoneNumbersForm from './phone-numbers-form/PhoneNumbersForm';
import ButtonsSave from './button-save/ButtonSave';

import './CoacheEdit.scss';

const { TabPane } = Tabs;

interface ICoacheEditProps {
    coache: ICoache,
    isEdit: boolean,
    onOk(coach: ICoache): void,
    onCancel(): void,
    loading: boolean
}

const defaultPassword = `febracis${new Date().getFullYear()}`

const CoacheEdit = ({ coache, isEdit, onOk, onCancel, loading }: ICoacheEditProps) => {
    const [coachEdit, setCoachEdit] = useState<ICoache>(coache);
    const { centers, countries, timezones, locales } = useSelector(({ utils }: IState) => utils);

    const currentSteps = useSelector(({ CreateCoaches }:IState)=>CreateCoaches.currentSteps);

    const canBecomeCoach = useMemo(() => {
        return !coache.coach && isEdit
    }, [coache, isEdit]);

    useEffect(()=>{
        if(!isEdit) {
            const item = { ...coachEdit, password: defaultPassword,  password_mode: PasswordMode.PERM };
            setCoachEdit(item as ICoache);
        };
    // eslint-disable-next-line
    },[]);

    function onChangeCoachEdit(key?: string, value?: any, key2?: string, value2?: any) {
        let item = { ...coachEdit };
        let changed = false;

        if (key) {
            item = { ...item, [key]: value };
            changed = true;
        }

        if (key2) {
            item = { ...item, [key2]: value2 };
            changed = true;
        }

        if (changed === true) {
            setCoachEdit(item as ICoache);
        }
    };

    return (
        <form className="coache-edit-container">
            { isEdit ? (
                <>
                    <Tabs defaultActiveKey="1">
                        <TabPane
                            key="1"
                            tab={<span>{'Perfil'}</span>}>
                            <ProfileForm
                                timezones={timezones}
                                locales={locales}
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit} />
                        </TabPane>

                        <TabPane
                            key="2"
                            tab={<span>{'Endereço'}</span>}>
                            <AddressForm
                                countries={countries}
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit}
                            />
                        </TabPane>

                        <TabPane
                            key="3"
                            tab={<span>{'Telefones'}</span>}>
                            <PhoneNumbersForm
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit}/>
                        </TabPane>

                        <TabPane
                            key="4"
                            tab={<span>{'IDs'}</span>}>
                            <PersonalIDs
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit}/>
                        </TabPane>

                        <TabPane
                            key="5"
                            tab={<span>{'Coaching'}</span>}>
                            <CoacheInfoForm
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit}
                                centers={centers}
                                canBecomeCoach={canBecomeCoach}
                                isEdit={isEdit} />
                        </TabPane>

                        <TabPane
                            key="6"
                            tab={<span>{'Status'}</span>}>
                            <EnableForm
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit}/>
                        </TabPane>

                        <TabPane
                            key="7"
                            tab={<span>{'Matrícula'}</span>}>
                            <EnrollmentForm
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit}/>
                        </TabPane>

                        <TabPane
                            key="8"
                            tab={<span>{'Senha'}</span>}>
                            <PasswordForm
                                onChange={onChangeCoachEdit}
                                coachEdit={coachEdit}
                                isEdit={isEdit} />
                        </TabPane>

                        </Tabs>
                    </>) : <CoacheCreate countries={countries} canBecomeCoach={canBecomeCoach} centers={centers} onChange={onChangeCoachEdit} coachEdit={coachEdit} /> }

                    { ( isEdit || currentSteps !==0) && <ButtonsSave currentSteps={currentSteps} coachEdit={coachEdit} loading={loading} isEdit={isEdit} onCancel={onCancel} onOk={onOk} />}
        </form>
    );
};

export default CoacheEdit;
