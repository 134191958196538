import React, { useCallback } from 'react';
import { Modal, DatePicker, Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import moment, { Moment } from 'moment';
import { FormComponentProps } from 'antd/lib/form';

import { changeParams, generateCoachingHoursReport } from '../../../reducers/coaching-hours-reports.reducer';

import './styles.scss';

const { MonthPicker } = DatePicker;

interface ICoachingHoursModalProps extends FormComponentProps {
    onClose(): void,
    loading: boolean,
};

const validationRules = {
    rules: [{ required: true, message: 'Campo obrigatório' }],
};

function CoachingHoursModal({onClose, loading, form}: ICoachingHoursModalProps) {

    const dispatch = useDispatch();
    const { getFieldDecorator, validateFields } = form;

    const handleChangeRange = useCallback((key: string, data: Moment | null) => {
        if(data) {
            dispatch(changeParams({[key]: data.format("YYYY/MM")}))
        }
    }, [dispatch]);

    const disabledDate = useCallback((current) => {
        return current ? current.isAfter(moment()) : false;
    }, []);

    const handleSubmit = useCallback((e) => {
        e.preventDefault();

        validateFields((err) => {
            if(!err) {
                dispatch(generateCoachingHoursReport());
            }
        });
    }, [dispatch, validateFields]);

    const changeInitialMonth = useCallback((data) => {
        handleChangeRange('initialMonth', data);
    }, [handleChangeRange]);

    const changeEndMonth = useCallback((data) => {
        handleChangeRange('endMonth', data);
    }, [handleChangeRange]);

    return (
        <Modal
            width={700}
            title={"Relatório de horas de coaching"}
            visible={true}
            footer={false}
            maskClosable={false}
            onCancel={onClose}
        >
            <Form onSubmit={handleSubmit} className="coaching-hours-modal">
                <div className="coaching-hours-content">
                    <div className='div-input-month'>
                        <span>Mês inicial</span>
                        <Form.Item>
                            {getFieldDecorator('initialMonth', validationRules)(
                                <MonthPicker
                                onChange={changeInitialMonth}
                                disabledDate={disabledDate}
                                format="MM/YYYY"
                            />,
                            )}
                        </Form.Item>
                    </div>
                    <div className='div-input-month'>
                        <span>Mês final</span>
                        <Form.Item>
                            {getFieldDecorator('endMonth', validationRules)(
                                <MonthPicker
                                onChange={changeEndMonth}
                                disabledDate={disabledDate}
                                format="MM/YYYY"
                            />
                            )}
                        </Form.Item>
                    </div>
                </div>
                <div className='coaching-hours-modal-button'>
                    <Button
                        className="cancel"
                        type="default"
                        onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        disabled={loading}
                        type="primary"
                        htmlType="submit"
                    >
                        {loading ? 'Gerando...' : 'Gerar Relatório'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
}

export default Form.create<ICoachingHoursModalProps>({})(CoachingHoursModal);
