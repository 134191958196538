interface IRestrictedComponentProps {
    hasPermission: boolean;
    children: JSX.Element,
}

function RestrictedComponent({hasPermission, children}: IRestrictedComponentProps): JSX.Element | null {
    if (hasPermission) {
        return children;
    } else {
        return null;
    }
}

export default RestrictedComponent;