import { ICategory } from "../models/categories.model";

//Types
export enum CategoriesActionTypes {
    ASYNC_GET_CATEGORIES = '@categories/ASYNC_GET_CATEGORIES',
    ASYNC_GET_PARENTS = '@categories/ASYNC_GET_PARENTS',
    ASYNC_SAVE_CATEGORY = '@categories/ASYNC_SAVE_CATEGORY',
    ASYNC_SAVE_NEW_CATEGORY = '@categories/ASYNC_SAVE_NEW_CATEGORY',
    ASYNC_GET_CATEGORY = '@categories/ASYNC_GET_CATEGORY',
    CHANGE_CATEGORY_EDIT = '@categories/CHANGE_CATEGORY_EDIT',
    SET_PAGE_LIST = '@categories/SET_PAGE_LIST',
    CHANGE_LOADING_SAVE = '@categories/CHANGE_LOADING_SAVE',
    CHANGE_CATEGORY = '@categories/CHANGE_CATEGORY',
    CHANGE_PAGE_CATEGORIES = '@categories/CHANGE_PAGE_CATEGORIES',
    CHANGE_SEARCH_VALUE = '@categories/CHANGE_SEARCH_VALUE',
    CHANGE_ORDER = '@categories/CHANGE_ORDER',
    CHANGE_LOADING ='@categories/CHANGE_LOADING',
    CHANGE_PARENTS = '@categories/CHANGE_PARENTS',
    ASYNC_ENABLE_DISABLE_CATEGORY = '@categories/ASYNC_ENABLE_DISABLE_CATEGORY',
};

//Interfaces
export interface ICategoriesStore {
    categories: ICategory[],
    categoryEdit: Partial<ICategory> | null,
    page: number,
    pageSize: number,
    pageList: string[] | undefined[] | null[][],
    search: string,
    order: string,
    order_mode: string,
    isLoading: boolean,
    isLoadingSave: boolean,
    parents: string[],
};

export interface CategoriesAction {
    type: CategoriesActionTypes,
    payload: any,
};

//Reducer
const INITIAL_STATE: ICategoriesStore = {
    categories: [],
    categoryEdit: null,
    page: 0,
    pageSize: 10,
    pageList: [undefined],
    search: '',
    order: 'title',
    order_mode: 'asc',
    isLoading: false,
    isLoadingSave: false,
    parents: [],
};

export const reducer = (state: ICategoriesStore = INITIAL_STATE, action: CategoriesAction): ICategoriesStore => {
    switch(action.type) {
        case CategoriesActionTypes.CHANGE_CATEGORY_EDIT:
            return { ...state, categoryEdit: action.payload };

        case CategoriesActionTypes.CHANGE_CATEGORY:
            return { ...state, categories: action.payload };

        case CategoriesActionTypes.CHANGE_PAGE_CATEGORIES:
            return { ...state, page: action.payload };

        case CategoriesActionTypes.SET_PAGE_LIST:
            return { ...state, pageList: action.payload };

        case CategoriesActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };
        
        case CategoriesActionTypes.CHANGE_LOADING_SAVE:
            return { ...state, isLoadingSave: action.payload };

        case CategoriesActionTypes.CHANGE_ORDER:
            return { ...state, order: action.payload.order, order_mode: action.payload.order_mode };
        
        case CategoriesActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case CategoriesActionTypes.CHANGE_PARENTS:
            return { ...state, parents: action.payload };

        default:
            return state;
    }
};

//Actions
export const getCategories = () => ({
    type: CategoriesActionTypes.ASYNC_GET_CATEGORIES,
});

export const getParents = () => ({
    type: CategoriesActionTypes.ASYNC_GET_PARENTS,
});

export const saveCategoryApi = (category: ICategory) => ({
    type: CategoriesActionTypes.ASYNC_SAVE_CATEGORY,
    payload: category
});

export const saveNewCategory = (category: ICategory) => ({
    type: CategoriesActionTypes.ASYNC_SAVE_NEW_CATEGORY,
    payload: category
});

export const getCategory = (value: ICategory) => ({
    type: CategoriesActionTypes.ASYNC_GET_CATEGORY,
    payload: value
});

export const changeCategoryEdit = (category: Partial<ICategory> | null) => ({
    type: CategoriesActionTypes.CHANGE_CATEGORY_EDIT,
    payload: category
});

export const setPageList = (pageList: string[] | undefined[] | null[]) => ({
    type: CategoriesActionTypes.SET_PAGE_LIST,
    payload: pageList,
});

export const changeLoadingSave = (value: boolean) => ({
    type: CategoriesActionTypes.CHANGE_LOADING_SAVE,
    payload: value
});

export const changeCategory = (category: ICategory[]) => ({
    type: CategoriesActionTypes.CHANGE_CATEGORY,
    payload: category
});

export const changePageCategories = (page: number) => ({
    type: CategoriesActionTypes.CHANGE_PAGE_CATEGORIES,
    payload: page
});

export const changeSearchValue = (value: string) => ({
    type: CategoriesActionTypes.CHANGE_SEARCH_VALUE,
    payload: value
});

export const changeOrder = (order: string, order_mode: string) => ({
    type: CategoriesActionTypes.CHANGE_ORDER,
    payload: { order, order_mode }
});

export const changeLoading = (value: boolean) => ({
    type: CategoriesActionTypes.CHANGE_LOADING,
    payload: value
});

export const allParents = (parents: string[]) => ({
    type: CategoriesActionTypes.CHANGE_PARENTS,
    payload: parents
});

export const enableDisableCategory = (category: ICategory, active: boolean) => ({
    type: CategoriesActionTypes.ASYNC_ENABLE_DISABLE_CATEGORY,
    payload: {category, active},
});

export default reducer;