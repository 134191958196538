import { IReport } from "../models/report.model";

//Types
export enum CoachReportsActionTypes {
    ASYNC_GET_COACH_REPORTS = '@coach-reports/ASYNC_GET_COACH_REPORTS',
    ASYNC_GENERATE_COACH_REPORT = '@coach-reports/ASYNC_GENERATE_COACH_REPORT',
    ASYNC_DELETE_REPORT = '@coach-reports/ASYNC_DELETE_REPORT',
    CHANGE_COACH_REPORTS = '@coach-reports/CHANGE_COACH_REPORTS',
    CHANGE_LOADING = '@coach-reports/CHANGE_LOADING',
    CHANGE_PARAMS = '@coach-reports/CHANGE_PARAMS',
    CHANGE_LOADING_GENERATE = '@coach-reports/CHANGE_LOADING_GENERATE',
    POLLING_FOR_STATUS = '@coach-reports/POLLING_FOR_STATUS',
    UPDATE_COACH_REPORTS = '@coach-reports/UPDATE_COACH_REPORTS',
    SET_USERNAMES = '@coach-reports/SET_USERNAMES',
};

//Interfaces
export interface ICoachReportsStore {
    reports: IReport[],
    isLoading: boolean,
    isLoadingGenerate: boolean,
    order_by: string,
    status: string | null,
    to: string,
    from: string,
    page: number,
    pageSize: number,
    total: number,
};

export interface CoachReportsAction {
    type: CoachReportsActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: ICoachReportsStore = {
    reports: [],
    isLoading: false,
    isLoadingGenerate: false,
    order_by: 'asc',
    status: null,
    to: '',
    from: '',
    page: 1,
    pageSize: 10,
    total: 0,
};

export const reducer = (state: ICoachReportsStore = INITIAL_STATE, action: CoachReportsAction): ICoachReportsStore => {
    switch (action.type) {
        case CoachReportsActionTypes.CHANGE_COACH_REPORTS:
            return { ...state, reports: action.payload };

        case CoachReportsActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case CoachReportsActionTypes.CHANGE_LOADING_GENERATE:
            return { ...state, isLoadingGenerate: action.payload };

        case CoachReportsActionTypes.UPDATE_COACH_REPORTS:
            return { ...state, reports: action.payload };

        case CoachReportsActionTypes.CHANGE_PARAMS:
            return {...state, ...action.payload};

        default:
            return state;
    };
};

//Actions
export const getCoachReports = () => ({
    type: CoachReportsActionTypes.ASYNC_GET_COACH_REPORTS,
});

export const generateCoachReport = () => ({
    type: CoachReportsActionTypes.ASYNC_GENERATE_COACH_REPORT,
});

export const changeLoading = (value: boolean) => ({
    type: CoachReportsActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeReports = (reports: IReport[]) => ({
    type: CoachReportsActionTypes.CHANGE_COACH_REPORTS,
    payload: reports,
});

export const changeLoadingGenerate = (value: boolean) => ({
    type: CoachReportsActionTypes.CHANGE_LOADING_GENERATE,
    payload: value
});

export const updateCoachReports = (reports: IReport[]) => ({
    type: CoachReportsActionTypes.CHANGE_COACH_REPORTS,
    payload: reports
})

export const reportsPolling = () => ({
    type: CoachReportsActionTypes.POLLING_FOR_STATUS,
});


export const deleteReport = (reportId: string) => ({
    type: CoachReportsActionTypes.ASYNC_DELETE_REPORT,
    payload: reportId,
});

export const updateReports = (reports: IReport[]) => ({
    type: CoachReportsActionTypes.UPDATE_COACH_REPORTS,
    payload: reports
});

export const changeParams = (params: {}) => ({
    type: CoachReportsActionTypes.CHANGE_PARAMS,
    payload: params,
});

export default reducer;
