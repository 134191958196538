/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef } from 'react';
import { Select, Form, Button } from 'antd';
import Search from 'antd/lib/input/Search';

import { IClassScisClasses  } from '../../../../models/scis-classes.model';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../../../../reducers/reducers';
import {
    changeSearchValue,
    getCoaches,
    changePage,
    changeFilterUnit,
    changeFilterClass,
    getCoachesXlsx,
    changeLoading,
} from '../../../../reducers/scis-classes.reducer';
import useCheckPermissions from '../../../../hooks/useCheckPermissions';
import { permissionKeys } from '../../../../utils/permissions';
import './SearchFilter.scss';

const { Option } = Select;

interface ISearch {
    classes: IClassScisClasses[],
}

const SearchFilter = ({classes}: ISearch) => {
    const refA = useRef<HTMLAnchorElement | null>(null);
    const { search, filter_unit, units, filter_class, isLoading } = useSelector(({ scisClasses }: IState) => ({
        search: scisClasses.search,
        filter_unit: scisClasses.filter_unit,
        units: scisClasses.units,
        filter_class: scisClasses.filter_class,
        isLoading: scisClasses.isLoading,
    }));

    const dispatch = useDispatch();
    const checkPermissions = useCheckPermissions();

    const classesToList = classes.filter(item => filter_unit.includes(item.centerId));
    classesToList.sort((a, b) => {
        const nameA = a.className.toLowerCase();
        const nameB = b.className.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    classes.sort((a, b) => {
        const nameA = a.className.toLowerCase();
        const nameB = b.className.toLowerCase();
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
    });

    const [searchTerm, setSearchTerm] = React.useState('');

    const filteredClasses = React.useMemo(() => {
        return classesToList.length > 0 ? classesToList : classes;
    }, [classesToList, classes]);

    const filteredAndSearchedClasses = React.useMemo(() => {
        return filteredClasses.filter(({ className }) =>
            className.toLowerCase().includes(searchTerm.toLowerCase())
        );
    }, [filteredClasses, searchTerm]);

    const onSearch = () => {
        dispatch(changePage(1));
        dispatch(getCoaches());
    };

    const onDownload = () => {
        dispatch(changeLoading(true));
        dispatch(getCoachesXlsx((string: string, error: boolean)=> {
            let current = refA.current;
            if (current && !error) {
                current.href = string;
                current.download = `xlsx_${new Date().toLocaleString()}-ScisClasses.xlsx`;
                current.click();
            }
        }));
    };

    function handleUnit(unitsToFind: String[]) {
        const filteredItems = units.filter(item => unitsToFind.includes(item.name));
        return filteredItems.map(item => item.id);
    };

    function handleUnitValue(unitsToFind: String[]) {
        const filteredItems = units.filter(item => unitsToFind.includes(item.id));
        return filteredItems.map(item => item.name);
    };

    return (
        <Form className="filter-content2">
            <div className="input-filter">
                <label>Buscar</label>
                <Search
                    value={search}
                    className="search-input"
                    placeholder="Buscar"
                    style={{ width: 250 }}
                    onSearch={onSearch}
                    onChange={({ target }) => dispatch(changeSearchValue(target.value))} />
            </div>

            <div className="input-filter">
                <label>Filtrar Por Turmas</label>
                <Select
                    mode="multiple"
                    className="filter-class"
                    placeholder="Todas"
                    allowClear={true}
                    maxTagCount={1}
                    value={filter_class && filter_class.length > 0 ? filter_class : undefined}
                    onChange={(filter_class: string[]) => {
                        setSearchTerm('');
                        dispatch(changeFilterClass(filter_class));
                    }}
                    onSearch={setSearchTerm}
                    filterOption={false}
                    showSearch={true}>

                    {filteredAndSearchedClasses.map(({ className, id }) => (
                        <Option key={id} value={id}>
                            {className}
                        </Option>
                    ))}

                </Select>
            </div>

            <div className="input-filter">
                <label>Filtrar Por Unidade</label>
                <Select
                    mode="multiple"
                    className="filter-class"
                    placeholder="Todas"
                    allowClear={true}
                    maxTagCount={1}
                    value={handleUnitValue(filter_unit)}
                    onChange={(filter_unit: string[]) => dispatch(changeFilterUnit(handleUnit(filter_unit)))}>
                    {units.map(({ id, name }) => (
                        <Option key={id} value={name}>
                            {name}
                        </Option>
                    ))}
                </Select>
            </div>

           <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search">
                Pesquisar
            </Button>

            <a style={{display: 'none'}} ref={refA} href="/#" />

            {checkPermissions([permissionKeys.SCIS_CLASSES_EXPORT]) &&
                <Button
                    onClick={onDownload}
                    className="btn-search"
                    type="primary"
                    loading={isLoading}
                >
                    Exportar XLSX
                </Button>
            }

        </Form>
    )
};

export default SearchFilter;