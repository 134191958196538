export interface IVideo {
    id: string,
    name: string,
    title: string,
    description: string,
    thumbnail: string,
    videos: IVideoInfo,
    category: string,
    permission: string,
    scopes: string[],
    action: string,
    categoryId: string,
    active: boolean,
    entryId: string | null,
    thumbnailUrl: string | null,
    index: number,
    dateExpiration: string,
    dateRelease: string,
    daysRequiredToWatch: number,
    properties_pt: any,
    properties_es: any | undefined,
    properties_en: any | undefined,
    updated_at: string,
    duration: number,
};

export interface IVideoInfo {
    date_create: string,
};

interface IQueryStringList {
    pageSize?: number,
    search?: string,
    filter_videos: string[],
    nextPage?: string,
    scope?: string[],
};

export const queryStringList = ({ pageSize, search, filter_videos, nextPage, scope }: IQueryStringList) => {
    let params = [];

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }

    if (nextPage) {
        params.push(`nextPage=${nextPage}`);
    }

    if (search) {
        params.push(`search=${window.encodeURIComponent(search)}`);
    }

    if (scope && scope.length > 0) {
         for( const vid in scope) {
            params.push(`scope=${scope[vid]}`);
        }
    }

    if (filter_videos && filter_videos.length > 0) {
        for( const vid of filter_videos) {
            params.push(`categoryId=${vid}`);
        }
    }

    return `?${params.join("&")}`;
};
