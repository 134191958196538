import React, { useEffect, useMemo, useState } from 'react';
import { Table, Pagination, Icon, Popconfirm } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { asyncGetUrls, controlPage, controlIsOpen, asyncDeleteUrl, controlPageList } from '../../../reducers/url-shorteners.reducer';
import CreateUrlShorteners from '../create-url-shorteners/CreateUrlShorteners';
import { IUrlShorteners } from '../../../models/url-shorteners.model';
import { formatDate } from '../../../utils/moment.functions';
import { linksUtils } from '../../../utils/urls.constants';

import './UrlShortenersList.scss'

const UrlShortenersList = () => {

    const { isLoading, urls, isOpen, pagesList, page } = useSelector(({ urlShorteners  }: IState )=> ({
        isLoading: urlShorteners.isLoading,
        urls: urlShorteners.urls,
        isOpen: urlShorteners.isOpen,
        pagesList: urlShorteners.pagesList,
        page: urlShorteners.page
    }));

    const [ currentUrl, setCurrentUrl ] = useState<IUrlShorteners|any>(undefined);

    const dispatch = useDispatch();

    const total = useMemo(()=>{
        if(pagesList[page+1]) return 10000000;
        return page*10
    }, [page,pagesList]);

    useEffect(()=>{
        dispatch(asyncGetUrls());
        // eslint-disable-next-line 
    },[page]);

    useEffect(()=>{
        if(!isOpen) setCurrentUrl(undefined);
    }, [isOpen])
    
    const columns = [
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code',
            render: (code: string) => <a target="_blank" rel="noopener noreferrer" href={linksUtils('urlShortenersRedirect') + code} className="span-code-url-shorteners" >{code}</a>
        },
        {
            title: 'Data de Criação',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (data: string) => (
                <span>{data ? formatDate(new Date(data), 'DD/MM/YYYY HH:mm'): '-'}</span>
            )
        },
        {
            title: 'Última Atualização',
            dataIndex: 'updated_at',
            key: 'updated_at',
            render: (data: string) => (
                <span>{data ? formatDate(new Date(data), 'DD/MM/YYYY HH:mm') : '-'}</span>
            )
        },
        {
            title: 'Url',
            dataIndex: 'url',
            key: 'url'
        },
        {
            title: 'Ações',
            key: 'ações',
            render: (url: IUrlShorteners)=> {
                return (
                    <>
                        <Icon type="edit" onClick={()=>{
                            setCurrentUrl(url);
                            dispatch(controlIsOpen(true));

                        }}/>
                        <Popconfirm
                            className="popconfirm-url"
                            title="Ao remover uma URL encurtada, qualquer pessoal ou sistema que estiver usando a, vai receber página não encontrada como resposta. Mesmo assim, tem certeza que deseja remover?"
                            onConfirm ={()=> dispatch(asyncDeleteUrl({ code: url.code }))}
                            okText={'Sim'}
                            cancelText={'Não'}>
                            <Icon type="delete" />
                        </Popconfirm>
                    </>
                )
            }
        }
    ]
    
    return (
        <>
            { isOpen && <CreateUrlShorteners url={currentUrl} changeUrl={setCurrentUrl} /> }
            <Table
                className="table-url-shorteners"
                dataSource={urls}
                rowKey={(element, index)=> `${index}`}
                columns={columns}
                loading={isLoading}
                pagination={false} />
            <Pagination
                pageSize={10}
                total={total}
                current={page+1}
                onChange={nextPage => {
                    if(nextPage-1 < page ) {
                        let newPagesList = [...pagesList];
                        newPagesList.pop();
                        newPagesList.pop();
                        dispatch(controlPageList(newPagesList));
                    }
                    dispatch(controlPage(nextPage-1));
                }}
                className="pagination-url-shorteners" />
        </>
    )
};

export default UrlShortenersList;