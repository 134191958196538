import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router'
import Store, { history } from './config/Store.config';
import { ConfigProvider } from 'antd';

// eslint-disable-next-line
import moment from 'moment-timezone';

import 'moment/locale/pt-br';
import pt_BR from 'antd/es/locale/pt_BR';

import 'antd/dist/antd.css';


ReactDOM.render(
    <Provider store={Store}>
        <ConnectedRouter history={history}>
            <ConfigProvider locale={pt_BR}>
                <App />
            </ConfigProvider>
        </ConnectedRouter>
    </Provider>
    , document.getElementById('root'));

