import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { queryStringList } from '../models/audit.model';
import { AuditActions, IAction, setIsLoading, setAudits, setActions } from '../reducers/audit.reducer';
import { IState } from '../reducers/reducers';
import { getAudits, getAuditsAction } from '../utils/webApi';
import handleError from '../utils/handleError';

function* requestAudit({payload}: IAction) {
    try {
        yield put(setIsLoading(true));
        const {pageSize, page} = yield select(({audits}: IState) => audits);
        const {search, action} = payload;

        const {results, total} = yield call(getAudits, queryStringList({
            pageSize,
            page,
            search,
            action,
        }));

        yield put(setAudits(results, total));
    } catch (error) {
        handleError(error);
    }
    finally {
        yield put(setIsLoading(false));
    }
}

function* requestActions() {
    try {
        const {actions} = yield call(getAuditsAction);
        yield put(setActions(actions));
    } catch (error) {
        handleError(error);
    }
    finally {
        yield put(setIsLoading(false));
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(AuditActions.ASYNC_GET_AUDITS, requestAudit),
        yield takeEvery(AuditActions.ASYNC_GET_ACTIONS, requestActions),
    ]);
}
