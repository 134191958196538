import { IResource } from "../models/resources.model";

//ResourceTypes
export enum ResourceActionTypes {
    ASYNC_GET_RESOURCES = '@resources/ASYNC_GET_RESOURCES',
    CHANGE_RESOURCES = '@resources/CHANGE_RESOURCES',
    CHANGE_LOADING = '@resources/CHANGE_LOADING',
};

//Interfaces
export interface IResourceStore {
    resources: IResource[],
    isLoading: boolean
};

export interface ResourceAction {
    type: ResourceActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: IResourceStore = {
    resources: [],
    isLoading: false,
};

export const reducer = (state: IResourceStore = INITIAL_STATE, action: ResourceAction): IResourceStore => {
    switch (action.type) {

        case ResourceActionTypes.CHANGE_RESOURCES:
            return { ...state, resources: action.payload };

        case ResourceActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        default:
            return state;
    };
};

//Actions
export const getResources = () => ({
    type: ResourceActionTypes.ASYNC_GET_RESOURCES
});

export const changeResources = (resources: IResource[]) => ({
    type: ResourceActionTypes.CHANGE_RESOURCES,
    payload: resources
});

export const changeLoading = (value: boolean) => ({
    type: ResourceActionTypes.CHANGE_LOADING,
    payload: value
})

export default reducer;
