import React, { useCallback } from "react";
import TitleSection from "../../components/title-section/TitleSection";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { IState } from "../../reducers/reducers";
import { changeParams, changeSetOpenModal, deleteReport, getInvoicesReports } from "../../reducers/invoices-reports.reducer";
import InvoicesReportsModal from './invoices-report-modal';
import ReportList from "../../components/reports-list/ReportList";
import { permissionKeys } from "../../utils/permissions";
import useCheckPermissions from "../../hooks/useCheckPermissions";

const InvoicesReports = () => {
    const dispatch = useDispatch();
    const checkPermissions = useCheckPermissions();

    const isLoadingGenerate = useSelector(({invoicesReports}: IState) => invoicesReports.isLoadingGenerate);
    const isLoading = useSelector(({invoicesReports}: IState) => invoicesReports.isLoading);
    const openModal = useSelector(({invoicesReports}: IState) => invoicesReports.openModal);
    const reports = useSelector(({invoicesReports}: IState) => invoicesReports.reports);
    const total = useSelector(({invoicesReports}: IState) => invoicesReports.total);

    const handleOpenModal = useCallback(() => dispatch(changeSetOpenModal(true)), [dispatch]);
    const handleCloseModal = useCallback(() => dispatch(changeSetOpenModal(false)), [dispatch]);

    return (
        <section>
            <TitleSection title={'Relatório de Notas Fiscais'} >
                <Button
                    onClick={handleOpenModal}
                    size="large"
                    loading={isLoadingGenerate}
                    type="primary"
                    hidden={!checkPermissions([permissionKeys.REPORT_INVOICES_CREATE])}
                >
                    Gerar relatório
                </Button>
            </TitleSection>
            <ReportList
                reports={reports}
                total={total}
                loading={isLoading}
                getReports={getInvoicesReports}
                deleteReport={deleteReport}
                changeParams={changeParams}
                statusSelector={({ invoicesReports }: IState) => invoicesReports.status}
                toSelector={({ invoicesReports }: IState) => invoicesReports.to}
                fromSelector={({ invoicesReports }: IState) => invoicesReports.from}
                orderSelector={({ invoicesReports }: IState) => invoicesReports.order_by}
                pageSelector={({ invoicesReports }: IState) => invoicesReports.page}
                pageSizeSelector={({ invoicesReports }: IState) => invoicesReports.pageSize}
            />
            {openModal && 
                <InvoicesReportsModal
                    loading={isLoadingGenerate}
                    onClose={handleCloseModal}
                />
            }
        </section>
    );
};

export default InvoicesReports;