import { IProfileCoacheStore, ICoache } from '../models/profile.modal';

export enum EProfileCoacheActionsTypes {
    ASYNC_GET_COACHES = 'profile/ASYNC_GET_COACHES',

    PROFILE_ISLOADING = 'profile/PROFILE_ISLOADING',
    PROFILE_COACHES = 'profile/PROFILE_COACHES',
    PROFILE_PAGE = 'profile/PROFILE_PAGE',
    PROFILE_TOTAL = 'profile/PROFILE_TOTAL',
    PROFILE_PAGE_SIZE = 'profile/PROFILE_PAGE_SIZE',
    PROFILE_SEARCH = 'profile/PROFILE_SEARCH',
    PROFILE_ISOPEN = 'profile/PROFILE_ISOPEN'
};

const INITIAL_STATE: IProfileCoacheStore = {
    coaches: [],
    isLoading: false,
    page: 1,
    pageSize: 10,
    search: '',
    total: 100,
    isOpen: false
};

export interface IAction {
    type: string,
    payload?: any
};

const reducer = ( state: IProfileCoacheStore = INITIAL_STATE, action: IAction ) => {
    switch(action.type){
        case EProfileCoacheActionsTypes.PROFILE_ISLOADING:
            return { ...state, isLoading: action.payload }
        case EProfileCoacheActionsTypes.PROFILE_COACHES:
            return { ...state, coaches: action.payload }
        case EProfileCoacheActionsTypes.PROFILE_PAGE:
            return { ...state, page: action.payload }
        case EProfileCoacheActionsTypes.PROFILE_PAGE_SIZE:
            return { ...state, pageSize: action.payload }
        case EProfileCoacheActionsTypes.PROFILE_SEARCH:
            return { ...state, search: action.payload }
        case EProfileCoacheActionsTypes.PROFILE_TOTAL:
            return { ...state, total: action.payload }
            case EProfileCoacheActionsTypes.PROFILE_ISOPEN:
                return { ...state, isOpen: action.payload }
        default:
            return state
    }
}

export default reducer;

export const asyncGetCoaches = () => ({
    type: EProfileCoacheActionsTypes.ASYNC_GET_COACHES
});

export const controlProfileIsLoading = ( value: boolean ) => ({
    type: EProfileCoacheActionsTypes.PROFILE_ISLOADING,
    payload: value
});

export const controlProfileIsOpen = ( value: boolean ) => ({
    type: EProfileCoacheActionsTypes.PROFILE_ISOPEN,
    payload: value
});

export const controlProfileCoaches = (value: ICoache[]) => ({
    type: EProfileCoacheActionsTypes.PROFILE_COACHES,
    payload: value
});

export const controlProfilePage = ( value: number ) => ({
    type: EProfileCoacheActionsTypes.PROFILE_PAGE,
    payload: value
});

export const controlProfilePageSize = ( value: number ) => ({
    type: EProfileCoacheActionsTypes.PROFILE_PAGE_SIZE,
    payload: value
});

export const controlProfileSearch = ( value: string ) => ({
    type: EProfileCoacheActionsTypes.PROFILE_SEARCH,
    payload: value
});

export const controlProfileTotal = ( value: number ) => ({
    type: EProfileCoacheActionsTypes.PROFILE_TOTAL,
    payload: value
});