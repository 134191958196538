import {useSelector} from 'react-redux';

import {IState} from '../reducers/reducers';

function useCheckPermissions() {
    const userPermissions = useSelector(({utils}: IState) => utils.permissions);

    function checkPermissions(permissions: string[] = [], atLeastOne: boolean = false): boolean {
        if (atLeastOne) {
            return permissions.some(permission => userPermissions.includes(permission));
        }

        return permissions.every(permission => userPermissions.includes(permission));
    }

    return checkPermissions;
}

export default useCheckPermissions;
