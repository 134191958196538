import React, { useRef } from 'react';
import { Collapse, Table, Button, Input } from 'antd'
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { IUrlShorteners, IErrosUrlShorteners } from '../../../models/url-shorteners.model';

import './ResultsUrlShorteners.scss';
import { controlUrlsResults, controlCleanStore, asyncGetUrls } from '../../../reducers/url-shorteners.reducer';
import { formatDate } from '../../../utils/moment.functions';

interface IProps {
    validate: (value: any) => void
}

const ResultsUrlShorteners = ({ validate }: IProps) => {

    const { urlsResults, loadingModal } = useSelector(({ urlShorteners }:IState)=>({ urlsResults: urlShorteners.urlsResults, loadingModal: urlShorteners.loadingModal}));
    const dispatch = useDispatch();
    const refButton = useRef(null);

    const columnsSuccess = [
        {
            title: 'Código',
            dataIndex: 'code',
            key: 'code'
        },
        {
            title: 'Url',
            dataIndex: 'url',
            key: 'url'
        }
    ]

    const columnsErros = [
        {
            title: 'Url',
            key: 'url',
            render: (dataUrl: IErrosUrlShorteners) => {
                return <Input value={dataUrl.url} onChange = {({ target })=>{
                    let newErros: any = [ ...urlsResults.erros ]
                    newErros[dataUrl.id].url = target.value
                    dispatch(controlUrlsResults({ ...urlsResults, success: urlsResults.success, erros: [...newErros] }));
                }}  />
            }
        },
        {
            title: 'Error',
            dataIndex: 'error',
            key: 'error'
        }
    ]

    const exportCsv = () => {
        let stringCsv = 'url,code\n';
        urlsResults.success.forEach((element: IUrlShorteners) => stringCsv+=`${element.url},${element.code}\n`)
        const data = new File([stringCsv], "urlShorteners.csv", { type: 'text/csv;charset=utf-8;' });
        const downloadUrl = window.URL.createObjectURL(data);
        if(refButton.current) {
            (refButton.current as any).href = downloadUrl as string
            (refButton.current as any).download = `UrlShorteners-${ formatDate(new Date(),'DD/MM/YYYY HH:mm')}.csv` as string
            (refButton.current as any).click();
        }
    }

    return (
        <>
            <Collapse
                className="collapse-success">
                <Collapse.Panel
                    header={`${urlsResults.success.length} urls importados com sucesso`}
                    key="1">

                    <Table columns={columnsSuccess} className="table-url-success" pagination={false} rowKey={(urlShortenersSuccess: IUrlShorteners, index)=> `${index}`} dataSource={urlsResults.success} />
                    <a className="invisible-button" href={window.location.origin} download="data.csv" ref={refButton}>ButtonInvisible</a>
                    {urlsResults.success.length > 0 &&
                        <Button 
                            type="primary" 
                            className="button-url-shorteners-resend" 
                            onClick={() => exportCsv()}>Exportar</Button>
                    }
                </Collapse.Panel>

                <Collapse.Panel
                    className="collapse-error"
                    header={`${urlsResults.erros.length} urls com erro ao tentar importar`}
                    key="2">

                    <Table columns={columnsErros} className="table-url-error" pagination={false} rowKey={(urlShortenersError: IErrosUrlShorteners, index)=> `${index}`} dataSource={urlsResults.erros} />

                    { urlsResults.erros.length > 0 && 
                        <Button className="button-url-shorteners-resend" disabled={loadingModal.isLoading} type="primary" onClick={()=> {
                            let newUrls = urlsResults.erros.map((element: IErrosUrlShorteners)=> element.url);
                            validate(newUrls.join(','));
                        } }>Reenviar</Button> 
                    }
                </Collapse.Panel>           
            </Collapse>

            <Button className="cancel-results-url-shorteners" disabled={loadingModal.isLoading} onClick={()=>{
                dispatch(controlCleanStore());
                dispatch(asyncGetUrls());
            }} >Cancelar</Button>
        </>
    )
};

export default ResultsUrlShorteners;