import React from 'react';
import './TitleSection.scss';

interface ITitleSectionProps {
    title: string,
    children?: any,
}

const TitleSection = ({ title, children }: ITitleSectionProps) => (
    <h2 className="title-section">
        <span>
            {title}
        </span>
        {children}
    </h2>
);

export default TitleSection;
