import { call, all, takeEvery, put, select, takeLatest, delay } from "redux-saga/effects";

import Notification, {
    NOTIFICATION_TYPE,
} from "../components/notification/Notification";
import { queryStringList, IUser } from "../models/user.model";
import {
    UsersActionTypes,
    changeLoading,
    changeUsers,
    changeTotal,
    changeUserEdit,
    changeLoadingSave,
} from "../reducers/users.reducer";

import {changeLoading as ChangeLoadingCoachee} from "../reducers/coachees.reducer";
import { IState } from "../reducers/reducers";

import handleError from "../utils/handleError";
import { difference, isEmpty, sanitize } from "../utils/utils.functions";
import { setISOToDayStart, setISOToDayEnd } from "../utils/moment.functions";
import { getUsers, saveUser, editUser, getUser } from "../utils/webApi";

let vector_keys_noDiff = ["address", "personal_ids", "phones"];

function* requestUsers() {
    try {
        const {
            page,
            pageSize,
            search,
            search_field,
            order,
            order_mode,
        } = yield select((state: IState) => state.users);
        yield put(changeLoading(true));
        const { total, users } = yield call(
            getUsers,
            queryStringList({
                page,
                pageSize,
                search,
                search_field,
                order,
                order_mode,
            })
        );
        yield put(changeUsers(users));
        yield put(changeTotal(total));
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    }
}

function* requestUserEdit({ payload }: any) {
    try {
        yield put(changeLoading(true));
        yield put(ChangeLoadingCoachee(true));
        const request = yield call(getUser, payload);
        yield put(changeUserEdit(request));
    } catch (err) {
        handleError(err);
    } finally {
        yield put(changeLoading(false));
        yield put(ChangeLoadingCoachee(false));
    }
}

function sanitizeUserInfo(userInfo: any) {
    // trim strings e converte strings vazias para null
    userInfo = sanitize(userInfo);

    if (typeof userInfo.birthdate !== "undefined") {
        userInfo.birthdate = setISOToDayStart(userInfo.birthdate as string);
    }

    if (userInfo.user) {
        const user: any = userInfo.user;

        if (typeof user.activation_date !== "undefined") {
            user.activation_date = setISOToDayStart(
                user.activation_date as string
            );
        }

        if (typeof user.expiration_date !== "undefined") {
            user.expiration_date = setISOToDayEnd(
                user.expiration_date as string
            );
        }
    }

    return userInfo;
}

function* requestSaveUser({ payload = {} }: any) {
    try {
        const {user, onSaveListUpdate = undefined} = payload;
        yield put(changeLoadingSave(true));

        let userToSend: IUser | any = { ...user };

        userToSend = sanitizeUserInfo(userToSend);

        if (isEmpty(userToSend)) {
            return Notification(
                NOTIFICATION_TYPE.WARNING,
                "Atenção",
                "Nenhum dado do novo user foi preenchido"
            );
        }

        if (userToSend.id) {
            let { userEdit } = yield select((state: IState) => state.users);

            userEdit = sanitizeUserInfo(userEdit);
            let diff: Partial<IUser> = difference(userToSend, userEdit);
            if (isEmpty(diff)) {
                return Notification(
                    NOTIFICATION_TYPE.WARNING,
                    "Atenção",
                    "Nenhuma alteração feita"
                );
            }

            const fullObjDiff: any = {};

            let sendFullDiff: any = Object.keys(diff).map((element: string) => {
                if (vector_keys_noDiff.includes(element)) {
                    fullObjDiff[element] = userToSend[element];
                    return true;
                }
                return false;
            });

            if (sendFullDiff.includes(true)) {
                yield call(editUser, userToSend.id, {
                    ...diff,
                    ...fullObjDiff,
                });
            } else {
                yield call(editUser, userToSend.id, diff);
            }
        } else {
            yield call(saveUser, userToSend);
        }
        yield put(changeUserEdit(null));
        // esperando um tempo a mais para evitar do backend não retornar os dados atualizados
        yield delay(1000);
        if (onSaveListUpdate) {
            yield onSaveListUpdate();
        } else {
            yield call(requestUsers);
        }
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoadingSave(false));
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(UsersActionTypes.ASYNC_GET_USERS, requestUsers),
        yield takeLatest(UsersActionTypes.CHANGE_ORDER, requestUsers),
        yield takeEvery(UsersActionTypes.ASYNC_SAVE_USER, requestSaveUser),
        yield takeEvery(UsersActionTypes.ASYNC_GET_USER, requestUserEdit),
    ]);
}
