import React from 'react';
import { Input as InputWrapper, } from 'antd';
import { TextAreaProps } from 'antd/lib/input';

interface IProps extends TextAreaProps {
    label?: string,
}

function TextArea(props: IProps) {
    return (
        <>
            {props.label && <label className="label-form">{props.label}</label>}
            <InputWrapper.TextArea {...props} />
        </>
    );
}


export default TextArea;
