import { takeEvery, all, put, call, select } from 'redux-saga/effects';
import { controlIsLoading, controlCoaching, controlTotal ,PanelcontrolCoachingActionTypes } from '../reducers/panelcontrol.coaching.reducer';
import handleError from '../utils/handleError';
import { getPanelCoaching } from '../utils/webApi';
import { IState } from '../reducers/reducers';
import { queryStringList, IRequest } from '../models/panelcontrol.coaching.model';

function* requestDataCoaching() {
    try {
        yield put(controlIsLoading(true));

        const { page, pageSize, search } = yield select(({ panelcontrolcoaching }: IState)=> panelcontrolcoaching )


        const response: IRequest = yield call(getPanelCoaching, queryStringList({page, pageSize, search}));

        yield put(controlTotal(response.total));

        yield put(controlCoaching(response.users));

    } catch (err){
        handleError(err);
    } finally {
        yield put(controlIsLoading(false))
    }
}

export default function* mySaga() {
    yield all([
        takeEvery(PanelcontrolCoachingActionTypes.ASYNC_PANELCONTROLCOACHING_GET_COACHING, requestDataCoaching)
    ])
}
