import { call, all, takeEvery, put, delay, select } from 'redux-saga/effects';
import { ImportActionsType, changeLoading, changeComponent, changePreviewInformation, changeIndexOfCoachInRequest, changeCoachesImportResult } from '../reducers/importation.reducer';
import { IImportationForm, ICoachImport, IPreviewInformation, ICoachPreview, ICoachToSave, ICoachesImportResult } from '../models/import.model';
import handleError from '../utils/handleError';
import { sendFormPreview, revalidateCoach, importCoach } from '../utils/webApi';
import { ImportationComponent } from '../containers/integrations/Integrations';
import { IState } from '../reducers/reducers';
import { cloneDeep } from 'lodash';
import Notification, { NOTIFICATION_TYPE } from '../components/notification/Notification';

function* sendImportCoachesForm({ payload }: any) {
    try {
        yield put(changeLoading(true));
        const importationPreview: IPreviewInformation = yield call(sendFormPreview, payload as IImportationForm);
        yield put(changePreviewInformation(importationPreview));
        yield put(changeComponent(ImportationComponent.PREVIEW));
    } catch (error) {
        handleError(error)
    } finally {
        yield put(changeLoading(false));
    }
};

function* changeCoachImportFromIndex(coach: ICoachPreview, index: number) {
    try {
        const previewInformation: IPreviewInformation = yield select((state: IState) => state.importation.previewInformation);
        let coaches = cloneDeep(previewInformation.coaches);
        coaches[index] = coach;
        yield put(changePreviewInformation({ ...previewInformation, coaches }));
    } catch (error) {
        throw error
    };
};

function* requestRevalidate({ payload: { coach, index } }: any) {
    try {
        yield put(changeIndexOfCoachInRequest(index))
        const coachValidated: ICoachPreview = yield call(revalidateCoach, coach as ICoachImport);
        yield call(changeCoachImportFromIndex, coachValidated, index);
        if (coachValidated.errors.length <= 0) {
            Notification(NOTIFICATION_TYPE.SUCCESS, 'Sucesso', 'Campo verificado e sem erros');
        } else {
            Notification(NOTIFICATION_TYPE.WARNING, 'Atenção', 'Campo verificado e ainda contém erros');
        }
    } catch (error) {
        handleError(error)
    } finally {
        yield put(changeIndexOfCoachInRequest(-1))
    };
};

function* checkIfHasDuplicatedEmail() {
    const { coaches }: IPreviewInformation = yield select((state: IState) => state.importation.previewInformation);
    for (let indexCoachToCheck = 0; indexCoachToCheck < coaches.length; indexCoachToCheck++) {
        for (let indexCoachToCompare = indexCoachToCheck + 1; indexCoachToCompare < coaches.length; indexCoachToCompare++) {
            if (coaches[indexCoachToCheck].coach.email === coaches[indexCoachToCompare].coach.email) {
                throw Error(`Você possui email duplicado no item ${indexCoachToCheck + 1} e ${indexCoachToCompare + 1}`);
            }
        }
    };
};

function* requestImportCoach({ payload }: any) {
    try {

        const previewInformation: IPreviewInformation = yield select((state: IState) => state.importation.previewInformation);

        yield call(checkIfHasDuplicatedEmail);
        let coachesImportResult: ICoachesImportResult |any = {
            success: [],
            error: []
        };
        const baseInfo: IPreviewInformation = { ...payload };
        delete baseInfo.coaches;

        for (let x = 0; x < payload.coaches.length; x++) {

            const coachWithBaseInfo: ICoachToSave = {
                ...baseInfo,
                coach: payload.coaches[x].coach
            };

            yield put(changeIndexOfCoachInRequest(x));

            try {
                const result = yield call(importCoach, coachWithBaseInfo);
                coachesImportResult.success.push({
                    coach: coachWithBaseInfo.coach,
                    errors: [],
                    result,
                });
            } catch (error) {
                const { data } = error.response
                coachesImportResult.error.push({
                    coach: coachWithBaseInfo.coach,
                    errors: [...previewInformation.coaches[x].errors],
                    error: data,
                });
            };
            yield delay(1000);
        };
        yield put(changeCoachesImportResult(coachesImportResult));
        yield put(changeComponent(ImportationComponent.RESULT));
    } catch (error) {
        handleError(error)
    } finally {
        yield put(changeIndexOfCoachInRequest(-1));
    };
};

export default function* MySaga() {
    yield all([
        yield takeEvery(ImportActionsType.ASYNC_SEND_FORM_PREVIEW, sendImportCoachesForm),
        yield takeEvery(ImportActionsType.ASYNC_REVALIDATE, requestRevalidate),
        yield takeEvery(ImportActionsType.ASYNC_IMPORT_COACHES, requestImportCoach),
    ]);
}