export interface ICenter {
    city: string,
    country: string,
    id: string,
    name: string,
    state: string,
};

export interface ICountry {
    model: AddressModel
    name: string
    url: string
};


export interface ITimezone {
    code: string
    id: string
    name: string
};

export interface IStates {
    abbrev: string
    cities: ICity[]
    name: string
};

export interface ILocale {
    code: string
    name: string
};

export interface ICity {
    name: string
};

export enum AddressModel {
    STATES_CITIES = "states-cities",
    CITIES = "cities"
};

export interface ILicenseTypes {
    active: boolean
    created: string
    diasAdicionais: number
    duracao: number
    edited: string
    id: string
    model: LicenseModel
    name: string
    recorrente: boolean
};

enum LicenseModel {
    MODEL_LEGADO = "LEGADO",
    MODEL_EXPERIMENTAL = "EXPERIMENTAL",
    MODEL_GRATUIDADE = "GRATUIDADE",
    MODEL_PAGAVEL = "PAGAVEL",
};

export enum Permissions {
    COACHES = "coaches",
    COMERCIAL = "comercial",
    CONTENT = "content",
    PAYMENTS = "payments",
    REPORT = "report",
    SYSTEM = "system",
};
