import { Button, InputNumber, Select, Switch, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropertiesCategories from "../../../components/properties-categories/PropertiesCategories";
import { ICategory } from "../../../models/categories.model";
import { getParents } from "../../../reducers/categories.reducer";
import { IState } from "../../../reducers/reducers";

import "./CategoriesEdit.scss";

const { Option } = Select;
const { TabPane } = Tabs;

interface ICategoriesEditProps {
    isEdit: boolean,
    onOk(category: ICategory, isEdit: boolean): void,
    onCancel(): void,
    loading: boolean,
    category: ICategory,
}

const CategoriesEdit = ({ isEdit, onCancel, onOk, loading, category }: ICategoriesEditProps) => {

    const initialState  = {
        properties_en: {
            name: "",
            description: "",
            thumbnailUrl: "",
          },
          properties_pt: {
            name: "",
            description: "",
            thumbnailUrl: "",
          },
          properties_es: {
            name: "",
            description: "",
            thumbnailUrl: "",
          },
    };

    const dispatch = useDispatch();
    
    const { parents } = useSelector((state: IState) => state.categories);
    const [catEdit, setCatEdit] = useState<ICategory>({...initialState, ...category});

    useEffect(() => {
        if(!isEdit) {
            dispatch(getParents());
            const item = {...catEdit};
            setCatEdit(item as ICategory);
        };
    // eslint-disable-next-line
    },[]);

    const handleVideo = (catEdit: ICategory) => {
        let item = {...catEdit};

        if (catEdit["properties_en"].name === "") {
                item = {...item, "properties_en": undefined};
        }
        if (catEdit["properties_es"].name === "") {
                item = {...item, "properties_es": undefined};
        }
        onOk(item, isEdit)
    };

    const onChangeChecked = (checked: boolean) => {
        const key = "active";
        setCatEdit({...catEdit, [key]: checked} as ICategory);
    };
    
    function onChangeCategoryEdit(key: string) {
        let item = {...catEdit};   
        const parent: string = "parent"
        
        item = {...item, [parent]: key}
        setCatEdit(item as ICategory);
    };

    function onChangeIndex(value: number | undefined) {
        let item = {...catEdit};
        const key: string = "index";
        item = {...item, [key]: value};
        setCatEdit(item as ICategory);
    };

    return (
        <form>
            <Tabs defaultActiveKey="1">
                <TabPane
                        key="1"
                        tab={<span>{"Categoria"}</span>}
                >
                    { !isEdit &&
                        <Select
                            className="input-filter"
                            mode="default"
                            placeholder="Parente"
                            maxTagCount={1}
                            onChange={onChangeCategoryEdit}
                        >
                            {parents.map(({name, id}: any) => 
                                (<Option key={id} value={id}>
                                    {name}
                                </Option>)
                            )}
                        </Select>
                    }
                    <div className="index-enable">
                        <div className="index">
                            <label>Index</label>
                            <InputNumber
                                value={catEdit.index}
                                onChange={onChangeIndex}
                                placeholder={"Index"}
                                min={1}
                                max={1000000000000}
                                defaultValue={1}
                                style={{ display: "flex", alignItems: "center", width: "100%", height: "32px"}}                  
                            />
                        </div>
                        <div className="checkbox-categories">
                            <Switch
                                defaultChecked
                                checked={catEdit.active}
                                onChange={onChangeChecked}
                                />
                            <span> Habilitar categoria</span>
                        </div>
                    </div>
                </TabPane>
                <TabPane
                    key="2"
                    tab={<span>{"Propriedades PT"}</span>}
                >
                    <PropertiesCategories
                        isEdit={isEdit}
                        types={"properties_pt"}
                        currentEdit={catEdit}
                        setCurrentEdit={setCatEdit}
                    />
                </TabPane>

                <TabPane
                    key="3"
                    tab={<span>{"Propriedades ES"}</span>}
                >
                    <PropertiesCategories
                        isEdit={isEdit}     
                        types={"properties_es"}
                        currentEdit={catEdit}
                        setCurrentEdit={setCatEdit}
                    />
                </TabPane>

                <TabPane
                    key="4"
                    tab={<span>{"Propriedades EN"}</span>}>
                    <PropertiesCategories
                        isEdit={isEdit}
                        types={"properties_en"}
                        currentEdit={catEdit}
                        setCurrentEdit={setCatEdit}
                    />
                </TabPane>
            </Tabs>
           
            <div className="buttons-bottom">
                <Button
                    className="btn-cancel"
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={!(catEdit["properties_pt"].name) || Object.keys(catEdit).length === 0}
                    type="primary"
                    onClick={() => handleVideo(catEdit)}
                >
                    {loading ? "Salvando..." : "Salvar"}
                </Button>
            </div>
        </form>
    )
};

export default CategoriesEdit;