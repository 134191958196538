import React from 'react';
import { Form, Button, Icon, Input } from 'antd';
import { useSelector } from 'react-redux';
import { ILoosePaymentStore } from '../../../reducers/loose-payment.reducer';
import './SearchFilter.scss';

interface IReportSearchFilterProps {
    onChangeFilter: (value?: string) => void;
    onSearch: () => void;
    email?: string;
};

const SearchFilterModal = ({
    onChangeFilter,
    onSearch,
    email,
}: IReportSearchFilterProps) => {
    const loadingCoach = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.loadingCoach);

    return (
        <Form className="filter-content-email">
            <div className="input-filter">
                <label>E-mail usado no SCIS</label>
                <Input
                    value={email}
                    className="search-input-email"
                    placeholder="Buscar por e-mail"
                    onChange={({ target }) => onChangeFilter(target.value)}
                    suffix={<Icon type="close-circle" onClick={() => onChangeFilter('')}/>}
                />
            </div>

            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                disabled={!email}
                >
                    {loadingCoach ? <Icon type='loading' /> : 'Consultar'}
            </Button>
        </Form>
    )
};

export default SearchFilterModal;
