import { IReport } from "../models/report.model";

export enum IMonthlyCoachingActionTypes {
    CHANGE_LOADING = '@monthly-coaching/CHANGE_LOADING',
    SET_OPEN_MODAL = '@monthly-coaching/SET_OPEN_MODAL',
    CHANGE_LOADING_GENERATE = '@monthly-coaching/CHANGE_LOADING_GENERATE',
    CHANGE_PARAMS = '@monthly-coaching/CHANGE_PARAMS',
    CHANGE_MONTHLY_COACHING_REPORTS = '@monthly-coaching/CHANGE_MONTHLY_COACHING_REPORTS',
    POLLING_FOR_STATUS = '@monthly-coaching/POLLING_FOR_STATUS',

    ASYNC_GENERATE_MONTHLY_COACHING_REPORT = '@monthly-coaching/ASYNC_GENERATE_MONTHLY_COACHING_REPORT',
    ASYNC_GET_MONTHLY_COACHING_REPORTS = '@monthly-coaching/ASYNC_GET_MONTHLY_COACHING_REPORTS',
    ASYNC_DELETE_REPORT = '@monthly-coaching/ASYNC_DELETE_REPORT',
};

export interface IMonthlyCoachingAction {
    type: IMonthlyCoachingActionTypes,
    payload: any,
};

export interface IMonthlyCoachingReportsStore {
    reports: IReport[],
    isLoading: boolean,
    openModal: boolean,
    isLoadingGenerate: boolean,
    page: number,
    pageSize: number,
    total: number,
    order_by: string,
    status: string | null,
    statusGenerate: string | null |undefined,
    to: string,
    from: string,
    initialData: string | undefined,
    endData: string | undefined,
};

const INITIAL_STATE: IMonthlyCoachingReportsStore = {
    reports: [],
    isLoading: false,
    openModal: false,
    isLoadingGenerate: false,
    page: 1,
    pageSize: 10,
    total: 0,
    order_by: 'asc',
    status: null,
    statusGenerate: undefined,
    to: '',
    from: '',
    initialData: undefined,
    endData: undefined,
};

export const reducer = (state: IMonthlyCoachingReportsStore = INITIAL_STATE, action: IMonthlyCoachingAction): IMonthlyCoachingReportsStore => {
    switch (action.type) {
        case IMonthlyCoachingActionTypes.CHANGE_MONTHLY_COACHING_REPORTS:
            return { ...state, reports: action.payload };

        case IMonthlyCoachingActionTypes.CHANGE_LOADING:
            return {...state, isLoading: action.payload};

        case IMonthlyCoachingActionTypes.CHANGE_LOADING_GENERATE:
            return { ...state, isLoadingGenerate: action.payload };

        case IMonthlyCoachingActionTypes.SET_OPEN_MODAL:
            return {...state, openModal: action.payload}

        case IMonthlyCoachingActionTypes.CHANGE_PARAMS:
            return {...state, ...action.payload};

        default:
            return state;
    }
};

export const changeReports = (reports: IReport[]) => ({
    type: IMonthlyCoachingActionTypes.CHANGE_MONTHLY_COACHING_REPORTS,
    payload: reports,
});

export const changeLoading = (value: boolean) => ({
    type: IMonthlyCoachingActionTypes.CHANGE_LOADING,
    payload: value
});

export const changeLoadingGenerate = (value: boolean) => ({
    type: IMonthlyCoachingActionTypes.CHANGE_LOADING_GENERATE,
    payload: value
});

export const changeSetOpenModal = (openModal: boolean) => ({
    type: IMonthlyCoachingActionTypes.SET_OPEN_MODAL,
    payload: openModal
});

export const changeParams = (params: {}) => ({
    type: IMonthlyCoachingActionTypes.CHANGE_PARAMS,
    payload: params,
});

export const deleteReport = (reportId: string) => ({
    type: IMonthlyCoachingActionTypes.ASYNC_DELETE_REPORT,
    payload: reportId,
});

export const generateMonthlyCoachingReports = () => ({
    type: IMonthlyCoachingActionTypes.ASYNC_GENERATE_MONTHLY_COACHING_REPORT,
});

export const getMonthlyCoachingReports = () => ({
    type: IMonthlyCoachingActionTypes.ASYNC_GET_MONTHLY_COACHING_REPORTS,
});

export const reportsPolling = () => ({
    type: IMonthlyCoachingActionTypes.POLLING_FOR_STATUS,
});

export default reducer;