import { InputNumber, Select, Switch } from "antd";
import React from "react";
import VideoUpload from "../../../../components/video-upload/VideoUpload";
import { IVideo } from "../../../../models/video.model";
import { SpecsOptions } from "../../../../utils/utils.constants";
import MaskedDatePicker from "../../../../components/masked-date-picker/masked-date-picker";

import "./SelectVideo.scss"

const { Option } = Select;

interface IPropsSelect {
    isEdit: boolean,
    options: string[],
    currentEdit: IVideo,
    setCurrentEdit: (video:IVideo) => void,
};

const SelectVideo = ({isEdit, options, currentEdit, setCurrentEdit}: IPropsSelect) => {

    function onChangeChecked (checked: boolean) {
        const key = "active";
        setCurrentEdit({...currentEdit, [key]: checked} as IVideo);
    };

    function onChangeCategory(key: string) {
        let item = {...currentEdit};
        const parent: string = "categoryId";
        item = {...item, [parent]: key};

        setCurrentEdit(item as IVideo);
    };

    function onChangePermissions(key: string[]) {
        let item = {...currentEdit};
        const parent: string = "scopes";
        item = {...item, [parent]: key};

        setCurrentEdit(item as IVideo);
    };

    function onChangeDate(key: string, value: string) {
        let item = {...currentEdit};
        item = {...item, [key]: value};

        setCurrentEdit(item as IVideo);
    };

    function onChangeDays(value: number | undefined) {
        let item = {...currentEdit};
        const key: string = "daysRequiredToWatch";
        item = {...item, [key]: value};

        setCurrentEdit(item as IVideo);
    };

    function onChangeIndex(value: number | undefined) {
        let item = {...currentEdit};
        const key: string = "index";
        item = {...item, [key]: value};
        setCurrentEdit(item as IVideo);
    };

    return (
        <div className="form-group-input">
            <Select
                className="input-filter"
                mode="default"
                placeholder="Categoria"
                maxTagCount={1}
                value={currentEdit.categoryId || undefined}
                onChange={onChangeCategory}
            >
                {options.map(( value: any ) => (
                    <Option key={value.id} value={value.id}>
                        {value.name}
                    </Option>
                ))}
            </Select>
            <Select
                className="input-filter"
                mode="multiple"
                allowClear
                placeholder="Permissão"
                maxTagCount={1}
                value={currentEdit.scopes || undefined}
                onChange={onChangePermissions}
            >
                {SpecsOptions.map(({ label, value }) => (
                    <Option key={value} value={value}>
                        {label}
                    </Option>
                ))}
            </Select>
            <div className="block-2">
                <div style={{width: "40%"}}>
                    <label style={{display: "grid", textAlign: "center"}}>Data de expiração</label>
                    <div className="data-Picker">
                        <MaskedDatePicker
                            onChange={onChangeDate}
                            currentValue={(currentEdit.dateExpiration) ? currentEdit.dateExpiration : null}
                            stringType={"dateExpiration"}
                            classText="enrollment-date"
                            placeholder="DD/MM/AAAA"
                        />
                    </div>
                </div>

                <div style={{width: "40%"}}>
                    <label style={{display: "grid", textAlign: "center"}}>Data de liberação</label>
                    <div className="data-Picker">
                        <MaskedDatePicker
                            minDate={new Date().toISOString()}
                            onChange={onChangeDate}
                            currentValue={(currentEdit.dateRelease) ? currentEdit.dateRelease : null}
                            stringType={"dateRelease"}
                            classText="enrollment-date"
                            placeholder="DD/MM/AAAA"
                        />
                    </div>
                </div>
            </div>

            <div className="block-3">
                <div className="input-days">
                    <label>Dias de licença</label>
                    <InputNumber
                        value={currentEdit.daysRequiredToWatch}
                        onChange={onChangeDays}
                        placeholder={"Quantidade de dias"}
                        style={{ display: "flex", alignItems: "center", width: "100%", height: "32px"}}
                    />
                </div>
                <div className="input-days">
                    <label>Index</label>
                    <InputNumber
                        value={currentEdit.index}
                        onChange={onChangeIndex}
                        placeholder={"Index"}
                        min={1}
                        max={1000000000000}
                        defaultValue={1}
                        style={{ display: "flex", alignItems: "center", width: "100%", height: "32px"}}
                    />
                </div>
            </div>

            <div className="uploads">
                <div className="checkbox-videos">
                    <Switch
                        defaultChecked
                        checked={currentEdit.active}
                        onChange={onChangeChecked}
                    />
                    <span> Habilitar Video</span>
                </div>
            </div>


            <div className="uploads">
                <VideoUpload
                    currentEdit={currentEdit}
                    disabled={isEdit}
                    setCurrentEdit={setCurrentEdit}
                />
            </div>
        </div>
    );
};

export default SelectVideo;
