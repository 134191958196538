import { IContactStore, IMessage } from '../models/contact.modal';

export enum ContactActionTypes {
    ASYNC_GET_MESSAGE = '@contact/ASYNC_GET_MESSAGE',
    ASYNC_CONTACT_US_MESSAGE_COMPLET = "@contact/ASYNC_CONTACT_US_MESSAGE_COMPLET",
    CONTACT_US_MESSAGE = '@contact/CONTACT_US_MESSAGE',
    CONTACT_US_SEARCH = '@contact/CONTACT_US_SEARCH',
    CONTACT_US_ISLOADING = '@contact/CONTACT_US_ISLOADIND',
    CONTACT_US_MESSAGE_COMPLET = '@contact/CONTACT_US_MESSAGE_COMPLET',
    CONTACT_US_TOTAL = '@contact/CONTACT_US_TOTAL',
    CONTACT_US_PAGE = '@contact/CONTACT_US_PAGE',
    CONTACT_US_PAGE_SIZE = '@contact/CONTACT_US_PAGE_SIZE'
}

export interface IContactAction {
    type: ContactActionTypes,
    payload: any
};


const INITIAL_STATE: IContactStore = {
    messages: [],
    messageComplet: {},
    isLoading: true,
    search: '',
    total: 0,
    page: 1,
    pageSize: 10,
}

export const reducer = (state: IContactStore = INITIAL_STATE, action: IContactAction ) => {
    switch(action.type){
        case ContactActionTypes.CONTACT_US_MESSAGE:
            return { ...state, messages: action.payload }
        case ContactActionTypes.CONTACT_US_SEARCH:
            return { ...state, search: action.payload }
        case ContactActionTypes.CONTACT_US_ISLOADING:
            return { ...state, isLoading: action.payload }
        case ContactActionTypes.CONTACT_US_MESSAGE_COMPLET:
            return { ...state, messageComplet: action.payload }
        case ContactActionTypes.CONTACT_US_PAGE:
            return { ...state, page: action.payload }
        case ContactActionTypes.CONTACT_US_TOTAL:
            return { ...state, total: action.payload }
        case ContactActionTypes.CONTACT_US_PAGE_SIZE:
            return { ...state, pageSize: action.payload }
        default:
            return state
    }
}

export const controlContactUsTotal = (value: number) => ({
    type: ContactActionTypes.CONTACT_US_TOTAL,
    payload: value
})

export const controlContactUsPage = (value: number) => ({
    type: ContactActionTypes.CONTACT_US_PAGE,
    payload: value
})

export const controlContactUsPageSize = (value: number) => ({
    type: ContactActionTypes.CONTACT_US_PAGE_SIZE,
    payload: value
})

export const controlContactUsSearch = (value: string) => ({
    type: ContactActionTypes.CONTACT_US_SEARCH,
    payload: value
})

export const getContactUsMessage = () => ({
    type: ContactActionTypes.ASYNC_GET_MESSAGE
})

export const getContactUsMessageComplet = (id: number) => ({
    type: ContactActionTypes.ASYNC_CONTACT_US_MESSAGE_COMPLET,
    payload: id
})

export const controlContactUsMessage = (value: IMessage[]) => ({
    type: ContactActionTypes.CONTACT_US_MESSAGE, 
    payload: value 
})

export const controlContactUsMessageComplet = (value: IMessage) => ({
    type: ContactActionTypes.CONTACT_US_MESSAGE_COMPLET, 
    payload: value 
})

export const controlIsLoading = (value: boolean) => ({
    type: ContactActionTypes.CONTACT_US_ISLOADING,
    payload: value
})

export default reducer;