import React, { useState } from 'react';
import Search from 'antd/lib/input/Search';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { asyncReadCode, controlPageList, controlPage, controlIsOpen } from '../../../reducers/url-shorteners.reducer';

import './UrlShortenersFilter.scss';

const UrlShortenersFilter = () => {
    const [ search, setSearch ] = useState("")
    const dispatch = useDispatch();

    const dispatchActions = () => {
        dispatch(controlPageList([null]));
        dispatch(controlPage(0));
        dispatch(asyncReadCode({ code: search}));
    }
    return (
        <div className='container-url-shorteners'>
            <div className='container-search' >
                <Search
                    value={search}
                    className='search-input'
                    placeholder='Pesquisar'
                    onSearch={()=>dispatchActions()}
                    onChange={({ target }) => setSearch(target.value)} />
                <Button 
                    onClick={()=>dispatchActions()} 
                    className='btn-search' 
                    type='primary'
                    icon='search'> 
                    Pesquisar
                </Button>
            </div>
            
            <Button
                type='primary'
                onClick={()=> dispatch(controlIsOpen(true))}>
                ADICIONAR
            </Button>
        </div>
    )
};

export default UrlShortenersFilter;