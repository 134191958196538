import { Button, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropertiesVideos from "../../../components/properties-videos/PropertiesVideos";
import { IVideo } from "../../../models/video.model";
import { getParents } from "../../../reducers/categories.reducer";
import { IState } from "../../../reducers/reducers";
import SelectVideo from "./select-video/SelectVideo";

import "./VideoEdit.scss";

const { TabPane } = Tabs;
interface IVideoEditProps {
    isEdit: boolean,
    onCancel(): void,
    onOk(video: IVideo, isEdit: boolean): void,
    loading: boolean,
    video: IVideo,
};

const initialState  = {
    properties_pt: {
      title: "",
      thumbnailUrl: "",
      description: "",
      attachments: [],
    },
    properties_es: {
      title: "",
      thumbnailUrl: "",
      description: "",
      attachments: [],
    },
    properties_en: {
      title: "",
      thumbnailUrl: "",
      description: "",
      attachments: [],
    },
};

const VideoEdit = ({isEdit, onCancel, onOk, video, loading}: IVideoEditProps) => {

    const dispatch = useDispatch();

    const { parents } = useSelector(({categories}: IState) => ({ parents: categories.parents }));
    const [vidEdit, setVidEdit] = useState<IVideo>({...initialState, ...video});

    useEffect(() => {
        if(!isEdit) {
            dispatch(getParents());
            const item = {...vidEdit};
            setVidEdit(item as IVideo);
        };
    // eslint-disable-next-line
    },[]);

    const handleVideo = (vidEdit: any) => {
        let item = {...vidEdit};

        if (vidEdit["properties_en"].title === '') {
            item = {...item, "properties_en": undefined};
        }
        if (vidEdit["properties_es"].title === '') {
            item = {...item, "properties_es": undefined};
        }
        onOk(item, isEdit);
    };

    return (
        <form>
            <Tabs defaultActiveKey="1">
                <TabPane
                    key="1"
                    tab={<span>{"Video"}</span>}
                >
                    <SelectVideo
                        isEdit={isEdit}
                        options={parents}
                        currentEdit={vidEdit}
                        setCurrentEdit={setVidEdit}
                    />
                </TabPane>

                <TabPane
                    key="2"
                    tab={<span>{"Propriedades PT"}</span>}
                >
                    <PropertiesVideos
                        isEdit={isEdit}
                        types={"properties_pt"}
                        currentEdit={vidEdit}
                        setCurrentEdit={setVidEdit}
                    />
                </TabPane>

                <TabPane
                    key="3"
                    tab={<span>{"Propriedades ES"}</span>}
                >
                    <PropertiesVideos
                        isEdit={isEdit}
                        types={"properties_es"}
                        currentEdit={vidEdit}
                        setCurrentEdit={setVidEdit}
                    />
                </TabPane>

                <TabPane
                    key="4"
                    tab={<span>{"Propriedades EN"}</span>}>
                    <PropertiesVideos
                        isEdit={isEdit}
                        types={"properties_en"}
                        currentEdit={vidEdit}
                        setCurrentEdit={setVidEdit}
                    />
                </TabPane>
            </Tabs>

            <div className="buttons-bottom">
                <Button
                    className="btn-cancel"
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
                <Button
                    disabled={!(vidEdit["properties_pt"].title) || Object.keys(vidEdit).length === 0}
                    type="primary"
                    onClick={() => handleVideo(vidEdit)}
                >
                    {loading ? "Salvando..." : "Salvar"}
                </Button>
            </div>
        </form>
    );
};

export default VideoEdit;
