import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import ContactFilter from './contact-filter/ContactFilter';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers/reducers';
import Loading from '../../components/loading/Loading';
import ContactList from './contact-list/ContactList';

const ContactUs = () => {
    const isLoading = useSelector(({ contact }: IState )=> contact.isLoading);

    return (
        <>
            <TitleSection title="FALE CONOSCO" />

            { isLoading && <Loading/>}

            <ContactFilter /> 
            
            <ContactList />
        </>
    );
}
export default ContactUs;