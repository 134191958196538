import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Tabs, Button } from "antd";

import { IUser } from "../../../models/user.model";
import ProfileForm from "./profile-form/ProfileForm";
import { PasswordMode } from "../../../models/user.model";

import AddressForm from "./address-form/AddressForm";
import { IState } from "../../../reducers/reducers";
import PersonalIDs from "./personal-ids/PersonalIDs";
import PhoneNumbersForm from "./phone-numbers-form/PhoneNumbersForm";
import PasswordForm from './password-form/PasswordForm';

import "./UserEdit.scss";

const { TabPane } = Tabs;

interface IUserEditProps {
    user: IUser;
    isEdit: boolean;
    onOk(user: IUser): void;
    onCancel(): void;
    loading: boolean;
}

const defaultPassword = `febracis${new Date().getFullYear()}`;

const UserEdit = ({
    user,
    isEdit,
    onOk,
    onCancel,
    loading,
}: IUserEditProps) => {
    const [userEdit, setUserEdit] = useState<IUser>(user);
    const { countries, timezones, locales } = useSelector(
        ({ utils }: IState) => utils
    );

    useEffect(() => {
        if (!isEdit) {
            const item = {
                ...userEdit,
                password: defaultPassword,
                password_mode: PasswordMode.PERM,
            };
            setUserEdit(item as IUser);
        }
        // eslint-disable-next-line
    }, []);

    function onChangeUserEdit(
        key?: string,
        value?: any,
        key2?: string,
        value2?: any
    ) {
        let item = { ...userEdit };
        let changed = false;

        if (key) {
            item = { ...item, [key]: value };
            changed = true;
        }

        if (key2) {
            item = { ...item, [key2]: value2 };
            changed = true;
        }

        if (changed === true) {
            setUserEdit(item as IUser);
        }
    }

    return (
        <form className="user-edit-container">
            {isEdit ? (
                <>
                    <Tabs defaultActiveKey="1">
                        <TabPane key="1" tab={<span>{"Perfil"}</span>}>
                            <ProfileForm
                                timezones={timezones}
                                locales={locales}
                                onChange={onChangeUserEdit}
                                userEdit={userEdit}
                            />
                        </TabPane>

                        <TabPane key="2" tab={<span>{"Endereço"}</span>}>
                            <AddressForm
                                countries={countries}
                                onChange={onChangeUserEdit}
                                userEdit={userEdit}
                            />
                        </TabPane>

                        <TabPane key="3" tab={<span>{"Telefones"}</span>}>
                            <PhoneNumbersForm
                                onChange={onChangeUserEdit}
                                userEdit={userEdit}
                            />
                        </TabPane>

                        <TabPane key="4" tab={<span>{"IDs"}</span>}>
                            <PersonalIDs
                                onChange={onChangeUserEdit}
                                userEdit={userEdit}
                            />
                        </TabPane>
                        <TabPane
                            key="5"
                            tab={<span>{'Senha'}</span>}>
                            <PasswordForm
                                onChange={onChangeUserEdit}
                                userEdit={userEdit}
                                isEdit={true} />
                        </TabPane>
                    </Tabs>
                </>
            ) : (
                <></>
            )}

            <div className="buttons-bottom">
                <Button className="btn-cancel" onClick={onCancel}>
                    Cancelar
                </Button>
                <Button
                    disabled={loading}
                    type="primary"
                    onClick={() => onOk(userEdit)}
                >
                    {loading ? "Salvando..." : "Salvar"}
                </Button>
            </div>
        </form>
    );
};

export default UserEdit;
