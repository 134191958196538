import React from 'react';
import Input from '../../../../components/Input/Input';
import { useDispatch } from 'react-redux';
import { asyncGetUserInfo } from '../../../../reducers/create-coache.reducer';
import { Button } from 'antd';

import './CoacheEmail.scss'
interface IProps{
    onChange(name: string, value: any): void,
    coachEdit: any
}

const CoacheEmail = ({
    coachEdit,
    onChange,
}: IProps) => {

    const dispatch = useDispatch();

    return ( <>
        <Input
            key={'email-request'}
            className='email-request'
            value={coachEdit.email} 
            onKeyPress={(event)=> {
                if(event.key === 'Enter') event.preventDefault();
                if(event.key === 'Enter' && (coachEdit && coachEdit.email)){
                    dispatch(asyncGetUserInfo(coachEdit.email));
                }
            }} 
            onChange={({ target }) => onChange('email', target.value ? target.value.trim() : target.value)} 
            label={'email'} 
        />
        <Button className='button-email-request' disabled={(!(coachEdit && coachEdit.email))} type='primary' onClick={()=> dispatch(asyncGetUserInfo(coachEdit.email))} > Continue </Button>

        </> )
};

export default CoacheEmail;