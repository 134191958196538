/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import TitleSection from '../../components/title-section/TitleSection';
import PaymentList from './payment-list/PaymentList';
import { Button, Modal, Icon } from 'antd';
import PaymentCreate from './payment-create/PaymentCreate';
import handleError from '../../utils/handleError';
import { listPayments, createPayment, cancelPayment, getCoach, listAllPayments } from '../../utils/webApi';
import { IListPayments, IPayment, IPaymentToCreate, PaymentTypes } from '../../models/payment.model';
import { setISOToDayEnd, setISOToDayStart } from '../../utils/moment.functions';
import PaymentCancel from './payment-cancel/PaymentCancel';
import { routes } from '../../utils/routers.constants';
import { Link } from 'react-router-dom';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import { permissionKeys } from '../../utils/permissions';

import './Payments.scss';
import { history } from '../../config/Store.config';
import SearchFilter from './search-filter/SearchFilter';
import moment from 'moment-timezone';
import { asyncRequestXslxCoachesPayments } from '../../reducers/coaches.reducer';
import { useDispatch } from 'react-redux';

const Payments = ({ match }: RouteComponentProps) => {
    const dispatch = useDispatch();
    const [loadingXlsx, setLoadingXlsx] = useState(false);
    const [total, setTotal] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [from, setFrom] = useState<string|undefined>(moment().subtract(30, 'days').toISOString());
    const [search, setSearch] = useState<string|undefined>();
    const [typesFields, changeTypeFields] = useState<PaymentTypes[]>([]);
    const [to, setTo] = useState<string|undefined>(moment().endOf('day').toISOString());
    const [pageSize, setPageSize] = useState<number>(10);
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const [payments, setPayments] = useState<IPayment[]>([]);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [paymentToCancel, setPaymentToCancel] = useState<IPayment|undefined>();
    const [reloadPayments, setReloadPayment] = useState<boolean>(false);
    const [idCoach, setIdCoach] = useState<string|undefined>((match.params as any).id);
    const [ user, setUser ] = useState({} as any );
    const checkPermissions = useCheckPermissions();

    const getPayments = useCallback(async function(cPage: number, cPageSize: number, cSearch?:string, cFrom?:string, cTo?:string, cTypesFields: PaymentTypes[] = [], noLoading: boolean = false){
        try {
            setLoading(noLoading ? false : true);
            const data = (idCoach
                    ? await listPayments(idCoach)
                    : await listAllPayments({page: cPage, pageSize: cPageSize, from: cFrom, to: cTo, search: cSearch, types: cTypesFields})
                ) as IListPayments;
            const payments = data.payments;
            if (idCoach) {
                const { nickname, email } = await getCoach(idCoach);
                setUser({ nickname, email });
            }
            setPayments(payments);
            setTotal(idCoach ? payments.length : data.total);
        } catch (error) {
            handleError(error)
        } finally {
            setLoading(false);
        }
    }, [idCoach, page, pageSize]);

    useEffect(() => {
        getPayments(page, pageSize, search, from, to, typesFields);
        //fetch payments with ID
    }, [idCoach, reloadPayments]);

    useEffect(() => {
        const params = match.params as {id?: string};
        setIdCoach(params.id);
    }, [match.params])

    useEffect(() => {
        if (!idCoach) {
            getPayments(page, pageSize, search, from, to, typesFields);
        }
    }, [page, pageSize, getPayments, idCoach]);

    function onSearch(noLoading = false) {
        setPage(1);
        getPayments(1, pageSize, search, from, to, typesFields, noLoading);
    }


    function onChangeFilter(key: string, value?: string) {
        switch(key) {
            case 'from':
                setFrom(value || undefined);
                break;
            case 'to':
                setTo(value || undefined);
                break;
            case 'search':
                setSearch(value || undefined);
                break;
            default:
                break;
        }
    }

    function onReloadPayment() {
        setReloadPayment(!reloadPayments);
    }

    async function onCreatePayment(payment: IPaymentToCreate) {
        if (idCoach) {
            try {
                setLoading(true);
                setOpenCreate(false);
                payment.dataAtivacao = setISOToDayStart(payment.dataAtivacao);
                payment.dataExpiracao = setISOToDayEnd(payment.dataExpiracao);
                if (!payment.transactionId) {
                    payment.transactionId = "";
                }
                const res = await createPayment(idCoach, payment);
                setPayments([...payments, res.payment]);
            } catch (error) {
                handleError(error);
            } finally {
                setLoading(false);
            }
        }
    }

    async function onCancelPayment(body: any) {
        const payment_id = paymentToCancel ? paymentToCancel.id : undefined;
        const coachId = paymentToCancel ? paymentToCancel.coachId : idCoach;
        if (payment_id && coachId) {
            setPaymentToCancel(undefined);
            try {
                setLoading(true);
                const { payment } = await cancelPayment(coachId, payment_id, body);
                let clonePayments = [...payments];
                let index = clonePayments.findIndex((payment) => payment.id === payment_id);
                clonePayments[index] = payment;
                setPayments(clonePayments);
            } catch (error) {
                handleError(error);
            } finally {
                setLoading(false);
            }
        }
    };

    function requestXslxCoachesPayments() {
        setLoadingXlsx(true);
        dispatch(asyncRequestXslxCoachesPayments({
            from,
            to,
            search,
            types: typesFields,
            callback: () => setLoadingXlsx(false),
        }));
    }

    return (
        <section>
            {
                idCoach &&
                    <Link className="link-redirect-coaches" to={routes.COACHES} onClick={(e) => {
                        e.preventDefault();
                        history.goBack();
                    }}>
                        <Icon style={{ marginRight: 10 }} type="arrow-left" />
                        Voltar
                    </Link>
            }

            <TitleSection title={idCoach ? (user && user.email) ? `Pagamentos de ${user.email}` : 'Pagamentos' : 'Pagamentos'}>
                <div>
                    <Button
                        style={{ marginRight: 10 }}
                        onClick={onReloadPayment}
                        size="default"
                        type="link"
                    >
                        Recarregar Pagamentos
                    </Button>
                    {
                        idCoach && <Button
                            onClick={() => setOpenCreate(true)}
                            size="large"
                            type="primary"
                            hidden={!checkPermissions([permissionKeys.PAYMENTS, permissionKeys.USER_PAYMENT_CREATE])}
                        >
                                Adicionar Pagamento
                        </Button>
                    }
                </div>
            </TitleSection>

            {!idCoach && 
                (
                    <SearchFilter
                        typesFields={typesFields}
                        changeTypeFields={changeTypeFields}
                        onChangeFilter={onChangeFilter}
                        onSearch={onSearch}
                        search={search}
                        to={to}
                        from={from}
                        loadingXlsx={loadingXlsx}
                        requestXslxCoachesPayments={requestXslxCoachesPayments}
                    />
                )}

            <PaymentList
                onCancelPayment={setPaymentToCancel}
                isLoading={isLoading}
                payments={payments}
                checkPermissions={checkPermissions}
                page={page}
                pageSize={pageSize}
                idCoach={idCoach}
                total={total}
                onChangePage={setPage}
                onChangePageSize={setPageSize}
            />


            {openCreate &&
                <Modal
                    onCancel={() => setOpenCreate(false)}
                    footer={false}
                    title="Adicionar Pagamento"
                    maskClosable={false}
                    visible={true}>

                    <PaymentCreate
                        payments={payments}
                        createPayment={onCreatePayment}
                    />
                </Modal>
            }
            {
                Boolean(paymentToCancel) && <Modal
                    onCancel={() => setPaymentToCancel(undefined)}
                    title="Cancelar Pagamento"
                    footer={false}
                    visible={true}>

                    <PaymentCancel
                        cancelPayment={onCancelPayment}
                    />
                </Modal>
            }
        </section >
    )
};

export default Payments;
