import React from 'react';
import {Button} from 'antd';

import Input from '../../../../components/Input/Input';
import {useDispatch} from 'react-redux';
import {asyncGetUserInfo} from '../../../../reducers/create-admin.reducer';
import {FormTypeValue, IAdmin} from '../../../../models/administrator.model';

import './AdminEmail.scss'

interface IAdminEmailProps{
    onChange(name: string, value: FormTypeValue): void,
    adminToEdit: IAdmin
}

function AdminEmail({adminToEdit, onChange}: IAdminEmailProps) {
    const dispatch = useDispatch();

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') event.preventDefault();

        if (event.key === 'Enter' && adminToEdit.email){
            dispatch(asyncGetUserInfo(adminToEdit.email));
        }
    }

    return (
        <>
            <Input
                key='email-request'
                className='email-request'
                value={adminToEdit.email} 
                onKeyPress={handleKeyPress} 
                onChange={({target}) => onChange('email', target.value ? target.value.trim() : target.value)} 
                label='email' 
            />
            <Button
                className='button-email-request'
                disabled={(!(adminToEdit && adminToEdit.email))}
                type='primary'
                onClick={()=> dispatch(asyncGetUserInfo(adminToEdit.email))}
            >
                Continue
            </Button>
        </>
    );
};

export default AdminEmail;