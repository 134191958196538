import React from "react";
import { Button, Form, Select } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { changeFilterStatuses, changeNextPage, changePreviousPage, changeSearchValue, fetchSalesRegistrations } from "../../../reducers/sales-registrations.reducer";
import Search from "antd/lib/input/Search";

import "./SearchFilter.scss";
import { SellerStatus } from "../../../utils/utils.constants";

const { Option } = Select;

const StatusOptions: { label: string, value?: string }[] = [
    { label: 'Pendente', value: SellerStatus.Pending },
    { label: 'Aguardando análise', value: SellerStatus.Wait },
    { label: 'Em análise', value: SellerStatus.Analysis },
    { label: 'Aguardando assinatura', value: SellerStatus.WaitSigned },
    { label: 'Aguardando correção', value: SellerStatus.WaitCorrection },
    { label: 'Assinado', value: SellerStatus.Signed },
    { label: 'Aprovado', value: SellerStatus.Approved },
    { label: 'Rejeitado', value: SellerStatus.Rejected },
];


const SearchFilter = () => {
    const { search, filter_statuses } = useSelector(({ salesRegistrations }: any) => ({
        filter_statuses: salesRegistrations.filter_statuses,
        search: salesRegistrations.search,
    }));

    const dispatch = useDispatch();
    const onSearch = () => {
        dispatch(changePreviousPage([undefined]));
        dispatch(changeNextPage(undefined));
        dispatch(fetchSalesRegistrations(true));
    };

    return (
        <Form className="filter-content2">
            <div className="input-filter">
                <label>Buscar</label>
                <Search
                    value={search}
                    className="search-input"
                    placeholder="Buscar"
                    style={{ width: 250 }}
                    onSearch={onSearch}
                    onChange={({ target }) => dispatch(changeSearchValue(target.value))} />
            </div>

            <div className="input-filter">
                <label>Filtrar Por Status</label>
                <Select
                    mode="default"
                    placeholder="Todos"
                    allowClear={true}
                    maxTagCount={1}
                    value={filter_statuses && filter_statuses.length > 0 ? filter_statuses : undefined}
                    onChange={(statuses: string) => dispatch(changeFilterStatuses(statuses))}>
                    {StatusOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}

                </Select>
            </div>
            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search">
                Pesquisar
            </Button>
        </Form>
    )
}

export default SearchFilter;