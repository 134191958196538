/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import PaymentList from './payment-list/PaymentList';
import { Button, Modal } from 'antd';
import PaymentCreate from './payment-create/PaymentCreate';
import { useDispatch, useSelector } from 'react-redux';
import { asyncGetFilterPayment, asyncGetLoosePayments, clearStates, generateReport, ILoosePaymentStore, setEndDate, setStartDate } from '../../reducers/loose-payment.reducer';
import LoosePaymentFilter from './loose-payment-filter/SearchFilter';

const LoosePayments = () => {
    const [openCreate, setOpenCreate] = useState<boolean>(false);
    const dispatch = useDispatch();
    const loosePayments = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.payments);
    const isLoading = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.isLoading);
    const total = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.total);
    const pageSize = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.pageSize);
    const currentPage = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.page);
    const loadingReport = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.loadingReport);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState<string|undefined>('');
    const [status, setStatus] = useState<string|undefined>('');

    const startDate = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.startDate);
    const endDate = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.endDate);

    useEffect(() => {
        if(!name && !email && !type && !status && !startDate && !endDate ) {
            dispatch(asyncGetLoosePayments(currentPage, pageSize));
        } else {
            paymentFilter();
        }
    }, [currentPage, pageSize,]);

    function handleCloseModal () {
        setOpenCreate(false);
        dispatch(clearStates());
    };

    function paymentFilter() {
        dispatch(asyncGetFilterPayment({name, email, type, status, startDate, endDate}));
    };

    function onChangeFilter(key: string, value?: string | null) {
        switch(key) {
            case 'from':
                dispatch(setStartDate(value || null));
                break;
            case 'to':
                dispatch(setEndDate(value || null));
                break;
            default:
                break;
        }
    }

    function generateLoosePaymentsReport() {
        dispatch(generateReport({name, email, type, status, startDate, endDate}))
    }

    return (
        <section>
            <TitleSection title="Pagamentos Avulsos">
                <div>
                    <Button
                        onClick={() => setOpenCreate(true)}
                        size="large"
                        type="primary"
                    >
                        Novo pagamento avulso
                    </Button>
                </div>
            </TitleSection>

            <LoosePaymentFilter
                isLoading={isLoading}
                name={name}
                email={email}
                status={status}
                type={type}
                loadingReport={loadingReport}
                from={startDate}
                to={endDate}
                setName={setName}
                setEmail={setEmail}
                search={paymentFilter}
                onChangeFilter={onChangeFilter}
                setStatus={setStatus}
                setType={setType}
                generateLoosePaymentsReport={generateLoosePaymentsReport}
            />

            <PaymentList
                isLoading={isLoading}
                payments={loosePayments}
                page={currentPage}
                pageSize={pageSize}
                total={total}
            />

            {openCreate &&
                <Modal
                    onCancel={handleCloseModal}
                    footer={false}
                    title="Adicionar Pagamento Avulso"
                    maskClosable={false}
                    visible={true}
                    width={780}
                >
                    <PaymentCreate
                        setOpenCreate={setOpenCreate}
                    />
                </Modal>
            }
        </section >
    );
};

export default LoosePayments;
