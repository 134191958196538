import { call,all, takeEvery, put, select, takeLatest, delay } from 'redux-saga/effects';
import {
    CoachingEvaluationsReportsActionTypes,
    changeLoading,
    changeReports,
    changeLoadingGenerate,
    reportsPolling,
    changeParams,
    changeSetOpenModal,
 } from '../reducers/coaching-evaluations-reports.reducer';

 import { ReportStatus, IChangedReports, IReport } from '../models/report.model';
import { deleteReport, getCoachingEvaluationsReports, generateCoachingEvaluationsReport } from '../utils/webApi';
import handleError from '../utils/handleError';
import { IState } from '../reducers/reducers';
import { isEmpty } from '../utils/utils.functions';
import { requestUserData } from '../reducers/reports.reducer';

function* requestEvaluationsReports() {
    try {
        const { status, order_by, page, pageSize, to, from } = yield select((state: IState) => state.coachingEvaluationsReports);
        yield put(changeLoading(true));
        const { reports, total } = yield call(getCoachingEvaluationsReports, {
            status: status ? [status] : undefined,
            page,
            pageSize,
            order_by,
            to: to ? new Date(to) : undefined,
            from: from ? new Date(from) : undefined
        });

        yield put(changeReports(reports));
        yield put(changeParams({total}));

        yield put(reportsPolling());
        yield put(requestUserData());
    } catch (error) {
        handleError(error);
    } finally {
        yield put(changeLoading(false));
    };
};

function* requestGenerateEvaluationsReport() {
    try {
        yield put(changeLoadingGenerate(true));
        const { reports, pageSize, isPolling, total, extra_args } = yield select((state: IState) => state.coachingEvaluationsReports);
        const report: IReport = yield call(generateCoachingEvaluationsReport, {year: parseInt(extra_args.year) || undefined});

        const newReports = [{...report, isLocal: true}, ...reports].slice(0, pageSize);

        yield put(changeReports(newReports));
        yield put(changeParams({total: total + 1}));
        yield put(changeSetOpenModal(false));

        if(!isPolling) {
            yield put(reportsPolling());
        };

    } catch (err) {
        handleError(err);
    } finally {
        yield put(changeLoadingGenerate(false));
    };
};

function* pollingEvaluationsForStatus() {
    try {
        const processingKeys = [ReportStatus.CREATED, ReportStatus.PROCESSING];
        yield delay(1000);
        const { reports } = yield select((state: IState) => state.coachingEvaluationsReports);
        const processingLocalReports = reports.filter((r: IReport) => processingKeys.includes(r.status));

        if(processingLocalReports.length > 0) {
            const response: {reports: IReport[]} = yield call(getCoachingEvaluationsReports, {status: processingKeys, page:1, pageSize: 1000});
            let changedReports: IChangedReports = {};
            for (let report of processingLocalReports) {
                const rep = response.reports.find((r: IReport) => r.id === report.id);

                if(rep && rep.status !== report.status) {
                    changedReports = {
                        ...changedReports,
                        [rep.id]: rep.status,
                    }
                } else if(!rep && !report.isLocal) {
                    changedReports = {
                        ...changedReports,
                        [report.id]: ReportStatus.READY,
                    };
                };
            };

            if(!isEmpty(changedReports)) {
                yield put(changeReports(reports.map((r: IReport) => ({
                    ...r,
                    isLocal: false,
                    status: changedReports[r.id] || r.status,
                }))));
            };
            yield put(reportsPolling());
        };
    } catch (err) {
        handleError(err);
    };
};

function* requestDelete({payload}: {type: string, payload: string}) {
    const { reports, total } = yield select((state: IState) => state.coachingEvaluationsReports);
    try {
        const filteredReports = reports.filter((el :IReport) => el.id !== payload);
        yield put(changeReports(filteredReports));
        yield call(deleteReport, payload);
        yield put(changeParams({total: total - 1}));

    } catch (err) {
        yield put(changeReports(reports));
        handleError(err);
    };
};

export default function* MySaga(): any {
    yield all([
        yield takeEvery(CoachingEvaluationsReportsActionTypes.ASYNC_GET_COACHING_EVALUATIONS_REPORTS, requestEvaluationsReports),
        yield takeLatest(CoachingEvaluationsReportsActionTypes.ASYNC_GENERATE_COACHING_EVALUATIONS_REPORT, requestGenerateEvaluationsReport),
        yield takeLatest(CoachingEvaluationsReportsActionTypes.POLLING_FOR_STATUS, pollingEvaluationsForStatus),
        yield takeEvery(CoachingEvaluationsReportsActionTypes.ASYNC_DELETE_REPORT, requestDelete),
    ]);
};
