import { IVideo } from "../models/video.model";
// Types
export enum VideosActionTypes {
    ASYNC_SAVE_VIDEO = "@videos/ASYNC_SAVE_VIDEO",
    ASYNC_NEW_VIDEO = "@videos/ASYNC_NEW_VIDEO",
    ASYNC_GET_VIDEOS = "@videos/ASYNC_GET_VIDEOS",

    CHANGE_VIDEO_MODAL = "@videos/CHANGE_VIDEO_MODAL",
    CHANGE_VIDEO = "@videos/CHANGE_VIDEO",
    CHANGE_PAGE_VIDEOS = "@videos/CHANGE_PAGE_VIDEOS",
    SET_PAGE_LIST = "@videos/SET_PAGELIST",
    CHANGE_SEARCH_VALUE = "@videos/CHANGE_SEARCH_VALUE",
    CHANGE_FILTER_SCOPES = "@videos/CHANGE_FILTER_SCOPES",
    CHANGE_ORDER = "@videos/CHANGE_ORDER",
    CHANGE_LOADING_SAVE = "@videos/CHANGE_LOADING_SAVE",
    CHANGE_LOADING = "@videos/CHANGE_LOADING",
    CHANGE_FILTER_CATEGORY = "@videos/CHANGE_FILTER_CATEGORY",
    ASYNC_ENABLE_DISABLE_VIDEO = "@videos/ASYNC_ENABLE_DISABLE_VIDEO",
};

// Interfaces
export interface IVideosStore {
    videos: IVideo[],
    videoModal: Partial<IVideo> | null,
    page: number,
    pageSize: number,
    pageList: string[] | undefined[] | null[],
    search: string,
    order: string,
    order_mode: string,
    filter_videos: string[],
    scope: string[],
    isLoadingSave: boolean,
    isLoading: boolean,
};

export interface VideosAction {
    type: VideosActionTypes,
    payload: any,
};

// Reducer
const INITIAL_STATE: IVideosStore = {
    videos: [],
    videoModal: null,
    page: 0,
    pageList: [undefined],
    pageSize: 10,
    search: '',
    order: 'title',
    order_mode: 'asc',
    filter_videos: [],
    scope: [],
    isLoadingSave: false,
    isLoading: false,

};

export const reducer = (state: IVideosStore = INITIAL_STATE, action: VideosAction): IVideosStore => {
    switch(action.type) {
        case VideosActionTypes.CHANGE_VIDEO_MODAL:
            return { ...state, videoModal: action.payload };
    
        case VideosActionTypes.CHANGE_VIDEO:
            return {...state, videos: action.payload };

        case VideosActionTypes.CHANGE_PAGE_VIDEOS:
            return { ...state, page: action.payload };

        case VideosActionTypes.SET_PAGE_LIST:
            return { ...state, pageList: action.payload };

        case VideosActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };

        case VideosActionTypes.CHANGE_FILTER_CATEGORY:
            return { ...state, filter_videos: action.payload };

        case VideosActionTypes.CHANGE_FILTER_SCOPES:
            return { ...state, scope: action.payload };

        case VideosActionTypes.CHANGE_ORDER:
            return { ...state, order: action.payload.order, order_mode: action.payload.order_mode };

        case VideosActionTypes.CHANGE_LOADING_SAVE:
            return { ...state, isLoadingSave: action.payload };

        case VideosActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        default:
            return state;
        };  
};

//Actions
export const getVideos = () => ({
    type: VideosActionTypes.ASYNC_GET_VIDEOS,
});

export const setPageList = (pageList: string[] | undefined[] | null[]) => ({
    type: VideosActionTypes.SET_PAGE_LIST,
    payload: pageList,
});

export const changeVideo = (video: IVideo[]) => ({
    type: VideosActionTypes.CHANGE_VIDEO,
    payload: video
});

export const changeVideoModal = (videos: Partial<IVideo> | null) => ({
    type: VideosActionTypes.CHANGE_VIDEO_MODAL,
    payload: videos
});

export const changePageVideos = (page: number) => ({
    type: VideosActionTypes.CHANGE_PAGE_VIDEOS,
    payload: page
});

export const changeSearchValue = (value: string) => ({
    type: VideosActionTypes.CHANGE_SEARCH_VALUE,
    payload: value
});

export const saveVideoApi = (video: IVideo) => ({
    type: VideosActionTypes.ASYNC_SAVE_VIDEO,
    payload: video
});

export const saveNewVideo = (video: IVideo) => ({
    type: VideosActionTypes.ASYNC_NEW_VIDEO,
    payload: video
});

export const changeFilterVideos = (filter_videos: string[]) => ({
    type: VideosActionTypes.CHANGE_FILTER_CATEGORY,
    payload: filter_videos,
});

export const changeFilterScopes = (scope: string[]) => ({
    type: VideosActionTypes.CHANGE_FILTER_SCOPES,
    payload: scope,
});

export const changeOrder = (order: string, order_mode: string) => ({
    type: VideosActionTypes.CHANGE_ORDER,
    payload: { order, order_mode }
});

export const changeLoadingSave = (value: boolean) => ({
    type: VideosActionTypes.CHANGE_LOADING_SAVE,
    payload: value
});

export const changeLoading = (value: boolean) => ({
    type: VideosActionTypes.CHANGE_LOADING,
    payload: value
});

export const enableDisableVideo = (video: IVideo, active: boolean) => ({
    type: VideosActionTypes.ASYNC_ENABLE_DISABLE_VIDEO,
    payload: {video, active},
});

export default reducer;