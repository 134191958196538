import React from 'react';
import { Steps } from 'antd';
import './steps.scss';

const { Step } = Steps;

export interface IStep {
    title: string,
    description: string
}

interface IProps{
    onChange: (number: number)=> void,
    current: number,
    steps: IStep[],
    isChange: boolean,
    classType?: string
}

const StepsComponent = ({
    onChange,
    current,
    steps,
    isChange,
    classType
}: IProps) => {
    return (
        <Steps className={`container-steps ${classType}`} current={current} {...{ onChange: isChange ? onChange  : ()=>{} }} >
            { steps.map((e: IStep, index: number )=> ( <Step key={index} title={e.title} description={e.description} /> )) }
        </Steps>
    )
};

export default StepsComponent;