import React, { useState } from 'react';
import { IResource } from '../../../models/resources.model';
import { Form, Input } from 'antd';

import './ResourceEdit.scss';

interface IProps {
    resource: Readonly<IResource>
}

type InputChange = React.ChangeEvent<HTMLInputElement>

const ResourceEdit = ({ resource }: IProps) => {

    const [formValue, setFormValue] = useState<IResource>(resource);

    function onChangeProperties({ target }: InputChange) {
        setFormValue({
            ...formValue, ...{ Properties: { ...formValue.Properties, [target.id]: target.value } }
        })
    };

    return (
        <Form className="resource-edit-form">
            <Input
                id="Name"
                value={formValue.Properties.Name}
                onChange={onChangeProperties}
                placeholder="Name" />

            <Input
                id="Description"
                value={formValue.Properties.Description}
                onChange={onChangeProperties}
                placeholder="Description" />

            <Input
                id="Author"
                value={formValue.Properties.Author}
                onChange={onChangeProperties}
                placeholder="Author" />

            <Input
                id="Link"
                value={formValue.Properties.Link}
                onChange={onChangeProperties}
                placeholder="Link" />

            <Input
                id="Synopse"
                value={formValue.Properties.Synopse}
                onChange={onChangeProperties}
                placeholder="Synopse" />

            <Input
                id="PublicationDate"
                value={formValue.Properties.PublicationDate}
                onChange={onChangeProperties}
                placeholder="PublicationDate" />


        </Form>
    );
};

export default ResourceEdit;