import { IPanelControlStore, IDashboardData } from '../models/panelcontrol.model';

export enum PanelControlActioTypes {
	ASYNC_GET_PANELCONTROL = '@panelcontrol/ASYNC_GET_PANELCONTROL',
    PANELCONTROL_ISLOADING = '@panelcontrol/PANELCONTROL_ISLOADING',
	PANELCONTROL_PANELCONTROL = '@panelcontrol/PANELCONTROL_PANELCONTROL',
	ASYNC_GET_PANELCONTROLCOACHES = '@panelchoachees/ASYNC_GET_PANELCONTROLCOACHES',
	PANELCONTROL_PANELCONTROLCOACHES = '@panelchoachees/PANELCONTROL_PANELCONTROLCOACHES'
};

export interface IContactAction {
    type: PanelControlActioTypes,
    payload: any
};

const INITIAL_STATE: IPanelControlStore = {
	panelControl: [],
	panelControlCoaches: [],
	isLoading: false
};

const reducer = (state:IPanelControlStore=INITIAL_STATE, action: IContactAction) => {
	switch (action.type){
		case PanelControlActioTypes.PANELCONTROL_ISLOADING:
			return { ...state, isLoading: action.payload};
        case PanelControlActioTypes.PANELCONTROL_PANELCONTROL:
			return { ...state, panelControl: action.payload }
		case PanelControlActioTypes.PANELCONTROL_PANELCONTROLCOACHES:
			return { ...state, panelControlCoaches: action.payload }
		default:
			return state;
	};
};

export default reducer;

export const panelcontrolIsLoading = (value: Boolean) => ({
	type: PanelControlActioTypes.PANELCONTROL_ISLOADING,
	payload: value
})

export const controlPanelcontrol = (value: IDashboardData[][]) => ({
    type: PanelControlActioTypes.PANELCONTROL_PANELCONTROL,
    payload: value
})

export const asyncGetPanelcontrol = () => ({
	type: PanelControlActioTypes.ASYNC_GET_PANELCONTROL
})

export const asyncGetPanelcontrolCoaches = () => ({
	type: PanelControlActioTypes.ASYNC_GET_PANELCONTROLCOACHES
})

export const controlPanelcontrolCoaches = (value: IDashboardData[][]) => ({
    type: PanelControlActioTypes.PANELCONTROL_PANELCONTROLCOACHES,
    payload: value
})