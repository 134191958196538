/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import { Select, Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './SearchFilterPanelClasses.scss';
import { IClass  } from '../../../models/coache.model';
import { IState } from '../../../reducers/reducers';
import { asyncFilter, changeFilterClass, changeFilterUnit } from '../../../reducers/panelcontrolclasses.reducer';

const { Option } = Select;

interface ISearch {
    classes?: IClass[],
}

const SearchFilterPanelClasses = ({classes}: ISearch) => {
    const [searchTerm, setSearchTerm] = React.useState('');
    const { allUnits, filterUnit, filterClass } = useSelector(({ panelcontrolclasses }: IState)=> panelcontrolclasses);

    const dispatch = useDispatch();

    const onSearch = () => {
        dispatch(asyncFilter());
    };

    const filteredAndSearchedClasses = React.useMemo(() => {
        return classes ? classes.filter(({ className }) =>
            className.toLowerCase().includes(searchTerm.toLowerCase())
        ) : [];
    }, [classes, searchTerm]);

    return (
        <Form className="filter-content2">

            <div className="input-filter">
                <label>Filtrar Por Turmas</label>
                <Select
                    mode="multiple"
                    className="filter-class"
                    placeholder="Todas"
                    allowClear
                    maxTagCount={1}
                    value={filterClass && filterClass.length > 0 ? filterClass : undefined}
                    onSearch={setSearchTerm}
                    filterOption={false}
                    showSearch={true}
                    onChange={(filter_class: string[]) => {
                        setSearchTerm('');
                        dispatch(changeFilterClass(filter_class));
                    }}>
                    {filteredAndSearchedClasses.map(({ className, id }) => (
                        <Option key={id} value={id}>
                            {className}
                        </Option>
                    ))}
                </Select>
            </div>

            <div className="input-filter">
                <label>Filtrar Por Unidade</label>
                <Select
                    className="select-filter"
                    placeholder="Todas"
                    value={filterUnit}
                    onChange={(field: string) => dispatch(changeFilterUnit(field))}>

                    {allUnits && allUnits.map(({ id, name }) => (
                        <Option key={id} value={id}>
                            {name}
                        </Option>
                    ))}
                </Select>
            </div>

            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search">
                Pesquisar
            </Button>

        </Form>
    )
};

export default SearchFilterPanelClasses;
