import React, { useEffect, useState } from "react";
import { Table, Button, Tag, Pagination } from "antd";
import { routes } from "../../../utils/routers.constants";
import { history } from '../../../config/Store.config';
import { ISalesRegistrationItem } from "../../../models/sales-registration.model";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { contractStatusTranslate } from "../../../utils/utils.constants";
import { useDispatch, useSelector } from "react-redux";
import { fetchSalesRegistrations, toogleFieldOrder } from "../../../reducers/sales-registrations.reducer";
import { IState } from "../../../reducers/reducers";
import SearchFilter from "../search-filter/SearchFilter"

import "./SalesRegistrationsList.scss";
import { formatDateWithHours } from "../../../utils/moment.functions";

interface ISalesRegistrationsListProps extends RouteComponentProps {
    isLoading: boolean;
    salesRegistrations: ISalesRegistrationItem[];
}

const totalPaginationSize = 100000000;

const SalesRegistrationsList = ({ isLoading, salesRegistrations }: ISalesRegistrationsListProps) => {
    const nextPage = useSelector(({ salesRegistrations }: IState) => salesRegistrations.nextPage);
    const previousPage = useSelector(({ salesRegistrations }: IState) => salesRegistrations.previousPage);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);
    const [totalControllerPagination, setTotalControllerPagination] = useState(totalPaginationSize);
    const dispatch = useDispatch();

    const parser = {
        'ascend': 'LAST',
        'descend': 'NEW',
    }

    function toggleFieldOrder(field: string, order: string) {
        dispatch(toogleFieldOrder({
            field,
            order: parser[order as keyof typeof parser],
        }))
        dispatch(fetchSalesRegistrations(false));
    }

    function handleDetail(record: ISalesRegistrationItem) {
        const detailPath = routes.SALES_REGISTRATION_DETAIL(record.id);
        history.push({
            pathname: detailPath,
            state: { detail: record }
        });
    }

    const columns = [
        { title: "E-mail do Coach", dataIndex: "email", key: "coachEmail" },
        { title: "Nome do Responsável", dataIndex: "responsibleName", key: "responsibleName" },
        { title: "E-mail do Responsável", dataIndex: "responsibleEmail", key: "responsibleEmail" },
        { title: "CNPJ da Empresa", dataIndex: "companyCNPJ", key: "companyCNPJ" },
        { title: "Razão Social", dataIndex: "companyInfo.corporateReason", key: "companyName" },
        {
            title: 'Data e hora da criação',
            dataIndex: 'createdAt',
            key: 'createdAt',
            sorter: true,
            width: 220,
            render: (createdAt: string) => createdAt ? formatDateWithHours(createdAt) : ''
        },
        {
            title: "Status do Cadastro", dataIndex: "status", key: "status",
            render: (text: keyof typeof contractStatusTranslate) => {
                const displayText = contractStatusTranslate[text] || "Unknown";
                return <Tag className={`report-status ${text}`}>{displayText}</Tag>;
            }
        },
        {
            title: "Ação", key: "action",
            render: (_: any, record: ISalesRegistrationItem) => (
                <Button type="primary" onClick={() => handleDetail(record)}>Detalhes</Button>
            )
        }
    ];

    const transformedData = salesRegistrations.map(item => ({
        ...item,
        responsibleName: item.companyInfo.name,
        responsibleEmail: item.companyInfo.email,
        companyCNPJ: item.companyInfo.documentValue,
        corporateReason: item.companyInfo.corporateReason,
        status: item.status,
    }));

    const currentItems = transformedData;
    const handleChangePage = (page: number) => {
        if (isLoading) {
            return
        }
        dispatch(fetchSalesRegistrations(currentPage < page && nextPage !== "lastPage" ? true : false));

        const lastPage = previousPage.length -1;
        if (nextPage === "lastPage") {
            setCurrentPage(lastPage);
        } else {
            setCurrentPage(page);
        }
    };

    useEffect(() => {
        if (nextPage === 'lastPage') {
            setTotalControllerPagination(1);
        } else {
            setTotalControllerPagination(totalPaginationSize);
        }
    }, [currentPage, nextPage]);

    return (
        <>
            <SearchFilter />
            <Table
                className="table-sales-registrations"
                loading={isLoading}
                columns={columns}
                dataSource={currentItems.map(item => ({ ...item, key: item.id }))}
                pagination={false}
                sortDirections={['ascend', 'descend', 'ascend']}
                onChange={(pagination, filters, sorter) => toggleFieldOrder(sorter.field, sorter.order)}
            />
            <Pagination
                current={currentPage}
                pageSize={itemsPerPage}
                total={totalControllerPagination}
                className='pagination'
                onChange={handleChangePage}
                style={{ marginTop: '16px', textAlign: 'right' }}
                showSizeChanger={false}

            />
        </>
    );
};
export default withRouter(React.memo(SalesRegistrationsList));
