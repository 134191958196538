import { IClass, ICoachByLicenseType, ICoachByStatus, IPanelControlClassesStore, IUnit } from '../models/panelcontrolClasses.model';
import { scisUnitID } from '../utils/utils.constants';

export enum PanelControlClassesActioTypes {
	ASYNC_GET_FILTER_BY_CLASS_AND_UNIT = '@panelcontrolclasses/ASYNC_GET_FILTER_BY_CLASS_AND_UNIT',
	ASYNC_GET_UNITS = '@panelcontrolclasses/ASYNC_GET_UNITS',
	ASYNC_GET_CLASSES = '@panelcontrolclasses/ASYNC_GET_CLASSES',
	SET_TOTAL_COACHES = '@panelcontrolclasses/SET_TOTAL_COACHES',
	SET_COACHES_ACCESS_SCIS = '@panelcontrolclasses/SET_COACHES_ACCESS_SCIS',
	SET_COACHES_BY_STATUS = '@panelcontrolclasses/SET_COACHES_BY_STATUS',
	SET_COACHES_BY_LICENSE_TYPE = '@panelcontrolclasses/SET_COACHES_BY_LICENSE_TYPE',
	SET_UNITS = '@panelcontrolclasses/SET_UNITS',
    SET_CLASS = '@panelcontrolclasses/SET_CLASS',
    CHANGE_FILTER_UNIT = '@panelcontrolclasses/CHANGE_FILTER_UNIT',
    CHANGE_FILTER_CLASS = '@panelcontrolclasses/CHANGE_FILTER_CLASS',
    SET_ISLOADING = '@panelcontrolclasses/SET_ISLOADING',
};

export interface IContactAction {
    type: PanelControlClassesActioTypes,
    payload: any
};

const INITIAL_STATE: IPanelControlClassesStore = {
	coachesTotal: 0,
	coachesAccessSCIS: 0,
    coachesByStatus: [],
    coachesByLicenseType: [],
    allClasses: [],
    allUnits: [],
    filterUnit: scisUnitID,
    filterClass: [],
	isLoading: true,
};

const reducer = (state:IPanelControlClassesStore=INITIAL_STATE, action: IContactAction) => {
	switch (action.type){
        case PanelControlClassesActioTypes.SET_ISLOADING:
			return { ...state, isLoading: action.payload};
		case PanelControlClassesActioTypes.SET_TOTAL_COACHES:
			return { ...state, coachesTotal: action.payload};
        case PanelControlClassesActioTypes.SET_COACHES_ACCESS_SCIS:
            return { ...state, coachesAccessSCIS: action.payload}
        case PanelControlClassesActioTypes.SET_COACHES_BY_STATUS:
            return { ...state, coachesByStatus: action.payload}
        case PanelControlClassesActioTypes.SET_COACHES_BY_LICENSE_TYPE:
            return { ...state, coachesByLicenseType: action.payload}
        case PanelControlClassesActioTypes.SET_CLASS:
            return {...state, allClasses: action.payload}
        case PanelControlClassesActioTypes.SET_UNITS:
            return { ...state, allUnits: action.payload};
        case PanelControlClassesActioTypes.CHANGE_FILTER_UNIT:
            return { ...state, filterUnit: action.payload};
        case PanelControlClassesActioTypes.CHANGE_FILTER_CLASS:
            return { ...state, filterClass: action.payload};
		default:
			return state;
	};
};

export default reducer;

export const setIsLoading = (value: Boolean) => ({
	type: PanelControlClassesActioTypes.SET_ISLOADING,
	payload: value
});

export const setCoachesTotal = (value: number) => ({
	type: PanelControlClassesActioTypes.SET_TOTAL_COACHES,
	payload: value
});

export const setCoacheAccessesSCIS = (value: number) => ({
	type: PanelControlClassesActioTypes.SET_COACHES_ACCESS_SCIS,
	payload: value
});

export const setCoachesByStatus = (value: ICoachByStatus[]) => ({
    type: PanelControlClassesActioTypes.SET_COACHES_BY_STATUS,
    payload: value
});

export const setCoachesByLicenseType = (value: ICoachByLicenseType[]) => ({
    type: PanelControlClassesActioTypes.SET_COACHES_BY_LICENSE_TYPE,
    payload: value
});

export const setUnits = (units: IUnit[]) => ({
    type: PanelControlClassesActioTypes.SET_UNITS,
    payload: units
});

export const setClasses = (classes: IClass[]) => ({
    type: PanelControlClassesActioTypes.SET_CLASS,
    payload: classes
});

export const changeFilterUnit = (filterUnit: string) => ({
    type: PanelControlClassesActioTypes.CHANGE_FILTER_UNIT,
    payload: filterUnit,
});

export const changeFilterClass = (filterClass: string[]) => ({
    type: PanelControlClassesActioTypes.CHANGE_FILTER_CLASS,
    payload: filterClass
});

export const asyncFilter = () => ({
	type: PanelControlClassesActioTypes.ASYNC_GET_FILTER_BY_CLASS_AND_UNIT
});

export const asyncGetAllUnits = () => ({
	type: PanelControlClassesActioTypes.ASYNC_GET_UNITS
});

export const asyncGetAllClasses = () => ({
	type: PanelControlClassesActioTypes.ASYNC_GET_CLASSES
});



