import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Input, Modal, Checkbox } from 'antd';
import MaskedDatePicker from '../../components/masked-date-picker/masked-date-picker';
import { asyncSetNotification } from '../../reducers/notificationsList.reducer';
import { ERealms, EScopes, IPostNotification } from '../../models/notificationsList.model';
import { Editor, EditorState, Modifier, RichUtils } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

interface INewNotificationModal {
    closeModal: () => void;
    recipient?: string;
}

interface IFormValues {
    title: string;
    expireDate: string | null;
}

export default function NewNotificationModal({
    closeModal,
    recipient = 'GLOBAL_NOTIFICATION',
}: INewNotificationModal) {
    const dispatch = useDispatch();
    const maxMessageLength = 500;
    const [scopes, setScopes] = useState<EScopes[]>([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [formValues, setFormValues] = useState<IFormValues>({
        title: '',
        expireDate: null,
    });

    function handleUpdatePlatform(platform: EScopes) {
        if (scopes.includes(platform)) {
            const removedScope = scopes.filter((value) => value !== platform);
            setScopes(removedScope);
        } else {
            setScopes([...scopes, platform]);
        }
    }

    function handleChangeExpireDate(_: string, value: any) {
        setFormValues({ ...formValues, expireDate: value });
    }

    function handleChangeTextSelection(evt: React.MouseEvent, inlineStyle: string) {
        evt.preventDefault();
        setEditorState(
            RichUtils.toggleInlineStyle(editorState, inlineStyle)
        );
    }

    function handleEditorState(value: EditorState) {
        setEditorState(value);
    }

    function getLengthOfSelectedText() {
        const currentSelection = editorState.getSelection();
        const isCollapsed = currentSelection.isCollapsed();

        let length = 0;

        if (!isCollapsed) {
            const currentContent = editorState.getCurrentContent();
            const startKey = currentSelection.getStartKey();
            const endKey = currentSelection.getEndKey();
            const startBlock = currentContent.getBlockForKey(startKey);
            const isStartAndEndBlockAreTheSame = startKey === endKey;
            const startBlockTextLength = startBlock.getLength();
            const startSelectedTextLength =
                startBlockTextLength - currentSelection.getStartOffset();
            const endSelectedTextLength = currentSelection.getEndOffset();
            const keyAfterEnd = currentContent.getKeyAfter(endKey);
            if (isStartAndEndBlockAreTheSame) {
                length +=
                    currentSelection.getEndOffset() -
                    currentSelection.getStartOffset();
            } else {
                let currentKey = startKey;

                while (currentKey && currentKey !== keyAfterEnd) {
                    if (currentKey === startKey) {
                        length += startSelectedTextLength + 1;
                    } else if (currentKey === endKey) {
                        length += endSelectedTextLength;
                    } else {
                        length +=
                            currentContent
                                .getBlockForKey(currentKey)
                                .getLength() + 1;
                    }

                    currentKey = currentContent.getKeyAfter(currentKey);
                }
            }
        }

        return length;
    }

    function handleBeforeInput(text: string, state: EditorState) {
        const totalLength = state.getCurrentContent().getPlainText().length + text.length;
        return totalLength > maxMessageLength ? 'handled' : 'not-handled';
    }

    function addPastedContent(input: any, editorState: EditorState) {
        const inputLength = editorState.getCurrentContent().getPlainText().length;
        let remainingLength = maxMessageLength - inputLength;
        const newContent = Modifier.insertText(
            editorState.getCurrentContent(),
            editorState.getSelection(),
            input.slice(0, remainingLength)
        );

        setEditorState(EditorState.push(editorState, newContent, 'insert-characters'));
    }

    function removeSelection() {
        const selection = editorState.getSelection();
        const startKey = selection.getStartKey();
        const startOffset = selection.getStartOffset();
        const endKey = selection.getEndKey();
        const endOffset = selection.getEndOffset();
        if (startKey !== endKey || startOffset !== endOffset) {
            const newContent = Modifier.removeRange(
                editorState.getCurrentContent(),
                selection,
                'forward'
            );
            const tempEditorState = EditorState.push(
                editorState,
                newContent,
                'remove-range'
            );
            setEditorState(tempEditorState);
            return tempEditorState;
        }
        return editorState;
    }

    function handlePastedText(pastedText: any) {
        const currentContent = editorState.getCurrentContent();
        const currentContentLength = currentContent.getPlainText('').length;
        const selectedTextLength = getLengthOfSelectedText();

        if (
            currentContentLength + pastedText.length - selectedTextLength >
            maxMessageLength
        ) {
            const selection = editorState.getSelection();
            const isCollapsed = selection.isCollapsed();
            const tempEditorState = !isCollapsed
                ? removeSelection()
                : editorState;
            addPastedContent(pastedText, tempEditorState);
            return 'handled';
        }
        return 'not-handled';
    }

    function handleSubmit() {
        const message = stateToHTML(editorState.getCurrentContent());
        const postNotification: IPostNotification = {
            title: formValues.title,
            message: message,
            user_id: recipient,
            expires_at: formValues.expireDate,
            type: 'ADMIN',
            extras: {},
            scopes,
            realms: [ERealms.coach],
        };
        dispatch(asyncSetNotification(postNotification));

        closeModal();
    }

    return (
        <Modal
            width={700}
            title='Criar nova notificação'
            visible={true}
            footer={false}
            maskClosable={false}
            onCancel={closeModal}
        >
            <Input
                placeholder='Digite o título'
                onChange={(e) => setFormValues({
                    ...formValues,
                    title: e.target.value,
                })}
            />

            <div className='editor-container'>
                <div className='editor-buttons'>
                    <button
                        type='button'
                        onMouseDown={(evt) =>
                            handleChangeTextSelection(evt, 'BOLD')
                        }
                    >
                        <strong>N</strong>
                    </button>
                    <button
                        type='button'
                        onMouseDown={(evt) =>
                            handleChangeTextSelection(evt, 'ITALIC')
                        }
                    >
                        <i>I</i>
                    </button>
                    <button
                        type='button'
                        onMouseDown={(evt) =>
                            handleChangeTextSelection(evt, 'UNDERLINE')
                        }
                    >
                        <u>U</u>
                    </button>
                </div>
                <div className='editor-input'>
                    <Editor
                        editorState={editorState}
                        onChange={handleEditorState}
                        handleBeforeInput={handleBeforeInput}
                        handlePastedText={handlePastedText}
                    />
                </div>
            </div>

            <div className='flex-container'>
                <span>Plataforma:</span>
                <Checkbox
                    onChange={() => handleUpdatePlatform(EScopes.SCIS)}
                    checked={scopes.includes(EScopes.SCIS)}
                >
                    SCIS
                </Checkbox>

                <Checkbox
                    onChange={() => handleUpdatePlatform(EScopes.APP)}
                    checked={scopes.includes(EScopes.APP)}
                >
                    APP
                </Checkbox>
            </div>
            {recipient === 'GLOBAL_NOTIFICATION' && (
                <div className='section-container'>
                    <span>Data de expiração:</span>
                    <MaskedDatePicker
                        currentValue={formValues.expireDate}
                        onChange={handleChangeExpireDate}
                        placeholder='Data de expiração'
                        stringType={'dataExpiracao'}
                    />
                </div>
            )}
            <Button
                type='primary'
                className='new-notification-button'
                onClick={handleSubmit}
                disabled={formValues.title === '' || scopes.length === 0}
            >
                Enviar notificação
            </Button>
        </Modal>
    );
}
