import React, { useCallback } from 'react';
import { Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import TitleSection from '../../components/title-section/TitleSection';
import ReportList from '../../components/reports-list/ReportList';
import CoachingHoursModal from './coaching-hours-modal';

import { IState } from '../../reducers/reducers';

import { changeParams, changeSetOpenModal, deleteReport, getCoachingHoursReports } from '../../reducers/coaching-hours-reports.reducer';

const CoachingHoursReport = (): any => {
    const openModal = useSelector(({coachingHoursReports}: IState) => coachingHoursReports.openModal);
    const isLoading = useSelector(({coachingHoursReports}: IState) => coachingHoursReports.isLoading);
    const isLoadingGenerate = useSelector(({coachingHoursReports}: IState) => coachingHoursReports.isLoadingGenerate);
    const reports = useSelector(({coachingHoursReports}: IState) => coachingHoursReports.reports);
    const total = useSelector(({coachingHoursReports}: IState) => coachingHoursReports.total);

    const dispatch = useDispatch();

    const handleOpenModal = useCallback(() => dispatch(changeSetOpenModal(true)), [dispatch]);
    const handleCloseModal = useCallback(() => dispatch(changeSetOpenModal(false)), [dispatch]);

    return (
        <section>
            <TitleSection title={'Relatório de Horas de Coaching'} >
                <Button
                    onClick={handleOpenModal}
                    size="large"
                    loading={isLoadingGenerate}
                    type="primary"
                >
                    Gerar relatório
                </Button>
            </TitleSection>
            <ReportList
                reports={reports}
                total={total}
                loading={isLoading}
                getReports={getCoachingHoursReports}
                deleteReport={deleteReport}
                changeParams={changeParams}
                statusSelector={({ coachingHoursReports }: IState) => coachingHoursReports.status}
                toSelector={({ coachingHoursReports }: IState) => coachingHoursReports.to}
                fromSelector={({ coachingHoursReports }: IState) => coachingHoursReports.from}
                orderSelector={({ coachingHoursReports }: IState) => coachingHoursReports.order_by}
                pageSelector={({ coachingHoursReports }: IState) => coachingHoursReports.page}
                pageSizeSelector={({ coachingHoursReports }: IState) => coachingHoursReports.pageSize}
            />
            {openModal && (
                <CoachingHoursModal
                    loading={isLoadingGenerate}
                    onClose={handleCloseModal}
                />
            )
            }
        </section>
    );
};

export default CoachingHoursReport;
