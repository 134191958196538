import React from 'react';
import TitleSection from '../../components/title-section/TitleSection';
import UrlShortenersFilter from './url-shorteners-filter/UrlShortenersFilter';
import UrlShortenersList from './url-shorteners-list/UrlShortenersList';


const UrlShorteners = () => {

    return (
        <>
            <TitleSection title="Encurtador de Url" />
            <UrlShortenersFilter/>
            <UrlShortenersList/>
        </>
    )
};

export default UrlShorteners;