import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Search from 'antd/lib/input/Search';
import { Button } from 'antd';
import { asyncGetBlockList, setCurrentPage, setPageList } from '../../../reducers/blockList.reducer';

const BlockListFilter = () => {
    const [search, setSearch] = useState('');
    const dispatch = useDispatch();

    const handleSearch = () => {
        dispatch(setPageList([undefined]));
        dispatch(setCurrentPage(0));
        dispatch(asyncGetBlockList(search));
    }

    return (
        <div className='searchbar'>
            <Search
                value={search}
                className=''
                placeholder='Pesquisar'
                onSearch={handleSearch}
                onChange={event => setSearch(event.target.value)}
            />
            <Button
                className=''
                type='primary'
                icon='search'
                onClick={handleSearch}
            >
                Pesquisar
            </Button>
        </div>
    );
}

export default BlockListFilter;
