export interface ISalesRegistrationItem {
    id: string;
    createdAt: string;
    updatedAt: string;
    step: string;
    status: string;
    observation: string;
    coachEmail: string;
    responsibleName: string;
    responsibleEmail: string;
    companyCNPJ: string;
    companyName: string;
    companyInfo: ICompanyInfo;
    addressInfo: IAddressInfo;
    bankDetail: IBankDetail;
    documents: IDocuments;
    contract: IContract;
}

export interface ICompanyInfo {
    documentValue: string;
    documentType: string;
    fantasyName: string;
    corporateReason: string;
    name: string;
    telephone: string;
    birthday: string;
    email: string;
    website: string;
    type: string;
}

export interface ISalesRegistrationStore {
    nextPage: any;
    orderBy: string;
    orderMode: string;
    salesRegistrations: ISalesRegistrationItem[],
    isLoading: boolean,
    search: string,
    pageSize: number,
    previousPage: string[] | undefined[],
    total: number,
    filter_statuses?: string,
    search_field: SearchFields[],
    salesRegistration?: ISalesRegistrationItem,
}

export interface IAddressInfo {
    street: string;
    number: string;
    complement: string;
    district: string;
    city: string;
    state: string;
    country: string;
    postalCode: string;
    type: string;
}

export interface IBankDetail {
    bank: string;
    accountType: string;
    branch: string;
    branchDigit: string;
    accountNumber: string;
    accountDigit: string;
    documentValue: string;
    documentType: string;
    type: string;
}

export interface IDocuments {
    documents: IDocument[];
    documentType: string;
    cnpjCard: string;
    certificateMei: string;
    type: string;
}

export interface IDocument {
    document: string;
    documentType: string;
}

export interface IContract {
    status: string;
    userUrl: string;
    companyUrl: string;
}

export interface IEditSalesRegistrationResponse {
    id: string;
}

export interface SalesRegistrationResponse {
    results: ISalesRegistrationItem[];
    nextPage: string;
}

export enum SearchFields {
    COACH_EMAIL = 'coachEmail',
    COMPANY_NAME = 'companyName',
    COMPANY_CNPJ = 'companyCNPJ',
    RESPONSIBLE_NAME = 'responsibleName',
};

export enum OrderMode {
    ASC = 'asc',
    DESC = 'desc',
};

export interface IQueryStringList {
    pageSize: number,
    search: string,
    nextPage?: string,
    orderBy?: string,
    orderMode?: string,
    search_field: SearchFields[],
    filter_statuses: string[],
}

export interface IRequest {
    total: number,
    results: ISalesRegistrationItem[],
    nextPage?: string;
}

export const queryStringList = ({
    pageSize,
    search,
    search_field,
    filter_statuses,
    nextPage,
    orderBy,
    orderMode,
}: IQueryStringList) => {
    let params = [];

    if (pageSize) {
        params.push(`pageSize=${pageSize}`);
    }

    if (search) {
        params.push(`search=${encodeURIComponent(search)}`);

        if (!search_field || search_field.length === 0) {
            search_field = Object.values(SearchFields);
        }
    }

    if (filter_statuses && filter_statuses.length > 0) {
        for (const status of filter_statuses) {
            params.push(`status=${status}`);
        }
    }

    if (nextPage) {
        params.push(`nextPage=${nextPage}`);
    }

    if (orderBy) {
        params.push(`orderBy=${orderBy}`);
    }

    if (orderMode) {
        params.push(`orderMode=${orderMode}`);
    }

    return `?${params.join('&')}`;
}
