import React from 'react';

interface IProps {
    results: string,
    titleText: string
}

const MiniCard = ({results, titleText}: IProps) => (
    <div key={results} className="container-panecontrol">
        <span className="panecontrol-results" >{results}</span>
        <span className="panecontrol-title" ><br/>{titleText}</span>
    </div>
)

export default MiniCard;
