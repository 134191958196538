import { IGraphStore, IGraphConvert, IGraphAllYear, IGraphAnoMes } from '../models/panelcontrol.graph.model';

export enum PanelGraphActionTypes {
    ASYNC_GET_PANELGRAPH_YEAR_MOUTH = '@panelgraph/ASYNC_GET_PANELGRAPH_YEAR_MOUTH',
    ASYNC_GET_PANELGRAPH_ALL_YEAR= '@panelgraph/ASYNC_GET_PANELGRAPH_ALL_YEAR',
    PANELGRAPH_GRAPH = '@panelgraph/PANELGRAPH_PANELGRAPH_GRAPH',
    PANELGRAPH_ISLOADING = '@panelgraph/PANELGRAPH_ISLOADING',
    PANELGRAPH_GRAPHCONVERT = '@panelgraph/PANELGRAPH_GRAPHCONVERT',
    PANELGRAPH_GRAPHALLYEAR = '@panelgraph/PANELGRAPH_GRAPHALLYEAR',
    PANELGRAPH_ONEYEAR = '@panelgraph/PANELGRAPH_ONEYEAR',
    PANELGRAPH_MAX_RANGE = '@panelgraph/PANELGRAPH_MAX_RANGE',
    PANELGRAPH_SELECTYEAR = '@panelgraph/PANELGRAPH_SELECTYEAR',
    PANELGRAPH_TOTALYEAR = '@panelgraph/PANELGRAPH_TOTALYEAR'
};

export interface IGraphAction {
    type: string,
    payload?: any
};

const INITIAL_STATE: IGraphStore = {
    graph: [],
    graphConvert: [],
    graphAllYear: [],
    oneYear: [],
    selectYear: 'todos os anos',
    isLoading: false,
    maxRange: 0,
    totalYear: ['todos os anos', '2019','2018','2017','2016','2015','2014','2012']
};

const reducer = (state: IGraphStore = INITIAL_STATE, action: IGraphAction) => {
    switch(action.type){
        case PanelGraphActionTypes.PANELGRAPH_GRAPHCONVERT: 
            return { ...state, graphConvert: action.payload }
        case PanelGraphActionTypes.PANELGRAPH_GRAPHALLYEAR:
            return { ...state, graphAllYear: action.payload }
        case PanelGraphActionTypes.PANELGRAPH_GRAPH: 
            return { ...state, graph: action.payload }
        case PanelGraphActionTypes.PANELGRAPH_ONEYEAR:
            return { ...state, oneYear: action.payload }
        case PanelGraphActionTypes.PANELGRAPH_SELECTYEAR:
            return { ...state, selectYear: action.payload }
        case PanelGraphActionTypes.PANELGRAPH_ISLOADING:
            return { ...state, isLoading: action.payload }
        case PanelGraphActionTypes.PANELGRAPH_TOTALYEAR:
            return { ...state, totalYear: action.payload }
        case PanelGraphActionTypes.PANELGRAPH_MAX_RANGE:
            return { ...state, maxRange: action.payload }
        default:
            return state
    }
};

export default reducer;


export const controlMaxRange = (value: number) => ({
    type: PanelGraphActionTypes.PANELGRAPH_MAX_RANGE,
    payload: value
});

export const asyncPanelGraphGetValue = () => ({
    type: PanelGraphActionTypes.ASYNC_GET_PANELGRAPH_YEAR_MOUTH
});

export const asyncPanelGraphGetAllYearValues = () => ({
    type: PanelGraphActionTypes.ASYNC_GET_PANELGRAPH_ALL_YEAR
});

export const controlPanelGraph = (value: IGraphAnoMes) => ({
    type: PanelGraphActionTypes.PANELGRAPH_GRAPH,
    payload: value
})

export const controlPanelGraphGraphConvert = (value: IGraphConvert) => ({
    type: PanelGraphActionTypes.PANELGRAPH_GRAPHCONVERT,
    payload: value
});

export const controlPanelGraphGraphAllYear= (value: IGraphAllYear) => ({
    type: PanelGraphActionTypes.PANELGRAPH_GRAPHALLYEAR,
    payload: value
});

export const controlPanelGraphOneYear = (value: any) => ({
    type: PanelGraphActionTypes.PANELGRAPH_ONEYEAR,
    payload: value
});

export const controlPanelGraphSelectYear = (value: string) => ({
    type: PanelGraphActionTypes.PANELGRAPH_SELECTYEAR,
    payload: value
});

export const controlPanelGraphIsLoading = (value: boolean) => ({
    type: PanelGraphActionTypes.PANELGRAPH_ISLOADING,
    payload: value
});

export const controlPanelGraphTotalYear = (value: []) => ({
    type: PanelGraphActionTypes.PANELGRAPH_TOTALYEAR,
    payload: value
});