import { Button, Form, Modal, Select } from "antd";
import { FormComponentProps } from "antd/lib/form";
import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Moment }from "moment-timezone";
import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';

import './styles.scss';
import { changeParams, generateInvoicesReport } from "../../../reducers/invoices-reports.reducer";
import { IState } from "../../../reducers/reducers";

const { Option } = Select;

interface IInvoicesReportsModal extends FormComponentProps {
    onClose(): void,
    loading: boolean,
};

const InvoiceStatus: { label: string, value?: string }[] = [
    { label: 'Todos', value: 'ALL' },
    { label: 'Não emitidos', value: 'NOT_EMIT' },
    { label: 'Processando', value: 'PROCESSING' },
    { label: 'Pronto', value: 'OK' },
    { label: 'Processando cancelamento', value: 'PROCESSING_CANCEL' },
    { label: 'Erro', value: 'FAILED' },
    { label: 'Cancelado', value: 'CANCELED' },
];

function InvoicesReportsModal({onClose, loading}: IInvoicesReportsModal) {
    const dispatch = useDispatch();
    const statusGenerated = useSelector(({ invoicesReports }: IState) => invoicesReports.statusGenerate);

    const handleSubmit = (e: any) => {
        e.preventDefault();
        dispatch(generateInvoicesReport());
    };

    const handleChangeRange = useCallback((key: string, data: Moment | null | string) => {
        dispatch(changeParams({[key]: data === 'ALL' ? undefined : data}))
    }, [dispatch]);

    const changeStatusGenerate = useCallback((statuses) => {        
        handleChangeRange('statusGenerate', statuses);
        dispatch(changeParams({statusGenerate: statuses}))
    }, [handleChangeRange, dispatch]);

    function changeData(key:string, value: any) {
        dispatch(changeParams({[key]: value})); 
    };

    const cleanInitialData = useCallback(() => dispatch(changeParams({initialData: ''})), [dispatch]);
    const cleanEndData = useCallback(() => dispatch(changeParams({endData: ''})), [dispatch]);

    return (
        <Modal
            width={700}
            title={"Relatório de Notas Fiscais"}
            visible={true}
            footer={false}
            maskClosable={false}
            onCancel={onClose}
        >
            <Form onSubmit={handleSubmit} className="invoices-modal">
                <div className="invoices-content">
                    <div className='div-input-date'>
                        <span>Início</span>
                        <Form.Item>
                            <MaskedDatePicker
                                onChange={changeData}
                                currentValue={""} 
                                stringType={'initialData'}
                                classText="input-date"
                                placeholder=""
                                onClean={cleanInitialData}
                            />
                        </Form.Item>
                    </div>
                    <div className="div-input-date" >
                        <span>Fim:</span>
                        <Form.Item>
                            <MaskedDatePicker
                                onChange={changeData}
                                currentValue={""} 
                                stringType={'endData'}
                                classText="input-date"
                                placeholder=""
                                onClean={cleanEndData}
                            />
                        </Form.Item>
                    </div>
                    <div className="div-input-filter">
                        <label>Filtrar Por Status</label>
                        <Form.Item>
                            <Select
                                mode="default"
                                placeholder="Todos"
                                maxTagCount={1}
                                style={{ width: 220 }}
                                value={statusGenerated || undefined}
                                defaultValue={undefined}
                                onChange={changeStatusGenerate}
                            >
                                {InvoiceStatus.map(({ label, value }) => (
                                    <Option key={value} value={value}>
                                        {label}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </div>
                </div>
                <div className='invoices-modal-button'>
                    <Button
                        className="cancel"
                        type="default"
                        onClick={onClose}>
                        Cancelar
                    </Button>
                    <Button
                        disabled={loading}
                        type="primary"
                        htmlType="submit"
                    >
                        {loading ? 'Gerando...' : 'Gerar Relatório'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default Form.create<IInvoicesReportsModal>({})(InvoicesReportsModal);