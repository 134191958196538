import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import TitleSection from '../../components/title-section/TitleSection';
import ImportFileForm from './import-file-form/ImportFileForm';
import ImportPreview from './import-preview/ImportPreview';
import ImportResult from './import-result/ImportResult';
import { IState } from '../../reducers/reducers';
import Loading from '../../components/loading/Loading';
import { clearImportStore } from '../../reducers/importation.reducer';

import './Integrations.scss';

export enum ImportationComponent {
    FORM,
    PREVIEW,
    RESULT
};

const Integrations = () => {

    const { isLoading, currentComponent } = useSelector(({ importation }: IState) => ({
        isLoading: importation.isLoading,
        currentComponent: importation.currentComponent
    }));

    const dispatch = useDispatch();

    useEffect((): any => {
        return () => dispatch(clearImportStore());
    // eslint-disable-next-line
    }, []);

    const getCurrentComponent = () => {
        switch (currentComponent) {
            case ImportationComponent.FORM:
                return <ImportFileForm />;

            case ImportationComponent.PREVIEW:
                return <ImportPreview />

            case ImportationComponent.RESULT:
                return <ImportResult />

            default:
                return;
        }
    }

    return (
        <section className="integrations-container">
            <TitleSection title={ currentComponent === ImportationComponent.PREVIEW ? "Revisar Importação"  :  "Importação"} />
            {isLoading && <Loading />}

            {getCurrentComponent()}

        </section>
    )
};

export default Integrations;