export interface IUser {
    id: string;
    email: string;
    phone_number: string;
    address: IAddress;
    personal_ids: IPersonalIDs[];
    phones: string[];
    given_name: string;
    cpf: string;
    family_name: string;
    nickname: string;
    gender: Gender;
    birthdate: string;
    locale: string;
    zoneinfo: string;
    password_mode: string | undefined;
    // realms: string[]
    // profile: string
}

export enum Gender {
    MALE = "male",
    FEMALE = "female",
}

export interface IPersonalIDs {
    country: string;
    docs: IDocsInfo[];
}

export interface IDocsInfo {
    name: string;
    value: string;
}

export interface IAddress {
    street_address: string;
    locality: string;
    region: string;
    postal_code: string;
    country: string;
}

export enum PasswordMode {
    PERM = "permanent",
    TEMP = "temporary",
    LINK = "confirm_link",
}

export enum SearchFields {
    ID = "id",
    EMAIL = "email",
    PHONE_NUMBER = "phone_number",
    NICKNAME = "nickname",
    GIVEN_NAME = "given_name",
    FAMILY_NAME = "family_name",
}

export enum SortField {
    EMAIL = "email",
    PHONE_NUMBER = "phone_number",
    NICKNAME = "nickname",
}

export enum OrderMode {
    ASC = "asc",
    DESC = "desc",
}

interface IQueryStringList {
    page: number;
    pageSize: number;
    search: string;
    search_field: SearchFields[];
    order: SortField;
    order_mode: OrderMode;
}

export const queryStringList = ({ page, pageSize, search, search_field, order, order_mode }: IQueryStringList) => {
    let params = [];

    if (page) {
        params.push(`page=${page - 1}`);
    }

    if (pageSize) {
        params.push(`page_size=${pageSize}`);
    }

    if (search) {
        params.push(`search=${window.encodeURIComponent(search)}`);

        if (!search_field || search_field.length === 0) {
            search_field = Object.values(SearchFields);
        }

        for (const field of search_field) {
            params.push(`search_field=${field}`);
        }
    }

    params.push(`order=${order}`);
    params.push(`order_mode=${order_mode}`);

    return `?${params.join('&')}`;
};
