import moment from "moment-timezone";
import { ICoache } from "../models/coache.model";
import { defaultPNumber, defaultPSize, ILoosePayment } from "../models/loose-payment.model";

//Types
export enum LoosePaymentActionTypes {
    ASYNC_GET_LOOSE_PAYMENTS = '@loose/ASYNC_GET_LOOSE_PAYMENTS',
    ASYNC_GET_COACH_BY_EMAIL = '@loose/ASYNC_GET_COACH_BY_EMAIL',
    ASYNC_POST_LOOSE_PAYMENT = '@loose/ASYNC_POST_LOOSE_PAYMENT',
    ASYNC_GET_CODE = '@loose/ASYNC_GET_CODE',
    ASYNC_GET_FILTER_PEYMENT = '@loose/ASYNC_GET_FILTER_PEYMENT',
    ASYNC_GET_LOOSE_PAYMENT_GENERATE_REPORT = '@loose/ASYNC_GET_LOOSE_PAYMENT_GENERATE_REPORT',
    SET_LOOSE_PAYMENTS = '@loose/SET_LOOSE_PAYMENTS',
    SET_IS_LOADING = '@loose/SET_IS_LOADING',
    SET_COACH = '@loose/SET_COACH',
    SET_LOADING_COACH = '@loose/SET_LOADING_COACH',
    SET_LOADING_SAVE = '@loose/SET_LOADING_SAVE',
    SET_CODE = '@loose/SET_CODE',
    SET_TOTAL = '@loose/SET_TOTAL',
    SET_LOADING_CODE = '@loose/SET_LOADING_CODE',
    CLEAR_STATES = '@loose/CLEAR_STATES',
    SET_PAGE_SIZE = '@loose/SET_PAGE_SIZE',
    SET_PAGE = '@loose/SET_PAGE',
    SET_LOADING_REPORT = '@loose/SET_LOADING_REPORT',
    SET_START_DATE = '@loose/SET_START_DATE',
    SET_END_DATE = '@loose/SET_END_DATE',
    ASYNC_RESEND_EMAIL = '@loose/ASYNC_RESEND_EMAIL',
};

//Interfaces
export interface ILoosePaymentStore {
    payments: ILoosePayment[],
    isLoading: boolean,
    isLoadingSave: boolean,
    total: number,
    page: number,
    pageSize: number,
    coach: ICoache | undefined,
    loadingCoach: boolean,
    code: string | undefined,
    loadingCode: boolean,
    loadingReport: boolean,
    startDate: string | undefined,
    endDate: string | undefined,
};

export interface LoosePaymentAction {
    type: LoosePaymentActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: ILoosePaymentStore = {
    payments: [],
    isLoading: false,
    isLoadingSave: false,
    total: 0,
    page: defaultPNumber,
    pageSize: defaultPSize,
    coach: undefined,
    loadingCoach: false,
    code: undefined,
    loadingCode: false,
    loadingReport: false,
    startDate: moment().subtract(30, 'days').toISOString(),
    endDate: moment().endOf('day').toISOString(),
};

export const reducer = (state: ILoosePaymentStore = INITIAL_STATE, action: LoosePaymentAction): ILoosePaymentStore => {
    switch (action.type) {

        case LoosePaymentActionTypes.SET_LOOSE_PAYMENTS:
            return { ...state, payments: action.payload };
        case LoosePaymentActionTypes.SET_IS_LOADING:
            return { ...state, isLoading: action.payload };
        case LoosePaymentActionTypes.SET_COACH:
            return { ...state, coach: action.payload };
        case LoosePaymentActionTypes.SET_LOADING_COACH:
            return { ...state, loadingCoach: action.payload };
        case LoosePaymentActionTypes.SET_LOADING_SAVE:
            return { ...state, isLoadingSave: action.payload };
        case LoosePaymentActionTypes.SET_CODE:
            return { ...state, code: action.payload };
        case LoosePaymentActionTypes.CLEAR_STATES:
            return { ...state, code: undefined, coach: undefined };
        case LoosePaymentActionTypes.SET_TOTAL:
            return { ...state, total: action.payload };
        case LoosePaymentActionTypes.SET_LOADING_CODE:
            return { ...state, loadingCode: action.payload };
        case LoosePaymentActionTypes.SET_PAGE_SIZE:
            return { ...state, pageSize: action.payload };
        case LoosePaymentActionTypes.SET_PAGE:
            return { ...state, page: action.payload };
        case LoosePaymentActionTypes.SET_LOADING_REPORT:
            return { ...state, loadingReport: action.payload };
        case LoosePaymentActionTypes.SET_START_DATE:
            return { ...state, startDate: action.payload };
        case LoosePaymentActionTypes.SET_END_DATE:
            return { ...state, endDate: action.payload };
        default:
            return state;
    };
};

//Actions
export const asyncGetLoosePayments = (currentPage: number, pageSize: number) => ({
    type: LoosePaymentActionTypes.ASYNC_GET_LOOSE_PAYMENTS,
    pageSize,
    currentPage,
});

export const asyncGetCoachByEmail = (payload: string) => ({
    type: LoosePaymentActionTypes.ASYNC_GET_COACH_BY_EMAIL,
    payload,
});

export const asyncCreateLossePayment = (payload: ILoosePayment) => ({
    type: LoosePaymentActionTypes.ASYNC_POST_LOOSE_PAYMENT,
    payload,
});

export const asyncGetCode = () => ({
    type: LoosePaymentActionTypes.ASYNC_GET_CODE,
});

export const asyncGetFilterPayment = (payload: object) => ({
    type: LoosePaymentActionTypes.ASYNC_GET_FILTER_PEYMENT,
    payload,
});

export const setLoosePayments = (loosePayments: ILoosePayment[]) => ({
    type: LoosePaymentActionTypes.SET_LOOSE_PAYMENTS,
    payload: loosePayments,
});

export const setIsLoading = (payload: boolean) => ({
    type: LoosePaymentActionTypes.SET_IS_LOADING,
    payload,
});

export const setCoach = (payload: object) => ({
    type: LoosePaymentActionTypes.SET_COACH,
    payload,
});

export const setLoadingCoach = (payload: boolean) => ({
    type: LoosePaymentActionTypes.SET_LOADING_COACH,
    payload,
});

export const setLoadingSave = (payload: boolean) => ({
    type: LoosePaymentActionTypes.SET_LOADING_SAVE,
    payload,
});

export const setCode = (payload: string) => ({
    type: LoosePaymentActionTypes.SET_CODE,
    payload,
});

export const setTotal = (payload: Number) => ({
    type: LoosePaymentActionTypes.SET_TOTAL,
    payload,
});

export const setLoadingCode = (payload: boolean) => ({
    type: LoosePaymentActionTypes.SET_LOADING_CODE,
    payload,
});

export const clearStates = () => ({
    type: LoosePaymentActionTypes.CLEAR_STATES,
});

export const setPageSize = (payload: Number) => ({
    type: LoosePaymentActionTypes.SET_PAGE_SIZE,
    payload,
});

export const setPage = (payload: Number) => ({
    type: LoosePaymentActionTypes.SET_PAGE,
    payload,
});

export const setLoadingReport = (payload: boolean) => ({
    type: LoosePaymentActionTypes.SET_LOADING_REPORT,
    payload,
});

export const generateReport = (payload: Object) => ({
    type: LoosePaymentActionTypes.ASYNC_GET_LOOSE_PAYMENT_GENERATE_REPORT,
    payload,
});

export const setStartDate = (payload: string | null) => ({
    type: LoosePaymentActionTypes.SET_START_DATE,
    payload,
});

export const setEndDate = (payload: string | null) => ({
    type: LoosePaymentActionTypes.SET_END_DATE,
    payload,
});

export const asyncResendEmail = (id: string, callback: () => void) => ({
    type: LoosePaymentActionTypes.ASYNC_RESEND_EMAIL,
    payload: {id, callback},
});


export default reducer;
