import React, { useEffect, useState } from 'react';
import { fetchFile, fetchResource } from '../../utils/webApi';

interface IProps {
    fileName: string,
    className?: string
}

const ThumbnailAsync = ({ fileName, className }: IProps) => {

    const [uri, setUri] = useState<string>('');

    useEffect(() => {
        async function getFile() {
            const { Properties } = await fetchResource(fileName);
            const { Uri } = await fetchFile(Properties.file as string)
            setUri(Uri);
        };

        getFile();
    }, [fileName])

    return (<img className={className} src={uri} alt="thumb" />);
};

export default ThumbnailAsync;