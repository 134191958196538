import { all, takeEvery, call, put, select } from 'redux-saga/effects';
import { transformGraph, maxDomain, currentYear } from '../models/panelcontrol.graph.model';
import { controlPanelGraphIsLoading, controlPanelGraphGraphConvert, controlPanelGraphTotalYear,
    controlPanelGraph, controlPanelGraphGraphAllYear, PanelGraphActionTypes, controlMaxRange } from '../reducers/panelcontrol.graph.reducer';
import { getPanelGraph, getPanelGraphAllYear, getPanelGraphLastYear } from '../utils/webApi';
import { IState } from '../reducers/reducers';
import handleError from '../utils/handleError';

function* requestGetValue() {
    try {
        yield put(controlPanelGraphIsLoading(true));

        const selectYear = yield select(({ panelcontrolgraph }: IState)=> panelcontrolgraph.selectYear);
        const totalYear = yield select(({ panelcontrolgraph }: IState)=> panelcontrolgraph.totalYear);
        const request = selectYear === currentYear.toString() ? yield call(getPanelGraphLastYear) : yield call(getPanelGraph);

        const response =  transformGraph(selectYear, request.value, totalYear);

        yield put(controlMaxRange(maxDomain(request.value)));

        if(selectYear !== currentYear.toString()) {
            yield put(controlPanelGraph(request.value));
            yield put(controlPanelGraphTotalYear(response.allYear));
        }
        yield put(controlPanelGraphGraphConvert(response.yearMonth));

    } catch (err) {
        handleError(err);
    } finally {
        yield put(controlPanelGraphIsLoading(false));
    }
};

function* requestAllYear() {
    try {
        yield put(controlPanelGraphIsLoading(true));

        const request = yield call(getPanelGraphAllYear);
        const reverseRequest = request.value.reverse();
        const newArray: any = [reverseRequest[0], { ano: 2013, totalCadastros: 0 }, ...reverseRequest.slice(1)];

        yield put(controlPanelGraphGraphAllYear(newArray));

    }catch(err){
        handleError(err);
    } finally {
        yield put(controlPanelGraphIsLoading(false));
    }
};

export default function* mySaga() {
    yield all([
        takeEvery(PanelGraphActionTypes.ASYNC_GET_PANELGRAPH_YEAR_MOUTH, requestGetValue),
        takeEvery(PanelGraphActionTypes.ASYNC_GET_PANELGRAPH_ALL_YEAR, requestAllYear)
    ])
};