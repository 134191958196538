import React, { useState, useEffect, useMemo } from 'react';
import { Table, Pagination, Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../../components/loading/Loading';
import { IState } from '../../../reducers/reducers'
import { IMessage } from '../../../models/contact.modal';
import { getContactUsMessageComplet, controlContactUsPageSize, controlContactUsPage, getContactUsMessage } from '../../../reducers/contact.reducer';
import './ContactList.scss';
import { formatDate } from '../../../utils/moment.functions';
import ContactDetails from '../contact-details/ContactDetails';

const ContactList = () => {

    const [ isOpen, setIsOpen ] = useState(false);
    const { messages, isLoading, page, pageSize } = useSelector(({ contact }: IState  ) => contact );
    const dispatch = useDispatch();

    const totalPag = useMemo(() => {
        return messages.length < pageSize ? 1 : (pageSize * (page + 1)) + 1
    }, [messages, pageSize, page]);

    const modalState = (id: number) => {
        dispatch(getContactUsMessageComplet(id));
        setIsOpen(true);
    }

    useEffect(()=>{
        dispatch(getContactUsMessage());
    }, [page, pageSize, dispatch]);

    const columns = [
        {
            title: "Data",
            dataIndex: "date",
            key: "Date",
            render: (data: string) => (
                <span>{formatDate(new Date(data), 'DD/MM/YYYY HH:mm')}</span>
            )
        },
        {
            title: "Usuário",
            dataIndex: "username",
            key: "e-mail"
        },
        {
            title: "Titulo",
            dataIndex: "subject",
            key: "title"
        },
        {
            title: "Ações",
            key: "action",
            render: ( message: IMessage ) => (
                <span>
                    <Icon type="fullscreen" onClick={()=>modalState(message.id)}/>
                </span>
            )
        }
    ]
    return (
        <>
            <Table
                className="message-list"
                columns={columns}
                rowKey={(element: IMessage)=> `${element.subject}+${element.id}`}
                pagination={false}
                dataSource={messages.map((element: IMessage)  => ({ ...element, Id: element.id }))} />

            { isLoading ? <Loading/> : <ContactDetails isOpen={isOpen} setIsOpen={setIsOpen}/> }

            <div className="contact-us-pagination" >
                <Pagination
                    className="contact-us-pagination"
                    showSizeChanger
                    onShowSizeChange={(current, size)=> dispatch(controlContactUsPageSize(size))}
                    defaultPageSize={10}
                    defaultCurrent={1}
                    total={totalPag}
                    onChange={page => dispatch(controlContactUsPage(page))}/>
            </div>
        </>
    )
}

export default ContactList;