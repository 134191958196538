export interface IUrlShorteners {
    code: string,
    url: string,
    created_at?: string,
    updated_at?: string
};

export interface IUrlShortenersStore {
    isLoading: boolean,
    search: string,
    pagesList: any[],
    urls: IUrlShorteners[],
    page: number,
    isOpen: false,
    loadingModal: ILoadingModal,
    urlsResults: IUrlsResults,
    currentModal: ECurrentModalUrlShorteners
};

export interface IUrlsResults {
    erros: IErrosUrlShorteners[],
    success: IUrlShorteners[]
}

export interface IErrosUrlShorteners {
    url: string,
    error: string,
    id: number
}

export interface ILoadingModal {
    isLoading: boolean,
    total: number,
    finished: number
}

export enum ECurrentModalUrlShorteners {
    ModalUrlShortenersCreate,
    ModalUrlShortenersResults
};

export interface IQueryStringList {
    nextPage: string
};

export const queryStringList = ({ nextPage }: IQueryStringList) =>
`${nextPage ? `?nextPage=${nextPage}` : ''}` 

export const queryStringAdicionar = (url : string) =>
`${url ? `${url}` : ''}` 