import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { mapEmail } from '../utils/utils.functions';
import { queryStringList } from '../models/blockList.model';
import { IBlockList } from '../models/blockList.model';
import { IBlockListAction, BlockListActionTypes, setBlockList, setCurrentPage, setIsLoading, setPageList } from '../reducers/blockList.reducer';
import { IState } from '../reducers/reducers';
import handleError from '../utils/handleError';
import { getBlockList, deleteEmail } from '../utils/webApi';

function* requestBlockList({payload}: IBlockListAction) {
    try {
        const {pageList, currentPage} = yield select(({blockList}: IState) => blockList);
        const newPageList = [...pageList];
        const pageSize = 10;
        const search = payload || '';

        yield put(setIsLoading(true));
        const {results, nextPage} = yield call(getBlockList, queryStringList({
            pageSize,
            nextPage: pageList[currentPage],
            search,
        }));

        if (nextPage) {
            newPageList.push(nextPage);
            yield put(setPageList(newPageList));
        } else {
            newPageList.push(null);
            yield put(setPageList(newPageList));
        }

        const formattedResult = results.map((item: IBlockList) => ({...item, ...mapEmail(item.error)}));

        yield put(setBlockList(formattedResult));
    } catch (error) {
        handleError(error);
    }
    finally {
        yield put(setIsLoading(false));
    }
}

function* removeEmail({payload}: IBlockListAction) {
    try {
        yield put(setIsLoading(true));
        yield call(deleteEmail, payload);

        yield put(setPageList([undefined]));
        yield put(setCurrentPage(0));

        yield requestBlockList({type: BlockListActionTypes.ASYNC_GET_BLOCKLIST, payload: '' });
    } catch (error) {
        handleError(error);
    }
    finally {
        yield put(setIsLoading(false));
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeEvery(BlockListActionTypes.ASYNC_GET_BLOCKLIST, requestBlockList),
        yield takeEvery(BlockListActionTypes.ASYNC_REMOVE_EMAIL, removeEmail),
    ]);
}
