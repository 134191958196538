import React from 'react';
import { Modal, Tabs } from 'antd';
import { useDispatch } from 'react-redux';
import { controlProfileIsOpen } from '../../../reducers/profile.reducer';

import ProfileModalProfile from './profile-modal-profile/ProfileModalProfile';
import ProfileModalUser from './profile-modal-user/ProfileModalUser';
import ProfileModalCoach from './profile-modal-coach/ProfileModalCoach';
import ProfileModalAccess from './profile-modal-access/ProfileModalAccess';
import { ICoache } from '../../../models/profile.modal';
import { ICenter } from '../../../models/utils.model';

import './ProfileModal.scss';

interface props {
    currentProfile: ICoache,
    currentCenter: ICenter
};

const ProfileModal = ({
    currentCenter,
    currentProfile
}: props) =>{

    const dispatch = useDispatch();

    return (
        <Modal
            className="content-modal-profile"
            visible={true}
            footer={false}
            maskClosable={false}
            title={currentProfile.openid_nickname || currentProfile.username}
            onCancel={()=> dispatch(controlProfileIsOpen(false))}>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane
                    key="1"
                    tab={<span>{'Usuário'}</span>}>
                    <ProfileModalUser currentProfile={currentProfile} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    key="2"
                    tab={<span>{'Coach'}</span>}>
                    <ProfileModalCoach currentProfile={currentProfile} currentCenter={currentCenter}/>
                </Tabs.TabPane>

                <Tabs.TabPane
                    key="3"
                    tab={<span>{'Perfil'}</span>}>
                    <ProfileModalProfile currentProfile={currentProfile} />
                </Tabs.TabPane>

                <Tabs.TabPane
                    key="4"
                    tab={<span>{'Acesso'}</span>}>
                    <ProfileModalAccess currentProfile={currentProfile}/>
                </Tabs.TabPane>
            </Tabs>
        </Modal>
    );
};

export default ProfileModal;