import React, { useCallback, useState } from 'react';
import { Modal, Button, Form } from 'antd';
import { useDispatch } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

import { changeParams, generateCoachingEvaluationssReport } from '../../../reducers/coaching-evaluations-reports.reducer';

import './styles.scss';
interface ICoachingEvaluationsModalProps extends FormComponentProps {
    onClose(): void,
    loading: boolean,
};

const initialYearEvaluations = 2022;

const yearsOptions = (() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const selectYearsEvaluation = [];

    selectYearsEvaluation.push({year: 'Todos', value: undefined});

    for (let initialYear = initialYearEvaluations - 1; initialYear < currentYear; initialYear++) {
        let nextYear = initialYear + 1;
        const fillYear = {year: nextYear.toString() , value: nextYear};
        selectYearsEvaluation.push(fillYear);
    };

    return selectYearsEvaluation;
})();

function CoachingEvaluationsModal({onClose, loading, form}: ICoachingEvaluationsModalProps) {
    const [ selectedYear, setSelectedYear] = useState({year: 'Todos', value: initialYearEvaluations});

    const dispatch = useDispatch();
    const { validateFields } = form;

    const handleSubmit = useCallback((e) => {
        e.preventDefault();

        validateFields((err) => {
            if(!err) {
                dispatch(generateCoachingEvaluationssReport());
            }
        });
    }, [dispatch, validateFields]);

    const changeInitialYear = useCallback(() => {
        dispatch(changeParams({extra_args: {year: selectedYear.value}}));
    },[dispatch, selectedYear]);

    function handleGetSelectedValue(selectValue: number) {
        setSelectedYear({
            year: selectValue.toString(),
            value: selectValue,
        });
    };

    return (
        <Modal
            width={700}
            title={'Relatório de avaliações de coaching'}
            visible={true}
            footer={false}
            maskClosable={false}
            onCancel={onClose}
        >
            <Form onSubmit={handleSubmit} className='coaching-hours-modal'>
                <div className='coaching-hours-content'>
                    <div className='div-input-year'>
                        <span>Selecione o ano</span>
                        <Form.Item>
                            <select
                                className='select-year'
                                placeholder='Todos os anos'
                                value={selectedYear.value}
                                onChange={(selectValue) => handleGetSelectedValue(parseInt(selectValue.target.value))}
                            >
                                {yearsOptions.map(({year, value}) => <option className='item' key={value} value={value}>{year}</option>)}
                            </select>
                        </Form.Item>
                    </div>
                </div>
                <div className='coaching-hours-modal-button'>
                    <Button
                        className='cancel'
                        type='default'
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={changeInitialYear}
                        disabled={loading}
                        type='primary'
                        htmlType='submit'
                    >
                        {loading ? 'Gerando...' : 'Gerar Relatório'}
                    </Button>
                </div>
            </Form>
        </Modal>
    );
};

export default Form.create<ICoachingEvaluationsModalProps>({})(CoachingEvaluationsModal);
