import React, { useEffect, useMemo } from 'react';
import { Icon, Table, Pagination, Popconfirm } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import TitleSection from '../../components/title-section/TitleSection';
import { formatDate } from '../../utils/moment.functions';

import { asyncGetBlockList, asyncRemoveEmail, setCurrentPage } from '../../reducers/blockList.reducer';
import { IState } from '../../reducers/reducers';
import BlockListFilter from './blockList-filter/BlockListFilter';
import { IBlockList } from '../../models/blockList.model';
import {permissionKeys} from '../../utils/permissions';
import useCheckPermissions from '../../hooks/useCheckPermissions';
import RestrictedComponent from '../../components/restricted-component/RestrictedComponent';

import './BlockList.scss';

const allActionPermissionKeys = [
    permissionKeys.USER_BLOCKLIST_DELETE,
];

function BlockList() {
    const {blockList, isLoading, currentPage, pageList} = useSelector(({blockList}: IState) => ({
        blockList: blockList.blockList,
        isLoading: blockList.isLoading,
        currentPage: blockList.currentPage,
        pageList: blockList.pageList,
    }));

    const checkPermissions = useCheckPermissions();

    const PAGE_SIZE = 10;

    const totalPages = useMemo(() => {
        const hasNextPage = Boolean(pageList[currentPage + 1]);

        return (currentPage + (hasNextPage ? 2 : 1)) * PAGE_SIZE;
    }, [currentPage, pageList]);

    const dispatch = useDispatch();

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Data de criação',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (created_at: string) => (
                <span>{formatDate(new Date(created_at), 'DD/MM/YYYY HH:mm')}</span>
            )
        },
        {
            title: 'Remetente',
            dataIndex: 'sender',
            key: 'sender',
        },
        {
            title: 'Mensagem de erro',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: 'Ações',
            dataIndex: 'action',
            key: 'action',
            render: (_: any, record: any) => {
                const { email } = record;

                return (
                    <RestrictedComponent hasPermission={checkPermissions([permissionKeys.USER_BLOCKLIST_DELETE])}>
                        <Popconfirm
                            title={`Tem certeza que deseja remover o email '${email}' da blocklist?`} 
                            okText="Sim"
                            cancelText="Não"
                            onConfirm={() => handleConfirm(email)}
                            placement='left'
                        >
                            <Icon type='delete' theme='filled' style={{color: '#ed4337'}} />
                        </Popconfirm>
                    </RestrictedComponent>
                ) 
            }
        },
    ];

    if (!checkPermissions(allActionPermissionKeys, true)) {
        columns.pop();
    }

    const handleConfirm = (email: string) => {
        dispatch(asyncRemoveEmail(email));
    };

    const handleNextPage = (nextPage: number) => {
        dispatch(setCurrentPage(nextPage - 1))
    };

    useEffect(() => {
        dispatch(asyncGetBlockList());
    }, [currentPage, dispatch]);

    return (
        <main className='blocklist-container'>
            <TitleSection title='BlockList' />

            <BlockListFilter />

            <Table
                columns={columns}
                dataSource={blockList}
                pagination={false}
                loading={isLoading}
                rowKey={(element: IBlockList) => element.email}
            />

            <Pagination
                pageSize={PAGE_SIZE}
                total={totalPages}
                current={currentPage + 1}
                onChange={handleNextPage}
                className='pagination'
                style={{marginTop: '25px'}}
            />
        </main>
    );
}

export default BlockList;
