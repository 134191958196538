/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Form, Select, Button, Input, Icon,  } from 'antd';
import { IPayment } from '../../../models/payment.model';
import { useDispatch, useSelector } from 'react-redux';
import TextArea from '../../../components/text-area/TextArea';
import MaskedDatePicker from '../../../components/masked-date-picker/masked-date-picker';
import SearchFilterModal from '../search-filter/SearchFilter';
import { asyncCreateLossePayment, asyncGetCoachByEmail, asyncGetCode, clearStates, ILoosePaymentStore } from '../../../reducers/loose-payment.reducer';
import { IUnit } from '../../../models/coache.model';
import { getUnits, listPayments } from '../../../utils/webApi';
import { sortBy } from 'lodash';
import { NumericFormat } from 'react-number-format';
import { setISOToDayEnd } from '../../../utils/moment.functions';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import { ILoosePayment } from '../../../models/loose-payment.model';
import './PaymentCreate.scss';

interface Option { label: string, value: string };

const _options: Option[] = [
    {
        label: 'Anual', value: 'ANUAL',
    },
    {
        label: 'Recorrente', value: 'RECORRENTE',
    }
];

interface IProps {
    setOpenCreate(openCreate: boolean): void,
};

interface ICurrentPayment {
    id: string,
    label: string,
    date: string,
};

interface IError {
    key: string | undefined,
    error: boolean,
    message: string| undefined,
};

const PaymentCreate = ({ setOpenCreate }: IProps) => {
    const dispatch = useDispatch();
    const coach = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.coach);
    const idCoach = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.coach && loosePayments.coach.id);
    const paymentCode = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.code);
    const isLoadingSave = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.isLoadingSave);
    const loadingCode = useSelector(({loosePayments}: {loosePayments: ILoosePaymentStore}) => loosePayments.loadingCode);
    const [email, setEmail] = useState<string|undefined>(undefined);
    const [name, setName] = useState<string|undefined>(undefined);
    const [units, setUnits] = useState<IUnit[]>([]);
    const [paymentValue, setPaymentValue] = useState<number>();
    const [paymentExpirationDate, setPaymentExpirationData] = useState<string>();
    const [recurrentPayments, setRecurrentPayments] = useState<ICurrentPayment[]>();
    const [recurrentId, setRecurrentId] = useState<string| undefined>();
    const [paymentType, setPaymentType] = useState<string>();
    const [paymentDescription, setPaymentDescription] = useState<string>();

    function onChangeFilter(value?: string) {
        setEmail(value || undefined);
    };

    async function getRecurrentPayments() {
        let options: ICurrentPayment[] = [];
        if (idCoach) {
            const data = await listPayments(idCoach);

            const allRecurrent = data.payments.filter((payment: IPayment) => Boolean(payment.recorrente));
            allRecurrent
            .sort((a: IPayment, b: IPayment) => a.recorrente.index < b.recorrente.index ? 1 : -1)
            .forEach(({ recorrente }: IPayment) => {
                const index = options.findIndex(({ id }) => id === recorrente.pagamentoRecorrente.id);
                if (index === -1) {
                    options.push({
                        id: recorrente.pagamentoRecorrente.recorrenteId,
                        label: `${recorrente.index + 1}/${recorrente.pagamentoRecorrente.duracao} -
                        ${recorrente.pagamentoRecorrente.recorrenteId}`,
                        date: recorrente.pagamentoRecorrente.created.date,
                    });
                }
            });

            const recurrentSorted = options.sort((a, b) => a.date < b.date ? 1 : -1);

            setRecurrentPayments(recurrentSorted);
        }
    };

    async function asyncGetUnits() {
        if (!units || (units.length === 0)) {
            const {centers}  = await getUnits();
            const sortedUnits = sortBy(centers, 'name');
            setUnits(sortedUnits)
        }
    };

    function generateCode() {
        dispatch(asyncGetCode());
    };

    function onSearch() {
        if (email) {
            dispatch(asyncGetCoachByEmail(email));
        }
    };

    const handleDatePiker: DatePickerProps['onChange'] = (date, dateString) => {
        const endDayDate = setISOToDayEnd(dateString);
        if (endDayDate) {
            setPaymentExpirationData(endDayDate);
        }
    };

    useEffect(() => {
        asyncGetUnits();
        getRecurrentPayments();
        if(coach) {
            setName(coach.nickname);
        }
    }, [coach]);

    useEffect(() => {
        if (paymentType !== 'RECORRENTE') {
            setRecurrentId(undefined);
        }
    }, [paymentType]);

    function onCreatePayment() {
        if(!name) {
            return;
        } if (!paymentDescription) {
            return;
        } else {
            const loosePayment = {
                id: paymentCode,
                coach_name: name,
                email: email,
                recurrent_id: recurrentId || undefined,
                user_id: idCoach,
                date_expiration: paymentExpirationDate,
                type: paymentType,
                value: paymentValue ? Math.ceil(paymentValue*100) : 1,
                description: paymentDescription,
            };

            dispatch(asyncCreateLossePayment(loosePayment as ILoosePayment));
            handleClose();
        }
    };

    function handleName(nameValue: string) {
        const nameTrim = nameValue.trim();

        if (nameTrim.length < 1) {
            setName(undefined)
        } else {
            setName(nameTrim)
        }
    }

    function handleDescription(descriptionValue: string) {
        const descriptionTrim = descriptionValue.trim();

        if (descriptionTrim.length < 1) {
            setPaymentDescription(undefined)
        } else {
            setPaymentDescription(descriptionTrim)
        }
    }

    const disableAdd = () => {
        if(
            !name ||
            !email ||
            !idCoach ||
            !paymentCode ||
            !paymentType ||
            !paymentValue ||
            !paymentDescription ||
            !paymentExpirationDate
        ) {
            return true;
        }
        if (paymentType === 'RECORRENTE' && recurrentId === undefined) {
            return true;
        } else {
            return false;
        }
    };

    function handleClose() {
        setOpenCreate(false);
        dispatch(clearStates());
    };

    return (
        <Form className="payment-create-form">
            <SearchFilterModal onChangeFilter={onChangeFilter} onSearch={onSearch} email={email} />
            <section className='coach-info-container'>
                <div className='coach-name'>
                    <label>Nome coach*</label>
                    <Input
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onBlur={(e) => handleName(e.target.value)}
                        placeholder='Nome do coach'
                        disabled={!coach}
                    />
                </div>
                <div className='coach-unit-code'>
                    <div className='coach-code'>
                        <label>Código*</label>
                        <div className='inp-code'>
                            <Input
                                value={paymentCode}
                                placeholder='Código'
                                disabled={!coach}
                            />
                        </div>
                    </div>
                    <div className='coach-new-code'>
                        <label>Gerar código</label>
                        <Button
                            onClick={generateCode}
                            className="generate-code"
                            type="primary"
                            disabled={!coach}
                        >
                            {loadingCode ? <Icon type='loading' /> : 'Gerar'}
                        </Button>
                    </div>
                </div>
                <div className='coach-payment-type'>
                    <div className='payment-type'>
                        <label>Tipo do pagamento*</label>
                        <Select
                            placeholder="Selecione o tipo"
                            onChange={(type: string) => setPaymentType(type)}
                            disabled={!coach}
                        >
                            {_options.map(({ label, value }) => (
                                <Select.Option key={value} value={value}>{label} </Select.Option>
                            ))}
                        </Select>
                    </div>
                    <div className='payment-recurrent'>
                        <label>Pagamento recorrente{paymentType === 'RECORRENTE' && '*'}</label>
                        <Select
                            onChange={(id: string) => setRecurrentId(id)}
                            placeholder="Selecione o pagamento"
                            disabled={paymentType !== 'RECORRENTE' || !coach}
                            value={recurrentId}
                        >
                            {recurrentPayments && recurrentPayments.map(payment => (
                            <Select.Option
                                key={payment.id}
                                value={payment.id}>
                                {payment.label}
                            </Select.Option>
                        ))}
                        </Select>
                    </div>
                </div>
                <div className='expiration-description'>
                    <div className='value'>
                        <label>Valor*</label>
                        <NumericFormat
                            min={1}
                            value={paymentValue}
                            customInput={Input}
                            onValueChange={(values) => setPaymentValue(Number(values.value))}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale
                            allowNegative={false}
                            decimalScale={2}
                            prefix="R$ "
                            placeholder='0,00'
                            disabled={!coach}
                        />
                    </div>
                    <div className='expiration-date'>
                        <label>Data de expiração*</label>
                        <MaskedDatePicker
                            onChange={handleDatePiker}
                            stringType={"dataPagamento"}
                            placeholder={'Exe: 01/01/2010'}
                            disabled={!coach}
                        />
                    </div>
                </div>
                <div className='description'>
                    <label>Descrição*</label>
                    <TextArea
                        placeholder='Descrição sobre o pagamento'
                        value={paymentDescription}
                        onChange={(e) => setPaymentDescription(e.target.value)}
                        onBlur={(e) => handleDescription(e.target.value)}
                        disabled={!coach}
                    />
                </div>
            </section>
            <div className='coach-infor-footer'>
                <Button
                    onClick={handleClose}
                    className="cancel"
                    type="primary"
                >
                    Cancelar
                </Button>
                <Button
                    onClick={onCreatePayment}
                    className="save"
                    type="primary"
                    disabled={disableAdd()}
                >
                    {isLoadingSave ? <Icon type='loading' /> : 'Adicionar'}
                </Button>
            </div>
        </Form>
    );
};

export default PaymentCreate;
