import React, { useEffect, useState, useMemo } from 'react';
import { Button, Checkbox, Icon, Modal, Select, Popover } from 'antd';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Input from '../../../components/Input/Input';
import Loading from '../../../components/loading/Loading';
import { IState } from '../../../reducers/reducers';
import { changeCoacheeEditOpen, saveCoachApi, asyncConfirm, asyncRetry } from '../../../reducers/coachees.reducer';
import { ICoacheeDetails } from '../../../models/coachee.model';
import { mapEmail } from '../../../utils/utils.functions';

import './styles.scss';
import { formatDate } from '../../../utils/moment.functions';

const statusMap = {
    PendingCognito: {
        className: 'yellow',
        text: 'Pendente no cognito'
    },
    PendingConfirmation: {
        className: 'yellow',
        text: 'Confirmação pendente'
    },
    Confirmed: {
        className: 'green',
        text: 'Cadastro concluído',
    },
    Removed: {
        className: 'red',
        text: 'Removido',
    },
};

const CoacheeEdit = () => {
    const [coach, setCoach] = useState<ICoacheeDetails|null|undefined>(null);
    const [coacheeEdit, setCoacheeEdit] = useState<ICoacheeDetails|null|undefined>(null);
    const dispatch = useDispatch();
    const {isOpen, coacheeDetails, isLoading} = useSelector(({coachees}: IState) => {
        return {
            isOpen: coachees.isOpenEditCoachee,
            coacheeDetails: coachees.currentCoacheesDetails,
            isLoading: coachees.isModalLoading,
        };
    }, shallowEqual);

    useEffect(() => {
        const coachee = coach ? coacheeDetails.find(e => e.CoachId === coach.CoachId) : null;
        setCoach(coachee || coacheeDetails[0]);
        setCoacheeEdit(coachee || coacheeDetails[0]);
    }, [coach, coacheeDetails]);

    const blocklist = useMemo(() => {
        const error = coacheeEdit ? coacheeEdit.blocklist.error : 'OK';
        return mapEmail(error);
    }, [coacheeEdit]);

    const onClose = () => {
        dispatch(changeCoacheeEditOpen(false));
        setCoach(null);
        setCoacheeEdit(null);
    }

    const onChange = (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
        setCoacheeEdit({...coacheeEdit, [key]: e.target.value} as ICoacheeDetails);
    }

    const onChangeChecked = (key: string,) => (e: any) => {
        setCoacheeEdit({...coacheeEdit, [key]: e.target.checked} as ICoacheeDetails);
    }

    const onConfirm = () => {
        dispatch(asyncConfirm(coacheeEdit as ICoacheeDetails));
    };

    const onRetry = () => {
        dispatch(asyncRetry(coacheeEdit as ICoacheeDetails));
    };

    const onRenderEmailInfo = () => {
        return (
            <div className='container-blocklist' >
              <p className='description' >{blocklist.description}</p>
            </div>
        );
    }

    const isRetry = coacheeEdit ? ['PendingCognito', 'PendingConfirmation'].includes(coacheeEdit.Status) : false;
    const isForceConfirm = coacheeEdit ? coacheeEdit.Status !== 'Confirmed' : false;

    return (
        <Modal
            className='coachee-details-modal' 
            visible={isOpen}
            onCancel={onClose}
            maskClosable={false}
            title='Editar Coachee'
            onOk={() => coacheeEdit ? dispatch(saveCoachApi(coacheeEdit)) : null}
        >

            <div className='container-coachee-details' >
                {isLoading && <Loading/>}
                <label className='label-coach' >Coach:</label>
                <Select
                    className='select-coach'
                    value={coach ? coach.CoachId : undefined}
                    placeholder={'Coachs'}
                    onChange={(id: string) => {
                        const coachee = coacheeDetails.find(e => e.CoachId === id);
                        setCoach(coachee);
                        setCoacheeEdit(coachee);
                    }}>
                    {coacheeDetails.map((coacheeDetail: ICoacheeDetails) => {
                        const name = coacheeDetail.NomeCoach || 'desconhecido';
                        const cName = name.length > 30 ? name.replace(name.slice(30, name.length), ' ...') : name;
                        return (
                            <Select.Option
                                key={coacheeDetail.CoachId}
                                value={coacheeDetail.CoachId}
                            >
                                {`${cName} <${coacheeDetail.CoachEmail || 'desconhecido'}>`}
                            </Select.Option>
                        )
                    })}
                </Select>

                <Input
                    label='Nome Completo'
                    placeholder='Nome Completo'
                    value={coacheeEdit ? coacheeEdit.NomeCompleto : undefined}
                    onChange={onChange('NomeCompleto')}
                />
                <Input
                    label='Nome Usual'
                    placeholder='Nome Usual'
                    value={coacheeEdit ? coacheeEdit.NomeUsual : undefined}
                    onChange={onChange('NomeUsual')}
                />
                <Input
                    label='Telefone'
                    placeholder='Telefone'
                    value={coacheeEdit ? coacheeEdit.Telefone : undefined}
                    onChange={onChange('Telefone')}
                />
                <div className='container-input-email' >
                    <div className='label-email' >
                        <Input
                            label='Email'
                            placeholder='Email'
                            value={coacheeEdit ? coacheeEdit.Email : undefined}
                            onChange={onChange('Email')}
                            readOnly
                        />
                    </div>
                    <div className='container-status-email' >
                        <span className='current-status'>STATUS:</span>
                        <span className={`status ${blocklist.status}`} >{blocklist.status}</span>
                        <Popover content={onRenderEmailInfo()}>
                            <Icon type='info-circle-o' />
                        </Popover>
                    </div>
                </div>
                <div className='container-status' >
                    <span >
                        STATUS:
                        <span
                            className={coacheeEdit && coacheeEdit.Status ? statusMap[coacheeEdit.Status].className : 'red'}
                        >
                            { coacheeEdit && coacheeEdit.Status ? statusMap[coacheeEdit.Status].text : null}
                        </span>
                    </span>
                    <div className='status-button' >
                        <Button
                            type='link'
                            disabled={!isRetry}
                            onClick={onRetry}
                        >
                            Renviar
                        </Button>
                        <Button
                            type='danger'
                            onClick={onConfirm}
                            disabled={!isForceConfirm}
                        >
                            Confirmar
                        </Button>
                    </div>
                </div>

                <Checkbox
                    className='checkbox-enable'
                    checked={coacheeEdit ? coacheeEdit.Habilitado : false}
                    onChange={onChangeChecked('Habilitado')}
                >
                    Habilitado
                </Checkbox>

                <div className='info' >
                    <label>OUTRAS INFORMAÇÕES: </label>
                </div>

                <div className='info' >
                    <label>Coach Email: </label>
                    <span>{coacheeEdit && coacheeEdit.CoachEmail ? coacheeEdit.CoachEmail  : 'desconhecido'}</span>
                </div>

                <div className='info' >
                    <label>Número de sessões: </label>
                    <span>{coacheeEdit ? coacheeEdit.NumeroSessoes : 0}</span>
                </div>

                <div className='info' >
                    <label>Última sessão: </label>
                    <span>{coacheeEdit && coacheeEdit.DataUltimaSessao ? formatDate(coacheeEdit.DataUltimaSessao) : 'desconhecido'}</span>
                </div>

            </div>
        </Modal>
    );
};

export default CoacheeEdit;