import { IInternalLinkItem, IInternalLinkStore } from "../models/internal-links.model";

export enum InternalLinkActionTypes {
    ASYNC_INTERNAL_LINKS = '@internallinks/ASYNC_GET_LINKS',
    SET_INTERNAL_LINKS = '@internallinks/SET_INTERNAL_LINKS',
    INTERNAL_LINKS_ISLOADING = '@internallinks/INTERNAL_LINKS_ISLOADING',
    INTERNAL_LINKS_SET_SEARCH = '@internallinks/INTERNAL_LINKS_SET_SEARCH',
    INTERNAL_LINKS_SET_PAGE_SIZE = '@internallinks/INTERNAL_LINKS_SET_PAGE_SIZE',
    INTERNAL_LINKS_SET_TOTAL = '@internallinks/INTERNAL_LINKS_SET_TOTAL',
    UPDATE_INTERNAL_LINK = '@internallinks/UPDATE_INTERNAL_LINK',
    SET_INTERNAL_LINK_INFO = '@internallinks/SET_INTERNAL_LINK_INFO',
    CHANGE_FILTER_STATUSES = '@internallinks/CHANGE_FILTER_STATUSES',
    CHANGE_SEARCH_VALUE = '@internallinks/CHANGE_SEARCH_VALUE',
    CHANGE_SEARCH_FIELD = '@internallinks/CHANGE_SEARCH_FIELD',
    CHANGE_NEXT_PAGE = '@internallinks/CHANGE_NEXT_PAGE',
    CHANGE_PREVIOUS_PAGE = '@internallinks/CHANGE_PREVIOUS_PAGE',
    ASYNC_INTERNAL_LINK = '@internallinks/ASYNC_GET_LINK',
    SET_INTERNAL_LINK = '@internallinks/SET_INTERNAL_LINK',
    RESET_PAGE = '@internallinks/RESET_PAGE',
    TOGGLE_FIELD_ORDER = '@internallinks/TOGGLE_FIELD_ORDER',
    ASYNC_GET_CHECKOUT_LINK= '@internallinks/ASYNC_GET_CHECKOUT_LINK',
}

interface EditInternalLinkPayload {
    id: string;
    observation: string;
    status?: string;
}

const INITIAL_STATE: IInternalLinkStore = {
    internalLinks: [],
    isLoading: false,
    search: '',
    search_field: [],
    pageSize: 10,
    total: 100,
    nextPage: null,
    previousPage: [],
    orderMode: 'DESC',
    filter_statuses: undefined,
    internalLink: undefined,
    currentPage: 1,
    itemsPerPage: 10,
};

const reducer = (state: IInternalLinkStore = INITIAL_STATE, action: any) => {
    switch (action.type) {
        case InternalLinkActionTypes.SET_INTERNAL_LINKS:
            return { ...state, internalLinks: action.payload };
        case InternalLinkActionTypes.SET_INTERNAL_LINK:
            return { ...state, internalLink: action.payload };
        case InternalLinkActionTypes.RESET_PAGE:
            return { ...INITIAL_STATE };
        case InternalLinkActionTypes.TOGGLE_FIELD_ORDER:
            return { ...state, ...action.payload };
        case InternalLinkActionTypes.INTERNAL_LINKS_ISLOADING:
            return { ...state, isLoading: action.payload };
        case InternalLinkActionTypes.SET_INTERNAL_LINK_INFO:
            return { ...state, [action.payload.key]: action.payload.value };
        case InternalLinkActionTypes.CHANGE_PREVIOUS_PAGE:
            return { ...state, previousPage: action.payload };
        case InternalLinkActionTypes.CHANGE_NEXT_PAGE:
            return { ...state, nextPage: action.payload };
        case InternalLinkActionTypes.CHANGE_SEARCH_VALUE:
            return { ...state, search: action.payload };
        case InternalLinkActionTypes.CHANGE_FILTER_STATUSES:
            return { ...state, filter_statuses: action.payload };
        case InternalLinkActionTypes.UPDATE_INTERNAL_LINK:
            return {
                ...state,
                internalLink: state.internalLink ? {
                    ...state.internalLink,
                    observation: action.payload.observation,
                    status: action.payload.status || state.internalLink.status
                } : undefined
            };
        default:
            return state;
    }
};

export default reducer;

export const fetchInternalLinks = (page: number, pageSize: number) => ({
    type: InternalLinkActionTypes.ASYNC_INTERNAL_LINKS,
    payload: { page, pageSize }
});

export const fetchInternalLink = (id: string) => ({
    type: InternalLinkActionTypes.ASYNC_INTERNAL_LINK,
    payload: { id }
});

export const setInternalLinks = (internalLinks: IInternalLinkItem[]) => ({
    type: InternalLinkActionTypes.SET_INTERNAL_LINKS,
    payload: internalLinks,
});

export const setInternalLink = (internalLink: IInternalLinkItem) => ({
    type: InternalLinkActionTypes.SET_INTERNAL_LINK,
    payload: internalLink,
});

export const changeFilterStatuses = (filter_statuses: string) => ({
    type: InternalLinkActionTypes.CHANGE_FILTER_STATUSES,
    payload: filter_statuses
});

export const setInternalLinkInfo = (key: string, value: any) => ({
    type: InternalLinkActionTypes.SET_INTERNAL_LINK_INFO,
    payload: { key, value },
});

export const toggleFieldOrder = (payload: any) => ({
    type: InternalLinkActionTypes.TOGGLE_FIELD_ORDER,
    payload,
});

export const setLoading = (isLoading: boolean) => ({
    type: InternalLinkActionTypes.INTERNAL_LINKS_ISLOADING,
    payload: isLoading,
});

export const resetPage = () => ({
    type: InternalLinkActionTypes.RESET_PAGE,
});

export const changePreviousPage = (previousPage: string[]) => ({
    type: InternalLinkActionTypes.CHANGE_PREVIOUS_PAGE,
    payload: previousPage,
});

export const changeNextPage = (nextPage: string | null) => ({
    type: InternalLinkActionTypes.CHANGE_NEXT_PAGE,
    payload: nextPage,
});

export const changeSearchValue = (search: string) => ({
    type: InternalLinkActionTypes.CHANGE_SEARCH_VALUE,
    payload: search,
});

export const editInternalLink = (id: string, observation: string, status?: string) => {
    const payload: EditInternalLinkPayload = { id, observation };
    if (status) {
        payload.status = status;
    }
    return {
        type: InternalLinkActionTypes.UPDATE_INTERNAL_LINK,
        payload: payload
    };
};

export const openCheckout = (id: string) => ({
    type: InternalLinkActionTypes.ASYNC_GET_CHECKOUT_LINK,
    payload: { id }
});