import { call, put, takeEvery, all, select } from 'redux-saga/effects';
import { setLoading, setSalesRegistrations, SalesRegistrationActionTypes, setSalesInfo, setSalesRegistration } from '../reducers/sales-registrations.reducer';
import handleError from '../utils/handleError';
import { editSalesRegistration, getSalesRegistration, getSalesRegistrations } from '../utils/webApi';
import { IState } from '../reducers/reducers';
import { IRequest, ISalesRegistrationItem, queryStringList } from '../models/sales-registration.model';
import { SagaIterator } from 'redux-saga';
import Notification, { NOTIFICATION_TYPE } from '../components/notification/Notification';

function* fetchSalesRegistrations({ payload }: any) {
    const { isNext } = payload;

    try {
        yield put(setLoading(true));

        let { pageSize, search, nextPage, previousPage, orderBy, search_field, filter_statuses } = yield select(({ salesRegistrations }: IState) => ({
            pageSize: salesRegistrations.pageSize,
            search: salesRegistrations.search,
            nextPage: salesRegistrations.nextPage,
            orderBy: salesRegistrations.orderMode,
            orderMode: salesRegistrations.orderMode,
            previousPage: salesRegistrations.previousPage,
            search_field: salesRegistrations.search_field,
            filter_statuses: salesRegistrations.filter_statuses,
        }));

        let newPrevious = [...previousPage];

        if (!isNext) {
            newPrevious.pop();
            newPrevious.pop();
        }
        const queryString = queryStringList({
            search_field,
            orderBy,
            filter_statuses: filter_statuses ? [filter_statuses] : filter_statuses,
            pageSize,
            search,
            nextPage: isNext ? nextPage : newPrevious[newPrevious.length - 1]
        });

        const response: IRequest | undefined = yield call(getSalesRegistrations, queryString);
        if (response) {
            yield put(setSalesRegistrations(response.results));
            yield put(setSalesInfo('previousPage', [...newPrevious, response.nextPage]));
            if (!response.nextPage) {
                yield put(setSalesInfo('nextPage', 'lastPage'));
            } else {
                yield put(setSalesInfo('nextPage', response.nextPage));
            }
        }

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

function* editSalesRegistrationSaga(action: any): SagaIterator {
    try {
        yield put(setLoading(true));
        const { id, status, observation } = action.payload;
        const payload: { observation: string; status?: string } = { observation };
        if (status) {
            payload.status = status;
        }
        yield call(editSalesRegistration, id, payload);
        yield call(fetchSalesRegistration, {payload: {id}})
        Notification(NOTIFICATION_TYPE.SUCCESS,'Sucesso', 'Registro atualizado com sucesso!');

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

function* fetchSalesRegistration({ payload }: any) {
    const { id } = payload as {id: string};

    try {
        yield put(setLoading(true));
        const response: ISalesRegistrationItem | undefined = yield call(getSalesRegistration, id);
        if (response) {
            yield put(setSalesRegistration(response));
        }

    } catch (error) {
        handleError(error);
    } finally {
        yield put(setLoading(false));
    }
}

export default function* salesRegistrationSaga() {
    yield all([
        takeEvery(SalesRegistrationActionTypes.ASYNC_SALES_REGISTRATIONS, fetchSalesRegistrations),
        takeEvery(SalesRegistrationActionTypes.ASYNC_SALES_REGISTRATION, fetchSalesRegistration),
        takeEvery(SalesRegistrationActionTypes.UPDATE_SALES_REGISTRATION, editSalesRegistrationSaga)
    ])
}
