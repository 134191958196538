import React, { useState, useEffect } from 'react';
import { Input, Select } from 'antd';
import { ICountry, AddressModel, IStates, ICity } from '../../../../models/utils.model';
import { ICoache } from '../../../../models/coache.model';
import handleError from '../../../../utils/handleError';
import { getStates } from '../../../../utils/webApi';

const { Option } = Select;

interface IProps {
    countries: ICountry[],
    onChange(name: string, value: any): void,
    coachEdit: ICoache,
};

const AddressForm = ({ countries, coachEdit, onChange }: IProps) => {
    const [states, setStates] = useState<IStates[]>([]);
    const [cities, setCities] = useState<ICity[]>([]);

    useEffect(() => {
        if (!coachEdit.address) return;
        const country: ICountry | undefined = countries.find(({ name }) => coachEdit.address.country === name);

        async function fetchState(currentCountry: ICountry) {
            try {
                const statesOrCities = await getStates(currentCountry.url);
                if (currentCountry.model === AddressModel.STATES_CITIES) {
                    setStates(statesOrCities);
                    setCities([]);
                } else {
                    setStates([]);
                    setCities(statesOrCities)
                };
            } catch (error) {
                handleError(error);
            };
        };

        if (country) {
            fetchState(country);
        }

        // eslint-disable-next-line
    }, [coachEdit.address && coachEdit.address.country, countries, getStates]);

    useEffect(() => {
        if (coachEdit.address && coachEdit.address.region) {
            const state: IStates | undefined = states.find(({ name, abbrev }) => coachEdit.address.region === name || coachEdit.address.region === abbrev  );
            if (state) {
                setCities(state.cities);
            };
        };
        // eslint-disable-next-line
    }, [coachEdit.address && coachEdit.address.region, states]);


    function onChangeAddress(key: string, value: any) {
        if (key === 'country') {
            onChange('address', {
                ...coachEdit.address, ...{
                    country: value,
                    region: undefined,
                    locality: undefined
                }
            })
        } else if (key === 'region') {
            onChange('address', {
                ...coachEdit.address, ...{
                    region: value,
                    locality: undefined
                }
            })
        } else {
            onChange('address', { ...coachEdit.address, [key]: value })
        }
    };

    return (
        <>
            <div className="form-group">
                <Select
                    value={coachEdit.address ? coachEdit.address.country : undefined}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'País'}
                    onChange={(country: string) => onChangeAddress('country', country)}>
                    {countries.map((country: ICountry) => (
                        <Option key={country.url} value={country.name}>{country.name}</Option>
                    ))}
                </Select>
                <Select
                    value={coachEdit.address ? coachEdit.address.region && coachEdit.address.region.length > 3 && coachEdit.address.country === 'Brasil' ? undefined : coachEdit.address.region : undefined}
                    allowClear={true}
                    showSearch={true}
                    placeholder={'Estado'}
                    disabled={states.length <= 0}
                    onChange={(state: string) => {
                        onChangeAddress('region', state)
                    }}>
                    {states.map((state: IStates, index: number) => (
                        <Option
                            key={state.abbrev+index}
                            value={state.abbrev || state.name}>{state.name}</Option>
                    ))}
                </Select>
            </div>
            <div className="form-group">
                <Select
                    placeholder={'Cidade'}
                    allowClear={true}
                    showSearch={true}
                    disabled={cities.length <= 0}
                    value={coachEdit.address ? coachEdit.address.locality : undefined}
                    onChange={(cityName: string) => onChangeAddress('locality', cityName)}>
                    {cities.map((city: ICity) => (
                        <Option
                            key={city.name}
                            value={city.name}>{city.name}</Option>
                    ))}
                </Select>
                <Input
                    value={coachEdit.address ? coachEdit.address.postal_code : ''}
                    onChange={({ target }) => onChangeAddress('postal_code', target.value)}
                    placeholder={'CEP'}
                />
            </div>
            <Input
                value={coachEdit.address ? coachEdit.address.street_address : ''}
                onChange={({ target }) => onChangeAddress('street_address', target.value)}
                placeholder={'Logradouro'}
            />
        </>
    );
};

export default AddressForm;