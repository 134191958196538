export interface IGraphAnoMes {
    ano: number,
    mes: number,
    totalCadastros: number
};

export interface IGraphRequest {
    value: IGraphAnoMes[]
};

export interface IGraphRequestAllYear {
    value: IGraphAllYear[]
};

export interface IGraphAllYear {
    ano: number,
    totalCadastros: number
};

export interface IGraphConvert {
    yearMonth: string,
    totalCadastros: number
};

export interface IGraphOneYear {
    mouth: number,
    totalCadastros: number
};

export interface IGraphStore {
    isLoading: boolean,
    maxRange: number,
    graph: IGraphAnoMes[],
    graphConvert: IGraphConvert[],
    graphAllYear: IGraphAllYear[],
    oneYear: IGraphOneYear[],
    selectYear: string,
    totalYear: string[]
};

export let all = 'todos os anos';
export const currentYear = new Date().getFullYear();

export const dateMonth = ['jan', 'fev', 'mar', 'abr', 'mai', 'jun', 'jul', 'ago', 'set', 'out', 'nov', 'dez'];
export const dateMonthComplet = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto',
    'Setembro', 'Outubro', 'Novembro', 'Dezembro'];

export const yearMonthTotal = dateMonth.map(element =>({
    yearMonth: element,
    totalCadastros: 0
}));

export const yearMonthComplet = (totalYear: []) => {

    const newTotalYear: any = [...totalYear];
    newTotalYear.splice(0,1);

    newTotalYear.reverse();

    let request: any = [];

    newTotalYear.forEach((element: any)=>{
        const ano = dateMonth.map((el, index)=>{
            return { yearMonth: `${element}/${index+1 < 10 ? `0${index+1}` : index+1}`, totalCadastros: 0 }
        });
        ano.forEach(e=>request.push(e))
    });
    return request;
};



export const transformText = () => {
    const changeText: any = {};

    dateMonth.forEach((element: string, index: number)=>{
        changeText[element] = dateMonthComplet[index];
        changeText[index] = dateMonthComplet[index];
    });
    return changeText;
};

export const transformGraph = (selectYear: string, request: IGraphAnoMes[], totalYear: any) => {

    const newTotalYear: any = [...totalYear];
    newTotalYear.splice(0,1);

    newTotalYear.reverse();

    let allYear:any = [];
    
    let yearComplet = yearMonthComplet(totalYear);

    let yearMonth: any = selectYear === all ? [...yearComplet] : [...yearMonthTotal];
    const newData = request.reverse();

    let ano = 0;
    let anoAtual = '2012';

    let cont = 0

    newData.forEach((element:IGraphAnoMes) => {
        let newAno = element.ano.toString();

        if(anoAtual !== newAno){
            if(cont===0){
                ano = 12
                cont++
            } else ano+=12
            anoAtual = newAno
        };

        allYear.indexOf(`${element.ano}`) === -1 && allYear.push(`${element.ano}`);

        if(selectYear === all){
            yearMonth[ano+(element.mes-1)] = {
                yearMonth: `${element.ano}/${element.mes < 10 ? `0${element.mes}` : element.mes}`,
                totalCadastros: element.totalCadastros
            };
        } else {
            if ( element.ano === parseInt(selectYear)) {
                yearMonth[element.mes-1] = {
                    yearMonth: `${dateMonth[element.mes-1]}`,
                    totalCadastros: element.totalCadastros                        
                };
            };
        };
    });

    allYear.push(all);
    allYear.reverse();

    return { allYear, yearMonth }
}

export const maxDomain = (data: any) => {
    let maxRange = 0;
    data.forEach((element: any)=>{
        let number = element.totalCadastros
        if (number > maxRange) maxRange = number; 
    });
    const totalZero = maxRange.toString().length -1;
    let currentDivisao = ['1']
    for(let i = 0; i<totalZero; i++){
        currentDivisao.push('0');
    }
    let divisao = parseInt(currentDivisao.join(''));
    maxRange = Math.ceil((maxRange/divisao)) * divisao
    return maxRange;
};