import React, { useEffect, useMemo } from 'react';
import { Table, Input, Button, Select, Tooltip, Popconfirm, Checkbox } from 'antd';
import { Gender } from '../../../models/coache.model';
import { useSelector, useDispatch } from 'react-redux';
import { IState } from '../../../reducers/reducers';
import { ICoachPreview, IPreviewInformation } from '../../../models/import.model';
import { revalidateCoach, changePreviewInformation, importCoaches, clearImportStore } from '../../../reducers/importation.reducer';
import ImportInfo from '../import-info/ImportInfo';
import './ImportPreview.scss';

const errorTransform: any = {
    CONFLICT: (value: string) => `Este ${errorTransform.stringTranslate(value)} já está cadastrado para outro usuário. Se você continuar a importação, esse outro usuário será adicionado à turma. Consulte o painel de ajuda logo acima para maiores informações.`,
    stringTranslate: (value: string) => {
        const objTranslate: any = {
            phone_number: "Telefone",
            fullname: "Nome completo",
            gender: "Sexo",
            country: "País",
            state: "Estado"
        }
        return objTranslate[value] || value
    },
    INVALID_VALUE: (value: string) => `O campo ${errorTransform.stringTranslate(value)} está incorretamente preenchido 
    ${value === 'cpf' && 'OU foi preenchido para um país que não é Brasil (neste caso, apague o CPF). Consulte o painel de ajuda logo acima para maiores informações.'}`
};


const fields = [{
    label: 'Nome completo',
    key: 'fullname',
    type: 'input'
}, {
    label: 'CPF',
    key: 'cpf',
    type: 'input'
}, {
    label: 'Sexo',
    key: 'gender',
    type: 'select',
    options: [{ label: 'Masculino', value: Gender.MALE }, { label: 'Feminino', value: Gender.FEMALE }]
}, {
    label: 'Email',
    key: 'email',
    type: 'input'
}, {
    label: 'Telefone',
    key: 'phone_number',
    type: 'input'
}, {
    label: 'País',
    key: 'country',
    type: 'input'
}, {
    label: 'Estado',
    key: 'state',
    type: 'input'
}, {
    label: 'Unidade',
    key: 'unit',
    type: 'input'
}, {
    label: 'Turma',
    key: 'class',
    type: 'input'
}, {
    label: 'Executive',
    key: 'executive',
    type: 'checkbox'
}, {
    label: 'Business',
    key: 'business',
    type: 'checkbox'
}, {
    label: 'Microfranchisee',
    key: 'microfranchisee',
    type: 'checkbox'
}, {
    label: 'Career',
    key: 'career',
    type: 'checkbox'
},{
    label: 'Trainer',
    key: 'trainer',
    type: 'checkbox'
},{
    label: 'livingFamily',
    key: 'livingFamily',
    type: 'checkbox'
}, {
    label: 'Master',
    key: 'master',
    type: 'checkbox'
}, {
    label: 'mindfulness',
    key: 'mindfulness',
    type: 'checkbox'
}];

function ImportPreview() {
    const { coachesPreview, coachIndexInValidation } = useSelector(({ importation }: IState) => ({
        coachesPreview: importation.previewInformation as IPreviewInformation,
        coachIndexInValidation: importation.indexOfCoachInRequest
    }));

    const dispatch = useDispatch();

    useEffect(() => {
        if (coachesPreview.coaches.length === 0) dispatch(clearImportStore());
        // eslint-disable-next-line
    }, [coachesPreview.coaches])

    useEffect(() => {
        if (coachIndexInValidation !== -1) {
            const element = document.getElementsByClassName(`row-${coachIndexInValidation}`)[0];
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            }
        };
    }, [coachIndexInValidation]);


    const duplicateEmail = useMemo(() => {
        return coachesPreview.coaches.reduce((acc: any, cv) => {
            if (typeof cv.coach.email === 'string') {
                acc[cv.coach.email] = acc[cv.coach.email] ? acc[cv.coach.email] + 1 : 1
            }
            return acc;
        }, {});
    }, [coachesPreview.coaches]);

    function onChangeValue(key: string, value: any, index: number) {
        let newCoaches: any = JSON.parse(JSON.stringify(coachesPreview));
        newCoaches.coaches[index].coach[key] = value;
        dispatch(changePreviewInformation(newCoaches));
    };

    function onRemoveCoach(index: number): void {
        let newCoaches = [...(coachesPreview as IPreviewInformation).coaches];
        newCoaches.splice(index, 1);
        dispatch(changePreviewInformation({ ...coachesPreview as IPreviewInformation, coaches: newCoaches }));
    };

    const columns = [{
        title: 'Nº',
        key: 'number',
        render: (_: any, record: ICoachPreview, index: number) => (
            <p style={{ textAlign: 'center' }}>{index + 1}</p>
        )
    },
    ...fields.map(({ label, key, type, options = [] }) => ({
        title: label,
        dataIndex: key,
        key: key,
        render: (_: any, record: ICoachPreview, index: number) => {
            const error = record.errors.find(error => error.field === key);
            const className = `${key} ${error ? 'invalid-field' : ''}`
            return <Tooltip title={(error && errorTransform[error.status](key)) || null}>
                {
                    type === 'select' ?
                        <Select
                            onChange={(value: Gender) => onChangeValue(key, value, index)}
                            value={(record.coach as any)[key]}
                            className={className}>
                            {options.map(({ label, value }) => (
                                <Select.Option key={value} value={value}>
                                    {label}
                                </Select.Option>
                            ))}
                        </Select>
                        : type === 'checkbox' 
                            ? <>
                                {
                                    typeof (record.coach as any)[key] === 'boolean' 
                                        ? <Checkbox
                                                checked={(record.coach as any)[key]}
                                                onChange={({ target }) => onChangeValue(key, target.checked, index)}
                                            >
                                                {label}
                                            </Checkbox>
                                        : <span>{'Padrão'}</span>
                                }
                            
                            </> 
                            : <Input
                            onChange={({ target }) => onChangeValue(key, target.value, index)}
                            className={`${className} ${typeof record.coach.email === 'string' ? duplicateEmail[record.coach.email] && duplicateEmail[record.coach.email] > 1 ? 'duplicate-email' : '' : ''}`}
                            value={(record.coach as any)[key]} />
                }
            </Tooltip>
        }
    })), {
        title: 'Action',
        key: 'action',
        render: (_: any, record: ICoachPreview, index: number) => {
            const coachInRequest = coachIndexInValidation === index;
            const hasCoachingInValidation = coachIndexInValidation !== -1;
            return (
                <div className="import-actions">
                    <Button
                        disabled={hasCoachingInValidation}
                        onClick={() => dispatch(revalidateCoach(record.coach, index))}>
                        {coachInRequest ? 'Validando' : 'Revalidar'}
                    </Button>
                    <Button
                        disabled={hasCoachingInValidation}
                        onClick={() => onRemoveCoach(index)}
                        type="danger">Remover</Button>
                </div>
            )
        }
    }];

    return (
        <section className="import-preview">

            <ImportInfo
                expiration_date={coachesPreview.expiration_date}
                activation_date={coachesPreview.activation_date}
                class_id={coachesPreview.class_id}
                unit_id={coachesPreview.unit_id}
                renderHelp={true}
            />

            <Table
                rowClassName={(_, index) => `row-table-importation row-${index} ${coachIndexInValidation === index ? 'in-request' : ''}`}
                className="import-result-table"
                pagination={false}
                rowKey={(coache: ICoachPreview, index) => `${index}`}
                dataSource={coachesPreview.coaches as any}
                columns={columns}
            />

            <div className="buttons-preview-import">
                <Popconfirm
                    title="Você deseja mesmo cancelar e voltar para a tela inicial de importação?"
                    onConfirm={() => dispatch(clearImportStore())}
                    okText="Sim"
                    cancelText="Não"
                >
                    <Button
                        className="button-cancel-import"
                        type="default" >Cancelar
                    </Button>
                </Popconfirm>

                <Button
                    disabled={coachIndexInValidation !== -1}
                    type="primary"
                    className="send-coaches"
                    onClick={() => dispatch(importCoaches(coachesPreview as IPreviewInformation))}
                >Enviar Coaches</Button>
            </div>
        </section >
    );
};

export default ImportPreview;
