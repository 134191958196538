import { call,all, put, select, takeLatest } from 'redux-saga/effects';
import {
    changeLoadingDownload,
    ReportsActionTypes,
    setUsernames,
} from '../reducers/reports.reducer';
import {downloadReport, getUsernames } from '../utils/webApi';
import handleError from '../utils/handleError';
import { IState } from '../reducers/reducers';
import { IReport, IDownloadRequest } from '../models/report.model';

function* requestDownloadReport({payload}: {type: string, payload: string}) {
    try {
        yield put(changeLoadingDownload(payload, true));
        const response: IDownloadRequest = yield call(downloadReport, payload);
        const link = document.createElement('a');
        link.href = response.download_url;
        link.setAttribute('download', 'coach.xlsx');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

    } catch(err) {
        handleError(err);
    } finally {
        yield put(changeLoadingDownload(payload, false));
    }
}

function* requestUserData() {
    try {
        const { usernames } = yield select((state: IState) => state.reports);
        const { reports: coachReports } = yield select((state: IState) => state.coachReports);
        const { reports: coachHoursReports } = yield select((state: IState) => state.coachingHoursReports);
        const { reports: coachCycleReports } = yield select((state: IState) => state.coachingCycleReports);
        const { reports: invoicesReports } = yield select((state: IState) => state.invoicesReports);
        const { reports: coachEvaluationsReports } = yield select((state: IState) => state.coachingEvaluationsReports);
        const { reports: experimentalSessionReports } = yield select((state: IState) => state.experimentalSessionReports);
        const { reports: monthlyCoachingReports} = yield select((state: IState) => state.monthlyCoachingReports);

        const ids = [
            ...coachReports,
            ...coachHoursReports,
            ...coachCycleReports,
            ...invoicesReports,
            ...coachEvaluationsReports,
            ...experimentalSessionReports,
            ...monthlyCoachingReports,
        ].map((r: IReport) => r.created_by);
        const idsSet: Set<string> = new Set(ids);
        const idsArray = [...idsSet].filter((id: string) => !usernames[id]);
        if(idsArray.length > 0) {
            const response: {users: []} = yield call(getUsernames, idsArray);
            const usernames = response.users.reduce((acc, v: any) => ({...acc, [v.id]: v.email}),{})
            yield put(setUsernames(usernames));
        }
    } catch (err) {
        handleError(err);
    }
}

export default function* MySaga(): any {
    yield all([
        yield takeLatest(ReportsActionTypes.ASYNC_DOWNLOAD_REPORT, requestDownloadReport),
        yield takeLatest(ReportsActionTypes.ASYNC_USER_DATA, requestUserData),
    ]);
}
