import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Select, Form, Button } from "antd";

import Search from "antd/lib/input/Search";
import { SearchFields } from "../../../../models/user.model";
import { IState } from "../../../../reducers/reducers";
import {
    changeSearchValue,
    changeSearchField,
    getUsers,
    changePage,
} from "../../../../reducers/users.reducer";
import "./SearchFilter.scss";

const { Option } = Select;

const fieldsOptions: { label: string; value: SearchFields }[] = [
    { label: 'ID do Usuário', value: SearchFields.ID },
    { label: "Email", value: SearchFields.EMAIL },
    { label: "Telefone", value: SearchFields.PHONE_NUMBER },
    { label: "Nome Usual", value: SearchFields.NICKNAME },
    { label: "Primeiro Nome", value: SearchFields.GIVEN_NAME },
    { label: "Segundo Nome", value: SearchFields.FAMILY_NAME },
];

const SearchFilter = () => {
    const { search, search_field } = useSelector(
        ({ users }: IState) => ({
            search: users.search,
            search_field: users.search_field,
        })
    );

    const dispatch = useDispatch();

    const onSearch = () => {
        dispatch(changePage(1));
        dispatch(getUsers());
    };

    return (
        <Form className="filter-content3">
            <div className="input-filter">
                <label>Buscar</label>
                <Search
                    value={search}
                    className="search-input"
                    placeholder="Buscar"
                    onSearch={onSearch}
                    onChange={({ target }) =>
                        dispatch(changeSearchValue(target.value))
                    }
                />
            </div>

            <div className="input-filter">
                <label>Campo de busca</label>
                <Select
                    mode="multiple"
                    placeholder="Todos"
                    maxTagCount={1}
                    allowClear={true}
                    value={
                        search_field && search_field.length > 0
                            ? search_field
                            : undefined
                    }
                    onChange={(fields: SearchFields[]) =>
                        dispatch(changeSearchField(fields))
                    }
                >
                    {fieldsOptions.map(({ label, value }) => (
                        <Option key={value} value={value}>
                            {label}
                        </Option>
                    ))}
                </Select>
            </div>

            <Button
                onClick={onSearch}
                className="btn-search"
                type="primary"
                icon="search"
            >
                Pesquisar
            </Button>
        </Form>
    );
};

export default SearchFilter;
