export interface ILoosePayment {
    id:  string | null,
    user_id:  string | null,
    nickname:  string | null,
    coach_name:   string | null,
    email:  string | null,
    type:  string | null,
    value:  number,
    status:  string | null,
    description:  string | null,
    payment_id:  string | null,
    recurrent_id:  string | null,
    date_created: string | null,
    date_expiration: string | null,
    license_id:  string | null,
    transaction_data:  string | null,
    code: string |null,
};

export const defaultPNumber = 1;
export const defaultPSize = 10;
