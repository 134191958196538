import React from 'react';
import { Card } from 'antd';
import { IDashboardData } from '../../models/panelcontrol.model';
import MiniCard from './mini-card/MiniCard';
import './PanelControlCard.scss'

interface IPanelCard {
    cardList: IDashboardData[],
    textIndex: number
}

const textCoaches = [
    ['totais', 'desabilitados', 'criados este ano', 'criados este mês', 'acessaram este mês',
        'acessaram hoje'],
    ['ativos', 'pagantes', 'gratuidade', 'experimental', 'expirado ou pronto', 'legado'],

    ['#coaches', '#desativados', '#criados este ano','#criados nos últimos 3 meses ','#criados este mês', '#criados nos últimos 30 dias',
     '#master', '#executivo', '#business', '#microfranchisee', '#mindfulness', '#troféu 500h', '#troféu 1000h', '#troféu 2000h','#acessaram este mês',
     '#acessaram hoje'],

    ['#coachees','#sessões realizadas','#coachees finalizados','#horas de coaching']
];

const PanelControlCard = ({cardList, textIndex}: IPanelCard) => {
    return (
        <>
            {
                cardList.map((element, index)=>(
                    <Card.Grid className='mini-card' key={`${element.value + index}`}>
                        <MiniCard
                            results={element.value}
                            textIndex={textIndex}
                            titleText={textCoaches[textIndex][index]} />
                    </Card.Grid>))
            }
        </>
    )
}

export default PanelControlCard;
