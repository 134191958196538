import { IReport } from "../models/report.model";

//Types
export enum CoachingEvaluationsReportsActionTypes {
    ASYNC_GET_COACHING_EVALUATIONS_REPORTS = '@coaching-evaluations-reports/ASYNC_GET_COACHING_EVALUATIONS_REPORTS',
    ASYNC_GENERATE_COACHING_EVALUATIONS_REPORT = '@coaching-evaluations-reports/ASYNC_GENERATE_COACHING_EVALUATIONS_REPORT',
    ASYNC_DELETE_REPORT = '@coaching-evaluations-reports/ASYNC_DELETE_REPORT',
    CHANGE_COACHING_EVALUATIONS_REPORTS = '@coaching-evaluations-reports/CHANGE_COACHING_EVALUATIONS_REPORTS',
    CHANGE_LOADING = '@coaching-evaluations-reports/CHANGE_LOADING',
    CHANGE_PARAMS = '@coaching-evaluations-reports/CHANGE_PARAMS',
    CHANGE_LOADING_GENERATE = '@coaching-evaluations-reports/CHANGE_LOADING_GENERATE',
    POLLING_FOR_STATUS = '@coaching-evaluations-reports/POLLING_FOR_STATUS',
    SET_OPEN_MODAL = '@coaching-evaluations-reports/SET_OPEN_MODAL',
    SET_SHOW_SELECT = '@coaching-evaluations-reports-/SET_SHOW_SELECT',
};

//Interfaces
export interface ICoachingEvaluationsReportsStore {
    reports: IReport[],
    isLoading: boolean,
    isLoadingGenerate: boolean,
    order_by: string,
    status: string | null,
    to: string,
    from: string,
    page: number,
    pageSize: number,
    total: number,
    openModal: boolean,
    extra_args: IExtraArgs,
};

export interface IExtraArgs {
    year?: number,
};

export interface CoachingEvaluationsReportsAction {
    type: CoachingEvaluationsReportsActionTypes,
    payload: any
};

//Reducer
const INITIAL_STATE: ICoachingEvaluationsReportsStore = {
    reports: [],
    isLoading: false,
    isLoadingGenerate: false,
    order_by: 'asc',
    status: null,
    to: '',
    from: '',
    page: 1,
    pageSize: 10,
    total: 0,
    openModal: false,
    extra_args: {year: 2022},
};

export const reducer = (state: ICoachingEvaluationsReportsStore = INITIAL_STATE, action: CoachingEvaluationsReportsAction): ICoachingEvaluationsReportsStore => {
    switch (action.type) {
        case CoachingEvaluationsReportsActionTypes.CHANGE_COACHING_EVALUATIONS_REPORTS:
            return { ...state, reports: action.payload };

        case CoachingEvaluationsReportsActionTypes.CHANGE_LOADING:
            return { ...state, isLoading: action.payload };

        case CoachingEvaluationsReportsActionTypes.CHANGE_LOADING_GENERATE:
            return { ...state, isLoadingGenerate: action.payload };

        case CoachingEvaluationsReportsActionTypes.CHANGE_PARAMS:
            return {...state, ...action.payload};

        case CoachingEvaluationsReportsActionTypes.SET_OPEN_MODAL:
            return {...state, openModal: action.payload};

        default:
            return state;
    };
};

//Actions
export const getCoachingEvaluationReports = () => ({
    type: CoachingEvaluationsReportsActionTypes.ASYNC_GET_COACHING_EVALUATIONS_REPORTS,
});

export const generateCoachingEvaluationssReport = () => ({
    type: CoachingEvaluationsReportsActionTypes.ASYNC_GENERATE_COACHING_EVALUATIONS_REPORT,
});

export const changeLoading = (value: boolean) => ({
    type: CoachingEvaluationsReportsActionTypes.CHANGE_LOADING,
    payload: value,
});

export const changeReports = (reports: IReport[]) => ({
    type: CoachingEvaluationsReportsActionTypes.CHANGE_COACHING_EVALUATIONS_REPORTS,
    payload: reports,
});

export const changeLoadingGenerate = (value: boolean) => ({
    type: CoachingEvaluationsReportsActionTypes.CHANGE_LOADING_GENERATE,
    payload: value,
});

export const reportsPolling = () => ({
    type: CoachingEvaluationsReportsActionTypes.POLLING_FOR_STATUS,
});

export const deleteReport = (reportId: string) => ({
    type: CoachingEvaluationsReportsActionTypes.ASYNC_DELETE_REPORT,
    payload: reportId,
});

export const changeParams = (params: {}) => ({
    type: CoachingEvaluationsReportsActionTypes.CHANGE_PARAMS,
    payload: params,
});

export const changeSetOpenModal = (openModal: boolean) => ({
    type: CoachingEvaluationsReportsActionTypes.SET_OPEN_MODAL,
    payload: openModal,
});

export default reducer;
